import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import ButtonIcon from '../../components/common/ButtonIcon';
import classNames from 'classnames';
import { testletStatusTypes } from '../../helpers/testletTypes';
import { customReviewTestletRoute, customPersonalTestletRoute } from '../../routes';
import Spinner from 'reactstrap/lib/Spinner';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const TestletFormActionButtons = ({
  isAnyCommentUnread,
  isChangesButtonFeatureEnabled,
  isSave,
  isUserAdmin,
  isUserTestletCreator,
  isUserQuestionCollaborator,
  isUserTestletCollaborator,
  redirectToPage,
  saveProgress,
  testletAction,
  testletType,
  toggleCommentsModal
}) => {
  const isTestletInProgress = testletStatusTypes.IN_PROGRESS.includes(testletType);
  const isTestletUnderReview = testletStatusTypes.UNDER_REVIEW.includes(testletType);
  const showSaveButton = isTestletInProgress || isUserAdmin;
  const showLeaveCollaborationButton = isUserTestletCollaborator || isUserQuestionCollaborator;
  const showDeleteTestletButton = isTestletInProgress && (isUserAdmin || isUserTestletCreator);
  const showApproveTestletButton = isTestletUnderReview && isUserAdmin;
  const showReviseTestletButton = isTestletUnderReview && isUserAdmin;
  const showChangesButton = false;
  const showExportButton = Boolean(isUserAdmin) && testletStatusTypes.APPROVED.includes(testletType);

  const returnUrl =
    isUserAdmin && !isUserTestletCreator ? customReviewTestletRoute.to : customPersonalTestletRoute.children[0].to;

  return (
    <div>
      {/* Form buttons and actions */}
      <div>
        {showSaveButton && (
          <Button className="mr-2" color="success" onClick={() => saveProgress()} disabled={isSave}>
            Save
            {isSave && <Spinner size="sm" color="light" className="ml-2" />}
          </Button>
        )}
        {isTestletInProgress && (
          <Button color="primary" className="mr-2" onClick={() => testletAction('submit')}>
            Submit for Review
          </Button>
        )}
        {showDeleteTestletButton && (
          <Button color="danger" className="mr-2" onClick={() => testletAction('delete')}>
            Delete
          </Button>
        )}
        {showLeaveCollaborationButton && (
          <Button color="warning" onClick={() => testletAction('leave')} className="mr-2">
            Leave
          </Button>
        )}
        {showApproveTestletButton && (
          <Button color="success" className="mr-2" onClick={() => testletAction('approve')}>
            Approve Testlet
          </Button>
        )}
        {showReviseTestletButton && (
          <Button color="warning" className="mr-2" onClick={() => testletAction('revise')}>
            Revise Testlet
          </Button>
        )}
        {showChangesButton && (
          <ButtonIcon
            color="secondary"
            icon="comments"
            transform="shrink-2"
            onClick={() => toggleCommentsModal()}
            className={classNames('d-inline-block mr-2', {
              'notification-indicator notification-indicator-danger': isAnyCommentUnread
            })}
          >
            Changes
          </ButtonIcon>
        )}
        {showExportButton && (
          <Button color="success" className="mr-2" onClick={() => testletAction('export')}>
            <FontAwesomeIcon icon="file-pdf" className="d-inline mr-2 text-white" />
            Export
          </Button>
        )}
        <Button color="secondary" onClick={() => redirectToPage(returnUrl)}>
          Close
        </Button>
      </div>
    </div>
  );
};

TestletFormActionButtons.propTypes = {
  isAnyCommentUnread: PropTypes.bool,
  isChangesButtonFeatureEnabled: PropTypes.bool,
  isSave: PropTypes.bool,
  isUserAdmin: PropTypes.bool,
  isUserTestletCreator: PropTypes.bool,
  isUserQuestionCollaborator: PropTypes.bool,
  isUserTestletCollaborator: PropTypes.bool,
  redirectToPage: PropTypes.func,
  saveProgress: PropTypes.func,
  testletAction: PropTypes.func,
  testletType: PropTypes.string,
  toggleCommentsModal: PropTypes.func
};

export default TestletFormActionButtons;
