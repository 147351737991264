import * as firebase from 'firebase/app';
import 'firebase/firestore';
import { BaseRepository } from '../BaseRepository';
import {
  collectionName,
  contentStatusField,
  contentTypeField,
  createdByIdField,
  isDeletedField,
  updatedOnField
} from './constants';

export class ContentRepository extends BaseRepository {
  #firestore;
  #firestoreCollection;

  constructor() {
    super(collectionName);
    this.#firestore = firebase.firestore();
    this.#firestoreCollection = this.#firestore.collection(collectionName);
  }

  async getExistingContentCreatedByUserHavingTypeAndStatus(
    userId,
    contentType,
    contentStatus,
    isLatestVersion,
    startAfter,
    limit,
    orderBy
  ) {
    try {
      let query = this.#firestoreCollection
        .where(createdByIdField, '==', userId)
        .where(isDeletedField, '==', false)
        .where(contentTypeField, '==', contentType)
        .where(contentStatusField, 'in', contentStatus)
        .where('is_latest_version', '==', isLatestVersion)
        .where('is_flagged', '==', false);
      if (orderBy) {
        query = query.orderBy(updatedOnField, 'desc');
      }
      const docs = await this.executeQuery(query, limit, startAfter);
      return docs;
    } catch (_error) {
      return [];
    }
  }
}
