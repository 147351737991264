import * as firebase from 'firebase/app';
import 'firebase/firestore';
import '../Firebase/FirebaseService';

// Get a reference to the firebase services service
const database = firebase.firestore();
const development = database.collection('development');
const togglesDoc = 'feature_toggles';
const courseDoc = 'course_list';

const mergeAndUpdateCourseList = (oldCourseList, newCourseList) => {
  if (!oldCourseList) {
    return newCourseList;
  }

  const newData = {};
  /* 
    courseList:
      curriculum:
        courses: { ... }
        curriculumOrder:
  */
  // mark each old course as is_latest = false and add to newData
  for (let curriculumKey in oldCourseList) {
    const curriculum = oldCourseList[curriculumKey]['courses']
      ? oldCourseList[curriculumKey]['courses']
      : oldCourseList[curriculumKey];
    newData[curriculumKey] = { courses: {}, curriculumOrder: -1 };
    for (let course in curriculum) {
      curriculum[course]['is_latest'] = false;
      newData[curriculumKey]['courses'][course] = curriculum[course];
    }
  }
  // mark each new course as is_latest = true and add to newData
  for (let curriculumKey in newCourseList) {
    const curriculum = newCourseList[curriculumKey]['courses'];
    if (!newData[curriculumKey]) {
      newData[curriculumKey] = { courses: {}, curriculumOrder: -1 };
    }
    newData[curriculumKey]['curriculumOrder'] = newCourseList[curriculumKey]['curriculumOrder'];
    for (let course in curriculum) {
      newData[curriculumKey]['courses'][course] = curriculum[course];
    }
  }

  return newData;
};

class FeatureService {
  toggleList = null;
  unsubscribe = null;

  async getCourseList() {
    try {
      const res = await development.doc(courseDoc).get();
      if (res.exists) {
        return res.data();
      }
    } catch (e) {
      console.error(e);
    }
    return null;
  }

  async getListOfToggles(end, callback) {
    this.unsubscribe && this.unsubscribe();
    this.unsubscribe = development.doc(togglesDoc).onSnapshot(
      doc => {
        if (doc.exists) {
          callback(doc.data());
        } else {
          callback(null);
        }
      },
      function() {}
    );

    end && this.unsubscribe && this.unsubscribe();
  }

  async uploadCourseList(data, callback) {
    // use firebase transaction to fetch course list, process it and then update/set it
    const sfDocRef = development.doc(courseDoc);
    try {
      database
        .runTransaction(async transaction => {
          const sfDoc = await transaction.get(sfDocRef);
          if (!sfDoc.exists) {
            await development.doc(courseDoc).set(data);
            callback(true);
          } else {
            const currentCourseList = sfDoc.data();
            const newUpdatedCourseList = mergeAndUpdateCourseList(currentCourseList, data);

            transaction.update(sfDocRef, newUpdatedCourseList);
          }
        })
        .then(() => {
          callback(true);
          // because we have to return true
          return true;
        })
        .catch(err => {
          console.error(err);
          callback(false);
        });
    } catch (e) {
      console.error(e);
      callback(false);
    }
  }
}

export default FeatureService;
