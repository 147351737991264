const chatOptions = {
  comments: {
    isShowChatContent: true,
    isShowChatSidebar: false
  },
  default: {
    isShowChatContent: true,
    isShowChatSidebar: true
  }
};

export default chatOptions;
