import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { accessTypeLabels } from '../../data/user/accessTypes';
import { organizationName } from '../../helpers/constants';

const UploadWhitelistConfirmationMessage = ({
  entryAlreadyInWhitelist,
  entryMissingFromOrgList,
  entryPresentInOrgList
}) => {
  const totalEntries = entryAlreadyInWhitelist.length + entryMissingFromOrgList.length + entryPresentInOrgList.length;
  return (
    <Fragment>
      <div>
        <h6 className="mb-0 p-2 fs-0">
          You are attempting to add {totalEntries} email(s) to the NYU INBDE whitelist.
          <br />
        </h6>
      </div>
      {Boolean(entryAlreadyInWhitelist.length) && (
        <div>
          <h6 className="mb-0 p-2 fs-0 font-weight-normal">
            The following {entryAlreadyInWhitelist.length} email(s) you submitted <strong>are already present</strong>{' '}
            in the whitelist:
            <br />
            <ul className="mb-0">
              {entryAlreadyInWhitelist.map(entry => {
                const { email, access_type } = entry;
                return (
                  <li key={email} className="p-1">
                    {email} - {accessTypeLabels[access_type]}
                  </li>
                );
              })}
            </ul>
          </h6>
        </div>
      )}
      {Boolean(entryMissingFromOrgList.length) && (
        <div>
          <h6 className="mb-0 p-2 fs-0 font-weight-normal">
            The following {entryMissingFromOrgList.length} email(s) you submitted <strong>are not present</strong> in
            the {organizationName} faculty list and will be added with the corresponding access:
            <br />
            <ul className="mb-0">
              {entryMissingFromOrgList.map(entry => {
                const { email, access_type } = entry;
                return (
                  <li key={email} className="p-1">
                    {email} - {accessTypeLabels[access_type]}
                  </li>
                );
              })}
            </ul>
          </h6>
        </div>
      )}
      {Boolean(entryPresentInOrgList.length) && (
        <div>
          <h6 className="mb-0 p-2 fs-0 font-weight-normal">
            The following {entryPresentInOrgList.length} email(s) you submitted <strong>are present</strong> in the{' '}
            {organizationName} faculty list and will be added with the corresponding access:
            <br />
            <ul className="mb-0">
              {entryPresentInOrgList.map(entry => {
                const { email, access_type, full_name } = entry;
                return (
                  <li key={email} className="p-1">
                    {full_name} ({email}) - {accessTypeLabels[access_type]}
                  </li>
                );
              })}
            </ul>
          </h6>
        </div>
      )}
    </Fragment>
  );
};

UploadWhitelistConfirmationMessage.propTypes = {
  entryAlreadyInWhitelist: PropTypes.array.isRequired,
  entryMissingFromOrgList: PropTypes.array.isRequired,
  entryPresentInOrgList: PropTypes.array.isRequired
};

export default UploadWhitelistConfirmationMessage;
