import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { DropdownItem, DropdownMenu } from 'reactstrap';

class SearchSuggestion extends Component {
  constructor(props) {
    super(props);

    this.handleClick = this.handleClick.bind(this);
  }

  handleClick(event) {
    this.props.handleChange(event);
    this.props.handleSubmit(event);
  }

  render() {
    return (
      <div>
        <DropdownMenu className="p-0 mt-0 w-100 pre-scrollable overflow-vertical-scroll">
          <div className="bg-white rounded-soft p-3">
            {this.props.suggestions.map((suggestion, index) => {
              return (
                <DropdownItem key={index} className="p-2" onClick={this.handleClick} value={suggestion}>
                  {suggestion}
                </DropdownItem>
              );
            })}
          </div>
        </DropdownMenu>
      </div>
    );
  }
}

SearchSuggestion.propTypes = {
  suggestions: PropTypes.array,
  handleChange: PropTypes.func,
  handleSubmit: PropTypes.func
};

export default SearchSuggestion;
