import React, { useState, useContext, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Input, Form, Button, ButtonDropdown, DropdownToggle, DropdownItem, DropdownMenu } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import 'emoji-mart/css/emoji-mart.css';
import data from 'emoji-mart/data/google.json';
import { NimblePicker } from 'emoji-mart';
import AppContext, { ChatContext } from '../../../context/Context';
import { TestletContext } from '../../../Contexts';
import classNames from 'classnames';
import CollaborationService from '../../../services/Collaboration/CollaborationService';
import { v4 as uuidv4 } from 'uuid';
import { getCurrentTimeStamp } from '../../../helpers/inbdeUtils';
import { toast } from 'react-toastify';

const collaborationService = new CollaborationService();

const MessageTextArea = ({ thread }) => {
  const { isDark, isRTL } = useContext(AppContext);
  const { textAreaInitialHeight, setTextAreaInitialHeight, options } = useContext(ChatContext);
  const { user, testletId, creator_id, collaboratorIds } = useContext(TestletContext);
  const [previewEmoji, setPreviewEmoji] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [usersToEmail, setUsersToEmail] = useState([]);
  const [message, setMessage] = useState('');
  const [isRequestChange, setRequestChange] = useState(false);
  const [commentToggle, setCommentToggle] = useState(false);
  const mountedRef = useRef(null);
  const isAdmin = user.access_type === 'admin';

  const currentTab = options.tab;
  const section = options && options !== {} && options.section ? options.section : '';
  const title =
    options && options !== {} && options.title
      ? typeof options.title === 'string'
        ? options.title
        : options.title.title
      : '';

  useEffect(() => {
    mountedRef.current = true;

    if (currentTab === 'changes') {
      setRequestChange(true);
    } else {
      setRequestChange(false);
    }

    return () => {
      mountedRef.current = false;
    };
  }, [currentTab]);

  useEffect(() => {
    mountedRef.current = true;

    const collaborators = [...collaboratorIds, creator_id];
    setUsersToEmail(collaborators.filter(id => id !== user.uid));

    return () => {
      mountedRef.current = false;
    };
  }, [collaboratorIds, creator_id, user.uid]);

  //Emoji box hiding Controller
  useEffect(() => {
    mountedRef.current = true;

    const handleClickOutsideEmojiBox = e => {
      if (e.target.closest('.emoji-mart')) return;
      mountedRef.current && setPreviewEmoji(false);
    };

    // || e.target.closest('.textarea')
    if (previewEmoji) {
      document.addEventListener('click', handleClickOutsideEmojiBox, false);
    } else {
      document.removeEventListener('click', handleClickOutsideEmojiBox, false);
    }

    return () => {
      document.removeEventListener('click', handleClickOutsideEmojiBox, false);
      mountedRef.current = false;
    };
  }, [previewEmoji]);

  useEffect(() => {
    mountedRef.current = true;

    //TextBox and message body height controlling
    let textAreaPreviousHeight = textAreaInitialHeight;
    const autoExpand = function(field) {
      // Reset field height
      field.style.height = '2rem';

      // Calculate the height
      const textAreaCurrentHeight = field.scrollHeight;

      if (textAreaCurrentHeight <= 160) {
        if (textAreaPreviousHeight !== textAreaCurrentHeight && mountedRef.current) {
          document.getElementsByClassName('card-chat-pane')[0].style.height = `calc(100% - ${textAreaCurrentHeight}px)`;

          setTextAreaInitialHeight((textAreaPreviousHeight = textAreaCurrentHeight));
        }
      }

      field.style.height = textAreaCurrentHeight + 'px';
    };
    document.addEventListener(
      'input',
      function(event) {
        if (event.target.className.includes('chat-textarea')) autoExpand(event.target);
      },
      false
    );

    return () => (mountedRef.current = false);
  }, [textAreaInitialHeight, setTextAreaInitialHeight]);

  const addEmoji = e => {
    let emoji = e.native;
    mountedRef.current && setMessage(message + emoji);
  };

  const changeUserMode = () => {
    setRequestChange(!isRequestChange);
  };

  const handleSubmit = e => {
    e.preventDefault();

    if (message) {
      submitMessage(message);
    }

    if (mountedRef.current) {
      setMessage('');

      document.getElementsByClassName('chat-textarea')[0].style.height = '2rem';
      document.getElementsByClassName('card-chat-pane')[0].style.height = `calc(100% - 2.563rem)`;
    }
  };

  const submitMessage = async comment => {
    mountedRef.current && setIsProcessing(true);
    const { uid, email, displayName, photoURL } = user;
    const message = comment.trim();
    let isCommentCreated;

    if (isRequestChange) {
      collaborationService.rejectTestletComment(testletId, user, message);
      isCommentCreated = true;
    } else {
      const commentId = uuidv4();
      const createdByDetails = { uid, email, displayName, photoURL };
      const commentDetails = {
        message,
        attachments: [],
        section,
        sectionTitle: title,
        createdAt: getCurrentTimeStamp()
      };

      isCommentCreated = await collaborationService.createComment(
        testletId,
        commentId,
        createdByDetails,
        commentDetails,
        usersToEmail
      );
    }

    if (isCommentCreated) {
      mountedRef.current && setIsProcessing(false);
    } else {
      toast.error('Could not add comment at the moment. Please check your internet connection');
    }
  };

  const toggleCommentDropdown = () => {
    setCommentToggle(!commentToggle);
  };

  return (
    <Form
      className="chat-editor-area bg-white d-flex align-items-center"
      onSubmit={handleSubmit}
      style={{ height: 45 }}
    >
      <Input
        className={classNames(`chat-textarea border-0 outline-none shadow-none resize-none textarea bg-white mt-auto`, {
          'pl-lg-11 pl-8 pr-3': isRTL,
          'pr-lg-11 pr-8 pl-3': !isRTL
        })}
        type="textarea"
        placeholder="Type your comment"
        bsSize="sm"
        value={message}
        onKeyUp={e => {
          if ((e.keyCode === 13 || e.which === 13) && !e.shiftKey) {
            message.trim() !== '' && handleSubmit(e);
          }
        }}
        onChange={({ target }) => {
          setMessage(target.value);
        }}
        style={{
          height: '2rem',
          maxHeight: '10rem'
        }}
        disabled={isProcessing}
      />
      {isAdmin && (
        <ButtonDropdown
          className="btn-send outline-none position-absolute chat-sender-type-lg-button chat-sender-type-button"
          isOpen={commentToggle}
          toggle={() => toggleCommentDropdown()}
        >
          <DropdownToggle color="transparent" size="sm" className="w-100 pl-lg-0 pr-lg-0">
            <span
              className={classNames(`d-lg-inline d-none`, {
                'text-primary': !isRequestChange,
                'text-danger': isRequestChange
              })}
            >
              {isRequestChange ? 'Request Change' : 'Comment'}
            </span>
            <FontAwesomeIcon
              className={classNames(`d-inline d-lg-none ml-1`, {
                'text-primary': !isRequestChange,
                'text-danger': isRequestChange
              })}
              icon="comment"
            />
            <FontAwesomeIcon
              className={classNames(`d-inline ml-1 mr-lg-0 mr-1`, {
                'text-primary': !isRequestChange,
                'text-danger': isRequestChange
              })}
              icon={commentToggle ? 'caret-up' : 'caret-down'}
            />
          </DropdownToggle>
          <DropdownMenu className="pt-0 pb-0">
            <DropdownItem
              onClick={() => isRequestChange && changeUserMode()}
              className={classNames(`cursor-pointer`, {
                'bg-300 cursor-default': !isRequestChange
              })}
            >
              Comment
            </DropdownItem>
            <DropdownItem
              onClick={() => !isRequestChange && changeUserMode()}
              className={classNames(`cursor-pointer`, {
                'bg-300 cursor-default': isRequestChange
              })}
            >
              Request Change
            </DropdownItem>
          </DropdownMenu>
        </ButtonDropdown>
      )}
      <FontAwesomeIcon
        icon={['far', 'laugh-beam']}
        transform="grow-5"
        className="emoji-icon"
        onClick={() => setPreviewEmoji(!previewEmoji)}
      />
      {previewEmoji && (
        <NimblePicker
          set="google"
          data={data}
          onSelect={addEmoji}
          sheetSize={20}
          style={{
            position: 'absolute',
            bottom: '100%',
            left: isRTL ? '2%' : 'auto',
            right: isRTL ? 'auto' : '2%',
            padding: 0,
            zIndex: 1,
            backgroundColor: '#f9fafd'
          }}
          theme={isDark ? 'dark' : 'light'}
          showPreview={false}
          showSkinTones={false}
        />
      )}
      <Button
        color="transparent"
        size="sm"
        className={classNames(`btn-send outline-none ml-1`, {
          'text-primary': message.length > 0
        })}
        type="submit"
      >
        Send
      </Button>
    </Form>
  );
};

MessageTextArea.propTypes = {
  thread: PropTypes.object.isRequired
};

export default MessageTextArea;
