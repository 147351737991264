import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Spinner } from 'reactstrap';

class DraftDeleteDialog extends Component {
  constructor(props) {
    super(props);

    this.confirmDeleteTestlet = this.confirmDeleteTestlet.bind(this);
    this.handleDeleteDialog = this.handleDeleteDialog.bind(this);
  }

  confirmDeleteTestlet() {
    this.props.deleteTestlet();
  }

  handleDeleteDialog() {
    this.props.deleteDialog();
  }

  render() {
    const { isLoading } = this.props;

    return (
      <Modal id="draftModalActions" isOpen={this.props.deleteModal} toggle={this.handleDeleteDialog}>
        <ModalHeader toggle={this.handleDeleteDialog}>Confirmation</ModalHeader>
        <ModalBody>Are you sure you wish to delete this testlet?</ModalBody>
        <ModalFooter>
          <Button color="danger" type="submit" onClick={this.confirmDeleteTestlet} disabled={isLoading}>
            Confirm
            {isLoading && <Spinner size="sm" color="light" className="ml-2" />}
          </Button>{' '}
          <Button color="secondary" onClick={this.handleDeleteDialog}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

DraftDeleteDialog.propTypes = {
  deleteModal: PropTypes.bool,
  deleteDialog: PropTypes.func,
  deleteTestlet: PropTypes.func,
  isLoading: PropTypes.bool
};

export default DraftDeleteDialog;
