import React, { useState, useContext, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import Scrollbar from 'react-scrollbars-custom';
import Message from './Message';
// import ThreadInfo from './ThreadInfo';
import { isIterableArray } from '../../../helpers/utils';
import { formatDate } from '../../../helpers/inbdeUtils';
import AppContext, { ChatContext } from '../../../context/Context';
import { TestletContext } from '../../../Contexts';
import ChatContentBodyIntro from './ChatContentBodyIntro';

const getUserDetails = (userId, userDetails) => {
  const user = userDetails.filter(user => user.id === userId);
  if (isIterableArray(user)) {
    return user[0];
  } else return null;
};

const getListOfUsers = (users, userDetails) => {
  let usersString = '';
  for (let i = 0; i < users.length - 1; i += 1) {
    const user = getUserDetails(users[i], userDetails);
    usersString += user ? user.name.split(' ')[0] + (i === users.length - 2 ? ' and ' : ', ') : '';
  }
  const lastUser = getUserDetails(users[users.length - 1], userDetails);

  usersString += lastUser ? lastUser.name.split(' ')[0] : 'None';

  return usersString;
};

const updateSeenByList = (seenList, createdBy, users, userDetails) => {
  const eligibleUsers = users.filter(id => id !== createdBy);
  return seenList.length === eligibleUsers.length
    ? 'Everyone'
    : seenList.length === 0
    ? 'None'
    : getListOfUsers(seenList, userDetails);
};

const ChatContentBody = ({ thread, isOpenThreadInfo }) => {
  let lastDate = null;
  const { isRTL } = useContext(AppContext);
  const { messages, getUser, textAreaInitialHeight, options, users: userDetails } = useContext(ChatContext);
  const { collaboratorIds, creator_id } = useContext(TestletContext);
  const testletUsers = [...collaboratorIds, creator_id];
  const [scrollHeight, setScrollHeight] = useState(0);
  const user = options.params === 'comments' ? null : getUser(thread);
  const mountedRef = useRef(null);

  useEffect(() => {
    mountedRef.current = true;
    setTimeout(() => {
      mountedRef.current &&
        setScrollHeight(document.getElementsByClassName('chat-content-scroll-area')[0].scrollHeight);
    }, 500);

    return () => (mountedRef.current = false);
  }, [textAreaInitialHeight]);

  return (
    <div className="chat-content-body" style={{ display: 'inherit' }}>
      {/* <ThreadInfo thread={thread} isOpenThreadInfo={isOpenThreadInfo} /> */}
      <Scrollbar
        style={{
          height: '100%',
          minWidth: '75px',
          display: 'block'
        }}
        rtl={isRTL}
        scrollTop={scrollHeight}
        noScrollX
        trackYProps={{
          renderer(props) {
            const { elementRef, ...restProps } = props;
            return <span {...restProps} ref={elementRef} className="TrackY" />;
          }
        }}
      >
        <div className="chat-content-scroll-area pb-2">
          {user && <ChatContentBodyIntro user={user} isGroup={false} />}

          {isIterableArray(messages) &&
            messages.map(
              ({ isUpdate, comment: message, created_at, created_by_id, id, seen_list, created_by_name, isChange }) => {
                const commentCreatorArray =
                  isUpdate || isChange
                    ? [{ id: created_by_id, name: created_by_name }]
                    : isIterableArray(userDetails)
                    ? userDetails.filter(user => user.id === created_by_id)
                    : [];
                const commentCreator = isIterableArray(commentCreatorArray) ? commentCreatorArray[0] : null;
                const time = formatDate(new Date(created_at));
                const seenBy = updateSeenByList(seen_list, created_by_id, testletUsers, userDetails);

                return (
                  <div key={id}>
                    {lastDate !== time.date && (
                      <div className="d-flex fs--2">
                        <p
                          className="mb-0 ml-auto mr-auto text-white font-weight-bold bg-500 w-fit-content pr-4 pl-4 rounded fs--1"
                          style={{ lineHeight: '1.5rem' }}
                        >
                          {`${time.date}`}
                        </p>
                      </div>
                    )}
                    {(() => {
                      lastDate = time.date;
                    })()}
                    <Message
                      message={message}
                      sender={commentCreator}
                      time={time}
                      isGroup={false}
                      seenBy={seenBy}
                      isUpdate={!!isUpdate}
                      isChange={!!isChange}
                    />
                  </div>
                );
              }
            )}
        </div>
      </Scrollbar>
    </div>
  );
};

ChatContentBody.propTypes = {
  thread: PropTypes.object.isRequired,
  isOpenThreadInfo: PropTypes.bool
};
ChatContentBody.defaultProps = {
  isOpenThreadInfo: false
};
export default ChatContentBody;
