import React from 'react'
import PropTypes from 'prop-types';

class User extends React.Component {}

User.propTypes = {
  id: PropTypes.string.isRequired,
  full_name: PropTypes.string.isRequired,
  given_name: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  image_url: PropTypes.string.isRequired,
  access_type: PropTypes.oneOf(['faculty', 'admin']).isRequired,
  is_active: PropTypes.string.isRequired,
  created_on: PropTypes.number.isRequired,
  deleted_on: PropTypes.number,
  deactivated_on: PropTypes.number,
  last_login: PropTypes.number,
  updated_on: PropTypes.number,
  isLoggedIn: PropTypes.bool,
  user_device: PropTypes.string,
  google_id_token: PropTypes.string
};

export default User;
