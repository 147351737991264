import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Redirect, Route, Switch } from 'react-router-dom';
import TestletListTile from '../widgets/TestletListTile';
import DraftTestletListTile from '../widgets/DraftTestletListTile';
import StatusTabs from '../components/review/StatusTabs';
import testletStatuses from '../data/mock/personal';
import TestletService from '../services/Testlet/TestletService';
import { UserContext } from '../../src/Contexts';
import TestletTypes from '../data/testlet/my_testlet_types';
import { isIterableArray, getObjectArraySortedByProperty } from '../helpers/utils';
import { testletTypes } from '../helpers/testletTypes';
import TestletTabPage from '../components/testlet/TestletTabPage.js';
import { concat } from 'lodash';
import { contentTypes } from '../data/content/contentTypes';
import { ViewContentSummaryCard } from '../components/content/content';
import { customContentRoutes, personalContentPath } from '../routes';
import { ContentService } from '../services/Content/content';
import { toast } from 'react-toastify';
import { isUserAccessOfTypeAdmin } from '../helpers/inbdeUtils';

class PersonalTestletPage extends Component {
  _isMounted = false;
  contentService = new ContentService();
  testletService = new TestletService();
  approveStatus = testletTypes.OPEN_FOR_COLLABORATION;
  testletsPerPage = 5;

  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      statuses: testletStatuses,
      selectedTab: testletStatuses[0],
      statusCount: [],
      // cache testlets in page
      testlets: {
        DRAFTS: {
          PERSONAL: null,
          COLLAB: null,
          OPENED_COLLAB: null,
          testlets: []
        },
        PENDING: {
          PERSONAL: null,
          COLLAB: null,
          OPENED_COLLAB: null,
          testlets: []
        },
        COLLAB_REVIEW: {
          PERSONAL: null,
          COLLAB: null,
          OPENED_COLLAB: null,
          testlets: []
        },
        UNPUBLISHED: {
          PERSONAL: null,
          COLLAB: null,
          OPENED_COLLAB: null,
          testlets: []
        },
        PUBLISHED: {
          PERSONAL: null,
          COLLAB: null,
          OPENED_COLLAB: null,
          testlets: []
        }
      }
    };

    this.changeTab = this.changeTab.bind(this);
    this.flagTestlet = this.flagTestlet.bind(this);
    this.getUserTestlets = this.getUserTestlets.bind(this);
    this.rejectTestlet = this.rejectTestlet.bind(this);
    this.updateContent = this.updateContent.bind(this);
    this.unFlagTestlet = this.unFlagTestlet.bind(this);
    this.refreshPage = this.refreshPage.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;
    const currentPath = this.props.location.pathname;
    const currentLink = testletStatuses.filter(status => status.linkTo === currentPath);
    const currentTab = Boolean(currentLink.length) ? currentLink[0] : testletStatuses[0];
    this.setState({ selectedTab: currentTab });
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  changeTab(newTab) {
    if (this._isMounted) {
      this.setState({ selectedTab: newTab });
    }
  }

  async flagTestlet(id) {
    const isTestletFlagged = await this.testletFlagMechanism(id, true);
    return isTestletFlagged;
  }

  getTesletsToDisplay(testlets, cachedTestlets, type) {
    const requestedTestlets = [];

    const { selectedTab } = this.state;
    const { value } = selectedTab;
    const { user } = this.context;
    const { uid: userId, access_type } = user;
    const isUserAdmin = isUserAccessOfTypeAdmin(access_type);
    const draftsStatus = TestletTypes.DRAFTS.types;

    testlets.forEach(testlet => {
      const { content_type, created_by, id: testletId, testlet_type } = testlet;
      const isCollab = created_by !== userId;

      let content;
      if (content_type === contentTypes.question.type) {
        content = (
          <ViewContentSummaryCard
            key={testletId}
            contentData={testlet}
            contentId={testletId}
            isReview={false}
            isUserAdmin={isUserAdmin}
            handleContentAction={this.updateContent}
            isCollaboration={isCollab}
            pageTitle={'personal'}
            userId={userId}
          />
        );
      } else {
        if (draftsStatus.includes(testlet_type)) {
          content = (
            <DraftTestletListTile
              key={testletId}
              testlet={testlet}
              refreshPage={this.refreshPage}
              isCollab={isCollab}
              updateContent={this.updateContent}
            />
          );
        } else {
          content = (
            <TestletListTile
              key={testletId}
              testlet={testlet}
              statusString={value}
              review={false}
              flagTestlet={this.flagTestlet}
              unFlagTestlet={this.unFlagTestlet}
              isCollab={isCollab}
              refreshPage={this.refreshPage}
              rejectTestlet={this.rejectTestlet}
              updateContent={this.updateContent}
            />
          );
        }
      }

      requestedTestlets.push(content);
    });

    const updatedTestlets = concat(cachedTestlets[type].testlets, requestedTestlets);

    // cache the newly fetched testlets locally
    cachedTestlets[type].testlets = updatedTestlets;
    this._isMounted && this.setState({ testlets: cachedTestlets });

    return updatedTestlets;
  }

  async getUserTestlets(testletType, isFirstFetch) {
    const { testlets: cachedTestlets } = this.state;

    // if user is landing on a tab, check if testlets already fetched for it and return them
    if (isFirstFetch && isIterableArray(cachedTestlets[testletType.key].testlets)) {
      return cachedTestlets[testletType.key].testlets;
    }

    this._isMounted && this.setState({ isLoading: true });

    const { user } = this.context;
    const { uid: userId } = user;

    // Testlet types does not include 2C
    const isFetchCollaborationTestlets = testletType.key !== TestletTypes.COLLAB_REVIEW.key;

    const userTestlets = await this.testletService.getUserTestletsOfType(
      userId,
      testletType.types,
      cachedTestlets[testletType.key].PERSONAL,
      this.testletsPerPage
    );
    const userCollabTestlets = await this.testletService.getUserCollaborationTestletsOfType(
      userId,
      testletType.types,
      cachedTestlets[testletType.key].COLLAB,
      this.testletsPerPage
    );
    // don't fetch opened for collaboration if fetching collab testlets for type 2C
    const openedForCollabByUser = isFetchCollaborationTestlets
      ? await this.testletService.getUserOpenedForCollaborationTestletsOfType(
          userId,
          testletType.types,
          cachedTestlets[testletType.key].OPENED_COLLAB,
          this.testletsPerPage
        )
      : [];
    const testlets = [];

    for (let i = 0; i < userTestlets.length; i += 1) {
      const doc = userTestlets[i];
      const id = doc.id;
      let data = doc.data();
      data.id = id;

      testlets.push(data);

      // save the last document fetched for startAfter() query
      if (i === userTestlets.length - 1) {
        cachedTestlets[testletType.key].PERSONAL = doc;
      }
    }

    for (let i = 0; i < userCollabTestlets.length; i += 1) {
      const doc = userCollabTestlets[i];
      const id = doc.id;
      let data = doc.data();
      data.id = id;

      testlets.push(data);

      // save the last document fetched for startAfter() query
      if (i === userCollabTestlets.length - 1) {
        cachedTestlets[testletType.key].COLLAB = doc;
      }
    }

    for (let i = 0; i < openedForCollabByUser.length; i += 1) {
      const doc = openedForCollabByUser[i];
      const id = doc.id;
      let data = doc.data();
      data.id = id;

      testlets.push(data);

      // save the last document fetched for startAfter() query
      if (i === openedForCollabByUser.length - 1) {
        cachedTestlets[testletType.key].OPENED_COLLAB = doc;
      }
    }

    this._isMounted && this.setState({ isLoading: false });

    // return null as no more testlets left in db to fetch
    if (!isIterableArray(testlets)) {
      return null;
    }

    const sortedTestlets = getObjectArraySortedByProperty(testlets, 'updated_on');
    return this.getTesletsToDisplay(sortedTestlets, cachedTestlets, testletType.key);
  }

  async testletFlagMechanism(id, flag) {
    const isTestletMarked = await this.testletService.markTestletAsFlagged(id, flag);
    return isTestletMarked;
  }

  refreshPage() {
    // clear cache and redirect to home
    const { history } = this.props;
    this._isMounted &&
      this.setState(
        {
          selectedTab: testletStatuses[0],
          testlets: {
            DRAFTS: {
              PERSONAL: null,
              COLLAB: null,
              OPENED_COLLAB: null,
              testlets: []
            },
            PENDING: {
              PERSONAL: null,
              COLLAB: null,
              OPENED_COLLAB: null,
              testlets: []
            },
            COLLAB_REVIEW: {
              PERSONAL: null,
              COLLAB: null,
              OPENED_COLLAB: null,
              testlets: []
            },
            UNPUBLISHED: {
              PERSONAL: null,
              COLLAB: null,
              OPENED_COLLAB: null,
              testlets: []
            },
            PUBLISHED: {
              PERSONAL: null,
              COLLAB: null,
              OPENED_COLLAB: null,
              testlets: []
            }
          }
        },
        () => {
          history.push(personalContentPath);
        }
      );
  }

  async rejectTestlet(testlet, comment) {
    const { id } = testlet;

    const isTestletRejected = await this.testletService.changeTestletStatus(testlet, '2A');

    if (isTestletRejected) {
      const { user } = this.context;
      const { uid: userId } = user;

      this.loggingService && this.loggingService.testletRevisedByAdmin(userId, id, comment);
    }

    return isTestletRejected;
  }

  async unFlagTestlet(id) {
    const isTestletUnflagged = await this.testletFlagMechanism(id, false);
    return isTestletUnflagged;
  }

  async updateContent(contentId, contentAction, contentData) {
    const { toggles, user } = this.context;
    const { history } = this.props;

    try {
      let action = '';
      let isSuccess = false;
      let isRedirect = false;
      let redirectUrl = '/';
      const { content_type } = contentData;

      if (contentAction === 'delete') {
        isSuccess = await this.contentService.deleteContent(contentId);
        action = 'deleted';
      }

      if (contentAction === 'submit') {
        isSuccess = await this.contentService.submitContent(contentId, contentData, user);
        action = 'submitted';
      }

      if (contentAction === 'copy') {
        const newContentId = await this.contentService.copyContent(contentData, user);
        if (newContentId) {
          isSuccess = true;
          redirectUrl = customContentRoutes[content_type].editPath.replace('{contentId}', newContentId);
          isRedirect = true;
        }
        action = 'made a copy of';
      }

      if (contentAction === 'export') {
        isSuccess = await this.contentService.exportContent(contentId, contentData, user, toggles);
        action = 'exported';
        isRedirect = true;
        redirectUrl = '#';
      }

      if (isSuccess) {
        const contentType = content_type || 'testlet';
        toast.success(`Successfully ${action} the ${contentType}`);
        !isRedirect && this.refreshPage();
        isRedirect && history.push(redirectUrl);
      } else {
        throw new Error(`there was an error`);
      }

      return isSuccess;
    } catch (error) {
      let toastMessage = 'This action cannot be performed at the moment';

      if (error.message) {
        toastMessage += ' because ' + error.message;
      }

      toast.error(toastMessage);
    }

    return;
  }

  render() {
    const { isLoading, statusCount, statuses, selectedTab } = this.state;
    return (
      <Fragment>
        <div className="min-vh-75 min-vh-lg-100">
          <div className="mb-lg-4 mb-2 border-bottom border-500">
            <div className="pl-lg-2">
              <div className="pr-2 pt-2 pb-2">
                <div className="title personal-page-title pr-2 pt-2 pb-2">
                  <h3>My Content</h3>
                </div>
              </div>

              <div>
                <StatusTabs
                  isLoading={isLoading}
                  stasuses={statuses}
                  statusCount={statusCount}
                  selectedTab={selectedTab}
                  changeTab={this.changeTab}
                />
              </div>
            </div>
          </div>
          <div className="ml-lg-2">
            <Switch>
              <Route
                path={TestletTypes.DRAFTS.linkTo}
                exact
                render={props => (
                  <TestletTabPage
                    {...props}
                    fetchTestlets={this.getUserTestlets}
                    isLoading={isLoading}
                    testletType={TestletTypes.DRAFTS}
                    testletsPerPage={this.testletsPerPage}
                  />
                )}
              />
              <Route
                path={TestletTypes.PENDING.linkTo}
                exact
                render={props => (
                  <TestletTabPage
                    {...props}
                    fetchTestlets={this.getUserTestlets}
                    isLoading={isLoading}
                    testletType={TestletTypes.PENDING}
                    testletsPerPage={this.testletsPerPage}
                  />
                )}
              />
              <Route
                path={TestletTypes.COLLAB_REVIEW.linkTo}
                exact
                render={props => (
                  <TestletTabPage
                    {...props}
                    fetchTestlets={this.getUserTestlets}
                    isLoading={isLoading}
                    testletType={TestletTypes.COLLAB_REVIEW}
                    testletsPerPage={this.testletsPerPage}
                  />
                )}
              />
              <Route
                path={TestletTypes.UNPUBLISHED.linkTo}
                exact
                render={props => (
                  <TestletTabPage
                    {...props}
                    fetchTestlets={this.getUserTestlets}
                    isLoading={isLoading}
                    testletType={TestletTypes.UNPUBLISHED}
                    testletsPerPage={this.testletsPerPage}
                  />
                )}
              />
              <Route
                path={TestletTypes.PUBLISHED.linkTo}
                exact
                render={props => (
                  <TestletTabPage
                    {...props}
                    fetchTestlets={this.getUserTestlets}
                    isLoading={isLoading}
                    testletType={TestletTypes.PUBLISHED}
                    testletsPerPage={this.testletsPerPage}
                  />
                )}
              />
              <Redirect to={TestletTypes.DRAFTS.linkTo} />
            </Switch>
          </div>
        </div>
      </Fragment>
    );
  }
}

PersonalTestletPage.contextType = UserContext;

PersonalTestletPage.propTypes = {
  location: PropTypes.object,
  history: PropTypes.object
};

export default PersonalTestletPage;
