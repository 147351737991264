import React from 'react';
import PropTypes from 'prop-types';
import INBDETableRowActions from '../table/INBDETableRowActions';

const PastReportsTableRowActions = ({ url, path }) => {
  const downloadReport = () => {
    const newTab = window.open(url, '_blank');
    newTab.focus();
  };

  const emailReport = () => {};

  const actions = [
    {
      key: 0,
      id: 0,
      label: 'Download',
      isMenuItem: true,
      onClickAction: () => downloadReport(),
      className: 'text-primary',
      isHide: false
    },
    {
      key: 1,
      id: 1,
      label: 'Email',
      isMenuItem: true,
      onClickAction: () => emailReport(),
      className: 'text-primary pointer-event-none d-none',
      isHide: false
    }
  ];

  return <INBDETableRowActions actionItems={actions} />;
};

PastReportsTableRowActions.propTypes = {
  path: PropTypes.string,
  url: PropTypes.string
};

export default PastReportsTableRowActions;
