function PaginationActions() {
  this.getPaginationArray = (totalSize, sizePerPage, pageNo) => {
    const noOfpages = Math.ceil(totalSize / sizePerPage);
    let minPage, maxPage;
    if (pageNo - 2 > 0) {
      if (pageNo + 2 <= noOfpages) {
        minPage = pageNo - 2;
        maxPage = pageNo + 2;
      } else {
        minPage = noOfpages - 4 > 0 ? noOfpages - 4 : 1;
        maxPage = noOfpages;
      }
    } else {
      minPage = 1;
      maxPage = Math.min(5, noOfpages);
    }
    const array = [];
    let count = minPage;
    while (count <= maxPage) {
      array.push(count);
      count += 1;
    }
    return array;
  };

  this.handleNextPage = ({ page, onPageChange }) => () => {
    onPageChange(page + 1);
  };

  this.handlePrevPage = ({ page, onPageChange }) => () => {
    onPageChange(page - 1);
  };

  this.handleLastPage = ({ onPageChange }, totalSize, sizePerPage) => () => {
    const noOfpages = Math.ceil(totalSize / sizePerPage);
    onPageChange(noOfpages);
  };

  this.handleFirstPage = ({ onPageChange }) => () => {
    onPageChange(1);
  };
}

export default PaginationActions;
