export const localhostBaseUrl = 'localhost';
export const qaBaseUrl = ['qa.inbde.dental.nyu.edu', 'qa-nyu-inbde.web.app', 'qa-nyu-inbde.firebaseapp.com'];
export const prodBaseUrl = ['inbde.dental.nyu.edu', 'nyu-inbde.firebaseapp.com', 'nyu-inbde.web.app'];
export const prodOldUrl = ['nyu-inbde.firebaseapp.com', 'nyu-inbde.web.app'];
export const qaOldUrl = ['qa-nyu-inbde.web.app', 'qa-nyu-inbde.firebaseapp.com'];
export const prodCurrentUrl = 'inbde.dental.nyu.edu';
export const qaCurrentUrl = 'qa.inbde.dental.nyu.edu';

// Faculty routes
export const searchPath = '/search';
export const personalContentPath = '/my-content';
export const testletPath = '/testlet';
export const questionPath = '/question';
// Admin routes
export const adminPath = '/admin';
export const adminAllContentPath = adminPath + '/all-content';
export const adminReviewContentPath = adminPath + '/review';
export const adminManageAccessPath = adminPath + '/access';
export const adminCoursePath = adminPath + '/courses';
export const adminReportPath = adminPath + '/reports';
// Error routes
export const notFoundError = '/errors/404';
export const appError = '/errors/500';

export const whitelistedBasePaths = [searchPath, personalContentPath, testletPath, questionPath, adminPath];
export const contentBasePath = [testletPath, questionPath];

export const customFacultyRoutes = {
  name: 'Faculty',
  children: [
    { to: '#', name: 'Create Content', icon: 'plus', key: 'create' },
    { to: searchPath, name: 'Search Content', icon: 'search' },
    { to: personalContentPath, name: 'My Content', icon: 'home' },
    {
      to:
        'https://jcnde.ada.org/-/media/project/ada-organization/ada/jcnde/files/foundation_knowledge_general_dentist.pdf',
      name: 'Resources',
      icon: 'file-pdf',
      newTab: true
    }
  ]
};

export const customAdminRoutes = {
  name: 'Admin',
  children: [
    { to: adminAllContentPath, name: 'All Content', icon: 'file-archive' },
    { to: adminReviewContentPath, name: 'Review Content', icon: 'list-alt' },
    { to: adminManageAccessPath, name: 'Manage Access', icon: 'lock' },
    { to: adminCoursePath, name: 'Courses', icon: 'book' },
    { to: adminReportPath, name: 'Reports', icon: 'file-alt' }
  ]
};

export const customSearchRoute = {
  name: '',
  children: [{ to: searchPath, name: 'Search Content', icon: 'search' }]
};

export const customContentRoutes = {
  question: {
    editPath: questionPath + '/{contentId}/edit',
    basePath: questionPath,
    viewPath: questionPath + '/{contentId}/view'
  },
  testlet: {
    editPath: testletPath + '/{contentId}/edit',
    basePath: testletPath,
    viewPath: testletPath + '/{contentId}/view'
  },
  default: {
    to: '/errors/404'
  }
};

export const customTestletEditRoute = {
  to: '/testlet/{testletId}/edit',
  name: 'Edit Testlets',
  icon: 'folder-plus'
};

export const customTestletViewRoute = {
  to: '/testlet/{testletId}/view',
  name: 'View Testlet',
  icon: ''
};

export const customCreateTestletRoute = {
  name: '',
  children: [{ to: '/testlet/create', name: 'Create Testlet', icon: 'folder-plus' }]
};

export const customPersonalTestletRoute = {
  name: '',
  children: [{ to: personalContentPath, name: 'My Content', icon: 'home' }]
};

export const customReviewTestletRoute = {
  to: '/admin/review',
  name: 'Review Testlets',
  icon: 'list-alt'
};

export const customReviewTestletSubRoutes = {
  pending: {
    to: customReviewTestletRoute.to + '/pending'
  },
  revision: {
    to: customReviewTestletRoute.to + '/under-revision'
  },
  approved: {
    to: customReviewTestletRoute.to + '/approved'
  },
  published: {
    to: customReviewTestletRoute.to + '/published'
  },
  flagged: {
    to: customReviewTestletRoute.to + '/flagged'
  }
};

export const customCourseManagementRoute = {
  to: '/admin/courses',
  name: 'Courses',
  icon: 'book'
};

export const homeRoutes = {
  name: 'Home',
  to: '/',
  exact: true,
  icon: 'chart-pie',
  children: [{ to: '/', name: 'Browse Testlets', exact: true }]
};

export const authenticationRoutes = {
  name: 'Authentication',
  to: '/authentication',
  icon: 'lock',
  children: [
    {
      to: '/authentication/basic',
      name: 'Basic',
      children: [
        { to: '/authentication/basic/login', name: 'Login' },
        { to: '/authentication/basic/logout', name: 'Logout' },
        { to: '/authentication/basic/register', name: 'Register' },
        { to: '/authentication/basic/forget-password', name: 'Forgot password' },
        { to: '/authentication/basic/password-reset', name: 'Reset password' },
        { to: '/authentication/basic/confirm-mail', name: 'Confirm mail' },
        { to: '/authentication/basic/lock-screen', name: 'Lock screen' }
      ]
    },
    {
      to: '/authentication/card',
      name: 'Card',
      children: [
        { to: '/authentication/card/login', name: 'Login' },
        { to: '/authentication/card/logout', name: 'Logout' },
        { to: '/authentication/card/register', name: 'Register' },
        { to: '/authentication/card/forget-password', name: 'Forgot password' },
        { to: '/authentication/card/password-reset', name: 'Reset password' },
        { to: '/authentication/card/confirm-mail', name: 'Confirm mail' },
        { to: '/authentication/card/lock-screen', name: 'Lock screen' }
      ]
    },
    {
      to: '/authentication/split',
      name: 'Split',
      children: [
        { to: '/authentication/split/login', name: 'Login' },
        { to: '/authentication/split/logout', name: 'Logout' },
        { to: '/authentication/split/register', name: 'Register' },
        { to: '/authentication/split/forget-password', name: 'Forgot password' },
        { to: '/authentication/split/password-reset', name: 'Reset password' },
        { to: '/authentication/split/confirm-mail', name: 'Confirm mail' },
        { to: '/authentication/split/lock-screen', name: 'Lock screen' }
      ]
    }
  ]
};

export const pageRoutes = {
  name: 'Testlet',
  to: '/testlet',
  icon: 'book',
  children: [
    { to: '/testlet/create', name: 'Create' },
    { to: '/pages/associations', name: 'Associations' },
    { to: '/pages/billing', name: 'Billing' },
    { to: '/pages/customer-details', name: 'Customer details' },
    { to: '/pages/event-detail', name: 'Event detail' },
    { to: '/pages/event-create', name: 'Event create' },
    { to: '/pages/events', name: 'Events' },
    { to: '/pages/faq', name: 'Faq' },
    { to: '/pages/invoice', name: 'Invoice' },
    { to: '/pages/invite-people', name: 'Invite people' },
    { to: '/pages/notifications', name: 'Notifications' },
    { to: '/pages/people', name: 'People' },
    { to: '/pages/pricing', name: 'Pricing' },
    { to: '/pages/pricing-alt', name: 'Pricing alt' },
    { to: '/pages/profile', name: 'Profile' },
    { to: '/pages/settings', name: 'Settings' },
    { to: '/pages/starter', name: 'Starter' },
    {
      to: '/errors',
      name: 'Errors',
      children: [{ to: '/errors/404', name: '404' }, { to: '/errors/500', name: '500' }]
    }
  ]
};

export const documentationRoutes = {
  name: 'Documentation',
  to: '/documentation',
  exact: true,
  icon: 'book'
};

export const componentRoutes = {
  name: 'Components',
  to: '/components',
  icon: 'puzzle-piece',
  children: [
    { to: '/components/alerts', name: 'Alerts' },
    { to: '/components/accordions', name: 'Accordions' },
    { to: '/components/avatar', name: 'Avatar' },
    { to: '/components/badges', name: 'Badges' },
    { to: '/components/backgrounds', name: 'Backgrounds' },
    { to: '/components/breadcrumb', name: 'Breadcrumb' },
    { to: '/components/buttons', name: 'Buttons' },
    { to: '/components/cards', name: 'Cards' },
    { to: '/components/collapses', name: 'Collapses' },
    { to: '/components/dropdowns', name: 'Dropdowns' },
    { to: '/components/forms', name: 'Forms' },
    { to: '/components/listgroups', name: 'List groups' },
    { to: '/components/modals', name: 'Modals' },
    { to: '/components/navs', name: 'Navs' },
    { to: '/components/navbars', name: 'Navbars' },
    { to: '/components/pageheaders', name: 'Page headers' },
    { to: '/components/paginations', name: 'Paginations' },
    { to: '/components/popovers', name: 'Popovers' },
    { to: '/components/progress', name: 'Progress' },
    { to: '/components/tables', name: 'Tables' },
    { to: '/components/tooltips', name: 'Tooltips' }
  ]
};

export const pluginRoutes = {
  name: 'Plugins',
  to: '/plugins',
  icon: 'plug',
  children: [
    { to: '/plugins/typed', name: 'Typed' },
    { to: '/plugins/image-lightbox', name: 'Image lightbox' },
    { to: '/plugins/google-map', name: 'Google map' },
    { to: '/plugins/chart', name: 'Chart' },
    { to: '/plugins/countup', name: 'Countup' },
    { to: '/plugins/datetime', name: 'Datetime' },
    { to: '/plugins/fontawesome', name: 'Fontawesome' },
    { to: '/plugins/echarts', name: 'Echarts' },
    { to: '/plugins/toastify', name: 'Toastify' },
    { to: '/plugins/select', name: 'Select' }
  ]
};

export const utilityRoutes = {
  name: 'Utilities',
  to: '/utilities',
  icon: ['fab', 'hotjar'],
  children: [
    { to: '/utilities/borders', name: 'Borders' },
    { to: '/utilities/clearfix', name: 'Clearfix' },
    { to: '/utilities/closeIcon', name: 'Close icon' },
    { to: '/utilities/colors', name: 'Colors' },
    { to: '/utilities/display', name: 'Display' },
    { to: '/utilities/embed', name: 'Embed' },
    { to: '/utilities/figures', name: 'Figures' },
    { to: '/utilities/flex', name: 'Flex' },
    { to: '/utilities/grid', name: 'Grid' },
    { to: '/utilities/sizing', name: 'Sizing' },
    { to: '/utilities/spacing', name: 'Spacing' },
    { to: '/utilities/stretchedLink', name: 'Stretched link' },
    { to: '/utilities/typography', name: 'Typography' },
    { to: '/utilities/verticalAlign', name: 'Vertical align' },
    { to: '/utilities/visibility', name: 'Visibility' }
  ]
};

// export default [customSearchRoute, customCreateTestletRoute, customPersonalTestletRoute, customReviewTestletRoute];
export default [customFacultyRoutes, customAdminRoutes];
