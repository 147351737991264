import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Redirect, Route, Switch } from 'react-router-dom';
import NavbarTop from '../components/navbar/NavbarTop';
import NavbarVertical from '../components/navbar/NavbarVertical';
import Footer from '../components/footer/Footer';

import SearchPage from '../pages/SearchPage';
import PersonalTestletPage from '../pages/PersonalTestletPage';
import AdminRoutes from './AdminRoutes';
import ContentRoutes from './ContentRoutes';
import { adminPath, contentBasePath, personalContentPath, searchPath } from '../routes';

const DashboardLayout = ({ location, userAccess }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return (
    <div className="container-fluid pr-xl-5 pl-xl-5">
      <NavbarVertical userAccess={userAccess} />
      <div className="content">
        <NavbarTop />
        <Switch>
          <Route path={searchPath} exact render={props => <SearchPage {...props} />} />
          <Route path={contentBasePath} render={props => <ContentRoutes {...props} userAccess={userAccess} />} />
          <Route path={personalContentPath} component={PersonalTestletPage} />
          <Route path={adminPath} render={props => <AdminRoutes {...props} userAccess={userAccess} />} />
          <Redirect to="/errors/404" />
        </Switch>
        <Footer />
      </div>
    </div>
  );
};

DashboardLayout.propTypes = { location: PropTypes.object.isRequired, userAccess: PropTypes.string };

export default DashboardLayout;
