import * as firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/analytics';
import 'firebase/auth';
import '../Firebase/FirebaseService';
import UserService from '../User/UserService';

const analytics = firebase.analytics();

class AuthenticationService {
  userService = new UserService();

  isUserEqual(googleUser, firebaseUser) {
    if (firebaseUser) {
      var providerData = firebaseUser.providerData;
      for (var i = 0; i < providerData.length; i++) {
        if (
          providerData[i].providerId === firebase.auth.GoogleAuthProvider.PROVIDER_ID &&
          providerData[i].uid === googleUser.getBasicProfile().getId()
        ) {
          // We don't need to reauth the Firebase connection.
          return true;
        }
      }
    }

    return false;
  }

  async getUserAuthenticationToken() {
    try {
      const token = await firebase.auth().currentUser.getIdToken();
      return token;
    } catch {
      return '';
    }
  }

  signInWithFirebase(response) {
    // We need to register an Observer on Firebase Auth to make sure auth is initialized.
    var unsubscribe = firebase.auth().onAuthStateChanged(firebaseUser => {
      unsubscribe();
      // Check if we are already signed-in Firebase with the correct user.
      // Build Firebase credential with the Google ID token.
      var credential = firebase.auth.GoogleAuthProvider.credential(response.credential);
      // Sign in with credential from the Google user.
      firebase
        .auth()
        .signInWithCredential(credential)
        .catch(function() {
          // Handle Errors here.
          const currentTimeStamp = Date.now();
          analytics.setUserProperties({ user: firebaseUser });
          analytics.logEvent('error', {
            class: 'AuthenticationService',
            rest: 'Error signing in with firebase',
            userId: firebaseUser.uid,
            timestamp: currentTimeStamp
          });
        });
    });
  }

  async signOutOfFirebase() {
    const firebaseUser = firebase.auth().currentUser;
    const currentTimeStamp = Date.now();
    try {
      if (firebaseUser) {
        // Sign-out successful
        const data = {
          is_logged_in: false,
          updated_on: currentTimeStamp
        };

        await this.userService.updateUserLogin(firebaseUser, data);
        await firebase.auth().signOut();
        return true;
      }
    } catch (e) {
      // An error happened.
      analytics.setUserProperties({ user: firebaseUser });
      analytics.logEvent('error', {
        class: 'AuthenticationService',
        name: e && e.name ? e.name : null,
        rest: e && e.message ? e.message : 'Error signing out of firebase',
        stackTrace: e && e.stack ? e.stack : null,
        userId: firebaseUser.uid,
        timestamp: currentTimeStamp
      });
    }
    return false;
  }
}

export default AuthenticationService;
