import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { ModalBody, Spinner } from 'reactstrap';

const CreateContentModalBody = ({ contentArray, isLoading, modalName, route, target }) => (
  <ModalBody className="max-vh-75 overflow-scroll no-scrollbar-container testlet-filter-options">
    {isLoading ? (
      <div className="d-flex min-vh-50 align-items-center justify-content-center">
        <Spinner color="primary" className="avatar-3xl" />
      </div>
    ) : (
      <div>
        <h6 className="fs-0">Are you sure you wish to create a new {modalName}?</h6>
        {contentArray.length > 0 && (
          <Fragment>
            <h6 className="fs-0">
              <strong>OR</strong>
            </h6>
            <div className="mb-2">
              <h6 className="mb-0 fs-0">
                You have {contentArray.length} {modalName}s in progress
              </h6>
              <p className="font-italic text-secondary fs--1 mb-0">Click on the {modalName} name to start editing it</p>
            </div>
            <ul className="user-draft-testlets-list mb-0 pl-0">
              {contentArray.map((draft, index) => {
                const redirectUrl = route.replace('{contentId}', draft.id);
                return (
                  <a href={redirectUrl} target={target} key={draft.id} className="d-block text-truncate mb-1">
                    {index + 1 + '.'}&emsp;{draft.title}
                  </a>
                );
              })}
            </ul>
          </Fragment>
        )}
      </div>
    )}
  </ModalBody>
);

CreateContentModalBody.propTypes = {
  contentArray: PropTypes.array.isRequired,
  isLoading: PropTypes.bool,
  modalName: PropTypes.string,
  route: PropTypes.string,
  target: PropTypes.string
};

export default CreateContentModalBody;
