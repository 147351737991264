import React from 'react';
import PropTypes from 'prop-types';
import TestletQuestion from './TestletQuestion';
import { Droppable } from 'react-beautiful-dnd';

class TestletQuestionsList extends React.Component {
  questions = [];

  constructor(props) {
    super(props);

    this.getAllQuestionsInformation = this.getAllQuestionsInformation.bind(this);
  }

  componentDidUpdate() {
    this.questions = [];
  }

  getAllQuestionsInformation(question) {
    this.questions.push(question);

    if (this.questions.length === this.props.questions.length) {
      this.props.setQuestionInformation(this.questions);
    }
  }

  render() {
    const { questions, formProperties, unreadCommentsNotification } = this.props;
    const isDeleteQuestion = questions.length > 1;

    return (
      <Droppable droppableId="question-list">
        {provided => (
          <div ref={provided.innerRef} {...provided.droppableProps}>
            {questions.map((question, index) => {
              const commentsKey = 'question-' + question.uuid;
              const isQuestionCommentsRead = !!unreadCommentsNotification[commentsKey];

              return (
                <TestletQuestion
                  key={question.uuid}
                  idx={index}
                  question={question}
                  formProperties={formProperties}
                  deleteQuestion={this.props.deleteQuestion}
                  setQuestionInformation={this.getAllQuestionsInformation}
                  isTriggerAjax={this.props.isTriggerAjax}
                  isBlocking={this.props.isBlocking}
                  isDeleteQuestion={isDeleteQuestion}
                  toggleCommentsModal={this.props.toggleCommentsModal}
                  isQuestionCommentsRead={isQuestionCommentsRead}
                  testletCreatorModel={this.props.testletCreatorModel}
                  isShowCommentsAndChangesButton={this.props.isShowCommentsAndChangesButton}
                  isCollaboratorInMultipleSections={this.props.isCollaboratorInMultipleSections}
                  isCollaboratorAtTestletLevel={this.props.isCollaboratorAtTestletLevel}
                  fetchUsersFromDB={this.props.fetchUsersFromDB}
                />
              );
            })}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    );
  }
}

TestletQuestionsList.propTypes = {
  deleteQuestion: PropTypes.func,
  questions: PropTypes.array,
  formProperties: PropTypes.object,
  setQuestionInformation: PropTypes.func,
  isBlocking: PropTypes.func,
  isTriggerAjax: PropTypes.bool,
  toggleCommentsModal: PropTypes.func,
  unreadCommentsNotification: PropTypes.object,
  testletCreatorModel: PropTypes.object,
  isShowCommentsAndChangesButton: PropTypes.bool,
  isCollaboratorInMultipleSections: PropTypes.func,
  isCollaboratorAtTestletLevel: PropTypes.func,
  fetchUsersFromDB: PropTypes.func
};

TestletQuestionsList.defaultProps = { questions: [] };

export default TestletQuestionsList;
