import React, { useState, useReducer, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { ChatContext } from '../../context/Context';
import { arrayReducer } from '../../reducers/arrayReducer';
import { isIterableArray } from '../../helpers/utils';
import { getMessageToDelete, getUpdatedMessage } from '../../helpers/inbdeUtils';

import rawThreads from './../../data/chat/threads';
import groups from './../../data/chat/groups';

const allSection = 'all';
const changeSection = 'testlet-changes';

const getSectionComments = (data, section, isCommentsEnabled) => {
  const comments = [];
  if (data[section] && data[section].comments) {
    data[section].comments.forEach(comment => {
      if (!comment['deleted_at']) {
        if (isCommentsEnabled) {
          comments.push(comment);
        } else {
          if (comment['isUpdate'] || comment['isChange']) {
            comments.push(comment);
          }
        }
      }
    });
  }
  return comments;
};

const getComments = options => {
  const { section: currentSection, tab: currentTab, data, isCommentsEnabled } = options;

  const filteredComments = [];
  let allComments = [];
  const showAll = currentSection === allSection;

  if (data) {
    if (showAll) {
      const sectionArrayComments = [];
      for (let section in data) {
        sectionArrayComments.push(getSectionComments(data, section, isCommentsEnabled));
      }
      sectionArrayComments.forEach(sectionComments => sectionComments.forEach(comment => allComments.push(comment)));
    } else {
      allComments = getSectionComments(data, currentSection);
      const sectionComments = getSectionComments(data, changeSection);
      allComments = [...allComments, ...sectionComments];
    }
  }

  allComments.sort((a, b) => a['created_at'] - b['created_at']);
  allComments.forEach(comment => {
    const isUpdate = !!comment.isUpdate;
    const isChange = !!comment.isChange;

    if (currentTab === 'comments') {
      if (!isUpdate && !isChange) filteredComments.push(comment);
    } else if (currentTab === 'changes') {
      if (isUpdate || isChange) filteredComments.push(comment);
    } else {
      return filteredComments.push(comment);
    }
  });

  return filteredComments;
};

const getTestletUsers = options => {
  return options && options.data && options.data.users ? options.data.users : [];
};

const ChatProvider = ({ children, options }) => {
  const [currentTab, setCurrentTab] = useState(options.tab);
  const [messages, messagesDispatch] = useReducer(arrayReducer, getComments(options));
  const [threads, threadsDispatch] = useReducer(arrayReducer, rawThreads);
  const [textAreaInitialHeight, setTextAreaInitialHeight] = useState(32);
  const [activeThreadId, setActiveThreadId] = useState(threads[0].id);
  const [users, usersDispatch] = useReducer(arrayReducer, getTestletUsers(options));
  const mountedRef = useRef(null);

  useEffect(() => {
    mountedRef.current = true;
    // also check for edit testlets
    const data = getComments(options);
    const isDifferentTab = options.tab !== currentTab;
    let type, newMessage, id;
    let sendDispatch = true;

    if (isDifferentTab) {
      type = 'SWITCH';
      newMessage = data;
      mountedRef.current && setCurrentTab(options.tab);
    } else {
      if (data.length > messages.length) {
        type = 'ADD';
        newMessage = data[data.length - 1];
        id = newMessage && newMessage.id;
      } else if (data.length === messages.length) {
        type = 'EDIT';
        newMessage = getUpdatedMessage(data, messages);
        id = newMessage && newMessage.id;
      } else {
        type = 'REMOVE';
        newMessage = getMessageToDelete(data, messages);
        id = newMessage && newMessage.id;

        if (!newMessage) sendDispatch = false;
      }
    }

    mountedRef.current &&
      newMessage &&
      sendDispatch &&
      messagesDispatch({
        type,
        payload: newMessage,
        id
      });

    return () => {
      mountedRef.current = false;
    };
  }, [options, messages, currentTab]);

  useEffect(() => {
    mountedRef.current = true;
    let type, newUser;
    const newUsers = getTestletUsers(options);

    if (newUsers.length > users.length) {
      type = 'ADD';
      newUser = newUsers[newUsers.length - 1];

      usersDispatch({
        type,
        payload: newUser,
        id: newUser.id
      });
    }

    return () => {
      mountedRef.current = false;
    };
  }, [options, users]);

  const getUser = thread => {
    let user = {};

    if (isIterableArray(thread.userId)) {
      const { name, members } = groups.find(({ id }) => id === thread.userId[0]);
      user = {
        name,
        avatarSrc: members.map(member => users.find(({ id }) => id === member.userId).avatarSrc)
      };
    } else {
      user = users.find(({ id }) => id === thread.userId);
    }

    return user;
  };

  const value = {
    users,
    groups,
    threads,
    getUser,
    messages,
    activeThreadId,
    setActiveThreadId,
    threadsDispatch,
    messagesDispatch,
    textAreaInitialHeight,
    setTextAreaInitialHeight,
    options
  };

  return <ChatContext.Provider value={value}>{children}</ChatContext.Provider>;
};

ChatProvider.propTypes = { children: PropTypes.node.isRequired, options: PropTypes.object.isRequired };

export default ChatProvider;
