import React, { useContext } from 'react';
import { Collapse, Navbar, NavbarToggler, Nav } from 'reactstrap';
import AppContext from '../../context/AppContext';
import { isProd } from '../../helpers/inbdeUtils';
import Logo from './Logo';
import ProfileDropdown from './ProfileDropdown';

const NavbarTop = () => {
  const { showBurgerMenu, setShowBurgerMenu } = useContext(AppContext);
  const isProdEnv = isProd();

  return (
    <Navbar
      light
      className={
        (isProdEnv ? 'navbar-glass ' : 'bg-warning ') + 'fs--1 font-weight-semi-bold row navbar-top sticky-kit'
      }
      expand
    >
      <NavbarToggler onClick={() => setShowBurgerMenu(!showBurgerMenu)} id="burgerMenu" />
      <Logo at="navbar-top" width={200} id="topLogo" />
      {!isProdEnv && <h2>QA ENVIRONMENT</h2>}
      <Collapse navbar>
        <Nav navbar className="align-items-center ml-auto">
          <ProfileDropdown />
        </Nav>
      </Collapse>
    </Navbar>
  );
};

export default NavbarTop;
