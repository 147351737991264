import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import TestletListTile from '../widgets/TestletListTile';
import SearchBox from '../components/search/SearchBox';
import { Row } from 'reactstrap';
import InlineFilters from '../components/filters/InlineFilters';
import TestletService from '../services/Testlet/TestletService';
import CollaborationService from '../services/Collaboration/CollaborationService';
import { UserContext } from '../../src/Contexts';
import { convertFilterOptionsToAlgoliaAttributes, isUserAccessOfTypeAdmin } from '../helpers/inbdeUtils';
import DisplayTestlets from '../components/testlet/DisplayTestlets';
import { concat } from 'lodash';
import { isIterableArray } from '../helpers/utils';
import ScrollDownTooltip from '../components/common/ScrollDownTooltip';
import { isEmpty } from 'lodash';
import { contentTypes } from '../data/content/contentTypes';
import { ViewContentSummaryCard } from '../components/content/content';
import { customContentRoutes, searchPath } from '../routes';
import { toast } from 'react-toastify';
import { ContentService } from '../services/Content/content';

const filterQuery =
  'is_deleted=0 AND is_flagged=0 AND (testlet_type:3 OR testlet_type:4 OR testlet_type:5) AND is_public=1 AND is_latest_version=1';
let newFilterQuery = filterQuery;
const resultsPerPage = 10;
const searchPageStart = 0;

class SearchPage extends Component {
  allowedTypes = ['3', '4', '5']; // import json enum and see if open to search
  _isMounted = false;
  testletService = new TestletService();
  collaborationService = new CollaborationService();
  contentService = new ContentService();
  searchPageLimit = 10;

  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      searching: false,
      query: '',
      searchParamsAndOptions: {},
      searchResults: null,
      testlets: [],
      hasMore: true,
      hasMoreSearch: true,
      lastTestlet: null
    };

    this.clearSearch = this.clearSearch.bind(this);
    this.filterTestlets = this.filterTestlets.bind(this);
    this.flagTestlet = this.flagTestlet.bind(this);
    this.getSearchResults = this.getSearchResults.bind(this);
    this.loadMore = this.loadMore.bind(this);
    this.refreshPage = this.refreshPage.bind(this);
    this.searchTestlets = this.searchTestlets.bind(this);
    this.unFlagTestlet = this.unFlagTestlet.bind(this);
    this.updateContent = this.updateContent.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;

    this.getTestlets();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  clearSearch(clearType) {
    const { query, searchParamsAndOptions } = this.state;
    searchParamsAndOptions['page'] = searchPageStart;
    const newState = {
      loading: true,
      query,
      searchParamsAndOptions
    };

    const searching =
      query !== '' && (searchParamsAndOptions['facetFilters'] && !isEmpty(searchParamsAndOptions['facetFilters']));
    if (clearType === 'search') {
      newState['query'] = '';
    } else {
      newFilterQuery = filterQuery;
      searchParamsAndOptions['filters'] = newFilterQuery;
      delete searchParamsAndOptions['facetFilters'];
      newState['searchParamsAndOptions'] = searchParamsAndOptions;
    }
    this._isMounted && this.setState({ ...newState });
    setTimeout(() => {
      if (searching) {
        this.getSearchResults(newState['query'], newState['searchParamsAndOptions'], true);
      } else {
        this._isMounted && this.setState({ loading: false, searching: false, searchResults: null, hasMoreSearch: true });
      }
    }, 2000);
  }

  async filterTestlets(filterOptions) {
    this._isMounted && this.setState({ loading: true, searching: true });

    const { query, searchParamsAndOptions } = this.state;
    const { filterAttributes, updatedNewFilterQuery } = convertFilterOptionsToAlgoliaAttributes(
      filterOptions,
      newFilterQuery,
      filterQuery
    );

    if (!!filterAttributes.length) {
      searchParamsAndOptions['facetFilters'] = filterAttributes;
      searchParamsAndOptions.filters = updatedNewFilterQuery;
      searchParamsAndOptions['hitsPerPage'] = resultsPerPage;
      searchParamsAndOptions['page'] = searchPageStart;

      this._isMounted && this.setState({ searchParamsAndOptions });

      this.getSearchResults(query, searchParamsAndOptions, true);
      newFilterQuery = updatedNewFilterQuery;
      newFilterQuery = filterQuery;
    } else {
      this.clearSearch();
    }
  }

  async flagTestlet(id, comment, collaborators, creatorId, testletName) {
    const { user, admins } = this.context;
    const isTestletFlagged = await this.testletFlagMechanism(id, true);

    if (isTestletFlagged) {
      this.collaborationService.rejectTestletComment(id, user, comment);
      this.collaborationService.sendAdminEmailForTestletFlagged(testletName, admins, comment, user, 'Flagged');
    }
    return isTestletFlagged;
  }

  loadMore() {
    const { hasMore, hasMoreSearch, query, searching, searchParamsAndOptions, searchResults } = this.state;

    if (!searchResults && hasMore) {
      this.getTestlets();
    } else if (searching && hasMoreSearch) {
      searchParamsAndOptions['page'] += 1;
      this._isMounted && this.setState({ searchParamsAndOptions: searchParamsAndOptions });
      this.getSearchResults(query, searchParamsAndOptions, false);
    }
  }

  async unFlagTestlet(id) {
    const isTestletUnflagged = await this.testletFlagMechanism(id, false);
    return isTestletUnflagged;
  }

  async testletFlagMechanism(id, flag) {
    const isTestletMarked = await this.testletService.markTestletAsFlagged(id, flag);
    return isTestletMarked;
  }

  async getTestlets() {
    this.setState({ loading: true });
    const { lastTestlet, testlets } = this.state;

    const listOfTestlets = await this.testletService.getPublicTestlets(lastTestlet, this.searchPageLimit);

    if (isIterableArray(listOfTestlets)) {
      if (listOfTestlets.length < this.searchPageLimit) {
        this._isMounted && this.setState({ hasMore: false });
      }

      const newTestlets = this.renderTestlets(listOfTestlets, false);
      const updatedTestlets = concat(testlets, newTestlets);

      this._isMounted && this.setState({ testlets: updatedTestlets });
    } else {
      this._isMounted && this.setState({ hasMore: false });
    }

    this._isMounted && this.setState({ loading: false });
  }

  async getSearchResults(query, options, isNewSearch) {
    let { hasMoreSearch, searchResults: records } = this.state;
    if (records === null) {
      records = [];
    }

    if (isNewSearch) {
      hasMoreSearch = true;
      records = [];
    }

    const searchResults = await this.testletService.searchForTestlets(query, options);
    if (searchResults) {
      if (searchResults.length < resultsPerPage) {
        hasMoreSearch = false;
      }

      const results = this.renderTestlets(searchResults, true);
      this._isMounted && this.setState({ searchResults: [...records, ...results], loading: false, hasMoreSearch });
    } else {
      toast.error('There was an error loading search results. Please try again later');
      this._isMounted && this.setState({ searchResults: records, loading: false, hasMoreSearch: false });
    }
  }

  refreshPage() {
    const { history } = this.props;

    this._isMounted &&
      this.setState(
        {
          query: '',
          searchParamsAndOptions: {},
          searchResults: null,
          testlets: [],
          hasMore: true,
          lastTestlet: null
        },
        () => {
          history.push(searchPath);
        }
      );
  }

  // list of JS objects
  renderTestlets(list, isSearch) {
    const testlets = [];
    const { user } = this.context;
    const { access_type, uid } = user;
    const isUserAdmin = isUserAccessOfTypeAdmin(access_type);

    for (let i = 0; i < list.length; i += 1) {
      let id, data;
      const doc = list[i];
      if (isSearch) {
        id = doc.testlet_id || doc.objectID;
        data = doc;
        doc.testlet_id && delete doc['testlet_id'];
        delete data.objectID;
        delete data._exams;
        delete data._questionCount;
        delete data._mock_exams;
      } else {
        id = doc.id;
        data = doc.data();

        if (i === list.length - 1) {
          this._isMounted && this.setState({ lastTestlet: doc });
        }
      }
      data.id = id;

      let contentViewType;
      const { content_type } = data;
      if (content_type === contentTypes.question.type) {
        contentViewType = (
          <ViewContentSummaryCard
            key={id}
            contentData={data}
            contentId={id}
            handleContentAction={this.updateContent}
            isUserAdmin={isUserAdmin}
            pageTitle={'search'}
            userId={uid}
          />
        );
      } else {
        contentViewType = (
          <TestletListTile
            key={id}
            testlet={data}
            review={false}
            flagTestlet={this.flagTestlet}
            unFlagTestlet={this.unFlagTestlet}
            refreshPage={this.refreshPage}
            updateContent={this.updateContent}
          />
        );
      }

      testlets.push(contentViewType);
    }

    return testlets;
  }

  async searchTestlets(searchRequest) {
    this._isMounted && this.setState({ loading: true, searching: true });
    const { searchParamsAndOptions } = this.state;
    const { options, query } = searchRequest;
    const { isSearchAll, params, contentTypeFilters } = options;

    const searchParams = isSearchAll ? [] : params;
    const searchFilterOptions = contentTypeFilters ? newFilterQuery + ' AND ' + contentTypeFilters : newFilterQuery;
    const newSearchParamsAndOptions = {
      restrictSearchableAttributes: searchParams,
      hitsPerPage: resultsPerPage,
      filters: searchFilterOptions,
      facetFilters: searchParamsAndOptions.facetFilters ? searchParamsAndOptions.facetFilters : [],
      page: searchPageStart
    };

    this._isMounted && this.setState({ query: query, searchParamsAndOptions: newSearchParamsAndOptions });
    this.getSearchResults(query, newSearchParamsAndOptions, true);
  }

  async updateContent(contentId, contentAction, contentData) {
    const { history } = this.props;
    const { toggles, user } = this.context;

    try {
      let action = '',
        isRedirect = false,
        isSuccess = false,
        redirectUrl = '/';
        const { content_type } = contentData;

      if (contentAction === 'copy') {
        const newContentId = await this.contentService.copyContent(contentData, user);
        if (newContentId) {
          isSuccess = true;
          redirectUrl = customContentRoutes[content_type].editPath.replace('{contentId}', newContentId);
          isRedirect = true;
        }
        action = 'copied';
      }

      if (contentAction === 'flag') {
        isSuccess = await this.contentService.flagContent(contentId, true, contentData, user);
        action = 'flagged';
      }

      if (contentAction === 'export') {
        isSuccess = await this.contentService.exportContent(contentId, contentData, user, toggles);
        action = 'exported';
        isRedirect = true;
        redirectUrl = '#';
      }

      if (isSuccess) {
        const contentType = content_type || 'testlet';
        toast.success(`Successfully ${action} the ${contentType}`);
        !isRedirect && this.refreshPage();
        isRedirect && history.push(redirectUrl);
      } else {
        throw new Error(`there was an error`);
      }

      return isSuccess;
    } catch (error) {
      let toastMessage = 'This action cannot be performed at the moment';

      if (error.message) {
        toastMessage += ' because ' + error.message;
      }

      toast.error(toastMessage);
    }

    return;
  }

  render() {
    const { hasMore, hasMoreSearch, loading, searching, searchResults, testlets } = this.state;
    const displayTestlets = searchResults ? searchResults : testlets;
    const loadMoreResults = searching ? hasMoreSearch : hasMore;

    return (
      <Fragment>
        <div className="min-vh-75 min-vh-lg-100">
          <div className="mb-lg-4 mb-2 border-bottom border-400 pb-3">
            <div className="pl-2">
              <div className="pr-2 pt-2 pb-2">
                <div className="title search-page-title pr-2 pt-2 pb-2">
                  <h3>Search Content</h3>
                </div>
              </div>

              <Row className="ml-2 mt-3 mb-1">
                <InlineFilters filterTestlets={this.filterTestlets} clearFilters={this.clearSearch} />
              </Row>
              <SearchBox searchWithKey={this.searchTestlets} clearSearch={this.clearSearch} />
            </div>
          </div>
          <DisplayTestlets
            isLoading={loading}
            reachedBottom={this.loadMore}
            testlets={displayTestlets}
            isSearch={searching}
          />
          {!loading && loadMoreResults && <ScrollDownTooltip />}
        </div>
      </Fragment>
    );
  }
}

SearchPage.contextType = UserContext;

SearchPage.propTypes = {
  history: PropTypes.object
};

export default SearchPage;
