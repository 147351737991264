import { getContentTypeOptions } from '../search/contentTypeOptions';

const filterOptions = [
  getContentTypeOptions(false),
  {
    key: 'inbde',
    filterValue: ['published_inbdeCourse.label', 'testlet_information.inbdeCourse.label', 'inbde_course.label'],
    label: 'INBDE Prep Course',
    selectedFilterLabel: 'INBDE Prep',
    options: [
      {
        id: 1,
        name: 'INBDE Prep, SP20, D1',
        semester: 'Spring',
        year: 2020,
        selected: false,
        selectedName: 'SP20, D1'
      },
      { id: 2, name: 'INBDE Prep, FA20, D2', semester: 'Fall', year: 2020, selected: false, selectedName: 'FA20, D2' },
      {
        id: 3,
        name: 'INBDE Prep, SP21, D1',
        semester: 'Spring',
        year: 2021,
        selected: false,
        selectedName: 'SP21, D1'
      },
      {
        id: 4,
        name: 'INBDE Prep, SP21, D2',
        semester: 'Spring',
        year: 2021,
        selected: false,
        selectedName: 'SP21, D2'
      },
      { id: 5, name: 'INBDE Prep, FA21, D2', semester: 'Fall', year: 2021, selected: false, selectedName: 'FA21, D2' },
      { id: 6, name: 'INBDE Prep, FA21, D3', semester: 'Fall', year: 2021, selected: false, selectedName: 'FA21, D3' },
      {
        id: 7,
        name: 'INBDE Prep, SP22, D1',
        semester: 'Spring',
        year: 2022,
        selected: false,
        selectedName: 'SP22, D1'
      },
      {
        id: 8,
        name: 'INBDE Prep, SP22, D2',
        semester: 'Spring',
        year: 2022,
        selected: false,
        selectedName: 'SP22, D2'
      },
      {
        id: 9,
        name: 'INBDE Prep, SP22, D3',
        semester: 'Spring',
        year: 2022,
        selected: false,
        selectedName: 'SP22, D3'
      },
      { id: 10, name: 'INBDE Prep, FA22, D2', semester: 'Fall', year: 2022, selected: false, selectedName: 'FA22, D2' },
      { id: 11, name: 'INBDE Prep, FA22, D3', semester: 'Fall', year: 2022, selected: false, selectedName: 'FA22, D3' },
      { id: 12, name: 'INBDE Prep, FA22, D4', semester: 'Fall', year: 2022, selected: false, selectedName: 'FA22, D4' },
      {
        id: 13,
        name: 'INBDE Prep, SP23, D1',
        semester: 'Spring',
        year: 2023,
        selected: false,
        selectedName: 'SP23, D1'
      },
      {
        id: 14,
        name: 'INBDE Prep, SP23, D2',
        semester: 'Spring',
        year: 2023,
        selected: false,
        selectedName: 'SP23, D2'
      },
      {
        id: 15,
        name: 'INBDE Prep, SP23, D3',
        semester: 'Spring',
        year: 2023,
        selected: false,
        selectedName: 'SP23, D3'
      },
      {
        id: 16,
        name: 'INBDE Prep, SP23, D4',
        semester: 'Spring',
        year: 2023,
        selected: false,
        selectedName: 'SP23, D4'
      },
      { id: 17, name: 'INBDE Prep, FA23, D2', semester: 'Fall', year: 2023, selected: false, selectedName: 'FA23, D2' },
      { id: 18, name: 'INBDE Prep, FA23, D3', semester: 'Fall', year: 2023, selected: false, selectedName: 'FA23, D3' },
      { id: 19, name: 'INBDE Prep, FA23, D4', semester: 'Fall', year: 2023, selected: false, selectedName: 'FA23, D4' },
      {
        id: 20,
        name: 'INBDE Prep, SP24, D1',
        semester: 'Spring',
        year: 2024,
        selected: false,
        selectedName: 'SP24, D1'
      },
      {
        id: 21,
        name: 'INBDE Prep, SP24, D2',
        semester: 'Spring',
        year: 2024,
        selected: false,
        selectedName: 'SP24, D2'
      },
      {
        id: 22,
        name: 'INBDE Prep, SP24, D3',
        semester: 'Spring',
        year: 2024,
        selected: false,
        selectedName: 'SP24, D3'
      },
      {
        id: 23,
        name: 'INBDE Prep, SP24, D4',
        semester: 'Spring',
        year: 2024,
        selected: false,
        selectedName: 'SP24, D4'
      },
      { id: 24, name: 'INBDE Prep, FA24, D2', semester: 'Fall', year: 2024, selected: false, selectedName: 'FA24, D2' },
      { id: 25, name: 'INBDE Prep, FA24, D3', semester: 'Fall', year: 2024, selected: false, selectedName: 'FA24, D3' },
      { id: 26, name: 'INBDE Prep, FA24, D4', semester: 'Fall', year: 2024, selected: false, selectedName: 'FA24, D4' },
      {
        id: 27,
        name: 'INBDE Prep, SP25, D1',
        semester: 'Spring',
        year: 2025,
        selected: false,
        selectedName: 'SP25, D1'
      },
      {
        id: 28,
        name: 'INBDE Prep, SP25, D2',
        semester: 'Spring',
        year: 2025,
        selected: false,
        selectedName: 'SP25, D2'
      },
      {
        id: 29,
        name: 'INBDE Prep, SP25, D3',
        semester: 'Spring',
        year: 2025,
        selected: false,
        selectedName: 'SP25, D3'
      },
      {
        id: 30,
        name: 'INBDE Prep, SP25, D4',
        semester: 'Spring',
        year: 2025,
        selected: false,
        selectedName: 'SP25, D4'
      },
      { id: 31, name: 'INBDE Prep, FA25, D2', semester: 'Fall', year: 2025, selected: false, selectedName: 'FA25, D2' },
      { id: 32, name: 'INBDE Prep, FA25, D3', semester: 'Fall', year: 2025, selected: false, selectedName: 'FA25, D3' },
      { id: 33, name: 'INBDE Prep, FA25, D4', semester: 'Fall', year: 2025, selected: false, selectedName: 'FA25, D4' },
      {
        id: 34,
        name: 'INBDE Prep, SP26, D1',
        semester: 'Spring',
        year: 2026,
        selected: false,
        selectedName: 'SP26, D1'
      },
      {
        id: 35,
        name: 'INBDE Prep, SP26, D2',
        semester: 'Spring',
        year: 2026,
        selected: false,
        selectedName: 'SP26, D2'
      },
      {
        id: 36,
        name: 'INBDE Prep, SP26, D3',
        semester: 'Spring',
        year: 2026,
        selected: false,
        selectedName: 'SP26, D3'
      },
      {
        id: 37,
        name: 'INBDE Prep, SP26, D4',
        semester: 'Spring',
        year: 2026,
        selected: false,
        selectedName: 'SP26, D4'
      },
      { id: 38, name: 'INBDE Prep, FA26, D2', semester: 'Fall', year: 2026, selected: false, selectedName: 'FA26, D2' },
      { id: 39, name: 'INBDE Prep, FA26, D3', semester: 'Fall', year: 2026, selected: false, selectedName: 'FA26, D3' },
      { id: 40, name: 'INBDE Prep, FA26, D4', semester: 'Fall', year: 2026, selected: false, selectedName: 'FA26, D4' },
      {
        id: 41,
        name: 'INBDE Prep, SP27, D1',
        semester: 'Spring',
        year: 2027,
        selected: false,
        selectedName: 'SP27, D1'
      },
      {
        id: 42,
        name: 'INBDE Prep, SP27, D2',
        semester: 'Spring',
        year: 2027,
        selected: false,
        selectedName: 'SP27, D2'
      },
      {
        id: 43,
        name: 'INBDE Prep, SP27, D3',
        semester: 'Spring',
        year: 2027,
        selected: false,
        selectedName: 'SP27, D3'
      },
      {
        id: 44,
        name: 'INBDE Prep, SP27, D4',
        semester: 'Spring',
        year: 2027,
        selected: false,
        selectedName: 'SP27, D4'
      },
      { id: 45, name: 'INBDE Prep, FA27, D2', semester: 'Fall', year: 2027, selected: false, selectedName: 'FA27, D2' },
      { id: 46, name: 'INBDE Prep, FA27, D3', semester: 'Fall', year: 2027, selected: false, selectedName: 'FA27, D3' },
      { id: 47, name: 'INBDE Prep, FA27, D4', semester: 'Fall', year: 2027, selected: false, selectedName: 'FA27, D4' }
    ]
  },
  {
    key: 'fk',
    filterValue: 'question.foundational_knowledge.label',
    label: 'Foundational Knowledge',
    selectedFilterLabel: 'FK',
    selectedOptions: [
      { id: 1, name: 'FK-1', selected: false },
      { id: 2, name: 'FK-2', selected: false },
      { id: 3, name: 'FK-3', selected: false },
      { id: 4, name: 'FK-4', selected: false },
      { id: 5, name: 'FK-5', selected: false },
      { id: 6, name: 'FK-6', selected: false },
      { id: 7, name: 'FK-7', selected: false },
      { id: 8, name: 'FK-8', selected: false },
      { id: 9, name: 'FK-9', selected: false },
      { id: 10, name: 'FK-10', selected: false }
    ],
    options: [
      {
        selectedName: 'FK-1',
        selected: false,
        id: 1,
        name: 'FK-1: Molecular, biochemical, cellular, and systems-level development, structure and function'
      },
      {
        selectedName: 'FK-2',
        selected: false,
        id: 2,
        name: 'FK-2: Physics and chemistry to explain normal biology and pathobiology '
      },
      {
        selectedName: 'FK-3',
        selected: false,
        id: 3,
        name: 'FK-3: Physics and chemistry to explain the characteristics and use of technologies and materials'
      },
      {
        selectedName: 'FK-4',
        selected: false,
        id: 4,
        name:
          'FK-4: Principles of genetic congenital and developmental diseases and conditions and their clinical features to understand patient risk '
      },
      {
        selectedName: 'FK-5',
        selected: false,
        id: 5,
        name: 'FK-5: Cellular and molecular bases of immune and non-immune host defense mechanisms '
      },
      {
        selectedName: 'FK-6',
        selected: false,
        id: 6,
        name: 'FK-6: General and disease-specific pathology to assess patient risk'
      },
      {
        selectedName: 'FK-7',
        selected: false,
        id: 7,
        name: 'FK-7: Biology of micro-organisms in physiology and pathology'
      },
      { selectedName: 'FK-8', selected: false, id: 8, name: 'FK-8: Pharmacology' },
      { selectedName: 'FK-9', selected: false, id: 9, name: 'FK-9: Behavioral sciences, ethics and jurisprudence' },
      {
        selectedName: 'FK-10',
        selected: false,
        id: 10,
        name: 'FK-10: Research methodology and analysis and informatics tools'
      }
    ]
  },
  {
    key: 'cc',
    filterValue: 'question.clinical_content.label',
    label: 'Clinical Content',
    selectedFilterLabel: 'CC',
    options: [
      { id: 0, name: 'Diagnosis and Treatment Planning', isOption: false },
      {
        selectedName: 'CC-1',
        selected: false,
        id: 1,
        name: 'CC-1: Interpret patient information and medical data to assess and manage patients'
      },
      {
        selectedName: 'CC-2',
        selected: false,
        id: 2,
        name: 'CC-2: Identify the chief complaint and understand the contributing factors.'
      },
      {
        selectedName: 'CC-3',
        selected: false,
        id: 3,
        name:
          'CC-3: Perform head and neck and intraoral examinations, interpreting and evaluating the clinical findings'
      },
      {
        selectedName: 'CC-4',
        selected: false,
        id: 4,
        name:
          'CC-4: Use clinical and epidemiological data to diagnose and establish a prognosis for dental abnormalities and pathology'
      },
      {
        selectedName: 'CC-5',
        selected: false,
        id: 5,
        name:
          'CC-5: Recognize the normal range of clinical findings and distinguish significant deviations that require monitoring, treatment, or management'
      },
      {
        selectedName: 'CC-6',
        selected: false,
        id: 6,
        name: 'CC-6: Predict the most likely diagnostic result given available patient information'
      },
      {
        selectedName: 'CC-7',
        selected: false,
        id: 7,
        name: 'CC-7: Interpret diagnostic results to inform understanding of the patient`s condition'
      },
      {
        selectedName: 'CC-8',
        selected: false,
        id: 8,
        name:
          'CC-8: Recognize the manifestations of systemic disease and how the disease and its management may affect the delivery of dental care'
      },
      {
        selectedName: 'CC-9',
        selected: false,
        id: 9,
        name:
          'CC-9: Recognize the interrelationship between oral health and systemic disease, and implement strategies for improving overall health'
      },
      {
        selectedName: 'CC-10',
        selected: false,
        id: 10,
        name: 'CC-10: Select the diagnostic tools most likely to establish or confirm the diagnosis'
      },
      {
        selectedName: 'CC-11',
        selected: false,
        id: 11,
        name:
          'CC-11: Collect information from diverse sources (patient, guardian, patient records, allied staff, and other healthcare professionals) to make informed decisions'
      },
      {
        selectedName: 'CC-12',
        selected: false,
        id: 12,
        name: 'CC-12: Formulate a comprehensive diagnosis and treatment plan for patient management'
      },
      {
        selectedName: 'CC-13',
        selected: false,
        id: 13,
        name:
          'CC 13: Discuss etiologies, treatment alternatives, and prognoses with patients so they are educated and can make informed decisions concerning the management of their care'
      },
      {
        selectedName: 'CC-14',
        selected: false,
        id: 14,
        name:
          'CC 14: Understand how patient attributes (e.g., gender, age, race, ethnicity, and special needs), social background and its influence the provision of oral health care at all stages of life'
      },
      {
        selectedName: 'CC-15',
        selected: false,
        id: 15,
        name: 'CC 15: Interact and communicate with patients using psychological, social, and behavioral principles'
      },
      {
        selectedName: 'CC-16',
        selected: false,
        id: 16,
        name: 'CC 16: Prevent, recognize and manage medical emergencies (e.g., cardiac arrest)'
      },
      {
        selectedName: 'CC-17',
        selected: false,
        id: 17,
        name: 'CC 17: Prevent, recognize and manage dental emergencies'
      },
      { id: 18, name: 'Oral Health Management', isOption: false },
      {
        selectedName: 'CC-18',
        selected: false,
        id: 19,
        name: 'CC-18: Recognize and manage acute pain, hemorrhage, trauma, and infection of the orofacial complex '
      },
      {
        selectedName: 'CC-19',
        selected: false,
        id: 20,
        name: 'CC-19: Prevent, diagnose and manage pain during treatment '
      },
      {
        selectedName: 'CC-20',
        selected: false,
        id: 21,
        name: 'CC-20: Prevent, diagnose and manage pulpal and periradicular diseases '
      },
      { selectedName: 'CC-21', selected: false, id: 22, name: 'CC-21: Prevent, diagnose and manage caries ' },
      {
        selectedName: 'CC-22',
        selected: false,
        id: 23,
        name: 'CC-22: Prevent, diagnose and manage periodontal diseases '
      },
      {
        selectedName: 'CC-23',
        selected: false,
        id: 24,
        name: 'CC-23: Prevent, diagnose and manage oral mucosal and osseous diseases '
      },
      {
        selectedName: 'CC-24',
        selected: false,
        id: 25,
        name: 'CC-24: Recognize, manage and report patient abuse and neglect '
      },
      { selectedName: 'CC-25', selected: false, id: 26, name: 'CC-25: Recognize and manage substance abuse ' },
      {
        selectedName: 'CC-26',
        selected: false,
        id: 27,
        name: 'CC-26: Select and administer or prescribe pharmacological agents in the treatment of dental patients '
      },
      {
        selectedName: 'CC-27',
        selected: false,
        id: 28,
        name:
          'CC-27: Anticipate, prevent, and manage complications arising from the use of therapeutic and pharmacological agents in patient care '
      },
      {
        selectedName: 'CC-28',
        selected: false,
        id: 29,
        name: 'CC-28: Diagnose endodontic conditions and peerform endodontic procedures '
      },
      {
        selectedName: 'CC-29',
        selected: false,
        id: 30,
        name: 'CC-29: Diagnose and manage the restorative needs of edentulous and partially edentulous patients '
      },
      {
        selectedName: 'CC-30',
        selected: false,
        id: 31,
        name:
          'CC-30: Restore tooth function, structure, and esthetics by replacing missing and defective tooth structure, while promoting soft and hard tissue health '
      },
      {
        selectedName: 'CC-31',
        selected: false,
        id: 32,
        name:
          'CC-31: Perform prosthetic restorations (fixed or removable) and implant procedures for edentulous and partially edentulous patients '
      },
      {
        selectedName: 'CC-32',
        selected: false,
        id: 33,
        name: 'CC-32: Diagnose and manage oral surgical treatment needs '
      },
      { selectedName: 'CC-33', selected: false, id: 34, name: 'CC-33: Perform oral surgical procedures ' },
      {
        selectedName: 'CC-34',
        selected: false,
        id: 35,
        name: 'CC-34: Prevent, diagnose and manage developmental or acquired occlusal problems '
      },
      {
        selectedName: 'CC-35',
        selected: false,
        id: 36,
        name: 'CC-35: Prevent, diagnose and manage temporomandibular disorders '
      },
      {
        selectedName: 'CC-36',
        selected: false,
        id: 37,
        name:
          'CC-36: Diagnose and manage patients requiring modification of oral tissues to optimize form, function and esthetics '
      },
      {
        selectedName: 'CC-37',
        selected: false,
        id: 38,
        name: 'CC-37: Evaluate outcomes of comprehensive dental care '
      },
      { selectedName: 'CC-38', selected: false, id: 39, name: 'CC-38: Manage the oral esthetic needs of patients' },
      { id: 40, name: 'Practice and Profession', isOption: false },
      {
        selectedName: 'CC-39',
        selected: false,
        id: 41,
        name: 'CC-39: Evaluate and integrate emerging trends in health care '
      },
      {
        selectedName: 'CC-40',
        selected: false,
        id: 42,
        name: 'CC-40: Evaluate social and economic trends and adapt to accommodate their impact on oral health care '
      },
      {
        selectedName: 'CC-41',
        selected: false,
        id: 43,
        name:
          'CC-41: Evaluate scientific literature and integrate new knowledge and best research outcomes with patient ids and other sources of information to make decisions about treatment '
      },
      {
        selectedName: 'CC-42',
        selected: false,
        id: 44,
        name:
          'CC-42: Practice within the general dentist`s scope of competence and consult with or refer to professional colleagues when indicated '
      },
      {
        selectedName: 'CC-43',
        selected: false,
        id: 45,
        name:
          'CC-43: Evaluate and utilize available and emerging resources (e g , laboratory and clinical resources, information technology) to facilitate patient care, practice management, and professional development '
      },
      {
        selectedName: 'CC-44',
        selected: false,
        id: 46,
        name:
          'CC-44: Conduct practice activities in a manner that manages risk and is consistent with jurisprudence and ethical requirements in dentistry and healthcare '
      },
      {
        selectedName: 'CC-45',
        selected: false,
        id: 47,
        name: 'CC-45: Recognize and respond to situations involving ethical and jurisprudence considerations '
      },
      {
        selectedName: 'CC-46',
        selected: false,
        id: 48,
        name: 'CC-46: Maintain patient records in accordance with jurisprudence and ethical requirements '
      },
      {
        selectedName: 'CC-47',
        selected: false,
        id: 49,
        name:
          'CC-47: Conduct practice related business activities and financial operations in accordance with sound business practices and jurisprudence (e g , OSHA and HIPAA) '
      },
      {
        selectedName: 'CC-48',
        selected: false,
        id: 50,
        name: 'CC-48: Develop a catastrophe preparedness plan for the dental practice '
      },
      {
        selectedName: 'CC-49',
        selected: false,
        id: 51,
        name: 'CC-49: Manage, coordinate and supervise the activity of allied dental health personnel '
      },
      {
        selectedName: 'CC-50',
        selected: false,
        id: 52,
        name: 'CC-50: Assess one`s personal level of skills and knowledge relative to dental practice '
      },
      {
        selectedName: 'CC-51',
        selected: false,
        id: 53,
        name: 'CC-51: Adhere to standard precautions for infection control for all clinical procedures '
      },
      {
        selectedName: 'CC-52',
        selected: false,
        id: 54,
        name: 'CC-52: Use prevention, intervention, and patient education strategies to maximize oral health '
      },
      {
        selectedName: 'CC-53',
        selected: false,
        id: 55,
        name:
          'CC-53: Collaborate with dental team members and other health care professionals to promote health and manage disease in communities '
      },
      {
        selectedName: 'CC-54',
        selected: false,
        id: 56,
        name:
          'CC-54: Evaluate and implement systems of oral health care management and delivery that will address the needs of patient populations served '
      },
      {
        selectedName: 'CC-55',
        selected: false,
        id: 57,
        name: 'CC-55: Apply quality assurance, assessment and improvement concepts to improve outcomes '
      },
      {
        selectedName: 'CC-56',
        selected: false,
        id: 58,
        name:
          'CC-56: Communicate case design to laboratory technicians and evaluate the resultant restoration or prosthesis'
      }
    ]
  },
  {
    key: 'systems',
    filterValue: 'question.systems_areas.label',
    label: 'Systems / Area',
    selectedFilterLabel: 'Systems/Area',
    options: [
      { id: 1, name: 'Cancer', selected: false },
      { id: 2, name: 'Cardiovascular', selected: false },
      { id: 3, name: 'Endocrine', selected: false },
      { id: 4, name: 'Gastrointestinal', selected: false },
      { id: 5, name: 'Head and Neck', selected: false },
      { id: 6, name: 'Hematological', selected: false },
      { id: 7, name: 'Hepatobilliary', selected: false },
      { id: 8, name: 'Immune/Infectious Diseases', selected: false },
      { id: 9, name: 'Integuments (skin and mucosa)', selected: false },
      { id: 10, name: 'Musculoskeletal', selected: false },
      { id: 11, name: 'Nervous/neuropsychology', selected: false },
      { id: 12, name: 'Pulmonary', selected: false },
      { id: 13, name: 'Renal', selected: false },
      { id: 14, name: 'Reproductive', selected: false }
    ]
  },
  {
    key: 'difficulty',
    filterValue: 'question.difficulty_level.label',
    label: 'Level of Difficulty',
    selectedFilterLabel: 'Difficulty',
    options: [
      { id: 1, name: 'Recall', selected: false },
      { id: 2, name: 'Application', selected: false },
      { id: 3, name: 'Synthesis', selected: false }
    ]
  },
  {
    key: 'class',
    filterValue: 'question.dds_course_semester',
    label: 'DDS Course & Semester',
    selectedFilterLabel: 'DDS Semester',
    options: [
      { id: 1, name: 'D1, Fall', selected: false },
      { id: 2, name: 'D1, Spring', selected: false },
      { id: 3, name: 'D2, Fall', selected: false },
      { id: 4, name: 'D2, Spring', selected: false },
      { id: 5, name: 'D3, Fall', selected: false },
      { id: 6, name: 'D3, Spring', selected: false },
      { id: 7, name: 'D4, Fall', selected: false },
      { id: 8, name: 'D4, Spring', selected: false }
    ]
  },
  {
    key: 'courses',
    filterValue: 'question.course.label',
    label: 'Courses',
    selectedFilterLabel: 'Courses',
    options: [
      { id: 1, name: 'Head and Neck Anatomy', selected: false },
      { id: 2, name: 'Basic Tissue', selected: false },
      { id: 3, name: 'Cell Organelles', selected: false }
    ]
  },
  {
    key: 'exams',
    filterValue: '_exams',
    label: 'Exams',
    selectedFilterLabel: 'Exams',
    options: [
      { id: 1, name: 'Spring, 2020', semester: 'Spring', year: 2020, selected: false },
      { id: 2, name: 'Fall, 2020', semester: 'Fall', year: 2020, selected: false },
      { id: 3, name: 'Spring, 2021', semester: 'Spring', year: 2021, selected: false },
      { id: 4, name: 'Fall, 2021', semester: 'Fall', year: 2021, selected: false },
      { id: 5, name: 'Spring, 2022', semester: 'Spring', year: 2022, selected: false },
      { id: 6, name: 'Fall, 2022', semester: 'Fall', year: 2022, selected: false },
      { id: 7, name: 'Spring, 2023', semester: 'Spring', year: 2023, selected: false },
      { id: 8, name: 'Fall, 2023', semester: 'Fall', year: 2023, selected: false },
      { id: 9, name: 'Spring, 2024', semester: 'Spring', year: 2024, selected: false },
      { id: 10, name: 'Fall, 2024', semester: 'Fall', year: 2024, selected: false },
      { id: 11, name: 'Spring, 2025', semester: 'Spring', year: 2025, selected: false },
      { id: 12, name: 'Fall, 2025', semester: 'Fall', year: 2025, selected: false },
      { id: 13, name: 'Spring, 2026', semester: 'Spring', year: 2026, selected: false },
      { id: 14, name: 'Fall, 2026', semester: 'Fall', year: 2026, selected: false },
      { id: 15, name: 'Spring, 2027', semester: 'Spring', year: 2027, selected: false },
      { id: 16, name: 'Fall, 2027', semester: 'Fall', year: 2027, selected: false },
      { id: 17, name: 'None', value: 'null', selected: false }
    ]
  },
  {
    key: 'mock_exams',
    filterValue: '_mock_exams',
    label: 'Mock Exams',
    selectedFilterLabel: 'Mock Exams',
    isShowAdminsOnly: true,
    options: [
      { id: 1, name: 'Mock Exam, SP22, D3', semester: 'Spring', selected: false },
      { id: 2, name: 'Mock Exam, SU22, D3', semester: 'Summer', selected: false },
      { id: 3, name: 'Mock Exam, FA22, D4', semester: 'Fall', selected: false },
      { id: 4, name: 'Mock Exam, SP23, D3', semester: 'Spring', selected: false },
      { id: 5, name: 'Mock Exam, SU23, D3', semester: 'Summer', selected: false },
      { id: 6, name: 'Mock Exam, FA23, D4', semester: 'Fall', selected: false },
      { id: 7, name: 'None', value: 'null', selected: false }
    ]
  },
  {
    key: 'content-status',
    filterValue: ['testlet_type', 'is_published'],
    label: 'Content Status',
    selectedFilterLabel: 'Status',
    options: [
      { id: 1, name: 'Open for Collaboration', selected: false, value: ['3'], key: 0 },
      { id: 2, name: 'Make a copy', selected: false, value: ['4', '5'], key: 0 },
      { id: 3, name: 'Published', selected: false, value: true, key: 1 },
      { id: 4, name: 'Unpublished', selected: false, value: false, key: 1 }
    ]
  }
];

export default filterOptions;
