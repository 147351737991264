import React from 'react';
import { getDateAndTime } from '../../helpers/inbdeUtils';
import PastReportsTableRowActions from '../../components/reports/PastReportsTableRowActions';

const actionFormatter = (dataField, { url, path }) => {
  return <PastReportsTableRowActions url={url} path={path} />;
};

const timeFormatter = time => <span>{getDateAndTime(time)}</span>;

export const columns = [
  {
    dataField: 'name',
    text: 'Report Name',
    sort: true,
    classes: 'align-middle max-vw-20 no-scrollbar-container overflow-scroll text-nowrap'
  },
  {
    dataField: 'created_by.displayName',
    text: 'Created By',
    sort: true,
    classes: 'align-middle text-truncate pl-2 pr-2'
  },
  {
    dataField: 'created_at',
    text: 'Created At',
    sort: true,
    formatter: timeFormatter,
    classes: 'align-middle text-truncate pl-2 pr-2'
  },
  {
    dataField: 'filters',
    text: 'Filters',
    classes: 'align-middle max-vw-20 no-scrollbar-container overflow-scroll text-nowrap'
  },
  {
    dataField: 'action',
    text: 'Actions',
    formatter: actionFormatter,
    classes: 'align-middle'
  }
];
