import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button, Row } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

class AnswerContent extends Component {
  render() {
    const { answer: data, togglePreviewModal } = this.props;

    const number = data.id + 1;
    const answerChoiceText = typeof data.answer_choice.text === 'string' ? data.answer_choice.text : data.answer_choice;
    const answerChoiceAttachments = data.answer_choice.attachments ? data.answer_choice.attachments : [];
    const answerExplanationText = typeof data.explanation.text === 'string' ? data.explanation.text : data.explanation;
    const answerExplanationAttachments = data.explanation.attachments ? data.explanation.attachments : [];

    return (
      <>
        <Row className="ml-0 p-2">
          <div className="col-sm-12 col-lg-3 font-weight-semi-bold">Answer Choice {number}:</div>
          <div className="col-sm-12 col-lg-9">
            {data.isCorrect && <FontAwesomeIcon color="green" icon="check" className="mr-2" />}
            {answerChoiceText}
          </div>
          <div className="col-sm-12 col-lg-3" />
          <div className="col-sm-12 col-lg-9">
            {answerChoiceAttachments.map(item => {
              return (
                <div className="mr-4" key={item.url}>
                  <FontAwesomeIcon icon="paperclip" transform="grow-2" className="d-inline mr-2 text-primary" />
                  <Button
                    color="link primary"
                    className="p-0 font-weight-bold"
                    onClick={() => togglePreviewModal(item)}
                  >
                    {item.file_name}
                  </Button>
                </div>
              );
            })}
          </div>
        </Row>
        <Row className="ml-0 p-2">
          <div className="col-sm-12 col-lg-3 font-weight-semi-bold">Explanation:</div>
          {!!answerExplanationText && (
            <>
              <div className="col-sm-12 col-lg-9">{answerExplanationText}</div>
              <div className="col-sm-12 col-lg-3" />
            </>
          )}
          <div className="col-sm-12 col-lg-9">
            {answerExplanationAttachments.map(item => {
              return (
                <div className="mr-4" key={item.url}>
                  <FontAwesomeIcon icon="paperclip" transform="grow-2" className="d-inline mr-2 text-primary" />
                  <Button
                    color="link primary"
                    className="p-0 font-weight-bold"
                    onClick={() => togglePreviewModal(item)}
                  >
                    {item.file_name}
                  </Button>
                </div>
              );
            })}
          </div>
        </Row>
      </>
    );
  }
}

AnswerContent.propTypes = {
  answer: PropTypes.object,
  togglePreviewModal: PropTypes.func
};

export default AnswerContent;
