import React, { useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import { ListGroup, Row } from 'reactstrap';
import LoadingCardList from './loading/LoadingCardList';

const DisplayTestlets = ({ isLoading, reachedBottom, testlets, isSearch }) => {
  useLayoutEffect(() => {
    const handleScroll = () => {
      const doc = document.documentElement;
      const top = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);

      const bottomDistance = Math.abs(doc.scrollHeight - top - doc.clientHeight);

      if (!isLoading && bottomDistance < 1) {
        reachedBottom();
      }
    };

    window.addEventListener('scroll', handleScroll, { passive: true });
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  });

  // if testlets are empty and isLoading, show only loading
  // else if testlets are not empty and isLoading, show both
  // else if testlets are not empty and not isLoading, show testlets
  // else if testlets are empty and not isLoading, show none

  return (
    <div className="ml-lg-2">
      {/* Render the testlets for the selected tab */}
      {isSearch && isLoading ? (
        <LoadingCardList />
      ) : (
        <>
          {isLoading && testlets.length === 0 && <LoadingCardList />}
          {isLoading && testlets.length > 0 && (
            <div>
              <ListGroup>{testlets}</ListGroup>
              <LoadingCardList />
            </div>
          )}
          {!isLoading && testlets.length > 0 && <ListGroup>{testlets}</ListGroup>}
          {!isLoading && testlets.length === 0 && (
            <Row>
              <div className="m-auto">No results found</div>
            </Row>
          )}
        </>
      )}
    </div>
  );
};

DisplayTestlets.propTypes = {
  testlets: PropTypes.array.isRequired,
  isLoading: PropTypes.bool,
  isSearch: PropTypes.bool,
  reachedBottom: PropTypes.func
};

export default DisplayTestlets;
