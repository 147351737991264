import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Card, CardBody, Input, Modal, ModalBody, ModalHeader, ModalFooter, Spinner } from 'reactstrap';
import TestletFormActionButtons from './TestletFormActionButtons';

function TestletFormActionButtonsSection({
  approveTestlet,
  deleteTestlet,
  exportTestlet,
  isAnyCommentUnread,
  isChangesButtonFeatureEnabled,
  isLoading,
  isSave,
  isUserAdmin,
  isUserTestletCreator,
  isUserQuestionCollaborator,
  isUserTestletCollaborator,
  leaveTestlet,
  redirectToPage,
  reviseTestlet,
  saveProgress,
  submitTestlet,
  testletType,
  toggleCommentsModal
}) {
  const [isConfirmationModelOpen, setIsConfirmationModelOpen] = useState(false);
  const [modalType, setIsModalType] = useState(null);
  const [reviseComment, setReviseComment] = useState('');

  const handleChange = e => {
    setReviseComment(e.target.value);
  };

  const formActionButtonProperties = {
    submit: {
      color: 'primary',
      action: submitTestlet,
      modalText: 'Are you sure you wish to submit this testlet for review by admins?',
      modalTitle: 'Submit'
    },
    delete: {
      color: 'danger',
      action: deleteTestlet,
      modalText: 'Are you sure you wish to delete this testlet?',
      modalTitle: 'Delete'
    },
    leave: {
      color: 'warning',
      action: leaveTestlet,
      modalText: 'Are you sure you wish to leave this testlet and stop collaborating on it?',
      modalTitle: 'Leave'
    },
    approve: {
      color: 'success',
      action: approveTestlet,
      modalText: 'Are you sure you wish to approve this testlet?',
      modalTitle: 'Approve'
    },
    export: {
      color: 'success',
      action: exportTestlet,
      modalText: 'Are you sure you wish to export this testlet?',
      modalTitle: 'Export'
    },
    revise: {
      color: 'warning',
      action: () => reviseTestlet(reviseComment),
      modalText: 'Are you sure you wish to send this testlet back for revision?',
      modalTitle: 'Revise',
      reactChild: (
        <div>
          <Input
            className="p-2 mb-2"
            draggable={false}
            onChange={handleChange}
            placeholder="Please enter your comment..."
            type="textarea"
            value={reviseComment}
            required
          />
        </div>
      )
    }
  };

  const confirmModal = () => {
    const formAction = formActionButtonProperties[modalType];
    const buttonColor = formAction ? formAction['color'] : '';
    const action = formAction ? formAction['action'] : '';
    const text = formAction ? formAction['modalText'] : '';
    const title = formAction ? formAction['modalTitle'] : '';
    const child = formAction && formAction['reactChild'] ? formAction['reactChild'] : '';

    return (
      <Modal isOpen={isConfirmationModelOpen} toggle={toggleConfirmationModal}>
        <ModalHeader title={' '}>{title} Testlet</ModalHeader>
        <ModalBody>
          <div>{text}</div>
          {child}
        </ModalBody>
        <ModalFooter>
          <Button color={buttonColor} onClick={action} disabled={isLoading}>
            Confirm
            {isLoading && <Spinner size="sm" color="light" className="ml-2" />}
          </Button>
          <Button color="secondary" onClick={() => toggleConfirmationModal(null)} disabled={isLoading}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    );
  };

  const toggleConfirmationModal = key => {
    setIsConfirmationModelOpen(!isConfirmationModelOpen);
    setIsModalType(key);
    setReviseComment('');
  };

  return (
    <div>
      {/* For large and x-large screens */}
      <div className="d-md-none">
        <Card>
          <CardBody>
            <TestletFormActionButtons
              isAnyCommentUnread={isAnyCommentUnread}
              isChangesButtonFeatureEnabled={isChangesButtonFeatureEnabled}
              isSave={isSave}
              isUserAdmin={isUserAdmin}
              isUserTestletCreator={isUserTestletCreator}
              isUserQuestionCollaborator={isUserQuestionCollaborator}
              isUserTestletCollaborator={isUserTestletCollaborator}
              testletAction={toggleConfirmationModal}
              testletType={testletType}
              redirectToPage={redirectToPage}
              saveProgress={saveProgress}
              toggleCommentsModal={toggleCommentsModal}
            />
          </CardBody>
        </Card>
      </div>
      {/* For small and medium screens */}
      <div
        className="form-action-buttons-container d-none d-md-flex w-100 fixed-bottom"
        style={{
          backgroundColor: 'rgba(93, 110, 129, 0.6)'
        }}
      >
        <div className="form-action-buttons-content pb-2 pt-2 pr-md-3 pl-md-3 pr-lg-5 pl-lg-5 ml-auto">
          <TestletFormActionButtons
            isAnyCommentUnread={isAnyCommentUnread}
            isChangesButtonFeatureEnabled={isChangesButtonFeatureEnabled}
            isSave={isSave}
            isUserAdmin={isUserAdmin}
            isUserTestletCreator={isUserTestletCreator}
            isUserQuestionCollaborator={isUserQuestionCollaborator}
            isUserTestletCollaborator={isUserTestletCollaborator}
            testletAction={toggleConfirmationModal}
            testletType={testletType}
            redirectToPage={redirectToPage}
            saveProgress={saveProgress}
            toggleCommentsModal={toggleCommentsModal}
          />
        </div>
      </div>
      {modalType && confirmModal()}
    </div>
  );
}

TestletFormActionButtonsSection.propTypes = {
  approveTestlet: PropTypes.func,
  deleteTestlet: PropTypes.func,
  exportTestlet: PropTypes.func,
  isAnyCommentUnread: PropTypes.bool,
  isChangesButtonFeatureEnabled: PropTypes.bool,
  isLoading: PropTypes.bool,
  isSave: PropTypes.bool,
  isUserAdmin: PropTypes.bool,
  isUserTestletCreator: PropTypes.bool,
  isUserQuestionCollaborator: PropTypes.bool,
  isUserTestletCollaborator: PropTypes.bool,
  leaveTestlet: PropTypes.func,
  redirectToPage: PropTypes.func,
  reviseTestlet: PropTypes.func,
  saveProgress: PropTypes.func,
  submitTestlet: PropTypes.func,
  testletType: PropTypes.string,
  toggleCommentsModal: PropTypes.func
};

export default TestletFormActionButtonsSection;
