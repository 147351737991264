import React, { useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { isIterableArray } from '../../helpers/utils';
import DisplayTestlets from './DisplayTestlets';
import ScrollDownTooltip from '../common/ScrollDownTooltip';

const TestletTabPage = ({ fetchTestlets, isLoading, searchTestlets, testletType, isSearching, testletsPerPage }) => {
  const [currentTestletType, setCurrentTestletType] = useState(null);
  const [displayTestlets, setDisplayTestlets] = useState([]);
  const [isTestletsFinished, setIsTestletsFinished] = useState(false);
  const mountedRef = useRef(null);

  const reachedBottom = () => {
    !searchTestlets && getTestlets(false);
  };

  const getTestlets = useCallback(
    async isFirstFetch => {
      if (!isTestletsFinished) {
        const testlets = await fetchTestlets(testletType, isFirstFetch);

        if (testlets && isIterableArray(testlets)) {
          mountedRef.current && setDisplayTestlets(testlets);
          if (testlets.length < testletsPerPage) {
            mountedRef.current && setIsTestletsFinished(true);
          }
        } else {
          mountedRef.current && setIsTestletsFinished(true);
        }
      }
    },
    [fetchTestlets, isTestletsFinished, testletType, testletsPerPage]
  );

  // used to reset state when tab is changed
  useEffect(() => {
    mountedRef.current = true;

    if (currentTestletType !== testletType && mountedRef.current && !searchTestlets) {
      setIsTestletsFinished(false);
      setDisplayTestlets([]);
      setCurrentTestletType(testletType);
    }

    return () => {
      mountedRef.current = false;
    };
  }, [currentTestletType, testletType, searchTestlets]);

  // used to fetch testlets
  useEffect(() => {
    mountedRef.current = true;

    if (isSearching && searchTestlets) {
      setDisplayTestlets(searchTestlets);
      setIsTestletsFinished(false);
    } else {
      getTestlets(true);
    }

    return () => {
      mountedRef.current = false;
    };
  }, [getTestlets, searchTestlets, isSearching]);

  return (
    <>
      <DisplayTestlets
        testlets={displayTestlets}
        isLoading={isLoading}
        isSearch={isSearching}
        reachedBottom={reachedBottom}
      />
      {!isLoading && !searchTestlets && !isTestletsFinished && <ScrollDownTooltip />}
    </>
  );
};

TestletTabPage.propTypes = {
  fetchTestlets: PropTypes.func,
  testletType: PropTypes.shape({
    key: PropTypes.string,
    types: PropTypes.array,
    linkTo: PropTypes.string
  }),
  isLoading: PropTypes.bool,
  isSearching: PropTypes.bool,
  searchTestlets: PropTypes.array,
  testletsPerPage: PropTypes.number
};

TestletTabPage.defaultProps = {
  testletsPerPage: 10
};

export default TestletTabPage;
