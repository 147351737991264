import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Label, Modal, ModalBody, ModalFooter, ModalHeader, Spinner } from 'reactstrap';
import Select from 'react-select';
import formData from '../../../data/mock/testletForm';

const formActionButtonProperties = {
  submit: {
    color: 'primary',
    modalText: 'Are you sure you wish to submit this {contentName} for review by admins?',
    modalTitle: 'Submit'
  },
  delete: {
    color: 'danger',
    modalText: 'Are you sure you wish to delete this {contentName}?',
    modalTitle: 'Delete'
  },
  approve: {
    color: 'primary',
    modalText: 'Are you sure you wish to approve this {contentName}?',
    modalTitle: 'Approve'
  },
  revise: {
    color: 'danger',
    modalText: 'Are you sure you wish to send this {contentName} back for revision?',
    modalTitle: 'Revise'
  },
  restore: {
    color: 'success',
    modalText: 'Are you sure you wish to restore this deleted {contentName}?',
    modalTitle: 'Restore'
  },
  publish: {
    color: 'primary',
    modalText:
      'Select INBDE Prep. Course from below to publish this {contentName}. Please make sure that the INBDE Prep. Course set during submission is the same to avoid any discrepancy.',
    modalTitle: 'Publish'
  },
  republish: {
    color: 'primary',
    modalText: 'Select a new INBDE Prep. Course from below to re-publish this {contentName}:',
    modalTitle: 'Republish'
  },
  unpublish: {
    color: 'info',
    modalText: 'Are you sure you wish to unpublish this {contentName}?',
    modalTitle: 'Unpublish'
  },
  copy: {
    color: 'info',
    modalText: 'Are you sure you wish to make a copy of this {contentName}?',
    modalTitle: 'Copy'
  },
  flag: {
    color: 'danger',
    modalText: 'Are you sure you wish to flag this {contentName}?',
    modalTitle: 'Flag'
  },
  unflag: {
    color: 'success',
    modalText: 'Are you sure you wish to unflag this {contentName}?',
    modalTitle: 'Unflag'
  },
  export: {
    color: 'success',
    modalText: 'Are you sure you wish to export this {contentName}?',
    modalTitle: 'Export'
  },
  default: {
    color: 'secondary',
    modalText: 'You have selected an incorrect action. Please go back',
    modalTitle: ''
  }
};

const getActionButtonProperties = (action, contentName) => {
  action = action || 'default';

  const property = formActionButtonProperties[action]
    ? formActionButtonProperties[action]
    : formActionButtonProperties['default'];
  property.modalText = property.modalText.replace('{contentName}', contentName.toLowerCase());

  return property;
};

export const BaseContentActions = ({
  contentAction,
  contentName,
  deleteContent,
  isConfirmationModelOpen,
  isLoading,
  persistContent,
  publishedInbdeCourse,
  toggleConfirmationModal,
  updateContentByAdmin,
  updateContentByFaculty
}) => {
  const initialProperty = getActionButtonProperties(null, contentName);
  const [actionProperties, setActionProperties] = useState(initialProperty);
  const [inbdeCourse, setInbdeCourse] = useState(publishedInbdeCourse);
  const isPublishAction = contentAction === 'publish' || contentAction === 'republish';

  const handleContentAction = () => {
    switch (contentAction) {
      case 'submit':
        persistContent();
        break;
      case 'delete':
        deleteContent();
        break;
      case 'flag':
        updateContentByFaculty(contentAction);
        break;
      case 'copy':
        updateContentByFaculty(contentAction);
        break;
      case 'publish':
        updateContentByAdmin(contentAction, inbdeCourse);
        break;
      case 'republish':
        updateContentByAdmin(contentAction, inbdeCourse);
        break;
      default:
        updateContentByAdmin(contentAction);
        break;
    }
  };

  useEffect(() => {
    const actionProperty = getActionButtonProperties(contentAction, contentName);
    setActionProperties(actionProperty);
  }, [contentAction, contentName]);

  return (
    <div>
      <Modal isOpen={isConfirmationModelOpen} toggle={toggleConfirmationModal}>
        <ModalHeader title={' '}>{`${actionProperties.modalTitle} ${contentName}`}</ModalHeader>
        <ModalBody>
          <div>{actionProperties.modalText}</div>
          {isPublishAction && (
            <div>
              <br />
              <Label for="readonly" className="fs-0">
                INBDE Prep Course(s)
              </Label>
              <Select
                value={inbdeCourse}
                onChange={inbdeCourse => setInbdeCourse(inbdeCourse)}
                options={formData.inbdeCourse}
                isMulti
              />
            </div>
          )}
        </ModalBody>
        <ModalFooter>
          {Boolean(actionProperties.modalTitle) && (
            <Button
              color={actionProperties.color}
              onClick={() => handleContentAction()}
              disabled={isLoading || (isPublishAction && inbdeCourse === null)}
            >
              Confirm
              {isLoading && <Spinner size="sm" color="light" className="ml-2" />}
            </Button>
          )}
          <Button color="secondary" onClick={() => toggleConfirmationModal()} disabled={isLoading}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

BaseContentActions.propTypes = {
  contentAction: PropTypes.string.isRequired,
  contentName: PropTypes.string.isRequired,
  deleteContent: PropTypes.func,
  isConfirmationModelOpen: PropTypes.bool,
  isLoading: PropTypes.bool,
  persistContent: PropTypes.func,
  publishedInbdeCourse: PropTypes.array,
  toggleConfirmationModal: PropTypes.func,
  updateContentByAdmin: PropTypes.func,
  updateContentByFaculty: PropTypes.func
};

BaseContentActions.defaultProps = {
  deleteContent: () => {},
  isConfirmationModelOpen: false,
  isLoading: false,
  persistContent: () => {},
  publishedInbdeCourse: [],
  toggleConfirmationModal: () => {},
  updateContentByAdmin: () => {},
  updateContentByFaculty: () => {}
};
