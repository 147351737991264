import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Button, Row } from 'reactstrap';
import { get } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DifficultyLevelToolTip from '../../../testlet/common/DifficultyLevelToolTip';
import AnswerContent from '../../../testlet/expanded/AnswerContent';
import { getMockExamText } from '../helpers/helpers';

export const QuestionDetailCardBodyContent = ({ previewAttachment, question }) => {
  const {
    answers,
    clinical_content,
    foundational_knowledge,
    id: number,
    key_concepts,
    mockExam,
    semester_mock_exam,
    question_creator,
    question_stem,
    systems_areas,
    review_material
  } = question;

  const course = get(question, 'course.label', '');
  const course_semester = get(question, 'course_semester.label', '');
  const course_year = get(question, 'course_year.label', '');
  const difficulty_level = get(question, 'difficulty_level.label', '');
  const questionStemText = question_stem && typeof question_stem.text === 'string' ? question_stem.text : question_stem;
  const questionStemAttachments =  question_stem && question_stem.attachments ? question_stem.attachments : [];
  const exams = getMockExamText(mockExam);
  const mockExams = getMockExamText(semester_mock_exam);
  const clinicalContent = clinical_content || [];
  const foundationalKnowledge = foundational_knowledge || [];
  const reviewMaterials = review_material || [];
  const systemsAreas = systems_areas || [];

  return (
    <Fragment>
      <Row className="ml-0 p-2">
        <div className="col-sm-12 col-lg-3 font-weight-semi-bold">Course:</div>
        <div className="col-sm-12 col-lg-9">{course}</div>
      </Row>
      <Row className="ml-0 p-2">
        <div className="col-sm-12 col-lg-3 font-weight-semi-bold">Semester of course:</div>
        <div className="col-sm-12 col-lg-9">{course_semester}</div>
      </Row>
      <Row className="ml-0 p-2">
        <div className="col-sm-12 col-lg-3 font-weight-semi-bold">DDS Year:</div>
        <div className="col-sm-12 col-lg-9">{course_year}</div>
      </Row>
      <Row className="ml-0 p-2">
        <div className="col-sm-12 col-lg-3 font-weight-semi-bold">Created By:</div>
        <div className="col-sm-12 col-lg-9">{question_creator}</div>
      </Row>
      <Row className="ml-0 p-2">
        <div className="col-sm-12 col-lg-3 font-weight-semi-bold">Foundational Knowledge:</div>
        <div className="col-sm-12 col-lg-9">
          {foundationalKnowledge.map((option, index) => {
            option = option || { value: index, label: '' };
            return (
              <div key={option.value}>
                {option.label}
                <br />
              </div>
            );
          })}
        </div>
      </Row>
      <Row className="ml-0 p-2">
        <div className="col-sm-12 col-lg-3 font-weight-semi-bold">Clinical Content:</div>
        <div className="col-sm-12 col-lg-9">
          {clinicalContent.map((option, index) => {
            option = option || { value: index, label: '' };
            return (
              <div key={option.value}>
                {option.label}
                <br />
              </div>
            );
          })}
        </div>
      </Row>
      <Row className="ml-0 p-2">
        <div className="col-sm-12 col-lg-3 font-weight-semi-bold">Exam(s):</div>
        <div className="col-sm-12 col-lg-9">{exams}</div>
      </Row>
      <Row className="ml-0 p-2">
        <div className="col-sm-12 col-lg-3 font-weight-semi-bold">Mock Exam(s):</div>
        <div className="col-sm-12 col-lg-9">{mockExams}</div>
      </Row>
      <Row className="ml-0 p-2">
        <div className="col-sm-12 col-lg-3 font-weight-semi-bold">System / Area(s):</div>
        <div className="col-sm-12 col-lg-9">
          {systemsAreas.map((option, index) => {
            option = option || { value: index, label: '' };
            return (
              <div key={option.value}>
                {option.label}
                <br />
              </div>
            );
          })}
        </div>
      </Row>
      <Row className="ml-0 p-2">
        <div className="col-sm-12 col-lg-3 font-weight-semi-bold">Key Concepts:</div>
        <div className="col-sm-12 col-lg-9">{key_concepts}</div>
      </Row>
      <Row className="ml-0 p-2">
        <div className="col-sm-12 col-lg-3 font-weight-semi-bold">Level of Difficulty:</div>
        <div id={'question-' + number + '-difficulty-level'} className="col-sm-12 col-lg-9 text-warning">
          {difficulty_level}
        </div>
        <DifficultyLevelToolTip id={'question-' + number + '-difficulty-level'} difficultyLevel={difficulty_level} />
      </Row>
      <Row className="ml-0 p-2">
        <div className="col-sm-12 col-lg-3 font-weight-semi-bold">Question stem:</div>
        {!!questionStemText && (
          <>
            <div className="col-sm-12 col-lg-9">{questionStemText}</div>
            <div className="col-sm-12 col-lg-3" />
          </>
        )}
        <div className="col-sm-12 col-lg-9">
          {questionStemAttachments.map((item, index) => {
            item = item || { url: index, file_name: '' };
            return (
              <div className="mr-4" key={item.url}>
                <FontAwesomeIcon icon="paperclip" transform="grow-2" className="d-inline mr-2 text-primary" />
                <Button color="link primary" className="p-0 font-weight-bold" onClick={() => previewAttachment(item)}>
                  {item.file_name}
                </Button>
              </div>
            );
          })}
        </div>
      </Row>
      {answers.map(answer => (
        <AnswerContent key={answer.id} answer={answer} togglePreviewModal={previewAttachment} />
      ))}
      <Row className="ml-0 p-2">
        <div className="col-sm-12 col-lg-3 font-weight-semi-bold">Question Explanation:</div>
        <div className="col-sm-12 col-lg-9" />
      </Row>
      <Row className="ml-0 p-2">
        <div className="col-sm-12 col-lg-3 font-weight-semi-bold">Review Material(s):</div>
        <div className="col-sm-12 col-lg-9">
          {reviewMaterials.map((image, index) => {
            image = image || { file_name: '' };
            return (
              <div className="mr-4" key={index}>
                <FontAwesomeIcon icon="paperclip" transform="grow-2" className="d-inline mr-2 text-primary" />
                <Button color="link primary" className="p-0 font-weight-bold" onClick={() => previewAttachment(image)}>
                  {image.file_name}
                </Button>
              </div>
            );
          })}
        </div>
      </Row>
      <hr />
    </Fragment>
  );
};

QuestionDetailCardBodyContent.propTypes = {
  previewAttachment: PropTypes.func,
  question: PropTypes.object.isRequired
};

QuestionDetailCardBodyContent.defaultProps = { previewAttachment: () => {} };
