import { getCollaboratorModel } from './commons';
import featureToggles from '../../services/Feature/toggles';

function TestletFormQuestionService() {
  this.getCollaboratorModel = (oldCollabs, newCollabs, toAdd) => {
    return getCollaboratorModel(oldCollabs, newCollabs, toAdd);
  };

  this.getUpdatedCollaboratorIdList = (idList, userId, isAdd) => {
    if (!userId) {
      return idList;
    }

    if (isAdd) {
      const isUserInList = !!idList.filter(id => id === userId).length;
      const newList = isUserInList ? [...idList] : [...idList, userId];
      return newList;
    } else {
      return idList.filter(id => id !== userId);
    }
  };

  this.isQuestionReorderEnabledForUserRole = (userRole, toggles) => {
    const featureMap = toggles ? toggles[featureToggles.isQuestionReorderEnabled] : null;
    return featureMap ? Boolean(featureMap[userRole]) : false;
  };
}

export default TestletFormQuestionService;
