import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Row } from 'reactstrap';

const ScrollDownTooltip = () => {
  const scrollToBottom = () => {
    const doc = document.documentElement;
    window.scrollTo(0, doc.scrollHeight);
  };

  return (
    <div className="sticky-bottom w-100">
      <Row className="justify-content-center">
        <div
          className="scrolldown-tooltip bg-primary rounded text-white text-center pt-2 pb-2 pr-4 pl-4 rounded-capsule mb-2 cursor-pointer"
          onClick={() => scrollToBottom()}
        >
          <p className="mb-0">Scroll to Load More</p>
          <FontAwesomeIcon icon="arrow-down" size="sm" />
        </div>
      </Row>
    </div>
  );
};

export default ScrollDownTooltip;
