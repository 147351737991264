import React, { Component, Fragment } from 'react';
import { Card, CardBody, Button, InputGroup, CustomInput, Modal, ModalBody, ModalHeader } from 'reactstrap';
import FalconCardHeader from '../components/common/FalconCardHeader';
import ButtonIcon from '../components/common/ButtonIcon';
import AddMembersForm from '../widgets/AddMembersForm';
import UserService from '../services/User/UserService';
import WhiteListService from '../services/WhiteList/WhiteListService';
import CollaborationService from '../services/Collaboration/CollaborationService';
import { isEmailValid, isIterableArray } from '../helpers/utils';
import { UserContext } from '../Contexts';
import { toast } from 'react-toastify';
import { allAccessTypesArray } from '../data/user/accessTypes';
import { maximumUsersUpload, userAccessTableTitle } from '../helpers/constants';
import featureToggles from '../services/Feature/toggles';

import loadable from '@loadable/component';
import UploadWhitelistModal from '../components/access/uploadWhitelistModal';
import { isFeatureActive } from '../helpers/inbdeUtils';

const AccessTable = loadable(() => import('../components/dashboard/AccessTable'));

class MemberAccessControl extends Component {
  _isMounted = false;
  tableRowLimit = 10;
  userService = new UserService();
  collaborationService = new CollaborationService();
  whiteListService = new WhiteListService();

  constructor(props) {
    super(props);

    this.state = {
      isSelected: false,
      addMembersModal: false,
      toggleImportModal: false,
      isSpinner: false,
      members: [],
      newWhiteList: null,
      oldWhiteList: [],
      whiteListFile: null,
      user: null,
      entryAlreadyInWhitelist: [],
      entryMissingFromOrgList: [],
      entryPresentInOrgList: [],
      isFetchMoreUsers: true,
      inbdeUsers: [],
      lastUserFetched: null,
      countOfUsersFetched: this.tableRowLimit,
      inbdeEntries: [],
      isFetchMoreEntries: true,
      lastEntryFetched: false,
      inbdeMembers: [],
      toggles: {}
    };

    this.checkEmailInOrganizationList = this.checkEmailInOrganizationList.bind(this);
    this.clearWhitelistState = this.clearWhitelistState.bind(this);
    this.fetchMembers = this.fetchMembers.bind(this);
    this.getInbdeUsers = this.getInbdeUsers.bind(this);
    this.getWhitelistEntries = this.getWhitelistEntries.bind(this);
    this.getEmailsInOrganizationList = this.getEmailsInOrganizationList.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.isUserExistInWhitelist = this.isUserExistInWhitelist.bind(this);
    this.processEmailsForWhitelist = this.processEmailsForWhitelist.bind(this);
    this.refreshPage = this.refreshPage.bind(this);
    this.sanitizeUser = this.sanitizeUser.bind(this);
    this.setIsSelected = this.setIsSelected.bind(this);
    this.submitUser = this.submitUser.bind(this);
    this.submitWhiteList = this.submitWhiteList.bind(this);
    this.toggleAddModal = this.toggleAddModal.bind(this);
    this.toggleImportModal = this.toggleImportModal.bind(this);
    this.uploadFile = this.uploadFile.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;

    const { user, toggles } = this.context;
    this.setState({ user, toggles }, () => {
      this.refreshPage();
    });
  }

  componentWillUnmount() {
    this.unsubscribeFirestoreListener();
    this._isMounted = false;
  }

  async checkEmailInOrganizationList(email) {
    const emailEntryResponse = await this.whiteListService.checkEmailInOrganizationList(email);
    return emailEntryResponse;
  }

  clearWhitelistState() {
    this._isMounted &&
      this.setState({
        entryAlreadyInWhitelist: [],
        entryMissingFromOrgList: [],
        entryPresentInOrgList: [],
        newWhiteList: null
      });
  }

  async getCurrentWhiteList() {
    const { newWhiteList, whiteListFile, user } = this.state;

    this.whiteListService.getWhiteList(false, data => {
      if (data) {
        let whiteList = [];

        data.forEach(doc => {
          let datum = doc.data();
          datum.email = doc.id;
          whiteList.push(datum);
        });

        if (this._isMounted) {
          if (newWhiteList && whiteListFile) {
            this.whiteListService.saveWhitelistVersion(whiteList, user);
          }

          this.setState({ oldWhiteList: whiteList, newWhiteList: null }, () => {
            this.getMembers();
          });
        }
      }
    });
  }

  async getEmailsInOrganizationList(emailEntries) {
    if (!isIterableArray(emailEntries)) {
      return emailEntries;
    }

    const emailEntryRequestDto = {};

    emailEntries.forEach(entry => {
      const { email } = entry;
      emailEntryRequestDto[email] = {};
    });

    const emailEntryResponseDto = await this.whiteListService.getEmailsInOrganizationList(emailEntryRequestDto);
    if (emailEntryResponseDto === null) {
      return emailEntries;
    }

    const emailEntriesResponse = emailEntries.map(entry => {
      const { email } = entry;
      entry['isExists'] = emailEntryResponseDto[email]['isExists'];
      if (entry['isExists']) {
        entry['data'] = emailEntryResponseDto[email]['data'];
      }
      return entry;
    });
    return emailEntriesResponse;
  }

  getMembers() {
    const { oldWhiteList } = this.state;

    this.userService.getListOfUsers(false, data => {
      if (data) {
        let pendingStatusList = [];
        let emails = [];
        let members = [];

        data.forEach(doc => {
          let datum = doc.data();
          datum.id = doc.id;
          members.push(datum);

          emails.push(datum.email);
        });

        oldWhiteList.forEach(item => {
          if (!emails.includes(item.email)) {
            pendingStatusList.push({
              id: item.email,
              email: item.email,
              access_type: item.access_type,
              is_active: 'pending',
              last_login: null,
              full_name: null
            });
          }
        });

        if (pendingStatusList.length) {
          members = [...members, ...pendingStatusList];
        }
        setTimeout(() => {
          this._isMounted && this.setState({ members });
        }, 2000);
      }
    });
  }

  async fetchMembers() {
    const { isFetchMoreUsers, lastUserFetched, isFetchMoreEntries, lastEntryFetched, countOfUsersFetched } = this.state;
    let inbdeUsers = [];
    let inbdeEntries = [];
    let isAllUsersFetched = false;
    let isAllEntriesFetched = false;

    if (isFetchMoreUsers) {
      inbdeUsers = await this.getInbdeUsers(lastUserFetched);
    }

    if (inbdeUsers.length < this.tableRowLimit) {
      isAllUsersFetched = true;
    }

    if (isAllUsersFetched && isFetchMoreEntries) {
      inbdeEntries = await this.getWhitelistEntries(lastEntryFetched, countOfUsersFetched);
      // checking for countOfUsersFetched to determine if entries previously fetched or not
      if (inbdeEntries.length < this.tableRowLimit && countOfUsersFetched === 0) {
        isAllEntriesFetched = true;
      }
    }

    this._isMounted &&
      this.setState(state => ({
        isFetchMoreUsers: !isAllUsersFetched,
        isFetchMoreEntries: !isAllEntriesFetched,
        inbdeMembers: [...state.inbdeMembers, ...inbdeUsers, ...inbdeEntries]
      }));
  }

  async getInbdeUsers(lastUserFetched) {
    const inbdeUsers = await this.userService.getListOfUsersPaginated(
      allAccessTypesArray,
      lastUserFetched,
      this.tableRowLimit
    );

    const users = inbdeUsers.map(userDoc => {
      const user = userDoc.data();
      user['id'] = userDoc.id;
      return user;
    });

    lastUserFetched = users[users.length - 1];
    this._isMounted &&
      this.setState({
        lastUserFetched,
        countOfUsersFetched: users.length
      });

    return users;
  }

  async getWhitelistEntries(lastEntryFetched, countOfUsersFetched) {
    const entriesToFetch =
      countOfUsersFetched === this.tableRowLimit ? this.tableRowLimit : this.tableRowLimit - countOfUsersFetched;
    const whitelistEntries = await this.whiteListService.getWhiteListPaginated(lastEntryFetched, entriesToFetch);

    const entries = whitelistEntries.map(entryDoc => {
      const entry = entryDoc.data();
      entry['id'] = entryDoc.id;
      entry['is_active'] = 'pending';
      entry['email'] = entryDoc.id;
      entry['last_login'] = null;
      entry['full_name'] = null;
      return entry;
    });

    lastEntryFetched = entries[entries.length - 1];
    this._isMounted &&
      this.setState({
        lastEntryFetched,
        countOfUsersFetched: 0
      });

    return entries;
  }

  handleSubmit(e) {
    e.preventDefault();

    const {
      newWhiteList,
      entryAlreadyInWhitelist,
      entryMissingFromOrgList,
      entryPresentInOrgList,
      toggles
    } = this.state;

    if (!isFeatureActive(featureToggles.isUpdateWhitelistEnabled, toggles, 'update whitelist')) {
      return;
    }

    if (entryAlreadyInWhitelist.length || entryMissingFromOrgList.length || entryPresentInOrgList.length) {
      this._isMounted && this.setState({ isSpinner: true });
      this.submitWhiteList(newWhiteList);
      return;
    }

    if (!newWhiteList) {
      toast.error('Please add a valid whitelist to upload');
      return;
    }

    this._isMounted && this.setState({ isSpinner: true });
    this.processEmailsForWhitelist(newWhiteList);
  }

  isUserExistInWhitelist(user) {
    const { oldWhiteList } = this.state;
    for (let entry = 0; entry < oldWhiteList.length; entry += 1) {
      if (user.email === oldWhiteList[entry]['email']) {
        return true;
      }
    }

    return false;
  }

  isValidAccess(access) {
    return allAccessTypesArray.includes(access);
  }

  async processEmailsForWhitelist(entries) {
    const entryAlreadyInWhitelist = [];
    const entryNotInWhitelist = [];

    const entryMissingFromOrgList = [];
    const entryPresentInOrgList = [];

    for (const entry of entries) {
      if (this.isUserExistInWhitelist(entry)) {
        entryAlreadyInWhitelist.push(entry);
      } else {
        entryNotInWhitelist.push(entry);
      }
    }

    const emailEntryResponseArray = await this.getEmailsInOrganizationList(entryNotInWhitelist);
    emailEntryResponseArray.forEach(emailEntryResponse => {
      const { email, access_type, isExists, data } = emailEntryResponse;
      if (isExists) {
        data['access_type'] = access_type;
        entryPresentInOrgList.push(data);
      } else {
        const data = { access_type, email };
        entryMissingFromOrgList.push(data);
      }
    });

    this._isMounted &&
      this.setState({
        entryAlreadyInWhitelist,
        entryMissingFromOrgList,
        entryPresentInOrgList,
        isSpinner: false
      });
  }

  readFile(file) {
    this._isMounted && this.setState({ whiteListFile: file });

    let reader = new FileReader();
    reader.onload = e => {
      const fileContent = e.target.result;

      const emailAddressList = fileContent.trim().split(/\r?\n/);
      const newWhiteList = this.sanitizeList(emailAddressList);

      if (newWhiteList.length === 0) {
        toast.error('No emails found in the uploaded file to add to the whitelist');
      } else if (newWhiteList.length <= maximumUsersUpload) {
        this._isMounted && this.setState({ newWhiteList });
      } else {
        toast.error('Could not add whitelist - number of emails added is more than ' + maximumUsersUpload);
      }
    };

    reader.readAsText(file);
  }

  async refreshPage() {
    const { toggles } = this.state;
    if (!isFeatureActive(featureToggles.isFetchInbdeAccessMembers, toggles, 'fetch users')) {
      return;
    }

    await this.getCurrentWhiteList();
  }

  sanitizeList(list) {
    let users = [];

    if (isIterableArray(list)) {
      list.forEach(item => {
        const temp = this.sanitizeUser(item.split(','));
        if (temp) {
          users.push(temp);
        }
      });
    }

    return users;
  }

  sanitizeUser(temp) {
    const { members } = this.state;
    if (temp.length === 2 && isEmailValid(temp[0].trim()) && this.isValidAccess(temp[1].trim())) {
      const email = temp[0].trim();
      const user = members.filter(user => user.email === email);
      const userId = isIterableArray(user) ? user[0].id : email;

      return {
        email,
        access_type: temp[1].trim(),
        userId
      };
    }

    return null;
  }

  setIsSelected(bool) {
    this._isMounted && this.setState({ isSelected: bool });
  }

  async submitUser(userItems, callback) {
    const { toggles } = this.state;
    if (!isFeatureActive(featureToggles.isUpdateWhitelistEnabled, toggles, 'update whitelist')) {
      callback(false);
      return;
    }

    const user = this.sanitizeUser(userItems);
    if (!user) {
      toast.error('The information added was invalid');
      callback(false);
      return;
    }

    if (this.isUserExistInWhitelist(user)) {
      toast.error('This email is already in the whitelist');
      callback(false);
      return;
    }

    this.uploadWhiteList([user], isSuccess => {
      callback(isSuccess);
    });
  }

  submitWhiteList(newWhiteList) {
    this.uploadWhiteList(newWhiteList, isUpload => {
      if (isUpload) {
        toast.success('Successfully uploaded whitelist');
        this._isMounted &&
          this.setState({
            isSpinner: false,
            toggleImportModal: false,
            entryAlreadyInWhitelist: [],
            entryMissingFromOrgList: [],
            entryPresentInOrgList: [],
            newWhiteList: null
          });
      } else {
        toast.error('All the entered emails are already in the whitelist');
        this._isMounted && this.setState({ isSpinner: false });
      }
    });
  }

  toggleAddModal() {
    this._isMounted && this.setState(state => ({ addMembersModal: !state.addMembersModal }));
  }

  toggleImportModal() {
    this._isMounted && this.setState(state => ({ toggleImportModal: !state.toggleImportModal, isSpinner: false }));
  }

  unsubscribeFirestoreListener() {
    this.whiteListService.getWhiteList(true, () => {});
    this.userService.getListOfUsers(true, () => {});
  }

  uploadFile(event) {
    try {
      const file = event.target.files[0];
      const fileExtension = file && file.name.split('.').pop();

      if (fileExtension === 'csv') {
        this.readFile(file);
      } else {
        toast.error('Invalid file format. Please make sure file is a CSV');
      }
    } catch {
      toast.error('Encountered error in adding file. Please make sure file is valid');
    }
  }

  /*
    separate users in users collection AND those not in users collection
    for those only in whitelist, simply update their access level in whitelist
    for those in users collection, update their access level in whitelist AND users collection
    set deactivated_on and deleted_on to null
    Note: use batch writes to guarantee writes to db
  */
  uploadWhiteList(usersToAdd, callback) {
    if (isIterableArray(usersToAdd)) {
      const { user: invitedBy } = this.state;
      const newUsers = [];
      const emails = [];

      usersToAdd.forEach(user => {
        if (!this.isUserExistInWhitelist(user)) {
          newUsers.push(user);

          const { email } = user;
          emails.push(email);
        }
      });

      this.userService.addUsersToWhitelist(newUsers, callback);
      this.collaborationService.sendInvitationEmailsToNewUsers(emails, invitedBy);
    } else {
      callback(false);
    }
  }

  render() {
    const {
      entryAlreadyInWhitelist,
      entryMissingFromOrgList,
      entryPresentInOrgList,
      isSelected,
      isSpinner,
      members,
      newWhiteList,
      toggleImportModal
    } = this.state;
    return (
      <Fragment>
        <Card className="mb-3">
          <FalconCardHeader title={userAccessTableTitle} light={false}>
            {isSelected ? (
              <InputGroup size="sm" className="input-group input-group-sm d-none">
                <CustomInput type="select" id="bulk-select">
                  <option>Bulk actions</option>
                  <option value="Refund">Activate</option>
                  <option value="Delete">Deactivate</option>
                  <option value="Archive">Delete</option>
                </CustomInput>
                <Button color="falcon-default" size="sm" className="ml-2">
                  Apply
                </Button>
              </InputGroup>
            ) : (
              <Fragment>
                <ButtonIcon
                  icon="plus"
                  transform="shrink-3 down-2"
                  color="falcon-default"
                  size="sm"
                  className="mx-2"
                  onClick={this.toggleAddModal}
                >
                  Add
                </ButtonIcon>
                <ButtonIcon
                  icon="filter"
                  transform="shrink-3 down-2"
                  color="falcon-default"
                  size="sm"
                  className="mx-2 d-none"
                >
                  Filter
                </ButtonIcon>
                <ButtonIcon
                  icon="external-link-alt"
                  transform="shrink-3 down-2"
                  color="falcon-default"
                  size="sm"
                  className="mx-2 d-none"
                >
                  Export
                </ButtonIcon>
                <ButtonIcon
                  icon="file-import"
                  transform="shrink-3 down-2"
                  color="falcon-default"
                  size="sm"
                  className="ml-2"
                  onClick={this.toggleImportModal}
                >
                  Import
                </ButtonIcon>
              </Fragment>
            )}
          </FalconCardHeader>
          <CardBody className="p-0">
            <AccessTable setIsSelected={this.setIsSelected} data={members} refreshPage={this.refreshPage} />
          </CardBody>
        </Card>
        <Modal toggle={this.toggleAddModal} isOpen={this.state.addMembersModal} className="add-member-section modal-md">
          <ModalHeader className="p-3 add-member-section-title">Add a New Member</ModalHeader>
          <ModalBody className="p-0">
            <div>
              <AddMembersForm
                checkEmailInOrganizationList={this.checkEmailInOrganizationList}
                isUserInWhitelist={this.isUserExistInWhitelist}
                closeAddMembersModal={this.toggleAddModal}
                addUserToWhitelist={this.submitUser}
                refreshPage={this.refreshPage}
              />
            </div>
          </ModalBody>
        </Modal>
        <UploadWhitelistModal
          clearWhitelistState={this.clearWhitelistState}
          entryAlreadyInWhitelist={entryAlreadyInWhitelist}
          entryMissingFromOrgList={entryMissingFromOrgList}
          entryPresentInOrgList={entryPresentInOrgList}
          handleSubmit={this.handleSubmit}
          isSpinner={isSpinner}
          newWhiteList={newWhiteList}
          toggleImportModal={this.toggleImportModal}
          toggleImportModalFlag={toggleImportModal}
          uploadFile={this.uploadFile}
        />
      </Fragment>
    );
  }
}

MemberAccessControl.contextType = UserContext;

export default MemberAccessControl;
