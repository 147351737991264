import React from 'react';
import PropTypes from 'prop-types';
import SearchOption from './SearchOption';
import _ from 'lodash';

const SearchOptions = ({ isSearch, searchOptions, onChange }) => {
  const handleChange = (id, selected) => {
    onChange(id, selected);
  };

  return searchOptions.map((option, index) => {
    const isOption = _.has(option, 'isOption') ? option['isOption'] : true;

    return (
      <div className={'col-12' + (isSearch ? ' col-md-6' : '')} key={index}>
        <SearchOption option={option} onChange={handleChange} isSearch={isSearch} isOption={isOption} />
      </div>
    );
  });
};

SearchOptions.propTypes = {
  isSearch: PropTypes.bool,
  searchOptions: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired
};

export default SearchOptions;
