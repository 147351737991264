import { contentMetaData } from './contentMetaData';
import { question } from './question';

export const testlet = {
  ...contentMetaData,
  content_type: 'testlet',
  patient_information: {
    about_patient: '',
    current_findings: '',
    patient_complaint: '',
    patient_history: '',
    patient_images: []
  },
  questions: [question],
  testlet_information: {
    testlet_title: '',
    testlet_creator: null,
    collaboratos: [],
    inbdeCourse: {}
  }
};
