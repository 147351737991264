import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Media, Row, Col } from 'reactstrap';
// import Avatar from '../../common/Avatar';
import FalconLightBox from '../../common/FalconLightBox';
import LightBoxGallery from '../../common/LightBoxGallery';
import Flex from '../../common/Flex';
// import ChatMessageOptions from './ChatMessageOptions';
import { isIterableArray } from '../../../helpers/utils';
import { TestletContext } from '../../../Contexts';
import classNames from 'classnames';

const parseMessage = message => {
  return message.split('\n').map((line, index) => {
    let jsxElement;
    if (line) {
      jsxElement = (
        <p className="mb-0 text-break" key={index}>
          {line}
        </p>
      );
    } else {
      jsxElement = <br key={index} />;
    }
    return jsxElement;
  });
};

const Message = ({ message, sender, status, time, isGroup, seenBy, isUpdate, isChange }) => {
  const { user: signedInUser } = useContext(TestletContext);
  const isLeft = sender.id !== signedInUser.uid;
  const isFormAction = !!message.is_testlet_action;
  const isFile = !!message.is_file;
  let messageText;

  if (isUpdate) {
    const fieldName = message['fieldName'];
    sender.name = 'Change made';
    if (isFormAction) {
      const action = message.oldValue ? 'removed' : 'added';
      const contentType = isFile ? 'attachment' : message.is_answer ? 'answer' : 'question';
      const content = message.oldValue ? message.oldValue : message.newValue;

      messageText = (
        <>
          <p className="mb-1">{message['made_by_name'] + ' ' + action + ' the ' + contentType + ' '}</p>
          <i>
            <strong>{parseMessage(content)}</strong>
          </i>
          <p className="mb-0 mt-1">
            {(message.oldValue ? ' from ' : ' to ') + 'the '}
            <span>
              <strong className="text-primary">
                <u>{fieldName}</u>
              </strong>
            </span>{' '}
            {!message.is_question && 'section'}
          </p>
        </>
      );
    } else {
      messageText = (
        <>
          <p className="mb-0">
            {message['made_by_name'] + ' updated the '}
            <span>
              <strong className="text-primary">
                <u>{fieldName}</u>
              </strong>
            </span>{' '}
            field
          </p>
          <p className="mb-0 mt-2 fs--1">From</p>
          <strong>{parseMessage(message['oldValue'])}</strong>
          <p className="mb-0 mt-2 fs--1">To</p>
          <strong>{parseMessage(message['newValue'])}</strong>
        </>
      );
    }
  } else if (isChange) {
    messageText = (
      <>
        {message.isComment ? (
          <>
            <p className="mb-2">{message['made_by_name'] + ' requested the following change:'}</p>
            <i>
              <strong>{parseMessage(message.isComment)}</strong>
            </i>
          </>
        ) : (
          <p className="mb-0">{message['made_by_name'] + ' requested a change to the testlet.'}</p>
        )}
      </>
    );
  } else {
    messageText = message.text ? parseMessage(message.text) : '';
  }

  return (
    <div className={classNames('p-2', { 'd-block': !isLeft })}>
      <Media>
        {/* {isLeft && <Avatar size="l" className="mr-2" src={sender.avatarSrc} />} */}
        {isLeft && (
          <p className="mb-1 fs-0">
            <strong>{sender.name}</strong>
          </p>
        )}
      </Media>
      <Media body className={classNames({ 'd-flex  justify-content-end': !isLeft })}>
        <div
          className={classNames('w-xxl-75', {
            'w-100': !isLeft
          })}
        >
          <Media className="justify-content-end">
            {/* {isLeft && <Avatar size="l" className="mr-2" src={sender.avatarSrc} />} */}
            {!isLeft && (
              <p className="mb-1 fs-0">
                <strong>You</strong>
              </p>
            )}
          </Media>
          <Flex
            align="center"
            className={classNames('hover-actions-trigger', {
              'justify-content-end': !isLeft
            })}
          >
            {/* {!isLeft && <ChatMessageOptions isUserMessage={!isLeft} />} */}
            {isIterableArray(message && message.attachment) ? (
              <div className="chat-message chat-gallery">
                {messageText}
                <LightBoxGallery images={message.attachment}>
                  {openImgIndex => (
                    <Row noGutters className="mx-n1">
                      {message.attachment.map((img, index) => {
                        return (
                          <Col xs={6} md={4} className="px-1" style={{ minWidth: 50 }} key={index}>
                            <img
                              className="img-fluid rounded mb-2 cursor-pointer"
                              src={img}
                              alt=""
                              onClick={() => openImgIndex(index)}
                            />
                          </Col>
                        );
                      })}
                    </Row>
                  )}
                </LightBoxGallery>
              </div>
            ) : (
              <>
                <div
                  className={classNames('rounded-soft fs-0', {
                    'bg-200 p-2 chat-message': isLeft && !isUpdate && !isChange,
                    'bg-primary text-white p-2 chat-message': !isLeft && !isUpdate && !isChange,
                    'bg-chat-update p-3 max-w-100 d-inline-block position-relative': isUpdate,
                    'bg-chat-change text-white p-3 chat-message': isChange
                  })}
                >
                  {messageText}
                  {message.attachment && (
                    <FalconLightBox imgSrc={message.attachment}>
                      <img src={message.attachment} className="img-fluid rounded" width={150} alt="" />
                    </FalconLightBox>
                  )}
                </div>
              </>
            )}
            {/* {isLeft && <ChatMessageOptions isUserMessage={!isLeft} />} */}
          </Flex>
          <div
            className={classNames('text-600 fs--1 mt-1', {
              'text-right': !isLeft
            })}
          >
            {time.hour}
            <br />
            Seen by {seenBy}
          </div>
        </div>
      </Media>
    </div>
  );
};

Message.propTypes = {
  message: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  sender: PropTypes.object.isRequired,
  status: PropTypes.string,
  time: PropTypes.object.isRequired,
  isChange: PropTypes.bool.isRequired,
  isGroup: PropTypes.bool.isRequired,
  isUpdate: PropTypes.bool.isRequired,
  seenBy: PropTypes.string
};

Message.defaultProps = { status: '' };

export default Message;
