import React, { Component } from 'react';
import { Modal, ModalBody, Row, Spinner } from 'reactstrap';
import PropTypes from 'prop-types';
import TestletCardExpandableContent from './TestletCardExpandableContent';
import TestletListTileContent from './TestletListTileContent';
import DraftDeleteDialog from './DraftDeleteDialog';
import DraftSubmitDialog from './DraftSubmitDialog';
import TypeBadge from './TypeBadge';
import { toast } from 'react-toastify';
import TestletService from '../services/Testlet/TestletService';
import CollaborationService from '../services/Collaboration/CollaborationService';
import DialogBox from '../components/common/DialogBox';
import { getCollabStatusBadge, getStatusDetails } from '../helpers/inbdeUtils';
import TestletActions from '../components/testlet/actions/TestletActions';
import { getExportTestletDialog, getRestoreTestletDialog } from '../components/testlet/dialogs/DialogBoxes';
import { UserContext } from '../Contexts';
import { isIterableArray } from '../helpers/utils';
import { ContentTypeBadge } from './content-badges/contentBadges';

const isStorageSupport = false;
const testletStorageKeyPrefix = 'testlet:';
class DraftTestletListTile extends Component {
  collaborationService = new CollaborationService();
  testletService = new TestletService();
  _isMounted = false;

  constructor(props) {
    super(props);

    this.state = {
      collabBadge: {},
      deleteModal: false,
      details: { className: '', tooltip: '', text: '' },
      exportModal: false,
      modal: false,
      restoreModal: false,
      spinner: false,
      submitModal: false,
      user: null,
      isLoadTestletDetails: false,
      testletDetails: null
    };

    this.deleteDialog = this.deleteDialog.bind(this);
    this.deleteTestlet = this.deleteTestlet.bind(this);
    this.handleExportTestlet = this.handleExportTestlet.bind(this);
    this.handleRestore = this.handleRestore.bind(this);
    this.loadTestletDetails = this.loadTestletDetails.bind(this);
    this.submitDialog = this.submitDialog.bind(this);
    this.submitTestlet = this.submitTestlet.bind(this);
    this.toggleModal = this.toggleModal.bind(this);
    this.toggleExport = this.toggleExport.bind(this);
    this.toggleRestore = this.toggleRestore.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;

    const { testlet, isCollab } = this.props;
    const { user } = this.context;

    const testletType = testlet.testlet_type;
    const details = getStatusDetails(testletType);
    const collabBadge = isCollab ? getCollabStatusBadge() : {};
    this.setState({ details, collabBadge, user, testletDetails: testlet });
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  deleteDialog() {
    this._isMounted && this.setState(state => ({ deleteModal: !state.deleteModal }));
  }

  async deleteTestlet() {
    this.setState({ spinner: true });

    const { refreshPage, testlet } = this.props;
    const currentTimeStamp = Date.now();
    const { testletDetails } = this.state;

    const data = testletDetails || testlet;

    const isTestletDeleted = await this.testletService.deleteTestlet(data, currentTimeStamp);

    if (isTestletDeleted) {
      toast.success('This testlet draft was deleted successfully');
      this._isMounted &&
        this.setState({ spinner: false, deleteModal: false }, () => {
          refreshPage();
        });
    } else {
      this._isMounted && this.setState({ spinner: false, deleteModal: false });
      toast.error('Could not delete this testlet at the moment!');
    }
  }

  getExportTestletDialogDetails() {
    const { exportModal } = this.state;
    const dialogDetails = getExportTestletDialog();

    dialogDetails.dialogToggleFlag = exportModal;
    dialogDetails.dialogActionButton = this.handleExportTestlet;
    dialogDetails.toggleDialogFunction = this.toggleExport;

    return dialogDetails;
  }

  getRestoreTestletDetails() {
    const { restoreModal } = this.state;

    const dialogBox = getRestoreTestletDialog();
    dialogBox.dialogToggleFlag = restoreModal;
    dialogBox.dialogActionButton = this.handleRestore;
    dialogBox.toggleDialogFunction = this.toggleRestore;

    return dialogBox;
  }

  async handleExportTestlet() {
    this._isMounted && this.setState({ spinner: true });
    const { testlet, updateContent } = this.props;
    const { testletDetails } = this.state;

    const data = testletDetails || testlet;
    const { id } = data;

    await updateContent(id, 'export', data);
  }

  async handleRestore() {
    this._isMounted && this.setState({ spinner: true });
    const { testlet, restoreTestlet, refreshPage } = this.props;
    const { testletDetails } = this.state;

    const data = testletDetails || testlet;

    const isRestored = await restoreTestlet(data);

    if (isRestored) {
      toast.success('Successfully restored testlet');
      refreshPage();
    } else {
      this._isMounted && this.setState({ spinner: false });
      toast.error('Failed to restore testlet');
    }
  }

  async loadTestletDetails() {
    // fetch testlet from session storage, otherwise from db
    const { testlet } = this.props;
    const { id: testletID } = testlet;
    const testletStorageKey = testletStorageKeyPrefix + testletID;

    if (isStorageSupport && sessionStorage.getItem(testletStorageKey)) {
      const testletDetails = sessionStorage.getItem(testletStorageKey);
      this._isMounted && this.setState({ testletDetails, isLoadTestletDetails: false });
    } else {
      const testlet = await this.testletService.getTestlet(testletID);

      if (testlet) {
        testlet['id'] = testletID;

        isStorageSupport && sessionStorage.setItem(testletStorageKey, testlet);
        this._isMounted && this.setState({ testletDetails: testlet, isLoadTestletDetails: false });
      }
    }
  }

  submitDialog() {
    this._isMounted && this.setState(state => ({ submitModal: !state.submitModal }));
  }

  async submitTestlet() {
    this._isMounted && this.setState({ spinner: true });
    const { testlet, refreshPage } = this.props;
    const { testletDetails } = this.state;
    const data = testletDetails || testlet;

    const { user } = this.state;
    const { uid: userId } = user;

    // validate testlet
    if (this.testletService.validForSubmission(data)) {
      const currentTimeStamp = Date.now();
      data['updated_on'] = currentTimeStamp;
      data['testlet_type'] = '2A';
      data['last_submitted_by'] = userId;

      const isTestletSubmitted = await this.testletService.updateTestlet(data);

      if (isTestletSubmitted) {
        toast.success('Testlet submitted successfully');

        // send email to inform owner and collaborator about testlet submission
        const { collaboratorIds, id, created_by } = data;
        const usersToNotify = [...collaboratorIds, created_by];
        this.collaborationService.sendTestletActionEmail(id, usersToNotify, '', user, 'Submitted');
      }

      this._isMounted && this.setState({ spinner: false });

      this.submitDialog();
      refreshPage();
    } else {
      this.submitDialog();
      this._isMounted && this.setState({ spinner: false });
      toast.error('Please complete all the fields before submission!');
    }
  }

  toggleModal() {
    const { testlet } = this.props;
    const { questions } = testlet;

    const { modal } = this.state;
    const isLoadTestletDetails = !modal && !isIterableArray(questions);

    this._isMounted &&
      this.setState({ modal: !modal, isLoadTestletDetails }, () => {
        // only load testlet details if model open and testlet is a search result
        if (isLoadTestletDetails) {
          this.loadTestletDetails();
        }
      });
  }

  toggleRestore() {
    this._isMounted && this.setState(state => ({ restoreModal: !state.restoreModal }));
  }

  toggleExport() {
    this._isMounted && this.setState(state => ({ exportModal: !state.exportModal }));
  }

  render() {
    const { testlet, isCollab } = this.props;
    const { details, spinner, collabBadge, user, isLoadTestletDetails, testletDetails } = this.state;

    const testletData = testletDetails || testlet;

    const flag = !!testletData.is_flagged;
    const isUserAdmin = user ? user.access_type === 'admin' : false;
    const userId = user ? user.uid : '';
    const title = testletData.testlet_information.testlet_title || '<No-Title>';
    const exportTestletDetails = this.getExportTestletDialogDetails();
    const restoreTestletDetails = this.getRestoreTestletDetails();

    return (
      <div>
        <TestletListTileContent
          title={title}
          toggleModal={this.toggleModal}
          type={testletData.testlet_type}
          statusDetails={details}
          data={testletData}
          id={testletData.id}
          collabBadge={collabBadge}
          isUserAdmin={isUserAdmin}
        />
        <Modal isOpen={this.state.modal} toggle={this.toggleModal} className="modal-lg modal-xl">
          <Row className="pl-3 pr-2 pt-3">
            <div className="d-flex col-12 col-md-6">
              <h5 className="cursor-pointer mr-2 mb-0 mt-auto">
                <ContentTypeBadge contentId={testletData.id} />
              </h5>
              <h5 className="mb-0 mt-auto">{title}</h5>
            </div>
            <div className="col-12 col-md-6">
              <Row>
                {isCollab ? (
                  <h5 className="cursor-pointer ml-auto mr-4 mb-0">
                    <TypeBadge details={collabBadge} testletId={testletData.id + 'collaborator'} />
                  </h5>
                ) : (
                  <h5 className="cursor-pointer mr-4 ml-auto mb-0">
                    <TypeBadge details={details} testletId={testletData.id} />
                  </h5>
                )}
              </Row>
            </div>
          </Row>
          <ModalBody>
            {isLoadTestletDetails ? (
              <div className="d-flex min-vh-50 align-items-center justify-content-center">
                <Spinner color="primary" className="avatar-3xl" />
              </div>
            ) : (
              <>
                <TestletCardExpandableContent data={testletData} />
                <DraftDeleteDialog
                  deleteDialog={this.deleteDialog}
                  deleteModal={this.state.deleteModal}
                  deleteTestlet={this.deleteTestlet}
                  isLoading={spinner}
                />
                <DraftSubmitDialog
                  submitDialog={this.submitDialog}
                  submitModal={this.state.submitModal}
                  submitTestlet={this.submitTestlet}
                  isLoading={spinner}
                />
                <DialogBox dialogDetails={restoreTestletDetails} isLoading={spinner} />
                <DialogBox dialogDetails={exportTestletDetails} isLoading={spinner} />
                <div className="testlet-action-buttons-container d-flex w-100 fixed-bottom testlet-action-buttons-footer-container">
                  <TestletActions
                    flag={flag}
                    isAdmin={isUserAdmin}
                    isCollab={isCollab}
                    review={false}
                    statusDetails={details}
                    testlet={testletData}
                    toggleDelete={this.deleteDialog}
                    toggleExportTestlet={this.toggleExport}
                    toggleModal={this.toggleModal}
                    toggleRestore={this.toggleRestore}
                    toggleSubmit={this.submitDialog}
                    userId={userId}
                  />
                </div>
              </>
            )}
          </ModalBody>
        </Modal>
      </div>
    );
  }
}

DraftTestletListTile.contextType = UserContext;

DraftTestletListTile.propTypes = {
  refreshPage: PropTypes.func,
  testlet: PropTypes.object,
  restoreTestlet: PropTypes.func,
  isCollab: PropTypes.bool,
  updateContent: PropTypes.func
};

export default DraftTestletListTile;
