import React, { Component } from 'react';
import Skeleton from 'react-loading-skeleton';
import { Card, CardBody, CardHeader } from 'reactstrap';

class LoadingCard extends Component {
  render() {
    return (
      <Card className="mb-2">
        <CardHeader className="bg-100 pb-0 pt-1 card-loader-header">
          <div className="col-12 pt-1 pb-1 pl-0 h-100">
            <Skeleton width="100%" height="100%" />
          </div>
        </CardHeader>
        <CardBody className="pt-2 pb-2 pl-1">
          <div className="col-12 pt-1 pb-1">
            <Skeleton width="100%" height="100%" count={4} />
          </div>
        </CardBody>
      </Card>
    );
  }
}

export default LoadingCard;
