import React from 'react';
import PropTypes from 'prop-types';
import { Card, CardBody, Row } from 'reactstrap';
import { contentTypes } from '../../../../data/content/contentTypes';
import FalconCardHeader from '../../../common/FalconCardHeader';
import { QuestionDetailCardBodyContent } from './QuestionDetailCardBodyContent';
import { getCollaboratorNameArray, getInbdePrepCourseText } from '../helpers/helpers';

export const QuestionDetailCardBody = ({ previewAttachment, questionData, contentType }) => {
  const { id, inbde_course, published_inbdeCourse, question } = questionData;
  const { collaborators } = question;

  const isQuestionStandAlone = contentType === contentTypes.question.type;
  const title = 'Question ' + (id + 1);
  const inbdePrepCourseText = getInbdePrepCourseText(inbde_course, published_inbdeCourse);
  const questionCollaborators = getCollaboratorNameArray(collaborators);

  return (
    <Card id={'question-' + id} className="mb-2 shadow-none border">
      {!isQuestionStandAlone && <FalconCardHeader title={title} titleTag="h6" />}
      <CardBody>
        <Row className="ml-0 p-2">
          <div className="col-sm-12 col-lg-3 font-weight-semi-bold">INBDE Prep Course:</div>
          <div className="col-sm-12 col-lg-9">{inbdePrepCourseText}</div>
        </Row>
        <Row className="ml-0 p-2">
          <div className="col-sm-12 col-lg-3 font-weight-semi-bold">Collaborators:</div>
          <div className="col-sm-12 col-lg-9">{questionCollaborators}</div>
        </Row>
        <QuestionDetailCardBodyContent previewAttachment={previewAttachment} question={question} />
      </CardBody>
    </Card>
  );
};

QuestionDetailCardBody.propTypes = {
  contentType: PropTypes.string,
  previewAttachment: PropTypes.func,
  questionData: PropTypes.object.isRequired
};

QuestionDetailCardBody.defaultProps = { previewAttachment: () => {} };
