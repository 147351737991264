import React from 'react';
import PropTypes from 'prop-types';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

const ReportDownloadModal = ({ modalFlag, report, toggleModal, emailReport, isError }) => {
  const reportGenerated = () => {
    const { name, url } = report;
    return (
      <>
        <div className="reports-modal-body-content">
          The report: <br />
          <strong>{name}</strong> <br />
          has been generated.
          <br />
          <br />
          You can choose to download this report or email it to yourself.
          <br />
          Or, you can close this dialog box and view the report in the past reports table.
          <br />
        </div>
        <div className="reports-modal-body-actions">
          <a href={url} target="_blank" rel="noopener noreferrer" download>
            <Button color="link">Click to download this report</Button>
          </a>
          <Button color="link" onClick={emailReport} disabled>
            Click to email this report to yourself
          </Button>
        </div>
      </>
    );
  };

  const reportNotGenerated = () => {
    return (
      <div>
        {isError ? (
          <p>
            Something went wrong - could not generate report at the moment. Please contact{' '}
            <a href="mailto:dental.inbde-prep@nyu.edu">dental.inbde-prep@nyu.edu</a> for assistance with this issue.
          </p>
        ) : (
          <p>
            No testlets matched the applied filter criterion to generate report. <br />
            <br />
            If no filter criterion results in a successful report generation, please clear the filters and simply click
            on "Run Query" to generate a report for all testlets in the system.
          </p>
        )}
      </div>
    );
  };

  return (
    <Modal isOpen={modalFlag} toggle={toggleModal}>
      <ModalHeader>Report {report ? 'Generated' : 'Not Generated'}</ModalHeader>
      <ModalBody>{report ? reportGenerated() : reportNotGenerated()}</ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={toggleModal}>
          Close
        </Button>
      </ModalFooter>
    </Modal>
  );
};

ReportDownloadModal.propTypes = {
  downloadReport: PropTypes.func,
  emailReport: PropTypes.func,
  isError: PropTypes.bool,
  modalFlag: PropTypes.bool,
  report: PropTypes.object,
  toggleModal: PropTypes.func
};

export default ReportDownloadModal;
