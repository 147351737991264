import React, { Component } from 'react';
import { Button, Row } from 'reactstrap';
import PropTypes from 'prop-types';
import InlineFilterWrapper from './InlineFilterWrapper';
import InlineFiltersSelected from './InlineFiltersSelected';
import filterOptions from '../../data/mock/filters';
import object from 'lodash/object';
import FeatureService from '../../services/Feature/FeatureService';
import { isIterableArray } from '../../helpers/utils';

class InlineFilters extends Component {
  _isMounted = false;
  featureService = new FeatureService();

  constructor(props) {
    super(props);
    this.state = {
      allOptions: filterOptions,
      filterApplied: false,
      filters: []
    };

    this.clearFilterGroup = this.clearFilterGroup.bind(this);
    this.clearForm = this.clearForm.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.onToggle = this.onToggle.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;

    const { isAdminsView } = this.props;

    const initializedFilters = filterOptions
      .filter(filterOption => {
        const { isShowAdminsOnly } = filterOption;
        if (isAdminsView) {
          return filterOption;
        } else {
          return !isShowAdminsOnly;
        }
      })
      .map(option => {
        const { options } = option;
        options.forEach(attributes => {
          attributes['selected'] = false;
        });
        return option;
      });

    this.setState({ allOptions: initializedFilters, filterApplied: false, filters: [] }, () => {
      this.initializeFilters();
    });
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  changeFilters(allOptions) {
    const { filterTestlets } = this.props;
    let newFilters = [];
    const newFiltersToShow = [];

    allOptions.forEach(filterGroup => {
      const { filterValue, label: filterKey, options, selectedFilterLabel } = filterGroup;
      const filtersToAlgolia = [];
      const filtersToShow = [];
      const selectedFiltersToShow = [];

      options.forEach(option => {
        if (option.selected) {
          const searchByFilter = object.has(option, 'value') ? option.value : option.name;
          filtersToAlgolia.push(searchByFilter);
          filtersToShow.push(option.name);
          option.selectedName
            ? selectedFiltersToShow.push(option.selectedName)
            : selectedFiltersToShow.push(option.name);
        }
      });
      if (!!filtersToAlgolia.length) {
        newFilters.push({ label: filterKey, filters: filtersToAlgolia, filterValue });
        newFiltersToShow.push({
          label: filterKey,
          filters: filtersToShow,
          filterValue,
          selectedFilterLabel,
          selectedFilters: selectedFiltersToShow
        });
      }
    });

    const filterApplied = !!newFiltersToShow.length;
    this._isMounted &&
      this.setState({
        allOptions: allOptions,
        filterApplied,
        filters: newFiltersToShow
      });

    filterTestlets(newFilters);
  }

  clearFilterGroup(label) {
    const { allOptions: checked } = this.state;

    checked.forEach(element => {
      if (element['label'] === label) {
        element['options'].forEach(option => {
          option['selected'] = false;
        });
      }
    });

    this.changeFilters(checked);
  }

  clearForm() {
    const { clearFilters } = this.props;
    const { allOptions } = this.state;

    let newState = allOptions.map(filterOptions => {
      const options = filterOptions['options'].map(option => {
        option['selected'] = false;
        return option;
      });
      filterOptions['options'] = options;
      return filterOptions;
    });

    this.setState({
      allOptions: newState,
      filterApplied: false,
      filters: []
    });

    clearFilters();
  }

  getFormattedCourseList(courseList) {
    const formattedCourses = [];
    let counter = 1;
    let lastCurriculum = '';

    const curriculumSorted = {};

    for (let curriculumKey in courseList) {
      const { curriculumOrder } = courseList[curriculumKey];
      if (!curriculumSorted[curriculumOrder]) {
        curriculumSorted[curriculumOrder] = [];
      }
      curriculumSorted[curriculumOrder].push(curriculumKey);
    }

    for (let curriculumOrder in curriculumSorted) {
      const curriculums = curriculumSorted[curriculumOrder];
      // eslint-disable-next-line no-loop-func
      curriculums.forEach(curriculumKey => {
        const curriculum = courseList[curriculumKey]['courses'] || courseList[curriculumKey];
        if (Object.keys(curriculum).length > 0) {
          formattedCourses.push({
            id: counter,
            name: curriculumKey,
            isOption: false
          });
          counter += 1;
          for (let course in curriculum) {
            const courseIdText = curriculum[course]['course_id'] || course;
            formattedCourses.push({
              courseId: course,
              title: curriculum[course]['title'],
              id: counter,
              name: courseIdText + ': ' + curriculum[course]['title'],
              selected: false,
              curriculum: curriculumKey
            });
            counter += 1;
          }
          // lastCurriculum = curriculumKey;
        }
      });
    }

    // Add mock course option at the end
    const mockCourse = {
      courseId: 'mock',
      title: 'Mock',
      id: counter,
      name: 'Mock',
      selected: false,
      curriculum: lastCurriculum,
    };
    formattedCourses.push(mockCourse);

    return formattedCourses;
  }

  handleChange(key, index, selected) {
    const { allOptions: checked } = this.state;

    for (let i = 0; i < checked.length; i++) {
      if (checked[i].key === key) {
        checked[i].options[index].selected = selected;
        break;
      }
    }

    this.changeFilters(checked);
  }

  async initializeFilters() {
    const { additionalFilters } = this.props;
    const { allOptions } = this.state;

    if (additionalFilters && isIterableArray(additionalFilters)) {
      additionalFilters.forEach(filter => {
        const filterKey = filter['key'];
        let isFilterExists = false;

        for (let i = 0; i < allOptions.length; i += 1) {
          if (allOptions[i]['key'] === filterKey) {
            isFilterExists = true;
            break;
          }
        }

        !isFilterExists && allOptions.push(filter);
      });
    }

    const courseList = await this.featureService.getCourseList();
    if (courseList) {
      for (let option = 0; option < allOptions.length; option += 1) {
        if (allOptions[option]['key'] === 'courses') {
          allOptions[option]['options'] = this.getFormattedCourseList(courseList);
          this._isMounted && this.setState({ allOptions });
          break;
        }
      }
    }
  }

  onToggle() {
    this._isMounted && this.setState(state => ({ dropdownOpen: !state.dropdownOpen }));
  }

  render() {
    const { filterApplied: isFilterApplied, allOptions: currentOptions, filters } = this.state;

    return (
      <div className="w-100 pr-3">
        <Row>
          <p className="pr-2 pl-2 pb-2 pt-0 mb-0">Filter By: </p>
        </Row>
        <Row className="mb-2">
          <InlineFilterWrapper filterOptions={currentOptions} onChange={this.handleChange} />
        </Row>
        <Row>
          <InlineFiltersSelected selectedFilters={filters} clearFilterGroup={this.clearFilterGroup} />
        </Row>
        <Row>
          {isFilterApplied && (
            <Button type="reset" color="link" className="pl-2" onClick={this.clearForm}>
              Clear Filters
            </Button>
          )}
        </Row>
      </div>
    );
  }
}

InlineFilters.propTypes = {
  clearFilters: PropTypes.func,
  filterTestlets: PropTypes.func,
  additionalFilters: PropTypes.array,
  isAdminsView: PropTypes.bool
};

InlineFilters.defaultProps = { isAdminsView: false };

export default InlineFilters;
