import React from 'react';
import PropTypes from 'prop-types';
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { isIterableArray } from '../../helpers/utils';
import classNames from 'classnames';

const INBDETableRowActions = ({ actionItems }) => {
  const getActionItems = () => {
    const menuOptions =
      actionItems && isIterableArray(actionItems) ? (
        actionItems.map(item => {
          if (item.isMenuItem) {
            return (
              <DropdownItem
                key={item.key}
                onClick={item.onClickAction}
                className={classNames(item.className, {
                  'd-none': item.isHide
                })}
              >
                {item.label}
              </DropdownItem>
            );
          } else {
            return <DropdownItem key={item.key} divider />;
          }
        })
      ) : (
        <div />
      );
    return menuOptions;
  };

  return (
    <UncontrolledDropdown>
      <DropdownToggle color="link" size="sm" className="text-600 btn-reveal mr-3">
        <FontAwesomeIcon icon="ellipsis-h" className="fs--1" />
      </DropdownToggle>
      <DropdownMenu right className="border py-2">
        {getActionItems()}
      </DropdownMenu>
    </UncontrolledDropdown>
  );
};

INBDETableRowActions.propTypes = {
  actionItems: PropTypes.array
};

INBDETableRowActions.defaultProps = {
  actionItems: []
};

export default INBDETableRowActions;
