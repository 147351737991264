import React, { Fragment, useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { ButtonDropdown, DropdownMenu, DropdownItem, DropdownToggle } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { contentTypes } from '../../../data/content/contentTypes';
import CreateContentModal from './CreateContentModal';
import { UserContext } from '../../../Contexts';
import { isFeatureActive } from '../../../helpers/inbdeUtils';
import featureToggles from '../../../services/Feature/toggles';

const CreateContentButton = ({ route }) => {
  const [isToggleOpen, setIsToggleOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState({});
  const [contentOptions, setContentOptions] = useState([]);
  const { toggles, user } = useContext(UserContext);

  const launchContentModal = content => {
    setModalContent(content);
    toggleContentModal();
  };

  const toggleContentModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  useEffect(() => {
    const { email } = user;
    const updatedContent = [];
    for (let content in contentTypes) {
      if (content === 'question') {
        contentTypes['question'].enabled = isFeatureActive(
          featureToggles.isStandAloneQuestionsEnabled,
          toggles,
          null,
          email
        );
      }
      updatedContent.push(contentTypes[content]);
    }
    setContentOptions(updatedContent);
  }, [toggles, user]);

  return (
    <Fragment>
      <ButtonDropdown
        nav
        isOpen={isToggleOpen}
        toggle={() => setIsToggleOpen(!isToggleOpen)}
        color="nav-link-icon"
        size="sm"
      >
        <DropdownToggle size="sm" color="nav-link-icon pl-0 text-left">
          {route.icon && (
            <span className="nav-link-icon mr-2">
              <FontAwesomeIcon icon={route.icon} />
            </span>
          )}
          <span className="fs--1">{route.name}</span>
        </DropdownToggle>
        <DropdownMenu className="overflow-hidden">
          {contentOptions.map(
            content =>
              content.enabled && (
                <DropdownItem key={content.type} onClick={() => launchContentModal(content)}>
                  {content.name}
                </DropdownItem>
              )
          )}
        </DropdownMenu>
      </ButtonDropdown>
      <CreateContentModal
        contentName={modalContent.name}
        contentType={modalContent.type}
        isModalOpen={isModalOpen}
        toggleModal={toggleContentModal}
      />
    </Fragment>
  );
};

CreateContentButton.propTypes = {
  route: PropTypes.shape({
    icon: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
    name: PropTypes.string.isRequired,
    to: PropTypes.string.isRequired
  }).isRequired
};

export default CreateContentButton;
