import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Form, Label, Input, FormGroup, Spinner } from 'reactstrap';
import { UserContext } from '../../Contexts';
import formData from '../../data/mock/testletForm';
import { toast } from 'react-toastify';
import Select from 'react-select';
import { isIterableArray } from '../../helpers/utils';

class DialogBox extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);

    this.state = { value: '', userName: '', inbdeYearSemester: null, inbdeCourse: null, publishedInbdeCourses: [] };

    this.handleChange = this.handleChange.bind(this);
    this.submitDialog = this.submitDialog.bind(this);
    this.toggleModal = this.toggleModal.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;

    const { user } = this.context;
    const { data } = this.props;
    let { publishedInbdeCourses } = this.state;

    if (data) {
      const { published_inbdeCourse: existingCourses } = data;
      publishedInbdeCourses = existingCourses;
    }
    this.setState({ userName: user ? user.displayName : null, publishedInbdeCourses });
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  handleChange(event) {
    this._isMounted && this.setState({ value: event.target.value });
  }

  submitDialog(event) {
    event && event.preventDefault();
    const { dialogDetails } = this.props;
    const { value, inbdeYearSemester, inbdeCourse, publishedInbdeCourses } = this.state;

    if (dialogDetails.id === 'publishTestlet') {
      if (inbdeCourse) {
        dialogDetails.dialogActionButton(inbdeCourse, inbdeYearSemester);
      } else toast.error('Please select an INBDE Prep Course before publishing!');
    } else if (dialogDetails.id === 'flagTestlet') {
      if (value) {
        dialogDetails.dialogActionButton(value);
      } else toast.error('Please enter a comment while flagging this testlet!');
    } else if (dialogDetails.id === 'republishTestlet') {
      if (publishedInbdeCourses && isIterableArray(publishedInbdeCourses)) {
        dialogDetails.dialogActionButton(publishedInbdeCourses);
      } else toast.error('Please select an INBDE Prep Course before re-publishing!');
    } else dialogDetails.dialogActionButton(value);
  }

  toggleModal() {
    const { dialogDetails, data } = this.props;
    const { toggleDialogFunction, id, dialogToggleFlag } = dialogDetails;
    toggleDialogFunction();
    if (id === 'republishTestlet' && data && dialogToggleFlag) {
      const { published_inbdeCourse: existingCourses } = data;
      this._isMounted && this.setState({ publishedInbdeCourses: existingCourses });
    }
  }

  render() {
    const { userName, value, inbdeCourse, publishedInbdeCourses } = this.state;
    const { dialogDetails, isLoading } = this.props;
    const { id, dialogToggleFlag, title, bodyText, date, buttonText } = dialogDetails;
    const buttonColor = dialogDetails.buttonColor ? dialogDetails.buttonColor : 'primary';

    return (
      <Modal id={id} isOpen={dialogToggleFlag} toggle={this.toggleModal}>
        <ModalHeader toggle={this.toggleModal}>{title}</ModalHeader>
        <Form onSubmit={this.submitDialog}>
          <ModalBody>
            <Label>{bodyText}</Label>
            {(id === 'rejectTestlet' || id === 'unflagTestlet') && (
              <Input
                className="p-2 mb-2"
                draggable={false}
                id={id}
                name={id + 'Comment'}
                onChange={this.handleChange}
                placeholder="Please enter your comment..."
                type="textarea"
                value={value}
                required
              />
            )}
            {id === 'cloneTestlet' && (
              <Input
                id="cloneName"
                name="cloneName"
                onChange={this.handleChange}
                placeholder="Please enter a unique name..."
                type="text"
                value={value}
                required
              />
            )}
            {id === 'flagTestlet' && (
              <FormGroup>
                <Input
                  className="p-2 mb-2"
                  id="flagModalComment"
                  name="flagModalComment"
                  onChange={this.handleChange}
                  placeholder="Please enter your comment..."
                  type="textarea"
                  value={value}
                  required
                />
                <Label className="mb-0">By: {userName}</Label>
                <br />
                <Label className="text-500">Date: {date}</Label>
              </FormGroup>
            )}
            {id === 'publishTestlet' && (
              <FormGroup>
                <Label for="readonly" className="fs-0">
                  INBDE Prep Course
                </Label>
                <Select
                  value={inbdeCourse}
                  onChange={inbdeCourse => this.setState({ inbdeCourse })}
                  options={formData.inbdeCourse}
                  isMulti
                />
                <br />
              </FormGroup>
            )}
            {id === 'republishTestlet' && (
              <FormGroup>
                <Label for="readonly" className="fs-0">
                  INBDE Prep Course(s)
                </Label>
                <Select
                  value={publishedInbdeCourses}
                  onChange={publishedInbdeCourses => this.setState({ publishedInbdeCourses })}
                  options={formData.inbdeCourse}
                  isMulti
                />
                <br />
              </FormGroup>
            )}
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={this.toggleModal} disabled={isLoading}>
              Cancel
            </Button>
            <Button color={buttonColor} onClick={this.submitDialog} disabled={isLoading}>
              {buttonText}
              {isLoading && <Spinner size="sm" color="light" className="ml-2" />}
            </Button>{' '}
          </ModalFooter>
        </Form>
      </Modal>
    );
  }
}

DialogBox.contextType = UserContext;

DialogBox.propTypes = {
  dialogDetails: PropTypes.object,
  isLoading: PropTypes.bool,
  data: PropTypes.object
};

export default DialogBox;
