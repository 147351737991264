import React from 'react';
import PropTypes from 'prop-types';
import { CardFooter, Row } from 'reactstrap';
import { AdminStatusBadge, ContentTypeBadge } from '../../../widgets/content-badges/contentBadges';

export const ContentSummaryCardFooter = ({
  contentId: id,
  contentStatus,
  contentType,
  isUserAdmin,
  openedDaysAgoText
}) => (
  <CardFooter className="pt-0">
    <Row>
      <div className="ml-auto">
        {Boolean(openedDaysAgoText) && <h6 className="mb-0 mr-2 d-inline">Opened {openedDaysAgoText}</h6>}
        {Boolean(isUserAdmin) && (
          <h5 className="cursor-pointer mb-0 mr-4 d-inline">
            <AdminStatusBadge contentId={id} contentStatus={contentStatus} />
          </h5>
        )}
        <h5 className="cursor-pointer mb-0 mr-2 d-inline">
          <ContentTypeBadge contentId={id} contentType={contentType} />
        </h5>
      </div>
    </Row>
  </CardFooter>
);

ContentSummaryCardFooter.propTypes = {
  contentId: PropTypes.string.isRequired,
  contentStatus: PropTypes.string.isRequired,
  contentType: PropTypes.string.isRequired,
  isUserAdmin: PropTypes.bool,
  openedDaysAgoText: PropTypes.string
};
