import React from 'react';
import PropTypes from 'prop-types';
import { Route, Switch, Redirect } from 'react-router-dom';
import ReviewPage from '../pages/ReviewPage';
import ManageAccessPage from '../pages/ManageAccessPage';
import ReportsPage from '../pages/ReportsPage';
import AllTestletsAdmin from '../pages/AllTestletsAdmin';
import CourseManagement from '../pages/CourseManagement';
import {
  adminAllContentPath,
  adminCoursePath,
  adminManageAccessPath,
  adminReportPath,
  adminReviewContentPath
} from '../routes';

class AdminRoutes extends React.Component {
  constructor(props) {
    super(props);

    const { userAccess } = props;
    const isAdmin = userAccess === 'admin';

    if (!isAdmin) {
      // eslint-disable-next-line react/prop-types
      props.history.push('/');
    }
  }

  render() {
    return (
      <>
        <Switch>
          <Route path={adminAllContentPath} component={AllTestletsAdmin} />
          <Route path={adminReviewContentPath} component={ReviewPage} />
          <Route path={adminManageAccessPath} exact component={ManageAccessPage} />
          <Route path={adminCoursePath} exact component={CourseManagement} />
          <Route path={adminReportPath} exact component={ReportsPage} />
          <Redirect to="/errors/404" />
        </Switch>
      </>
    );
  }
}

AdminRoutes.propTypes = {
  userAccess: PropTypes.string
};

export default AdminRoutes;
