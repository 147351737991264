import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link } from 'react-router-dom';

class StatusTabs extends Component {
  constructor(props) {
    super(props);

    this.handleClick = this.handleClick.bind(this);
  }

  handleClick(status) {
    const { changeTab, isLoading } = this.props;
    !isLoading && changeTab(status);
  }

  render() {
    const { isLoading, selectedTab, stasuses: statusList, statusCount, isShowCount } = this.props;
    const mediumScreenClass =
      statusList.length > 3
        ? 'status-tab-width-sm-3 status-tab-width-lg-2'
        : 'status-tab-width-sm-3 status-tab-width-lg-3';
    const cursorClassName = isLoading ? '' : 'cursor-pointer';

    return (
      <div className="status-tabs d-flex pr-2 overflow-auto flex-wrap flex-md-nowrap">
        {statusList.map((status, index) => {
          return (
            <div
              key={index}
              className={
                selectedTab === status
                  ? `d-flex w-100 p-2 bg-400 ${mediumScreenClass} ${cursorClassName}`
                  : `d-flex w-100 p-2 ${mediumScreenClass} ${cursorClassName}`
              }
            >
              <Link to={status.linkTo} className="w-100 text-decoration-none">
                <div className="w-100 d-flex justify-content-between" onClick={this.handleClick.bind(this, status)}>
                  <h5
                    className={classNames('mb-0 ml-auto', {
                      'mr-auto': !isShowCount
                    })}
                  >
                    {status.value}
                  </h5>
                  {isShowCount && <h6 className="mb-0 mt-1 ml-3 ml-md-2 mr-auto">({statusCount[index]})</h6>}
                </div>
              </Link>
            </div>
          );
        })}
      </div>
    );
  }
}

StatusTabs.propTypes = {
  isLoading: PropTypes.bool,
  changeTab: PropTypes.func.isRequired,
  selectedTab: PropTypes.object.isRequired,
  stasuses: PropTypes.array.isRequired,
  statusCount: PropTypes.array,
  isShowCount: PropTypes.bool
};

StatusTabs.defaultProps = { isLoading: false };

export default StatusTabs;
