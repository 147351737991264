import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Route, Switch, Redirect } from 'react-router-dom';
import { notFoundError } from '../routes';
import TestletForm from '../pages/TestletForm';
import ViewTestletPage from '../pages/ViewTestletPage';

const validTestletActions = { edit: 'edit', view: 'view' };

const TestletRoutes = ({ action, contentId }) => {
  const [isEditTestlet, setIsEditTestlet] = useState(false);
  const [isRedirect, setIsRedirect] = useState(false);
  const [isViewTestlet, setIsViewTestlet] = useState(false);

  useEffect(() => {
    switch (validTestletActions[action]) {
      case 'edit':
        setIsEditTestlet(true);
        break;
      case 'view':
        setIsViewTestlet(true);
        break;
      default:
        setIsRedirect(true);
        break;
    }
  }, [action]);

  return (
    <Switch>
      {isEditTestlet && <Route render={props => <TestletForm {...props} testletId={contentId} />} />}
      {isViewTestlet && <Route render={props => <ViewTestletPage {...props} testletId={contentId} />} />}
      {isRedirect && <Redirect to={notFoundError} />}
    </Switch>
  );
};

TestletRoutes.propTypes = {
  contentId: PropTypes.string.isRequired,
  action: PropTypes.string.isRequired
};

export default TestletRoutes;
