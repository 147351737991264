import React from 'react';
import PropTypes from 'prop-types';
import { Button, Label, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

export const UpdateCollaborationModal = ({ collaborationAction, contentType, handleConfirm, isSendEmail, isOpen, toggle }) => {
  const isAddCollaborator = collaborationAction === 'add';
  const emailInviteText = isSendEmail ? ' An email invite will be sent to notify them.' : '';

  return (
    <Modal id="collabModal" isOpen={isOpen} toggle={() => toggle()}>
      <ModalHeader toggle={() => toggle()}>{isAddCollaborator ? 'Add' : 'Remove'} Collaborator</ModalHeader>
      <ModalBody>
        <Label for="collabModalComment">
          Are you sure you want to{' '}
          {isAddCollaborator
            ? `add the selected faculty member as collaborator for this ${contentType}?${emailInviteText}`
            : `remove the added collaborator from this ${contentType}? They will no longer be able to contribute to this ${contentType}.`}
        </Label>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={() => toggle()}>
          Cancel
        </Button>
        <Button color={isAddCollaborator ? 'success' : 'danger'} onClick={() => handleConfirm()}>
          {isAddCollaborator ? 'Add' : 'Remove'}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

UpdateCollaborationModal.propTypes = {
  collaborationAction: PropTypes.string.isRequired,
  contentType: PropTypes.string,
  handleConfirm: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  isOpen: PropTypes.bool.isRequired,
  isSendEmail: PropTypes.bool,
  toggle: PropTypes.func.isRequired
};

UpdateCollaborationModal.defaultProps = {
  contentType: 'testlet',
  isSendEmail: false
};
