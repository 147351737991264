import React from 'react';
import PropTypes from 'prop-types';
import { Redirect, Route, Switch } from 'react-router';
import { appError, contentBasePath, notFoundError, questionPath, testletPath } from '../routes';
import QuestionRoutes from './QuestionRoutes';
import TestletRoutes from './TestletRoutes';
import { isIterableArray } from '../helpers/utils';

const isValidContentUrl = pathArray => {
  /*
  valid url format: {contentType}/{contentId}/{action}
  */
  if (pathArray.length !== 3) {
    return false;
  }

  const contentType = '/' + pathArray[0];
  if (!contentBasePath.includes(contentType)) {
    return false;
  }

  return true;
};

const ContentRoutes = ({ location }) => {
  // get content Id and action in this class and pass it to specific routes to decide
  let contentAction = '';
  let contentId = '';
  let isRedirect = false;
  let redirectUrl = notFoundError;

  try {
    const path = location.pathname;
    let pathSliced = path.split('/');

    if (isIterableArray(pathSliced)) {
      if (pathSliced[0] === '') {
        pathSliced.shift();
      }

      if (isValidContentUrl(pathSliced)) {
        contentId = pathSliced[pathSliced.length - 2];
        contentAction = pathSliced[pathSliced.length - 1];
      } else {
        isRedirect = true;
      }
    } else {
      isRedirect = true;
    }
  } catch (_error) {
    redirectUrl = appError;
  }

  return (
    <Switch>
      <Route
        path={testletPath}
        render={props => <TestletRoutes {...props} action={contentAction} contentId={contentId} />}
      />
      <Route
        path={questionPath}
        render={props => <QuestionRoutes {...props} action={contentAction} contentId={contentId} />}
      />
      {isRedirect && <Redirect to={redirectUrl} />}
    </Switch>
  );
};

ContentRoutes.propTypes = { location: PropTypes.object.isRequired };

export default ContentRoutes;
