const formData = {
  questions: [
    {
      uuid: '',
      id: 0,
      collaborators: [],
      course: {},
      course_semester: {},
      created_by: '',
      created_at: '',
      creator_type: '',
      course_year: {},
      dds_course_semester: null,
      question_creator: '',
      foundational_knowledge: [],
      clinical_content: [],
      systems_areas: [],
      difficulty_level: [],
      key_concepts: [],
      review_material: [],
      question_stem: {
        text: '',
        attachments: []
      },
      answers: [
        {
          id: 0,
          answer_choice: {
            text: '',
            attachments: []
          },
          explanation: {
            text: '',
            attachments: []
          },
          isCorrect: false
        },
        {
          id: 1,
          answer_choice: {
            text: '',
            attachments: []
          },
          explanation: {
            text: '',
            attachments: []
          },
          isCorrect: false
        }
      ]
    }
  ],
  collaborators: [
    {
      value: 1,
      label: 'David Lee'
    },
    {
      value: 2,
      label: 'Yang Chen'
    },
    {
      value: 3,
      label: 'Tiger Woods'
    },
    {
      value: 4,
      label: 'Peter Sellers'
    },
    {
      value: 5,
      label: 'Ayrton Senna'
    },
    {
      value: 6,
      label: 'Ajmal Khan'
    },
    {
      value: 7,
      label: 'Sandeep Chowdry'
    }
  ],
  courseYearOptions: [
    { value: 1, label: 'Head and Neck Anatomy' },
    { value: 2, label: 'Basic Tissue' },
    { value: 3, label: 'Cell Organelles' }
  ],
  semesterOptions: [{ value: 1, label: 'Fall' }, { value: 2, label: 'Spring' }],
  ddsYearOptions: [
    { value: 1, label: 'D1' },
    { value: 2, label: 'D2' },
    { value: 3, label: 'D3' },
    { value: 4, label: 'D4' }
  ],
  adminInbdeCourse: [
    { value: 1, label: 'INBDE Prep, SP20, D1' },
    { value: 2, label: 'INBDE Prep, FA20, D2' },
    { value: 3, label: 'INBDE Prep, SP21, D1' },
    { value: 4, label: 'INBDE Prep, SP21, D2' },
    { value: 5, label: 'INBDE Prep, FA21, D2' },
    { value: 6, label: 'INBDE Prep, FA21, D3' },
    { value: 7, label: 'INBDE Prep, SP22, D1' },
    { value: 8, label: 'INBDE Prep, SP22, D2' },
    { value: 9, label: 'INBDE Prep, SP22, D3' },
    { value: 10, label: 'INBDE Prep, FA22, D2' },
    { value: 11, label: 'INBDE Prep, FA22, D3' },
    { value: 12, label: 'INBDE Prep, FA22, D4' }
  ],
  inbdeCourse: [
    { value: 1, label: 'INBDE Prep, SP20, D1' },
    { value: 2, label: 'INBDE Prep, FA20, D2' },
    { value: 3, label: 'INBDE Prep, SP21, D1' },
    { value: 4, label: 'INBDE Prep, SP21, D2' },
    { value: 5, label: 'INBDE Prep, FA21, D2' },
    { value: 6, label: 'INBDE Prep, FA21, D3' },
    { value: 7, label: 'INBDE Prep, SP22, D1' },
    { value: 8, label: 'INBDE Prep, SP22, D2' },
    { value: 9, label: 'INBDE Prep, SP22, D3' },
    { value: 10, label: 'INBDE Prep, FA22, D2' },
    { value: 11, label: 'INBDE Prep, FA22, D3' },
    { value: 12, label: 'INBDE Prep, FA22, D4' },
    { value: 13, label: 'INBDE Prep, SP23, D1' },
    { value: 14, label: 'INBDE Prep, SP23, D2' },
    { value: 15, label: 'INBDE Prep, SP23, D3' },
    { value: 16, label: 'INBDE Prep, SP23, D4' },
    { value: 17, label: 'INBDE Prep, FA23, D2' },
    { value: 18, label: 'INBDE Prep, FA23, D3' },
    { value: 19, label: 'INBDE Prep, FA23, D4' },
    { value: 20, label: 'INBDE Prep, SP24, D1' },
    { value: 21, label: 'INBDE Prep, SP24, D2' },
    { value: 22, label: 'INBDE Prep, SP24, D3' },
    { value: 23, label: 'INBDE Prep, SP24, D4' },
    { value: 24, label: 'INBDE Prep, FA24, D2' },
    { value: 25, label: 'INBDE Prep, FA24, D3' },
    { value: 26, label: 'INBDE Prep, FA24, D4' },
    { value: 27, label: 'INBDE Prep, SP25, D1' },
    { value: 28, label: 'INBDE Prep, SP25, D2' },
    { value: 29, label: 'INBDE Prep, SP25, D3' },
    { value: 30, label: 'INBDE Prep, SP25, D4' },
    { value: 31, label: 'INBDE Prep, FA25, D2' },
    { value: 32, label: 'INBDE Prep, FA25, D3' },
    { value: 33, label: 'INBDE Prep, FA25, D4' },
    { value: 34, label: 'INBDE Prep, SP26, D1' },
    { value: 35, label: 'INBDE Prep, SP26, D2' },
    { value: 36, label: 'INBDE Prep, SP26, D3' },
    { value: 37, label: 'INBDE Prep, SP26, D4' },
    { value: 38, label: 'INBDE Prep, FA26, D2' },
    { value: 39, label: 'INBDE Prep, FA26, D3' },
    { value: 40, label: 'INBDE Prep, FA26, D4' },
    { value: 41, label: 'INBDE Prep, SP27, D1' },
    { value: 42, label: 'INBDE Prep, SP27, D2' },
    { value: 43, label: 'INBDE Prep, SP27, D3' },
    { value: 44, label: 'INBDE Prep, SP27, D4' },
    { value: 45, label: 'INBDE Prep, FA27, D2' },
    { value: 46, label: 'INBDE Prep, FA27, D3' },
    { value: 47, label: 'INBDE Prep, FA27, D4' }
  ],
  diffcultyLevelOptions: [
    { value: 1, label: 'Application' },
    { value: 2, label: 'Recall' },
    { value: 3, label: 'Synthesis' }
  ],
  systemAreasOptions: [
    { value: 1, label: 'Cancer' },
    { value: 2, label: 'Cardiovascular' },
    { value: 3, label: 'Endocrine' },
    { value: 4, label: 'Gastrointestinal' },
    { value: 5, label: 'Head and Neck' },
    { value: 6, label: 'Hematological' },
    { value: 7, label: 'Hepatobilliary' },
    { value: 8, label: 'Immune/Infectious Diseases' },
    { value: 9, label: 'Integuments (skin and mucosa)' },
    { value: 10, label: 'Musculoskeletal' },
    { value: 11, label: 'Nervous/neuropsychology' },
    { value: 12, label: 'Pulmonary' },
    { value: 13, label: 'Renal' },
    { value: 14, label: 'Reproductive' },
    { value: 15, label: 'Not Applicable' }
  ],
  inbdeYearSemester: [
    { value: 1, label: 'Spring, 2020', semester: 'Spring', year: 2020 },
    { value: 2, label: 'Fall, 2020', semester: 'Fall', year: 2020 },
    { value: 3, label: 'Spring, 2021', semester: 'Spring', year: 2021 },
    { value: 4, label: 'Fall, 2021', semester: 'Fall', year: 2021 },
    { value: 5, label: 'Spring, 2022', semester: 'Spring', year: 2022 },
    { value: 6, label: 'Fall, 2022', semester: 'Fall', year: 2022 },
    { value: 7, label: 'Spring, 2023', semester: 'Spring', year: 2023 },
    { value: 8, label: 'Fall, 2023', semester: 'Fall', year: 2023 },
    { value: 9, label: 'Spring, 2024', semester: 'Spring', year: 2024 }
  ],
  semesterMockExamOptions: [
    { value: 1, label: 'Mock Exam, SP22, D3', semester: 'Spring' },
    { value: 2, label: 'Mock Exam, SU22, D3', semester: 'Summer' },
    { value: 3, label: 'Mock Exam, FA22, D4', semester: 'Fall' },
    { value: 4, label: 'Mock Exam, SP23, D3', semester: 'Spring' },
    { value: 5, label: 'Mock Exam, SU23, D3', semester: 'Summer' },
    { value: 6, label: 'Mock Exam, FA23, D4', semester: 'Fall' }
  ],
  foundationalKnowledgeOptions: [
    {
      value: 1,
      label: 'FK-1: Molecular, biochemical, cellular, and systems-level development, structure and function'
    },
    { value: 2, label: 'FK-2: Physics and chemistry to explain normal biology and pathobiology ' },
    {
      value: 3,
      label: 'FK-3: Physics and chemistry to explain the characteristics and use of technologies and materials'
    },
    {
      value: 4,
      label:
        'FK-4: Principles of genetic congenital and developmental diseases and conditions and their clinical features to understand patient risk   '
    },
    {
      value: 5,
      label: 'FK-5: Cellular and molecular bases of immune and non-immune host defense mechanisms '
    },
    { value: 6, label: 'FK-6: General and disease-specific pathology to assess patient risk' },
    { value: 7, label: 'FK-7: Biology of micro-organisms in physiology and pathology' },
    { value: 8, label: 'FK-8: Pharmacology' },
    { value: 9, label: 'FK-9: Behavioral sciences, ethics and jurisprudence' },
    { value: 10, label: 'FK-10: Research methodology and analysis and informatics tools' },
    { value: 11, label: 'Not Applicable' }
  ],
  clinicalContentOptions: [
    { value: 0, label: 'Diagnosis and Treatment Planning', isDisabled: true, className: 'text-black' },
    {
      value: 1,
      label: 'CC-1: Interpret patient information and medical data to assess and manage patients',
      isDisabled: false
    },
    {
      value: 2,
      label: 'CC-2: Identify the chief complaint and understand the contributing factors.',
      isDisabled: false
    },
    {
      value: 3,
      label:
        'CC-3: Perform head and neck and intraoral examinations, interpreting and evaluating the clinical findings',
      isDisabled: false
    },
    {
      value: 4,
      label:
        'CC-4: Use clinical and epidemiological data to diagnose and establish a prognosis for dental abnormalities and pathology',
      isDisabled: false
    },
    {
      value: 5,
      label:
        'CC-5: Recognize the normal range of clinical findings and distinguish significant deviations that require monitoring, treatment, or management',
      isDisabled: false
    },
    {
      value: 6,
      label: 'CC-6: Predict the most likely diagnostic result given available patient information',
      isDisabled: false
    },
    {
      value: 7,
      label: 'CC-7: Interpret diagnostic results to inform understanding of the patient`s condition',
      isDisabled: false
    },
    {
      value: 8,
      label:
        'CC-8: Recognize the manifestations of systemic disease and how the disease and its management may affect the delivery of dental care',
      isDisabled: false
    },
    {
      value: 9,
      label:
        'CC-9: Recognize the interrelationship between oral health and systemic disease, and implement strategies for improving overall health',
      isDisabled: false
    },
    {
      value: 10,
      label: 'CC-10: Select the diagnostic tools most likely to establish or confirm the diagnosis',
      isDisabled: false
    },
    {
      value: 11,
      label:
        'CC-11: Collect information from diverse sources (patient, guardian, patient records, allied staff, and other healthcare professionals) to make informed decisions',
      isDisabled: false
    },
    {
      value: 12,
      label: 'CC-12: Formulate a comprehensive diagnosis and treatment plan for patient management',
      isDisabled: false
    },
    {
      value: 13,
      label:
        'CC 13: Discuss etiologies, treatment alternatives, and prognoses with patients so they are educated and can make informed decisions concerning the management of their care',
      isDisabled: false
    },
    {
      value: 14,
      label:
        'CC 14: Understand how patient attributes (e.g., gender, age, race, ethnicity, and special needs), social background and values influence the provision of oral health care at all stages of life',
      isDisabled: false
    },
    {
      value: 15,
      label: 'CC 15: Interact and communicate with patients using psychological, social, and behavioral principles',
      isDisabled: false
    },
    {
      value: 16,
      label: 'CC 16: Prevent, recognize and manage medical emergencies (e.g., cardiac arrest)',
      isDisabled: false
    },
    { value: 17, label: 'CC 17: Prevent, recognize and manage dental emergencies', isDisabled: false },
    { value: 0, label: 'Oral Health Management', isDisabled: true, className: 'text-black' },
    {
      value: 18,
      label: 'CC-18: Recognize and manage acute pain, hemorrhage, trauma, and infection of the orofacial complex ',
      isDisabled: false
    },
    { value: 19, label: 'CC-19: Prevent, diagnose and manage pain during treatment ', isDisabled: false },
    { value: 20, label: 'CC-20: Prevent, diagnose and manage pulpal and periradicular diseases ', isDisabled: false },
    { value: 21, label: 'CC-21: Prevent, diagnose and manage caries ', isDisabled: false },
    { value: 22, label: 'CC-22: Prevent, diagnose and manage periodontal diseases ', isDisabled: false },
    { value: 23, label: 'CC-23: Prevent, diagnose and manage oral mucosal and osseous diseases ', isDisabled: false },
    { value: 24, label: 'CC-24: Recognize, manage and report patient abuse and neglect ', isDisabled: false },
    { value: 25, label: 'CC-25: Recognize and manage substance abuse ', isDisabled: false },
    {
      value: 26,
      label: 'CC-26: Select and administer or prescribe pharmacological agents in the treatment of dental patients ',
      isDisabled: false
    },
    {
      value: 27,
      label:
        'CC-27: Anticipate, prevent, and manage complications arising from the use of therapeutic and pharmacological agents in patient care ',
      isDisabled: false
    },
    {
      value: 28,
      label: 'CC-28: Diagnose endodontic conditions and peerform endodontic procedures ',
      isDisabled: false
    },
    {
      value: 29,
      label: 'CC-29: Diagnose and manage the restorative needs of edentulous and partially edentulous patients ',
      isDisabled: false
    },
    {
      value: 30,
      label:
        'CC-30: Restore tooth function, structure, and esthetics by replacing missing and defective tooth structure, while promoting soft and hard tissue health ',
      isDisabled: false
    },
    {
      value: 31,
      label:
        'CC-31: Perform prosthetic restorations (fixed or removable) and implant procedures for edentulous and partially edentulous patients ',
      isDisabled: false
    },
    { value: 32, label: 'CC-32: Diagnose and manage oral surgical treatment needs ', isDisabled: false },
    { value: 33, label: 'CC-33: Perform oral surgical procedures ', isDisabled: false },
    {
      value: 34,
      label: 'CC-34: Prevent, diagnose and manage developmental or acquired occlusal problems ',
      isDisabled: false
    },
    { value: 35, label: 'CC-35: Prevent, diagnose and manage temporomandibular disorders ', isDisabled: false },
    {
      value: 36,
      label:
        'CC-36: Diagnose and manage patients requiring modification of oral tissues to optimize form, function and esthetics ',
      isDisabled: false
    },
    { value: 37, label: 'CC-37: Evaluate outcomes of comprehensive dental care ', isDisabled: false },
    { value: 38, label: 'CC-38: Manage the oral esthetic needs of patients', isDisabled: false },
    { value: 0, label: 'Practice and Profession', isDisabled: true, className: 'text-black' },
    { value: 39, label: 'CC-39: Evaluate and integrate emerging trends in health care ', isDisabled: false },
    {
      value: 40,
      label: 'CC-40: Evaluate social and economic trends and adapt to accommodate their impact on oral health care ',
      isDisabled: false
    },
    {
      value: 41,
      label:
        'CC-41: Evaluate scientific literature and integrate new knowledge and best research outcomes with patient values and other sources of information to make decisions about treatment ',
      isDisabled: false
    },
    {
      value: 42,
      label:
        'CC-42: Practice within the general dentist`s scope of competence and consult with or refer to professional colleagues when indicated ',
      isDisabled: false
    },
    {
      value: 43,
      label:
        'CC-43: Evaluate and utilize available and emerging resources (e g , laboratory and clinical resources, information technology) to facilitate patient care, practice management, and professional development ',
      isDisabled: false
    },
    {
      value: 44,
      label:
        'CC-44: Conduct practice activities in a manner that manages risk and is consistent with jurisprudence and ethical requirements in dentistry and healthcare ',
      isDisabled: false
    },
    {
      value: 45,
      label: 'CC-45: Recognize and respond to situations involving ethical and jurisprudence considerations ',
      isDisabled: false
    },
    {
      value: 46,
      label: 'CC-46: Maintain patient records in accordance with jurisprudence and ethical requirements ',
      isDisabled: false
    },
    {
      value: 47,
      label:
        'CC-47: Conduct practice related business activities and financial operations in accordance with sound business practices and jurisprudence (e g , OSHA and HIPAA) ',
      isDisabled: false
    },
    { value: 48, label: 'CC-48: Develop a catastrophe preparedness plan for the dental practice ', isDisabled: false },
    {
      value: 49,
      label: 'CC-49: Manage, coordinate and supervise the activity of allied dental health personnel ',
      isDisabled: false
    },
    {
      value: 50,
      label: 'CC-50: Assess one`s personal level of skills and knowledge relative to dental practice ',
      isDisabled: false
    },
    {
      value: 51,
      label: 'CC-51: Adhere to standard precautions for infection control for all clinical procedures ',
      isDisabled: false
    },
    {
      value: 52,
      label: 'CC-52: Use prevention, intervention, and patient education strategies to maximize oral health ',
      isDisabled: false
    },
    {
      value: 53,
      label:
        'CC-53: Collaborate with dental team members and other health care professionals to promote health and manage disease in communities ',
      isDisabled: false
    },
    {
      value: 54,
      label:
        'CC-54: Evaluate and implement systems of oral health care management and delivery that will address the needs of patient populations served ',
      isDisabled: false
    },
    {
      value: 55,
      label: 'CC-55: Apply quality assurance, assessment and improvement concepts to improve outcomes ',
      isDisabled: false
    },
    {
      value: 56,
      label:
        'CC-56: Communicate case design to laboratory technicians and evaluate the resultant restoration or prosthesis',
      isDisabled: false
    },
    { value: 57, label: 'Not Applicable', isDisabled: false }
  ],
  commentsSection: {
    'testlet-information': {
      title: 'Testlet Information Section'
    },
    'patient-information': {
      title: 'Patient Information Section'
    },
    'question-information': {
      id: 0,
      title: 'Question '
    },
    all: {
      title: 'Testlet'
    },
    default: {}
  },
  fieldNameAndValues: {
    testlet_title: { fieldName: 'Testlet Title', isRequired: true },
    inbdeCourse: { fieldName: 'INBDE Prep Course ', isRequired: true },
    about_patient: { fieldName: 'Patient', isRequired: true },
    course: { fieldName: 'DDS Course', isRequired: true },
    course_semester: { fieldName: 'Semester of Course', isRequired: true },
    course_year: { fieldName: 'DDS Year', isRequired: true },
    foundational_knowledge: { fieldName: 'Foundational Knowledge', isRequired: true },
    clinical_content: { fieldName: 'Clinical Content', isRequired: true },
    systems_and_areas: { fieldName: 'Systems/Areas', isRequired: true },
    difficulty_level: { fieldName: 'Level of Difficulty', isRequired: true },
    key_concepts: { fieldName: 'Key Concepts', isRequired: true },
    question_stem: { fieldName: 'Question Stem', isRequired: true },
    answer_choice: { fieldName: 'Answer Choice', isRequired: true }
  },
  fieldsToInclude: [
    'testlet_title',
    'inbdeCourse',
    'about_patient',
    'course',
    'course_semester',
    'course_year',
    'foundational_knowledge',
    'clinical_content',
    'systems_areas',
    'difficulty_level',
    'key_concepts',
    'question_stem',
    'answer_choice'
  ]
};

export default formData;
