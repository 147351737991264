import React from 'react';
import { GoogleLogout } from 'react-google-login';
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap';
import Avatar from '../common/Avatar';
import genericIcon from '../../assets/img/icons/generic-user-icon.png';
import application_properties from '../../applicationProperties';
import AuthenticationService from '../../services/Authentication/AuthenticationService';
import FirebaseLoggingService from '../../services/Firebase/FirebaseLoggingService';
import { UserContext } from '../../Contexts';
import { toast } from 'react-toastify';

class ProfileDropdown extends React.Component {
  _isMounted = false;
  authenticationService = new AuthenticationService();
  loggingService = new FirebaseLoggingService();

  constructor(props) {
    super(props);

    this.state = { profileIcon: genericIcon, email: '', name: '', accessType: null, userId: null };

    this.logoutFail = this.logoutFail.bind(this);
    this.logoutSuccess = this.logoutSuccess.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;

    const { user } = this.context;
    this.getUserDetails(user);
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  getUserDetails(user) {
    let userName = user.displayName;
    let profileImage = user.photoURL;
    let userEmail = user.email;
    let userAccess = user.access_type === 'admin' ? 'Admin' : null;
    const userId = user.uid;

    if (this._isMounted) {
      this.setState({ profileIcon: profileImage, email: userEmail, name: userName, accessType: userAccess, userId });
    }
  }

  logoutFail(error) {
    console.error(error);
  }

  logoutSuccess() {
    const { userId } = this.state;

    if (!this.authenticationService.signOutOfFirebase()) {
      toast.error('Could not signout at the moment!');
    } else {
      this.loggingService.userHasLoggedOut(userId);
    }
  }

  render() {
    const { email, name, profileIcon, accessType } = this.state;

    return (
      <>
        <UncontrolledDropdown nav inNavbar>
          <DropdownToggle nav className="pr-0 d-table">
            <span className="d-none d-md-table-cell align-middle pr-1 pr-lg-2">{name}</span>
            <Avatar src={profileIcon} />
          </DropdownToggle>
          <DropdownMenu right className="dropdown-menu-card">
            <div className="bg-white rounded-soft py-2">
              <DropdownItem className="cursor-default">{email}</DropdownItem>
              {accessType && (
                <DropdownItem className="cursor-default">
                  <strong>{accessType}</strong>
                </DropdownItem>
              )}
              <DropdownItem divider />
              <DropdownItem>
                <GoogleLogout
                  client_id={application_properties.client_id}
                  render={renderProps => (
                    <div onClick={this.logoutSuccess} disabled={renderProps.disabled}>
                      Logout
                    </div>
                  )}
                  onLogoutSuccess={this.logoutSuccess}
                  onFailure={this.logoutFailure}
                  access_type={'offline'}
                />
              </DropdownItem>
            </div>
          </DropdownMenu>
        </UncontrolledDropdown>
      </>
    );
  }
}

ProfileDropdown.contextType = UserContext;

export default ProfileDropdown;
