import React from 'react';
import PropTypes from 'prop-types';
import { Button, Form, Input, DropdownToggle, Row, UncontrolledDropdown } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SearchDropdown from './SearchDropdown';
import SearchSuggestion from './SearchSuggestion';

const suggestions = [
  'Angina',
  'Epidural Bleed 1',
  'Calcium Channel Blockers',
  'Mandibular Molar Extraction',
  'Mucous Membrane Pemphigoid',
  'Trigeminal neuralgia',
  'Cavities',
  'Periodontitis',
  'Oral cancer',
  'Acute membranous gingivitis',
  'Aphthous ulcer'
];

class SearchBox extends React.Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      showSearch: false,
      showSuggestions: false,
      value: ''
    };

    this.clearForm = this.clearForm.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.showSearchSuggestions = this.showSearchSuggestions.bind(this);
    this.toggleSearchOptions = this.toggleSearchOptions.bind(this);
    this.toggleSearchSuggestions = this.toggleSearchSuggestions.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  clearForm() {
    this._isMounted && this.setState({ value: '' });

    const { clearSearch } = this.props;
    clearSearch('search');
  }

  handleChange(event) {
    this._isMounted && this.setState({ value: event.target.value, showSearch: true, showSuggestions: false });
  }

  handleSubmit(searchOptions) {
    const { value } = this.state;
    const { clearSearch, searchWithKey } = this.props;

    if (value && value.trim() !== '') {
      this._isMounted && this.setState({ showSearch: false });
      searchWithKey({ query: value, options: searchOptions });
    } else clearSearch('search');
  }

  showSearchSuggestions() {
    this._isMounted && this.setState({ showSuggestions: true });
  }

  toggleSearchOptions() {
    this._isMounted && this.setState(state => ({ showSearch: !state.showSearch }));
  }

  toggleSearchSuggestions() {
    this._isMounted && this.setState(state => ({ showSuggestions: !state.showSuggestions }));
  }

  render() {
    const { pageTitle } = this.props;
    const { showSearch, showSuggestions } = this.state;
    return (
      <Row>
        <div className="col-sm col-md-6 pl-md-3">
          <Form className="search-box mt-2" onSubmit={this.handleSubmit}>
            <div>
              <Input
                id="searchField"
                placeholder="Search..."
                aria-label="Search"
                value={this.state.value}
                className="rounded-pill pb-4 pt-4 pl-5 pr-5 mb-0"
                onChange={this.handleChange}
                onClick={this.toggleSearchOptions}
                required
                autoComplete="off"
              />
              <FontAwesomeIcon icon="search" className="position-absolute text-400 fs-1 search-box-icon ml-2 ml-md-1" />
            </div>
            {this.state.value && (
              <FontAwesomeIcon
                icon="times"
                className="cursor-pointer dropdown-menu-right text-400 fs-4 mr-2 pr-2 absolute-vertical-center"
                onClick={this.clearForm}
              />
            )}

            <UncontrolledDropdown isOpen={showSearch} toggle={this.toggleSearchOptions}>
              <DropdownToggle tag="span" data-toggle="dropdown" />
              <SearchDropdown
                onClearAll={this.clearForm}
                onSubmitForm={this.handleSubmit}
                pageTitle={pageTitle}
                toggle={this.toggleSearchOptions}
              />
            </UncontrolledDropdown>

            <UncontrolledDropdown isOpen={showSuggestions} toggle={this.toggleSearchSuggestions}>
              <DropdownToggle tag="span" data-toggle="dropdown" />
              <SearchSuggestion
                suggestions={suggestions}
                handleChange={this.handleChange}
                handleSubmit={this.handleSubmit}
              />
            </UncontrolledDropdown>
          </Form>
        </div>
        <div className="col-sm col-md-5 mt-auto mb-auto pt-2 pb-2 d-none d-md-block">
          <Button color="primary" onClick={this.clearForm}>
            View all Content
          </Button>
        </div>
      </Row>
    );
  }
}

SearchBox.propTypes = {
  clearSearch: PropTypes.func,
  pageTitle: PropTypes.string,
  searchWithKey: PropTypes.func
};

SearchBox.defaultProps = {
  clearSearch: () => {},
  pageTitle: 'search',
  searchWithKey: () => {}
};

export default SearchBox;
