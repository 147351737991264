import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { UserContext } from '../Contexts';
import { ContentService } from '../services/Content/content';
import UserService from '../services/User/UserService';
import { isIterableArray } from '../helpers/utils';
import { toast } from 'react-toastify';
import { Redirect } from 'react-router';
import SpinnerModal from '../components/common/SpinnerModal';
import BaseContentForm from './BaseContentForm';
import { personalContentPath, adminReviewContentPath, searchPath, customContentRoutes } from '../routes';
import { convertUsers, getFormattedCourseList, updateUsers, validateAccess } from '../components/content/content';
import { allAccessTypesArray } from '../data/user/accessTypes';
import { isFeatureActive, isProd } from '../helpers/inbdeUtils';
import FeatureService from '../services/Feature/FeatureService';
import formData from '../data/mock/testletForm';
import featureToggles from '../services/Feature/toggles';

const isProductionEnvironment = isProd();

class QuestionForm extends Component {
  _isMounted = false;
  contentService = new ContentService();
  featureService = new FeatureService();
  userQueryPageLimit = 10;
  userService = new UserService();

  constructor(props) {
    super(props);

    this.state = {
      allUsers: [],
      fetchUsers: true,
      isLoading: true,
      isRedirect: false,
      isSendCollaboratorEmail: false,
      isUserActionInProgress: false,
      lastUserFetched: null,
      questionData: null,
      redirectUrl: personalContentPath
    };

    this.formOptions = {
      courseOptions: formData.courseYearOptions,
      semesterOptions: formData.semesterOptions,
      ddsYearOptions: formData.ddsYearOptions,
      diffcultyLevelOptions: formData.diffcultyLevelOptions,
      systemAreasOptions: formData.systemAreasOptions,
      fkOptions: formData.foundationalKnowledgeOptions,
      ccOptions: formData.clinicalContentOptions,
      inbdeYearSemester: formData.inbdeYearSemester,
      inbdeCourse: formData.inbdeCourse,
      semesterMockExamOptions: formData.semesterMockExamOptions
    };

    this.deleteAttachment = this.deleteAttachment.bind(this);
    this.deleteQuestion = this.deleteQuestion.bind(this);
    this.emailInviteCollaborator = this.emailInviteCollaborator.bind(this);
    this.getCourses = this.getCourses.bind(this);
    this.getUsers = this.getUsers.bind(this);
    this.handleRedirect = this.handleRedirect.bind(this);
    this.initialize = this.initialize.bind(this);
    this.isUserHaveAccess = this.isUserHaveAccess.bind(this);
    this.saveQuestion = this.saveQuestion.bind(this);
    this.unsubscribeListeners = this.unsubscribeListeners.bind(this);
    this.updateQuestionByAdmin = this.updateQuestionByAdmin.bind(this);
    this.updateQuestionByFaculty = this.updateQuestionByFaculty.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;

    this.initialize();
  }

  componentWillUnmount() {
    this.unsubscribeListeners();
    this._isMounted = false;
  }

  async deleteAttachment(attachmentUrl) {
    return await this.contentService.deleteAttachmentFromStorage(attachmentUrl);
  }

  async deleteQuestion() {
    this._isMounted && this.setState({ isLoading: true, isUserActionInProgress: true });

    const { questionId } = this.props;
    const isSuccess = await this.contentService.deleteContent(questionId);

    if (!isSuccess) {
      toast.error('Could not delete question at the moment');
    }

    this._isMounted && this.setState({ isLoading: false });
  }

  emailInviteCollaborator(collaboratorEmail) {
    const { user } = this.context;
    const { isSendCollaboratorEmail } = this.state;

    if (!isSendCollaboratorEmail) {
      return;
    }

    const { questionId } = this.props;
    const { questionData } = this.state;
    const { content_type } = questionData;

    this.contentService.inviteCollaborator(questionId, content_type, collaboratorEmail, user);
  }

  async getCourses() {
    const courseList = await this.featureService.getCourseList();
    if (courseList) {
      const formattedCourseList = getFormattedCourseList(courseList);
      this.formOptions.courseOptions = formattedCourseList;
    }
  }

  async getUsers() {
    const { fetchUsers, lastUserFetched: lastUser, allUsers } = this.state;

    if (fetchUsers) {
      const fetchedUsers = await this.userService.getListOfActiveUsersPaginated(
        allAccessTypesArray,
        lastUser,
        this.userQueryPageLimit
      );

      const lastUserFetched =
        fetchedUsers.length < this.userQueryPageLimit ? null : fetchedUsers[fetchedUsers.length - 1];
      // if no more users to fetch, set flag to false
      const updateFetchUsers = Boolean(lastUserFetched);

      const convertedUserDocs = convertUsers(fetchedUsers, isProductionEnvironment);
      const updatedUsers = updateUsers(allUsers, convertedUserDocs);

      this._isMounted &&
        this.setState({
          fetchUsers: updateFetchUsers,
          lastUserFetched,
          allUsers: updatedUsers
        });
    }
  }

  handleRedirect() {
    this.unsubscribeListeners();
    this._isMounted && this.setState({ isRedirect: true });
  }

  async initialize() {
    const { questionId } = this.props;
    const { toggles, user } = this.context;

    this.contentService.getDocListenerById(questionId, false, questionData => {
      const { isUserActionInProgress } = this.state;
      try {
        if (!isUserActionInProgress) {
          validateAccess(questionData, user);
        }
        this._isMounted && this.setState({ questionData, isUserActionInProgress: false });
      } catch (error) {
        const toastMessage = error.message || 'This question cannot be viewed or edited';
        toast.error(toastMessage);

        this.handleRedirect();
      }
    });

    await this.getCourses();
    await this.getUsers();

    const { uid } = user;
    const isSendCollaboratorEmail = Boolean(isFeatureActive(featureToggles.isEmailUsersForCollaboration, toggles, null, uid));
    this._isMounted && this.setState({ isLoading: false, isSendCollaboratorEmail });
  }

  isUserHaveAccess(singedInUserId, questionCreatorId, questionCollaboratorIds) {
    if (singedInUserId === questionCreatorId) {
      return true;
    }

    if (isIterableArray(questionCollaboratorIds) && questionCollaboratorIds.includes(singedInUserId)) {
      return true;
    }

    return false;
  }

  async saveQuestion(data, isSubmit) {
    const { questionId } = this.props;
    const { user } = this.context;

    try {
      let isSuccess = false;
      if (isSubmit) {
        this._isMounted && this.setState({ isLoading: true, isUserActionInProgress: true });
        isSuccess = await this.contentService.submitContent(questionId, data, user);
      } else {
        isSuccess = await this.contentService.updateContent(questionId, data);
      }

      if (isSuccess && isSubmit) {
        this.handleRedirect();
      } else if (!isSuccess) {
        throw new Error('there was an error. Please try again a bit later');
      }

      return true;
    } catch (error) {
      const userAction = isSubmit ? 'submitted' : 'saved';
      let toastMessage = `This question cannot be ${userAction}`;

      if (error.message) {
        toastMessage += ' because ' + error.message;
      }

      toast.error(toastMessage);
    }

    this._isMounted && this.setState({ isLoading: false });
    return false;
  }

  async updateQuestionByAdmin(action, data) {
    const { toggles, user } = this.context;
    const { questionId } = this.props;
    const { questionData } = this.state;

    const contentData = data || questionData;

    this._isMounted && this.setState({ isLoading: true, isUserActionInProgress: true });
    let isSuccess;
    let isRedirect = true;
    try {
      if (action === 'approve') {
        isSuccess = await this.contentService.approveContentByAdmin(questionId);
      } else if (action === 'revise') {
        isSuccess = await this.contentService.rejectContentByAdmin(questionId, contentData, user);
      } else if (action === 'restore') {
        isSuccess = await this.contentService.restoreContentByAdmin(questionId);
      } else if (action === 'unpublish') {
        isSuccess = await this.contentService.unpublishContentByAdmin(questionId, null);
      } else if (action === 'publish' || action === 'republish') {
        isSuccess = await this.contentService.publishContentByAdmin(questionId, data);
      } else if (action === 'unflag') {
        isSuccess = await this.contentService.flagContent(questionId, false);
      } else if (action === 'export') {
        isSuccess = await this.contentService.exportContent(questionId, contentData, user, toggles);
        isRedirect = false;
      } else {
        throw new Error('Cannot update question as no valid action performed');
      }

      if (isSuccess) {
        this._isMounted && this.setState({ isLoading: false, redirectUrl: adminReviewContentPath });

        toast.success(`Successfully ${action}d the question`);
        isRedirect && this.handleRedirect();
      } else {
        throw new Error('There was an error in updating the question');
      }
    } catch (error) {
      this._isMounted && this.setState({ isLoading: false });

      const message = error.message ? error.message : 'There was an error in updating the question';
      toast.error(message);
    }
  }

  async updateQuestionByFaculty(action, _data) {
    const { user } = this.context;
    const { questionId } = this.props;
    const { questionData } = this.state;

    this._isMounted && this.setState({ isLoading: true, isUserActionInProgress: true });
    let actionText, isSuccess, redirectUrl;

    try {
      if (action === 'copy') {
        const newContentId = await this.contentService.copyContent(questionData, user);
        if (newContentId) {
          isSuccess = true;
          redirectUrl = customContentRoutes.question.editPath.replace('{contentId}', newContentId);
        }
        actionText = 'copied';
      } else if (action === 'flag') {
        isSuccess = await this.contentService.flagContent(questionId, true, questionData, user);
        redirectUrl = searchPath;
        actionText = 'flagged';
      } else {
        throw new Error('Cannot update question as no valid action performed');
      }

      if (isSuccess) {
        toast.success(`Successfully ${actionText} the question`);
        this._isMounted &&
          this.setState({ isLoading: false, redirectUrl }, () => {
            this.handleRedirect();
          });
      } else {
        throw new Error('There was an error in updating the question');
      }
    } catch (error) {
      this._isMounted && this.setState({ isLoading: false });

      const message = error.message ? error.message : 'There was an error in updating the question';
      toast.error(message);
    }
  }

  unsubscribeListeners() {
    this.contentService.getDocListenerById(null, true, () => {});
  }

  render() {
    const { questionId } = this.props;
    const { allUsers, isLoading, isRedirect, isSendCollaboratorEmail, questionData, redirectUrl } = this.state;

    return (
      <>
        {isRedirect && <Redirect to={redirectUrl} />}
        {isLoading || questionData === null ? (
          <SpinnerModal />
        ) : (
          <BaseContentForm
            activeUsers={allUsers}
            contentData={questionData}
            contentId={questionId}
            deleteAttachment={this.deleteAttachment}
            deleteContent={this.deleteQuestion}
            emailInviteCollaborator={this.emailInviteCollaborator}
            fetchUsers={this.getUsers}
            formOptions={this.formOptions}
            isLoading={isLoading}
            isSendCollaboratorEmail={isSendCollaboratorEmail}
            redirect={this.handleRedirect}
            saveContentToDatabase={this.saveQuestion}
            updateContentByAdmin={this.updateQuestionByAdmin}
          />
        )}
      </>
    );
  }
}

QuestionForm.contextType = UserContext;

QuestionForm.propTypes = {
  questionId: PropTypes.string.isRequired
};

export default QuestionForm;
