import React, { Component } from 'react';
import PropTypes from 'prop-types';
import InlineFilterSelected from './InlineFilterSelected';

class InlineFiltersSelected extends Component {
  constructor(props) {
    super(props);

    this.clearFilters = this.clearFilters.bind(this);
  }

  clearFilters(label) {
    const { clearFilterGroup } = this.props;
    clearFilterGroup(label);
  }

  render() {
    const { selectedFilters } = this.props;
    return selectedFilters.map((element, index) => {
      return <InlineFilterSelected key={index} selectedFilter={element} clearFilters={this.clearFilters} />;
    });
  }
}

InlineFiltersSelected.propTypes = {
  selectedFilters: PropTypes.array,
  clearFilterGroup: PropTypes.func
};

export default InlineFiltersSelected;
