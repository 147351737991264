// Search options
const options = [
  {
    id: 1,
    key: 'testlet_title',
    paramValue: ['testlet_information.testlet_title'],
    name: 'Testlet title',
    selected: true
  },
  {
    id: 2,
    key: 'question_stem',
    paramValue: ['questions.question_stem'],
    name: 'Question stem',
    selected: true
  },
  {
    id: 3,
    key: 'patient_information',
    paramValue: [
      'patient_information.about_patient',
      'patient_information.patient_history',
      'patient_information.patient_complaint',
      'patient_information.current_findings'
    ],
    name: 'Patient box',
    selected: true
  },
  {
    id: 4,
    key: 'key_concepts',
    name: 'Key concepts',
    paramValue: ['questions.key_concepts'],
    selected: true
  },
  {
    id: 5,
    key: 'systems_areas',
    name: 'Systems / Areas',
    paramValue: ['questions.systems_areas.label'],
    selected: true
  },
  {
    id: 6,
    key: 'faculty',
    name: 'Faculty',
    paramValue: ['testlet_information.testlet_creator', 'questions.question_creator'],
    selected: true
  },
  {
    id: 7,
    key: 'fk',
    name: 'Foundational Knowledge',
    paramValue: ['questions.foundational_knowledge.label'],
    selected: true
  },
  {
    id: 8,
    key: 'cc',
    name: 'Clinical Content',
    paramValue: ['questions.clinical_content.label'],
    selected: true
  },
  {
    id: 9,
    key: 'class',
    paramValue: ['questions.course_year.label'],
    name: 'DDS Year',
    selected: true
  },
  {
    id: 10,
    key: 'courses',
    paramValue: ['questions.course.label'],
    name: 'Course',
    selected: true
  },
  {
    id: 11,
    key: 'inbdeCourse',
    paramValue: ['published_inbdeCourse.label', 'testlet_information.inbdeCourse.label'],
    name: 'INBDE Prep Course',
    selected: true
  }
  // {
  //   id: 3,
  //   key: 'isCorrect',
  //   paramValue
  //   name: 'Correct answer choices',
  //   selected: true
  // }
];

export default options;
