import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button, Card, CardBody, CardFooter, Form, FormGroup, Input, Label, Spinner } from 'reactstrap';
import FalconCardHeader from '../common/FalconCardHeader';
import XLSX from 'xlsx';
import { toast } from 'react-toastify';
import FirebaseLoggingService from '../../services/Firebase/FirebaseLoggingService';
import { getDDSCourseId, getDDSCourseText } from '../../helpers/inbdeUtils';

const courseListTemplate =
  'https://firebasestorage.googleapis.com/v0/b/qa-nyu-inbde.appspot.com/o/courses%2FINBDE_Course_List_Template%20(2).xlsx?alt=media&token=d49813d6-905f-4617-8286-97f7e74fe246';
const fieldsToIgnore = [
  'DDS Course Inventory 2019-20',
  'DDS Course Inventory 2020-21',
  'First Year Core Curriculum',
  'Curric Year',
  'Course Number',
  'Course Title',
  'Course ID',
  'Course Name',
  'Course Director',
  'NetID',
  'Second Year Core Curriculum',
  'Third Year Core Curriculum',
  'Fourth Year Core Curriculum',
  'Supplemental Curriculum',
  'Elective Curriculum'
];

// const curriculumKeyStrings = [
//   'First Year Core Curriculum',
//   'Second Year Core Curriculum',
//   'Third Year Core Curriculum',
//   'Fourth Year Core Curriculum',
//   'Supplemental Curriculum',
//   'Elective Curriculum'
// ];

export class UploadCourseListForm extends Component {
  _isMounted = false;
  loggingService = new FirebaseLoggingService();

  constructor(props) {
    super(props);

    this.state = { cols: null, data: null, isSpinner: false };
    this.formRef = React.createRef();

    this.readFile = this.readFile.bind(this);
    this.submitCourseList = this.submitCourseList.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  make_cols(refstr) {
    let o = [],
      C = XLSX.utils.decode_range(refstr).e.c + 1;
    for (var i = 0; i < C; ++i) o[i] = { name: XLSX.utils.encode_col(i), key: i };
    return o;
  }

  readFile(event) {
    try {
      const file = event.target.files[0];
      const fileExtension = file && file.name.split('.').pop();

      if (fileExtension === 'xlsx') {
        const reader = new FileReader();
        const rABS = !!reader.readAsBinaryString;
        // const blob = new Blob([file], { type: 'file/.xlsx' });

        reader.onload = e => {
          /* Parse data */
          const bstr = e.target.result;

          const wb = XLSX.read(bstr, { type: rABS ? 'binary' : 'array' });
          /* Get first worksheet */
          const wsname = wb.SheetNames[0];
          const ws = wb.Sheets[wsname];
          /* Convert array of arrays */
          const rawData = XLSX.utils.sheet_to_json(ws, { header: 1 });
          /* sanitize data */
          const sanitizedData = this.sanitizeListData(rawData);
          this._isMounted && this.setState({ data: sanitizedData, cols: this.make_cols(ws['!ref']) });
        };
        if (rABS) reader.readAsBinaryString(file);
        else reader.readAsArrayBuffer(file);
      }
    } catch (e) {
      this.loggingService.errorParsingFile(e);
    }
  }

  sanitizeListData(rawData) {
    let startPos = 0;
    let courseCurriculum = '';
    const data = {};
    let curriculumOrder = 0;

    try {
      if (
        rawData[startPos].length === 0 ||
        (rawData[startPos].length === 1 && rawData[startPos][0].indexOf('DDS Course Inventory') !== -1)
      ) {
        startPos += 1;
      }

      for (let row = startPos; row < rawData.length; row += 1) {
        const courseArray = rawData[row];
        const numOfCols = courseArray.length;
        if (numOfCols === 0) {
          continue;
        } else if (numOfCols === 1) {
          courseCurriculum = courseArray;
          data[courseCurriculum] = {};
          data[courseCurriculum]['curriculumOrder'] = curriculumOrder;
          data[courseCurriculum]['courses'] = {};
          curriculumOrder += 1;
        } else {
          if (!fieldsToIgnore.includes(courseArray[0])) {
            const courseId = courseArray[1];
            const courseUniqueId = getDDSCourseId(courseId.toUpperCase());
            const courseIdText = getDDSCourseText(courseUniqueId) || courseId.toUpperCase();
            const courseDetails = {
              course_id: courseIdText,
              year: courseArray[0],
              title: numOfCols >= 2 ? courseArray[2] : '',
              is_latest: true
            };

            data[courseCurriculum]['courses'][courseUniqueId] = { ...courseDetails };
          }
        }
      }

      return data;
    } catch (e) {
      toast.error(
        'There was an issue formatting this file. Please make sure the data in the file is in the correct format'
      );
      return null;
    }
  }

  submitCourseList(e) {
    e.preventDefault();
    const { uploadCourseList } = this.props;
    const { data, cols } = this.state;

    if (data && cols) {
      this._isMounted &&
        this.setState({ isSpinner: true }, async () => {
          uploadCourseList(data, isListUploaded => {
            if (isListUploaded) {
              this.formRef.current.reset();
              toast.success('Course List updated successfully!');
              this._isMounted && this.setState({ data: null, col: null, isSpinner: false });
            } else {
              toast.error('Could not upload course list at the moment');
              this._isMounted && this.setState({ isSpinner: false });
            }
          });
        });
    } else {
      toast.error('Please upload a valid file to proceed');
    }
  }

  render() {
    const { isSpinner } = this.state;

    return (
      <div>
        <Form onSubmit={this.submitCourseList} innerRef={this.formRef}>
          <Card>
            <FalconCardHeader title="Upload Course List" />
            <CardBody className="pb-0">
              <FormGroup>
                <Input type="file" name="file" id="course-list" accept=".xlsx" onChange={this.readFile} />
                <small>Accepted file format(s): .xlsx only</small>
                <br />
                <small>
                  <a href={courseListTemplate} target="_blank" rel="noopener noreferrer">
                    Click to download course list template
                  </a>
                </small>
              </FormGroup>
              <FormGroup check>
                <Input type="checkbox" name="document_permission" required />
                <Label>I understand that by uploading this course list, the previous list will be overridden.</Label>
              </FormGroup>
            </CardBody>
            <CardFooter className="d-flex pt-0">
              <Button color="primary" className="ml-auto" type="submit" disabled={isSpinner}>
                Upload
                {isSpinner && <Spinner size="sm" color="light" className="ml-2" />}
              </Button>
            </CardFooter>
          </Card>
        </Form>
      </div>
    );
  }
}

UploadCourseListForm.propTypes = {
  uploadCourseList: PropTypes.func
};

export default UploadCourseListForm;
