import React from 'react';
import PropTypes from 'prop-types';
import { Button, DropdownItem, DropdownMenu, FormGroup, Input, Label, Row } from 'reactstrap';
import SearchOptions from './SearchOptions';
import options from '../../data/mock/search';
import { UserContext } from '../../Contexts';
import featureToggles from '../../services/Feature/toggles';
import { getContentTypeOptions } from '../../data/search/search';

class SearchDropdown extends React.Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      remainingOptionsSelected: true,
      allOptions: options,
      allOptionsSelected: true,
      showSearchOptions: false,
      contentOptions: getContentTypeOptions(true)
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleChangeForContentType = this.handleChangeForContentType.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.onPerformSelectionOnAllOptions = this.onPerformSelectionOnAllOptions.bind(this);
    this.resetForm = this.resetForm.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;
    const { toggles } = this.context;

    if (toggles) {
      this.setState({ showSearchOptions: Boolean(toggles[featureToggles.isShowSearchOptions]) });
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  getContentTypeFilterQuery(selectedContentOptions, contentOptions) {
    let contentTypeFilterQuery = '';
    if (selectedContentOptions.length < contentOptions.options.length) {
      if (selectedContentOptions.length === 1) {
        contentTypeFilterQuery = contentOptions.filterValue + ':' + selectedContentOptions[0].filterValue;
      } else if (selectedContentOptions.length > 1) {
        contentTypeFilterQuery =
          '(' +
          selectedContentOptions.map(option => contentOptions.filterValue + ':' + option.filterValue).join(' OR ') +
          ')';
      }
    }

    return contentTypeFilterQuery;
  }

  handleChange(index, selected) {
    let { allOptions, allOptionsSelected } = this.state;
    allOptions[index].selected = selected;

    allOptionsSelected = !!allOptions.filter(option => option.selected).length;

    this._isMounted &&
      this.setState({
        allOptions,
        allOptionsSelected
      });
  }

  handleChangeForContentType(index, selected) {
    const { contentOptions } = this.state;
    const { options } = contentOptions;
    if (index >= options.length) {
      return;
    }

    options[index].selected = selected;
    this._isMounted && this.setState({ contentOptions });
  }

  handleSubmit(e) {
    e.preventDefault();

    const { onSubmitForm } = this.props;
    const { allOptions, remainingOptionsSelected, contentOptions } = this.state;

    const selectedOptions = allOptions.filter(option => {
      return option.selected;
    });
    const params = [];
    selectedOptions.forEach(option => {
      option.paramValue.forEach(value => params.push(value));
    });

    const selectedContentOptions = contentOptions.options.filter(option => option.selected);
    const contentTypeFilterQuery = this.getContentTypeFilterQuery(selectedContentOptions, contentOptions);

    if (params.length > 0 && selectedContentOptions.length > 0) {
      onSubmitForm({ isSearchAll: remainingOptionsSelected, params, contentTypeFilters: contentTypeFilterQuery });
    }
  }

  onPerformSelectionOnAllOptions(event) {
    const { allOptions } = this.state;
    const newState = [];

    allOptions.forEach(option => {
      const newOption = { ...option };
      newOption.selected = event.target.checked;
      newState.push(newOption);
    });

    this._isMounted &&
      this.setState(state => ({
        remainingOptionsSelected: !state.remainingOptionsSelected,
        allOptions: [].concat(newState)
      }));
  }

  resetForm() {
    const { onClearAll, toggle } = this.props;
    const { allOptions, showSearchOptions, contentOptions } = this.state;

    contentOptions.options.forEach(option => {
      option.selected = false;
    });

    if (showSearchOptions) {
      const newState = allOptions.map(option => {
        const newOption = { ...option };
        newOption.selected = false;
        return newOption;
      });

      this._isMounted &&
        this.setState(
          {
            contentOptions,
            remainingOptionsSelected: false,
            allOptions: [].concat(newState)
          },
          () => {
            onClearAll();
            toggle();
          }
        );
    } else {
      this._isMounted &&
        this.setState({ contentOptions }, () => {
          onClearAll();
          toggle();
        });
    }
  }

  render() {
    const { pageTitle } = this.props;
    const { remainingOptionsSelected, allOptions, showSearchOptions, contentOptions } = this.state;

    return (
      <DropdownMenu className="dropdown-menu-card mt-1 w-100">
        <div className="bg-white rounded-soft p-3">
          {/* Show content type check boxes when NOT on search page */}
          {pageTitle !== 'search' && (
            <div className="content-type-filter">
              <DropdownItem header className="p-2">
                Search the following content types:
              </DropdownItem>
              <div className="content-type-filter-options">
                <SearchOptions
                  searchOptions={contentOptions.options}
                  onChange={this.handleChangeForContentType}
                  isSearch
                />
              </div>
              <DropdownItem divider />
            </div>
          )}
          {showSearchOptions && (
            <div className="search-options">
              <DropdownItem header className="p-2">
                Select the following to search:
              </DropdownItem>
              <div className="search-options-checklist p-2">
                <FormGroup check>
                  <Label check>
                    <Input
                      type="checkbox"
                      onChange={this.onPerformSelectionOnAllOptions}
                      checked={remainingOptionsSelected}
                    />
                    {remainingOptionsSelected ? 'Deselect ' : 'Select '}All
                  </Label>
                </FormGroup>
                <DropdownItem divider />
                <Row>
                  <SearchOptions searchOptions={allOptions} onChange={this.handleChange} isSearch />
                </Row>
              </div>
            </div>
          )}
          <Row className="p-2">
            <Button type="reset" className="ml-auto mr-2" onClick={this.resetForm}>
              Clear Search
            </Button>
            <Button color="primary" type="submit" className="mr-2" onClick={this.handleSubmit}>
              Search
            </Button>
          </Row>
        </div>
      </DropdownMenu>
    );
  }
}

SearchDropdown.contextType = UserContext;

SearchDropdown.propTypes = {
  onSubmitForm: PropTypes.func,
  onClearAll: PropTypes.func.isRequired,
  pageTitle: PropTypes.string,
  toggle: PropTypes.func
};

SearchDropdown.defaultProps = {
  onSubmitForm: () => {},
  pageTitle: 'search',
  toggle: () => {}
};

export default SearchDropdown;
