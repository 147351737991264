export function getAdminFacultyActivityDialog() {
  const dialogDetails = {
    id: 'adminFacultyActivityModal',
    dialogToggleFlag: null,
    title: 'Comment Activity',
    buttonText: 'Comment',
    dialogActionButton: null,
    toggleDialogFunction: null,
    comments: []
  };

  return dialogDetails;
}

export function getApproveDialog() {
  const dialogDetails = {
    id: 'approveTestlet',
    dialogToggleFlag: null,
    title: 'Approve Testlet',
    bodyText: 'Are you sure you wish to approve this testlet?',
    buttonText: 'Confirm Approve',
    buttonColor: 'primary',
    dialogActionButton: null,
    toggleDialogFunction: null
  };

  return dialogDetails;
}

export function getCloneDialog() {
  const dialogDetails = {
    id: 'cloneTestlet',
    dialogToggleFlag: null,
    title: 'Make a Copy of the Testlet',
    bodyText: 'Enter a new name for the cloned testlet:',
    buttonText: 'Confirm',
    buttonColor: 'primary',
    dialogActionButton: null,
    toggleDialogFunction: null
  };

  return dialogDetails;
}

export function getCloseCollaborationDialog(type) {
  const bodyText = type === '3' ? 'mark this closed for collaboration?' : 'open this for collaboration?';
  const dialogDetails = {
    id: 'closeCollaboration',
    dialogToggleFlag: null,
    title: 'Change Testlet Status',
    bodyText: 'Are you sure you wish to change the status of this testlet and ' + bodyText,
    buttonText: 'Confirm',
    buttonColor: 'primary',
    dialogActionButton: null,
    toggleDialogFunction: null
  };

  return dialogDetails;
}

export function getCollabDialog() {
  const dialogDetails = {
    id: 'collabTestlet',
    dialogToggleFlag: null,
    title: 'Add as a Collaborator',
    bodyText:
      'Are you sure you wish to join this testlet as a collaborator? You have {X} days to work on it and submit for review.',
    buttonText: 'Confirm',
    buttonColor: 'primary',
    dialogActionButton: null,
    toggleDialogFunction: null
  };

  return dialogDetails;
}

export function getEndTestletCollabDialog() {
  const dialogDetails = {
    id: 'endTestletCollab',
    dialogToggleFlag: null,
    title: 'End Testlet Collaboration',
    bodyText: 'Are you sure you wish to end the collaboration for this testlet?',
    buttonText: 'Confirm',
    buttonColor: 'warning',
    dialogActionButton: null,
    toggleDialogFunction: null
  };

  return dialogDetails;
}

export function getFlagDialog() {
  const dialogDetails = {
    id: 'flagTestlet',
    dialogToggleFlag: null,
    title: 'Flag Testlet',
    bodyText: 'Add a comment when flagging this testlet:',
    buttonText: 'Confirm Flag',
    buttonColor: 'primary',
    date: null,
    dialogActionButton: null,
    toggleDialogFunction: null
  };

  return dialogDetails;
}

export function getPublishDialog() {
  const dialogDetails = {
    id: 'publishTestlet',
    dialogToggleFlag: null,
    title: 'Publish Testlet',
    bodyText:
      'Select INBDE Prep. Course from below to publish this testlet. Please make sure to set the INBDE Prep. Course in the Testlet Information section to the same value to avoid any discrepancy.',
    buttonText: 'Confirm Publish',
    buttonColor: 'primary',
    date: null,
    dialogActionButton: null,
    toggleDialogFunction: null
  };

  return dialogDetails;
}

export function getRejectDialog() {
  const dialogDetails = {
    id: 'rejectTestlet',
    dialogToggleFlag: null,
    title: 'Revise Testlet',
    bodyText: 'Add a comment when revising this testlet:',
    buttonText: 'Confirm Revise',
    buttonColor: 'danger',
    dialogActionButton: null,
    toggleDialogFunction: null
  };

  return dialogDetails;
}

export function getRepublishDialog() {
  const dialogDetails = {
    id: 'republishTestlet',
    dialogToggleFlag: null,
    title: 'Re-Publish Testlet',
    bodyText: 'Select a new INBDE Prep. Course from below to re-publish this testlet:',
    buttonText: 'Confirm',
    buttonColor: 'primary',
    date: null,
    dialogActionButton: null,
    toggleDialogFunction: null
  };

  return dialogDetails;
}

export function getRestoreTestletDialog() {
  const dialogDetails = {
    id: 'restoreTestlet',
    dialogToggleFlag: null,
    title: 'Restore Testlet',
    bodyText: 'Are you sure you wish to restore this testlet?',
    buttonText: 'Confirm Restore',
    buttonColor: 'success',
    dialogActionButton: null,
    toggleDialogFunction: null
  };

  return dialogDetails;
}

export function getReviseTestletDialog() {
  const dialogDetails = {
    id: 'reviseTestlet',
    dialogToggleFlag: null,
    title: 'Discuss with Admin',
    bodyText:
      'Are you sure you wish to discuss this testlet with an admin and send it for revision?' +
      '\n' +
      'An email will be sent to the admin notifying them about this.' +
      '\n' +
      'All Changes to the testlet will remain saved.',
    buttonText: 'Revise',
    buttonColor: 'danger',
    dialogActionButton: null,
    toggleDialogFunction: null
  };

  return dialogDetails;
}

export function getUnFlagDialog() {
  const dialogDetails = {
    id: 'unflagTestlet',
    dialogToggleFlag: null,
    title: 'Unflag Testlet',
    bodyText: 'Add a comment when unflagging this testlet:',
    buttonText: 'Confirm Unflag',
    buttonColor: 'success',
    dialogActionButton: null,
    toggleDialogFunction: null
  };

  return dialogDetails;
}

export function getUnpublishDialog() {
  const dialogDetails = {
    id: 'unpublishTestlet',
    dialogToggleFlag: null,
    title: 'Unpublish Testlet',
    bodyText: 'Are you sure you wish to unpublish this testlet?',
    buttonText: 'Confirm Unpublish',
    buttonColor: 'primary',
    dialogActionButton: null,
    toggleDialogFunction: null
  };

  return dialogDetails;
}

export function getExportTestletDialog() {
  const dialogDetails = {
    id: 'exportTestlet',
    dialogToggleFlag: null,
    title: 'Export Testlet',
    bodyText: 'Are you sure you wish to export this testlet?',
    buttonText: 'Confirm',
    buttonColor: 'success',
    dialogActionButton: null,
    toggleDialogFunction: null
  };

  return dialogDetails;
}
