import React, { useState } from 'react';
import Container from 'reactstrap/es/Container';
import Background from '../components/common/Background';
import CardBody from 'reactstrap/es/CardBody';
import CardHeader from 'reactstrap/es/CardHeader';
import { Card, Col, Row } from 'reactstrap';
import { Link } from 'react-router-dom';

import logo from '../assets/img/illustrations/dentistry-white.png';

const withAuthSplit = OriginalComponent => {
  const UpdatedComponent = props => {
    // State
    const [bgProps, setBgProps] = useState(null);

    return (
      <Container fluid>
        <Row className="min-vh-100 bg-100 bg-nyu">
          <Col xs={6} className="d-none d-lg-block">
            {bgProps && <Background {...bgProps} />}
          </Col>
          <Col>
            <div className="col-12 justify-content-center align-items-center p-4">
              <div className="d-grid">
                <img src={logo} alt="NYU Dentistry" width={300} className="ml-auto mr-auto fs-0" />
              </div>
            </div>
            <div className="row px-sm-0 align-self-center mx-auto py-5">
              <Row noGutters className="justify-content-center min-vh-75">
                <Col md={8} lg={9} xl={8} className="col-xxl-7 my-auto">
                  <Card>
                    <CardHeader className="bg-split-card-header text-center p-2 border-bottom border-secondary">
                      <div className="fs-4 z-index-1 position-relative p-4">
                        <Link to="/" className="text-nyu text-sans-serif font-weight-extra-bold">
                          Integrated National Board Dental Exam
                        </Link>
                        <br />
                        <div className="fs-0 mt-4 mb-2 text-800 text-sans-serif font-weight-bold">
                          With the power of the Integrated National Board Dental Exam (INBDE) app, you can create, edit,
                          review, collaborate, and reuse testlets and questions with your colleagues
                        </div>
                      </div>
                    </CardHeader>
                    <CardBody className="pt-4 pr-4 pl-4 pb-5">
                      <OriginalComponent setBgProps={setBgProps} {...props} />
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </Container>
    );
  };

  return UpdatedComponent;
};

export default withAuthSplit;
