import { TestletTypes } from '../testlet/review_testlet_types';

const testletStatuses = [
  {
    id: TestletTypes.PENDING.types,
    value: 'Pending',
    linkTo: TestletTypes.PENDING.linkTo
  },
  {
    id: TestletTypes.UNDER_REVISION.types,
    value: 'Under Revision',
    linkTo: TestletTypes.UNDER_REVISION.linkTo
  },
  {
    id: TestletTypes.APPROVED.types,
    value: 'Approved',
    linkTo: TestletTypes.APPROVED.linkTo
  },
  {
    id: TestletTypes.PUBLISHED.types,
    value: 'Published',
    linkTo: TestletTypes.PUBLISHED.linkTo
  },
  {
    id: TestletTypes.FLAGGED.types,
    value: 'Flagged',
    linkTo: TestletTypes.FLAGGED.linkTo
  }
];

export default testletStatuses;
