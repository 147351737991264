import React from 'react';
import { Card, CardBody, Row, CardHeader, CardFooter } from 'reactstrap';
import PropTypes from 'prop-types';
import TypeBadge from './TypeBadge';
import _ from 'lodash';
import { isIterableArray } from '../helpers/utils';
import { getCollaboratorName, getAdminStatusDetails, getTestletCreationDays } from '../helpers/inbdeUtils';
import { ContentTypeBadge } from './content-badges/contentBadges';

const TestletListTileContent = ({
  data,
  title,
  toggleModal,
  statusDetails,
  id,
  collabBadge,
  publishBadge,
  isTestletPublic,
  isUserAdmin
}) => {
  const {
    testlet_information,
    questions,
    published_inbdeCourse,
    version,
    testlet_type,
    is_latest_version,
    created_on,
    is_deleted
  } = data;
  const isCollab = !_.isEmpty(collabBadge);

  let courses_year_semester = [];
  let mockExams = [];

  if (isIterableArray(questions)) {
    questions.forEach(question => {
      const { course, course_year: courseYear, course_semester: courseSemester, mockExam } = question;
      const courses = course && _.isEmpty(course) ? '' : course['label'];
      const course_year = _.isEmpty(courseYear) ? '' : courseYear['label'];
      const course_semester = _.isEmpty(courseSemester) ? '' : courseSemester['label'];

      courses_year_semester.push([courses, course_year, course_semester].filter(option => option !== '').join(', '));

      if (mockExam) {
        let mockExamData;
        if (isIterableArray(mockExam)) {
          mockExam.forEach(exam => {
            mockExamData = !_.isEmpty(exam) && exam['label'] ? exam['label'] : '';
            mockExams.push(mockExamData);
          });
        } else {
          mockExamData = !_.isEmpty(mockExam) && mockExam['label'] ? mockExam['label'] : '';
          mockExams.push(mockExamData);
        }
      }
    });
  } else {
    const { mockExams: examArray, courseSemesterYear } = data;
    mockExams = examArray || [];
    courses_year_semester = courseSemesterYear || [];
  }

  courses_year_semester = courses_year_semester.filter(option => option !== '').join('; ');
  mockExams = mockExams.filter(option => option !== '').join('; ');

  let inbdePrepCourse =
    published_inbdeCourse && !_.isEmpty(published_inbdeCourse)
      ? published_inbdeCourse
      : testlet_information.inbdeCourse;
  if (inbdePrepCourse) {
    if (isIterableArray(inbdePrepCourse)) {
      inbdePrepCourse = inbdePrepCourse.map(course => (course && course['label'] ? course['label'] : '')).join('; ');
    } else {
      inbdePrepCourse = inbdePrepCourse['label'] ? inbdePrepCourse['label'] : '';
    }
  } else inbdePrepCourse = '';

  const collaborators = testlet_information.collaboratos
    .map(collab => {
      return getCollaboratorName(collab);
    })
    .join(', ');

  const adminBadge = getAdminStatusDetails(testlet_type);
  const openedDaysAgo =
    testlet_type === '1B' && !is_latest_version && !is_deleted ? getTestletCreationDays(created_on) : null;
  const openedDaysAgoText =
    openedDaysAgo !== null ? (openedDaysAgo === 0 ? 'Today' : `${openedDaysAgo} Day(s) Ago`) : '';
  const showCollabBadge = !isTestletPublic && isCollab;

  return (
    <Card className="mb-2 cursor-pointer" onClick={() => toggleModal()}>
      <CardHeader className="bg-100 pb-2">
        <Row>
          <div className="col-12 col-md-6 pt-1 pb-1">
            <h5 className="text-truncate">{title !== '' ? title : '<Testlet-Title>'}</h5>
          </div>
          <div className="col-12 col-md-6 pt-1 pb-1">
            <Row>
              {isTestletPublic && (
                <h5 className="cursor-pointer mr-2 mb-2 mb-md-0 ml-md-auto ml-2">
                  <TypeBadge details={publishBadge} testletId={id + '-published'} />
                </h5>
              )}
              {showCollabBadge ? (
                <h5 className={'cursor-pointer mb-0 ml-2 ' + (isTestletPublic ? 'mr-2 ml-md-0' : 'ml-md-auto mr-4')}>
                  <TypeBadge details={collabBadge} testletId={id + '-collaboration'} />
                </h5>
              ) : (
                <h5 className={'cursor-pointer mb-0 ml-2 ' + (isTestletPublic ? 'mr-2 ml-md-0' : 'ml-md-auto mr-4')}>
                  <TypeBadge details={statusDetails} testletId={id} />
                </h5>
              )}
            </Row>
          </div>
        </Row>
      </CardHeader>
      <CardBody className="pt-2 pb-2 pl-1">
        <Row className="ml-0">
          <div className="col-12 col-md-6 col-xl-5 pt-1 pb-1">
            <Row>
              <div className="col-12 font-weight-semi-bold">Course, Semester of course &amp; DDS Year:</div>
              <div className="col-12 text-truncate">{courses_year_semester}</div>
            </Row>
          </div>
          <div className="col-12 col-md-6 col-xl-3 pt-1 pb-1">
            <Row>
              <div className="col-12 font-weight-semi-bold">Testlet Originator:</div>
              <div className="col-12 text-truncate">{testlet_information['testlet_creator']}</div>
            </Row>
          </div>
          <div className="col-12 col-md-6 col-xl-4 pt-1 pb-1">
            <Row>
              <div className="col-12 font-weight-semi-bold">Collaborators:</div>
              <div className="col-12 text-truncate">{collaborators}</div>
            </Row>
          </div>
          <div className="col-12 col-md-5 pt-1 pb-1">
            <Row>
              <div className="col-12 font-weight-semi-bold">INBDE Prep Course:</div>
              <div className="col-12 text-truncate">{inbdePrepCourse}</div>
            </Row>
          </div>
          <div className="col-12 col-md-6 col-xl-3 pt-1 pb-1">
            <Row>
              <div className="col-12 font-weight-semi-bold">Exam:</div>
              <div className="col-12 text-truncate">{mockExams}</div>
            </Row>
          </div>
          <div className="col-12 col-md-6 col-xl-4 pt-1 pb-1">
            <Row>
              <div className="col-12 font-weight-semi-bold">Version:</div>
              <div className="col-12 text-truncate">{version}</div>
            </Row>
          </div>
        </Row>
      </CardBody>

      <CardFooter className="pt-0">
        <Row>
          <div className="ml-auto">
            {Boolean(openedDaysAgoText) && <h6 className="mb-0 mr-2 d-inline">Opened {openedDaysAgoText}</h6>}
            {Boolean(isUserAdmin) && (
              <h5 className="cursor-pointer mb-0 mr-4 d-inline">
                <TypeBadge details={adminBadge} testletId={id + '-admin'} />
              </h5>
            )}
            <h5 className="cursor-pointer mb-0 mr-2 d-inline">
              <ContentTypeBadge contentId={id} />
            </h5>
          </div>
        </Row>
      </CardFooter>
    </Card>
  );
};

TestletListTileContent.propTypes = {
  data: PropTypes.object,
  title: PropTypes.string,
  toggleModal: PropTypes.func,
  statusDetails: PropTypes.object,
  id: PropTypes.string,
  collabBadge: PropTypes.object,
  publishBadge: PropTypes.object,
  isTestletPublic: PropTypes.bool,
  isUserAdmin: PropTypes.bool
};

export default TestletListTileContent;
