import React from 'react';
import PropTypes from 'prop-types';
import INBDETable from '../table/INBDETable';
import { columns } from '../../data/reports/pastReportsTable';
import { Card, CardBody } from 'reactstrap';
import FalconCardHeader from '../common/FalconCardHeader';

const PastReportsTable = ({ reports }) => {
  const setIsSelected = () => {};

  return (
    <Card className="mb-3 mt-3">
      <FalconCardHeader title="Generated Reports" light={false} />
      <CardBody>
        <INBDETable setIsSelected={setIsSelected} data={reports} columns={columns} />
      </CardBody>
    </Card>
  );
};

PastReportsTable.propTypes = {
  reports: PropTypes.array
};

export default PastReportsTable;
