import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Form,
  Label,
  Input,
  Row,
  ButtonDropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  Spinner
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { toast } from 'react-toastify';
import { isEmailValid, isStringBlankOrNull } from '../helpers/utils';
import AddMemberConfirmationMessage from '../components/access/addMemberConfirmationMessage';

class AddMembersForm extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      dropdownOpen: false,
      accessType: 'faculty',
      value: '',
      isAjaxRequest: false,
      isEmailMissingFromList: false,
      IsEmailPresentInList: false,
      userName: ''
    };

    this.addEmailToWhitelist = this.addEmailToWhitelist.bind(this);
    this.checkEmailInOrganizationMembersList = this.checkEmailInOrganizationMembersList.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.toggle = this.toggle.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  addEmailToWhitelist(email, accessType) {
    const { addUserToWhitelist } = this.props;

    addUserToWhitelist([email, accessType], isUserAdded => {
      if (isUserAdded) {
        this._isMounted &&
          this.setState(
            {
              isAjaxRequest: false,
              value: '',
              accessType: 'faculty',
              isEmailMissingFromList: false,
              IsEmailPresentInList: false,
              userName: ''
            },
            () => {
              this.closeModal();
              toast.success(`${email} was successfully added to the whitelist`);
            }
          );
      } else {
        this._isMounted && this.setState({ isAjaxRequest: false });
      }
    });
  }

  changeAccessCategory(type) {
    this._isMounted && this.setState({ accessType: type });
  }

  async checkEmailInOrganizationMembersList(email) {
    const { checkEmailInOrganizationList } = this.props;
    const emailEntryResponse = await checkEmailInOrganizationList(email);

    if (emailEntryResponse.isExists) {
      // proceed to submit the user email address
      const userEntry = emailEntryResponse.data;
      const userName = userEntry && userEntry.full_name ? userEntry.full_name : '';
      this._isMounted && this.setState({ IsEmailPresentInList: true, isAjaxRequest: false, userName });
    } else {
      if (emailEntryResponse.isSuccess) {
        // show that email does not exist and give option to proceed
        this._isMounted && this.setState({ isEmailMissingFromList: true, isAjaxRequest: false });
      } else {
        // show error message that there was an error and try again later
        toast.error('Could not add email at the moment. Please try again later.');
        this._isMounted && this.setState({ isAjaxRequest: false });
      }
    }
  }

  closeModal() {
    const { closeAddMembersModal } = this.props;
    closeAddMembersModal();
  }

  handleChange(e) {
    this._isMounted && this.setState({ value: e.target.value });
  }

  handleSubmit(e) {
    e.preventDefault();
    const { isUserInWhitelist } = this.props;
    const { value: email, accessType, isEmailMissingFromList, IsEmailPresentInList } = this.state;

    if (isEmailMissingFromList || IsEmailPresentInList) {
      this._isMounted && this.setState({ isAjaxRequest: true });
      this.addEmailToWhitelist(email, accessType);
      return;
    }

    if (typeof email !== 'string' || isStringBlankOrNull(email)) {
      return;
    }

    if (!isEmailValid(email)) {
      toast.error('Please enter an email address with valid format');
      return;
    }

    if (isUserInWhitelist({ email })) {
      toast.error('This email is already in the whitelist');
      return;
    }

    this._isMounted && this.setState({ isAjaxRequest: true });
    this.checkEmailInOrganizationMembersList(email);
  }

  toggle() {
    this._isMounted && this.setState(state => ({ dropdownOpen: !state.dropdownOpen }));
  }

  render() {
    const {
      accessType: access,
      isAjaxRequest,
      dropdownOpen,
      value,
      userName,
      isEmailMissingFromList,
      IsEmailPresentInList
    } = this.state;
    const accessType = access.charAt(0).toUpperCase() + access.slice(1);
    const isShowConfirmationMessage = isEmailMissingFromList || IsEmailPresentInList;

    return (
      <Form onSubmit={this.handleSubmit}>
        <Row form>
          <div className="col-12">
            <div className="p-3 ml-auto mr-auto">
              {isShowConfirmationMessage ? (
                <AddMemberConfirmationMessage
                  accessType={accessType}
                  isEmailMissingFromList={isEmailMissingFromList}
                  userName={userName}
                  value={value}
                />
              ) : (
                <div>
                  <Label>Enter the email address of the person to add:</Label>
                  <Row form className="mb-2">
                    <div className="col-9 pr-0">
                      <Input
                        type="email"
                        name="email"
                        id="addMembersEmail"
                        placeholder="Enter email address"
                        className="pr-0 border-radius-zero h-100"
                        required
                        autoComplete="off"
                        onChange={this.handleChange}
                        value={value}
                      />
                    </div>
                    <div className="col-2 pl-0">
                      <ButtonDropdown isOpen={dropdownOpen} toggle={this.toggle} className="h-100">
                        <DropdownToggle caret className="bg-300 react-select__control" color="black">
                          <FontAwesomeIcon icon="lock" className="d-inline mr-2 text-dark d-md-none" />
                          <p className="d-md-inline d-none">{accessType}</p>
                        </DropdownToggle>
                        <DropdownMenu>
                          <DropdownItem header>Access Type</DropdownItem>
                          <DropdownItem divider />
                          <DropdownItem onClick={this.changeAccessCategory.bind(this, 'faculty')}>
                            Faculty
                            {accessType === 'Faculty' && (
                              <FontAwesomeIcon icon="check" className="d-inline ml-2 mr-2 text-dark" />
                            )}
                          </DropdownItem>
                          <DropdownItem onClick={this.changeAccessCategory.bind(this, 'admin')}>
                            Admin
                            {accessType === 'Admin' && (
                              <FontAwesomeIcon icon="check" className="d-inline ml-2 mr-2 text-dark" />
                            )}
                          </DropdownItem>
                        </DropdownMenu>
                      </ButtonDropdown>
                    </div>
                  </Row>
                </div>
              )}
            </div>
            <hr className="mb-0" />
            <div className="p-2">
              <Row className="p-2">
                <div className="ml-auto">
                  <Button
                    color="secondary"
                    className="mt-2 mt-sm-0 mr-2 pr-4 pl-4"
                    onClick={this.closeModal}
                    disabled={isAjaxRequest}
                  >
                    Cancel
                  </Button>
                  <Button
                    type="submit"
                    color="primary"
                    className="mt-2 mt-sm-0 pr-4 pl-4 mr-3"
                    disabled={isAjaxRequest}
                  >
                    {isEmailMissingFromList ? 'Confirm' : 'Invite'}
                    {isAjaxRequest && <Spinner size="sm" color="light" className="ml-2" />}
                  </Button>
                </div>
              </Row>
            </div>
          </div>
        </Row>
      </Form>
    );
  }
}

AddMembersForm.propTypes = {
  checkEmailInOrganizationList: PropTypes.func.isRequired,
  closeAddMembersModal: PropTypes.func.isRequired,
  isUserInWhitelist: PropTypes.func.isRequired,
  addUserToWhitelist: PropTypes.func,
  refreshPage: PropTypes.func
};

export default AddMembersForm;
