import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  FormGroup,
  Label,
  Input,
  Card,
  CardBody,
  CardFooter,
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Spinner,
  UncontrolledTooltip
} from 'reactstrap';
import FalconCardHeader from '../components/common/FalconCardHeader';
import Select from 'react-select';
import AnswerChoice from '../widgets/AnswerChoice';
import ButtonIcon from '../components/common/ButtonIcon';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FileViewer from 'react-file-viewer';
import _ from 'lodash';
import TestletService from '../services/Testlet/TestletService';
import CollaborationService from '../services/Collaboration/CollaborationService';
import { toast } from 'react-toastify';
import UploadFile from '../components/files/UploadFile';
import { TestletContext } from '../Contexts';
import classNames from 'classnames';
import TestletFormQuestionService from '../components/testletForm/TestletFormQuestionService';
import DifficultyLevelToolTip from '../components/testlet/common/DifficultyLevelToolTip';
import { getCurrentTimeStamp } from '../helpers/inbdeUtils';
import { Draggable } from 'react-beautiful-dnd';

const baseStorageDir = 'testlets';
const fileTypes = ['image/*', 'application/pdf'];
const customReactSelectStyles = {
  option: (provided, state) => {
    const color = state.isDisabled ? 'black' : (state.isSelected ? 'white' : '');
    const fontWeight = state.isDisabled ? 800 : '';

    return { ...provided, color, fontWeight };
  }
};
const maxAllowedAnswers = 6;

class TestletQuestion extends Component {
  _isMounted = false;
  finalAnswers = [];
  collaborationService = new CollaborationService();
  testletService = new TestletService();
  testletFormQuestionService = new TestletFormQuestionService();
  previousQuestionState = {};
  updateQuestionModel = {};

  constructor(props) {
    super(props);

    this.state = {
      question: null,
      created_by: '',
      created_at: null,
      creator_type: null,
      id: '',
      uuid: '',
      course: [],
      course_semester: [],
      collaboratorIds: [],
      course_year: [],
      question_creator: '',
      foundational_knowledge: [],
      mockExam: [],
      collaborators: [],
      clinical_content: [],
      systems_areas: [],
      difficulty_level: [],
      key_concepts: '',
      semester_mock_exam: [],
      review_material: [],
      question_stem: {
        text: '',
        attachments: []
      },
      answers: [],
      tempCollaborators: [],
      collabFlag: 'add',
      collabModal: false,
      isUploadAttachmentModalOpen: false,
      collapseSection: false,
      previewModalFlag: false,
      fileObjectView: null,
      activeDrags: 0,
      deltaPosition: {
        x: 0,
        y: 0
      },
      controlledPosition: {
        x: -400,
        y: 200
      },
      attachmentToAddIn: null,
      attachmentToRemoveFrom: null,
      deleteAttachmentModal: false,
      isSpinner: false,
      testletId: null,
      user: null,
      creator_id: null,
      isQuestionEditbyUser: false,
      isQuestionReorderEnabled: false
    };

    this.addComment = this.addComment.bind(this);
    this.addAnswerChoice = this.addAnswerChoice.bind(this);
    this.changeQuestionCreator = this.changeQuestionCreator.bind(this);
    this.deleteAnswerChoice = this.deleteAnswerChoice.bind(this);
    this.deleteAttachmentConfirm = this.deleteAttachmentConfirm.bind(this);
    this.deleteQuestionMethod = this.deleteQuestionMethod.bind(this);
    this.getAnswerInformation = this.getAnswerInformation.bind(this);
    this.handleCollab = this.handleCollab.bind(this);
    this.handleCollaborators = this.handleCollaborators.bind(this);
    this.setCorrectAnswerChoice = this.setCorrectAnswerChoice.bind(this);
    this.setFileModel = this.setFileModel.bind(this);
    this.submitCollaborators = this.submitCollaborators.bind(this);
    this.toggleDeleteAttachmentModal = this.toggleDeleteAttachmentModal.bind(this);
    this.toggleSection = this.toggleSection.bind(this);
    this.togglePreviewModal = this.togglePreviewModal.bind(this);
    this.toggleUploadAttachmentModal = this.toggleUploadAttachmentModal.bind(this);
    this.updateAnswerInfoForComments = this.updateAnswerInfoForComments.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;

    if (this.context) {
      // get testlet and user
      const { isCollaboratorAtTestletLevel } = this.props;
      const { user, testletId, creator_id, collaboratorIds, testlet_type, toggles } = this.context;
      const userId = user ? user.uid : '';
      const isUserAdmin = user ? user.access_type === 'admin' : false;
      let userRole;
      if (isUserAdmin) {
        userRole = 'admin';
      } else {
        if (userId === creator_id) {
          userRole = 'testlet_creator';
        } else if (isCollaboratorAtTestletLevel(userId)) {
          userRole = 'testlet_collaborator';
        } else {
          userRole = 'question_collaborator';
        }
      }
      const isQuestionReorderEnabled = this.testletFormQuestionService.isQuestionReorderEnabledForUserRole(
        userRole,
        toggles
      );
      this.setState({ user, testletId, creator_id, collaboratorIds, testlet_type, isQuestionReorderEnabled }, () => {
        this.updateStateFromProps();
      });
    }
  }

  componentDidUpdate(prevProps) {
    this.finalAnswers = [];

    if (!_.isEqual(this.props.question, this.state.question) && !_.isEqual(prevProps.question, this.props.question)) {
      this.updateStateFromProps();
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  addAnswerChoice() {
    const { isBlocking } = this.props;

    let answersList = this.state.answers;
    const answerLength = answersList.length;

    if (answerLength >= maxAllowedAnswers) {
      toast.error('Cannot add more answers');
      return;
    }

    const answerName = 'Answer ' + (answerLength + 1);

    const newAnswer = {
      id: answerLength,
      answer_choice: {
        text: '',
        attachments: []
      },
      explanation: {
        text: '',
        attachments: []
      },
      isCorrect: false
    };

    answersList.push(newAnswer);

    this._isMounted && this.setState({ answers: answersList });

    this.addComment(answerName, true, false, true);
    isBlocking();
  }

  addComment(fieldValue, action, isFile, isAnswer) {
    const { id, uuid } = this.state;
    const sectionKey = 'question-' + uuid;
    const fieldName = 'Question ' + (id + 1);
    const { testletId, user } = this.state;
    const commentInfo = [
      {
        fieldName,
        oldValue: action ? null : fieldValue,
        newValue: action ? fieldValue : null,
        is_testlet_action: true,
        is_file: isFile,
        is_answer: isAnswer
      }
    ];

    this.collaborationService.updateTestletComments(testletId, sectionKey, commentInfo, user);
  }

  changeQuestionCreator(newCreator) {
    if (!newCreator) {
      return;
    }

    try {
      const { isBlocking, isCollaboratorAtTestletLevel } = this.props;
      const { collaborators, collaboratorIds, testletId, creator_id } = this.state;
      const { value: newCreatorId, label, email, name } = newCreator;

      // check if user is testlet collaborator - if yes, do nothing. Else, add to testlet collaborators
      // check if user in collaboratorIds - if yes, do nothing. Else, add to collaboratorIds
      // check if user is question collaborator - if yes, remove from question collaborators. Else, do nothing

      const updatedCollaboratorIds =
        collaboratorIds.includes(newCreatorId) || newCreatorId === creator_id
          ? collaboratorIds
          : [...collaboratorIds, newCreatorId];

      const isUserQuestionCollaborator = Boolean(collaborators.filter(user => user.value === newCreatorId).length);
      const updatedQuestionCollaborators =
        isUserQuestionCollaborator && newCreatorId !== creator_id
          ? collaborators.filter(user => user.value !== newCreatorId)
          : collaborators;

      if (!isCollaboratorAtTestletLevel(newCreatorId) && newCreatorId !== creator_id) {
        // add to testlet collaborators
        const testletCollabModel = {
          added_at: getCurrentTimeStamp(),
          removed_at: null,
          value: newCreatorId,
          label,
          id: newCreatorId,
          email,
          name
        };
        this.testletService.updateTestletCollaborators(testletId, testletCollabModel, updatedCollaboratorIds);
      }

      this._isMounted &&
        this.setState({
          question_creator: label,
          created_by: newCreatorId,
          collaborators: updatedQuestionCollaborators
        });
      isBlocking();
    } catch (e) {
      console.error(e);
      toast.error('Unable to change question creator');
    }
  }

  deleteAnswerChoice(id) {
    const { isBlocking } = this.props;

    const { answers } = this.state;
    let newAnswers = [];
    const answerName = 'Answer ' + (id + 1);

    newAnswers = answers.filter(answer => {
      return answer.id !== id;
    });

    for (let i = 0; i < newAnswers.length; i += 1) {
      newAnswers[i].id = i;
    }

    this._isMounted && this.setState({ answers: newAnswers });

    this.addComment(answerName, false, false, true);
    isBlocking();
  }

  async deleteAttachmentConfirm() {
    const { isBlocking } = this.props;
    const { fileObjectView, attachmentToRemoveFrom } = this.state;

    this._isMounted && this.setState({ isSpinner: true });

    const isAttachmentDeleted = await this.testletService.deleteAttachmentFromStorage(fileObjectView.url);

    if (isAttachmentDeleted) {
      // remove from local state and set isBlocking to true
      if (attachmentToRemoveFrom === 'review') {
        const { review_material } = this.state;
        const newMaterial = review_material.filter(item => item.url !== fileObjectView.url);

        this._isMounted && this.setState({ review_material: newMaterial });
      } else if (attachmentToRemoveFrom === 'question_stem') {
        const { question_stem } = this.state;

        const newAttachments = question_stem.attachments.filter(item => item.url !== fileObjectView.url);

        const newQuestionStem = question_stem;
        newQuestionStem.attachments = newAttachments;

        this._isMounted && this.setState({ question_stem: newQuestionStem });
      } else {
        const { answers } = this.state;
        let newAnswers = [];

        if (attachmentToRemoveFrom === 'answer_choice') {
          answers.forEach(answer => {
            const newAttachments = [];

            answer.answer_choice.attachments.forEach(item => {
              if (item.url !== fileObjectView.url) {
                newAttachments.push(item);
              }
            });
            answer.answer_choice.attachments = newAttachments;
            newAnswers.push(answer);
          });

          this._isMounted && this.setState({ answers: newAnswers });
        } else if (attachmentToRemoveFrom === 'answer_explanation') {
          newAnswers = answers.map(answer => {
            answer.explanation.attachments = answer.explanation.attachments.filter(item => {
              return item.url !== fileObjectView.url;
            });
            return answer;
          });

          this._isMounted && this.setState({ answers: newAnswers });
        }
      }

      toast.success('Attachment deleted successfully!');

      this.addComment(fileObjectView.file_name, false, true, false);
      isBlocking();
    } else {
      toast.error('Could not delete attachment!');
    }

    this._isMounted && this.setState({ isSpinner: false });

    this.toggleDeleteAttachmentModal();
    this.togglePreviewModal(null, null);
  }

  deleteQuestionMethod() {
    const { question, deleteQuestion } = this.props;
    const { id } = question;
    deleteQuestion(id);
  }

  handleCollaborators(change) {
    this._isMounted && this.setState({ tempCollaborators: change });

    const { collaborators } = this.state;

    if (change) {
      if (collaborators.length < change.length) {
        this.inviteCollabs();
      } else {
        this.removeCollab();
      }
    } else {
      if (collaborators.length) {
        this.removeCollab();
      }
    }
  }

  handleCollab() {
    this._isMounted && this.setState(state => ({ collabModal: !state.collabModal }));
  }

  inviteCollabs() {
    this._isMounted && this.setState({ collabFlag: 'add' });
    this.handleCollab();
  }

  removeCollab() {
    this._isMounted &&
      this.setState({ collabFlag: 'del' }, () => {
        this.handleCollab();
      });
  }

  setFileModel(fileModel) {
    const { isBlocking } = this.props;
    const { attachmentToAddIn, review_material, question_stem } = this.state;

    if (attachmentToAddIn === 'review') {
      const newMaterials = [...review_material, fileModel];

      this._isMounted &&
        this.setState({ review_material: newMaterials }, () => {
          this.toggleUploadAttachmentModal(null);
          isBlocking();
        });
    } else if (attachmentToAddIn === 'question_stem') {
      const newStem = question_stem;
      newStem['attachments'] = [...question_stem.attachments, fileModel];

      this._isMounted &&
        this.setState({ question_stem: newStem }, () => {
          this.toggleUploadAttachmentModal(null);
          isBlocking();
        });
    }
    this.addComment(fileModel.file_name, true, true, false);
  }

  submitCollaborators(e) {
    e.preventDefault();

    const { isCollaboratorInMultipleSections } = this.props;
    let { collaborators, tempCollaborators, collaboratorIds, collabFlag, user, testletId } = this.state;
    const prevCollaborators = collaborators ? collaborators.map(user => user.label).join(', ') : [];
    const isAddCollaborator = collabFlag === 'add';
    let userToUpdate;

    if (tempCollaborators === null) {
      // removing last collaborator
      if (!!collaborators.length) {
        userToUpdate = collaborators[0];
      }

      collaborators = [];
    } else {
      userToUpdate = this.testletFormQuestionService.getCollaboratorModel(
        collaborators,
        tempCollaborators,
        isAddCollaborator
      );
      collaborators = tempCollaborators;
    }

    if (userToUpdate) {
      const { id: userId } = userToUpdate;
      let isSendEmail = false;
      const isUserInCollaboratorIdList = !!collaboratorIds.filter(id => id === userId).length;

      if (isAddCollaborator) {
        if (isUserInCollaboratorIdList) {
          collaboratorIds = null;
        } else {
          collaboratorIds.push(userId);
          isSendEmail = true;
        }
      } else {
        if (isCollaboratorInMultipleSections(userId)) {
          collaboratorIds = null;
        } else {
          collaboratorIds = collaboratorIds.filter(id => id !== userId);
        }
      }

      collaboratorIds &&
        this.testletService.updateQuestionCollaborators(testletId, user, userId, collaboratorIds, isSendEmail);

      const newValue = collaborators.map(user => user.label).join(', ');
      this.updateQuestionModel['collaborators'] = {
        fieldName: 'Collaborators',
        oldValue: prevCollaborators,
        newValue
      };
      this._isMounted && this.setState({ isBlocking: true, collaborators });
    }

    this.handleCollab();
  }

  toggleDeleteAttachmentModal() {
    this._isMounted && this.setState(state => ({ deleteAttachmentModal: !state.deleteAttachmentModal }));
  }

  toggleSection() {
    this._isMounted && this.setState(state => ({ collapseSection: !state.collapseSection }));
  }

  togglePreviewModal(file, key) {
    const { previewModalFlag } = this.state;

    if (!previewModalFlag) {
      const fileType = file.content_type.split('/').pop();
      file.content_type = fileType;
      this._isMounted &&
        this.setState({
          previewModalFlag: true,
          fileObjectView: file,
          attachmentToRemoveFrom: key
        });
    } else {
      this.setState({ previewModalFlag: false, fileObjectView: null, attachmentToRemoveFrom: null });
    }
  }

  toggleUploadAttachmentModal(key) {
    this._isMounted &&
      this.setState(state => ({
        isUploadAttachmentModalOpen: !state.isUploadAttachmentModalOpen,
        attachmentToAddIn: key
      }));
  }

  deleteAttachmentModal() {
    const { deleteAttachmentModal, isSpinner } = this.state;
    return (
      <Modal isOpen={deleteAttachmentModal} toggle={this.toggleDeleteAttachmentModal}>
        <ModalHeader title={''}>Document Preview</ModalHeader>
        <ModalBody className="no-scrollbar-container">
          <h6>Are you sure you wish to delete this attachment?</h6>
        </ModalBody>
        <ModalFooter>
          <Button color="danger" onClick={this.deleteAttachmentConfirm} disabled={isSpinner}>
            Delete
            {isSpinner && <Spinner size="sm" color="light" className="ml-2" />}
          </Button>
          <Button onClick={this.toggleDeleteAttachmentModal} disabled={isSpinner}>
            Close
          </Button>
        </ModalFooter>
      </Modal>
    );
  }

  documentPreviewModal() {
    const { previewModalFlag, fileObjectView, isQuestionEditbyUser } = this.state;

    return (
      <Fragment>
        <Modal isOpen={previewModalFlag} toggle={this.togglePreviewModal} className="modal-lg modal-xl">
          <ModalHeader title={''}>Document Preview</ModalHeader>
          <ModalBody className="no-scrollbar-container vh-50 vh-md-75">
            {fileObjectView && (
              <FileViewer fileType={fileObjectView.content_type} filePath={fileObjectView.url} onError={this.onError} />
            )}
          </ModalBody>
          {this.deleteAttachmentModal()}
          <ModalFooter>
            <Button
              color="danger"
              onClick={this.toggleDeleteAttachmentModal}
              className={classNames({ 'd-none': !isQuestionEditbyUser })}
            >
              Delete
            </Button>
            <Button onClick={this.togglePreviewModal.bind(this, null, null)}>Close</Button>
          </ModalFooter>
        </Modal>
      </Fragment>
    );
  }

  getAnswerInformation(answer) {
    this.finalAnswers.push(answer);

    if (this.finalAnswers.length === this.state.answers.length) {
      this.submitQuestionInformation(this.finalAnswers);
    }
  }

  onError(e) {
    console.error(e);
  }

  onStart = () => {
    const newState = this.state.activeDrags + 1;
    this._isMounted && this.setState({ activeDrags: newState });
  };

  onStop = () => {
    const newState = this.state.activeDrags - 1;
    this._isMounted && this.setState({ activeDrags: newState });
  };

  setCorrectAnswerChoice(id, bool) {
    let newAnswers = this.state.answers.map(answer => {
      if (answer.id === id) {
        answer.isCorrect = bool;
      }
      return answer;
    });

    this._isMounted && this.setState({ answers: newAnswers });
  }

  submitQuestionInformation(answers) {
    const { setQuestionInformation } = this.props;
    let { course_semester, course_year, key_concepts } = this.state;
    const key_concepts_type = typeof key_concepts;

    if (key_concepts_type === 'string') {
      key_concepts = key_concepts.trim().split(',');
    }

    let dds_course_semester = null;
    if (course_year && course_year.label && course_semester && course_semester.label) {
      dds_course_semester = course_year.label + ', ' + course_semester.label;
    }

    const content = {
      id: this.state.id,
      uuid: this.state.uuid,
      course: this.state.course,
      course_semester: this.state.course_semester,
      course_year: this.state.course_year,
      dds_course_semester,
      question_creator: this.state.question_creator.trim(),
      foundational_knowledge: this.state.foundational_knowledge,
      clinical_content: this.state.clinical_content,
      systems_areas: this.state.systems_areas,
      difficulty_level: this.state.difficulty_level,
      key_concepts,
      review_material: this.state.review_material,
      semester_mock_exam: this.state.semester_mock_exam,
      question_stem: {
        attachments: this.state.question_stem.attachments,
        text: this.state.question_stem.text.trim()
      },
      mockExam: this.state.mockExam,
      collaborators: this.state.collaborators,
      answers: answers,
      created_by: this.state.created_by,
      created_at: this.state.created_at,
      creator_type: this.state.creator_type
    };

    if (!_.isEmpty(this.previousQuestionState) && !_.isEqual(this.previousQuestionState, content)) {
      const questionKey = 'question-' + this.state.uuid;
      this.collaborationService.updateTestletComments(
        this.state.testletId,
        questionKey,
        this.updateQuestionModel,
        this.state.user
      );

      this.previousQuestionState = JSON.parse(JSON.stringify(content));
    }

    this.updateQuestionModel = {};
    setQuestionInformation(content);
  }

  updateAnswerInfoForComments(updateToAnswer, id) {
    for (let key in updateToAnswer) {
      const newKey = key + id;
      this.updateQuestionModel[newKey] = updateToAnswer[key];
    }
  }

  updateStateFromProps() {
    const { question } = this.props;
    let { user, testletId, creator_id, collaboratorIds, testlet_type } = this.state;

    const collaborators = question.collaborators ? question.collaborators : [];
    let isQuestionEditbyUser = false;
    // user is question creator
    if (user) {
      isQuestionEditbyUser =
        user.uid === question.created_by ||
        // user is admin
        user.access_type === 'admin' ||
        // user is question collaborator
        !!collaborators.filter(collab => collab.value === user.uid).length;
    }
    const mockExam = question.mockExam ? question.mockExam : [];
    const semesterMockExam = question.semester_mock_exam || [];

    const previousQuestionState = {
      question: question,
      created_by: question.created_by,
      id: question.id,
      uuid: question.uuid,
      course: question.course,
      course_semester: question.course_semester,
      course_year: question.course_year,
      dds_course_semester: question.dds_course_semester ? question.dds_course_semester : null,
      question_creator: question.question_creator,
      foundational_knowledge: question.foundational_knowledge,
      clinical_content: question.clinical_content,
      systems_areas: question.systems_areas,
      difficulty_level: question.difficulty_level,
      key_concepts: question.key_concepts,
      review_material: question.review_material,
      semester_mock_exam: semesterMockExam,
      question_stem: question.question_stem,
      answers: question.answers,
      mockExam,
      collaborators,
      isQuestionEditbyUser,
      collapseSection: !isQuestionEditbyUser,
      created_at: question.created_at,
      creator_type: question.creator_type,
      user,
      testletId,
      creator_id,
      collaboratorIds,
      testlet_type
    };

    this._isMounted && this.setState({ ...previousQuestionState });

    delete previousQuestionState['question'];
    delete previousQuestionState['isQuestionEditbyUser'];
    delete previousQuestionState['collapseSection'];

    this.previousQuestionState = JSON.parse(JSON.stringify(previousQuestionState));
  }

  render() {
    const {
      formProperties,
      isBlocking,
      isDeleteQuestion,
      isQuestionCommentsRead,
      isShowCommentsAndChangesButton,
      idx,
      question
    } = this.props;
    const {
      attachmentToAddIn,
      isUploadAttachmentModalOpen,
      testletId,
      user,
      isQuestionEditbyUser,
      question_creator,
      uuid,
      created_by,
      difficulty_level,
      isQuestionReorderEnabled
    } = this.state;
    const { uuid: qUuid, id } = question;
    const questionUuid = uuid || qUuid;
    const { collaborators, isTestletCloned, creator_id } = this.context;
    const collaboratorOptions = collaborators.filter(collab => collab.value !== created_by);

    const userAccess = user ? user.access_type : 'faculty';
    const isUserAdmin = userAccess === 'admin';
    const userId = user ? user.uid : '';

    const questionTitle = 'Question ' + (id + 1) + ' by ' + question_creator;
    const formOptions = formProperties;
    const questionID = 'cardHeaderContainer' + id;
    const questionCommentsTitle = 'Question ' + (id + 1) + ' Section';
    const questionCommentsKey = { section: 'question-' + uuid, title: questionCommentsTitle };
    const difficultyLevel = Array.isArray(difficulty_level) ? '' : difficulty_level['label'];
    const isQuestionDeleteByUser = isQuestionEditbyUser || (isTestletCloned && creator_id === userId);
    const uploadSubDir = testletId + '/question_' + (id + 1) + '/' + attachmentToAddIn;

    return (
      <Fragment>
        <UploadFile
          allowedFileTypes={fileTypes}
          baseStoragePath={baseStorageDir}
          creatorId={userId}
          isModalOpen={isUploadAttachmentModalOpen}
          parentRefId={uploadSubDir}
          setFileModel={this.setFileModel}
          toggleModal={this.toggleUploadAttachmentModal}
        />
        <Draggable draggableId={questionUuid || idx + ''} index={idx} isDragDisabled={!isQuestionReorderEnabled}>
          {provided => (
            <Card className="mb-3" {...provided.draggableProps} innerRef={provided.innerRef}>
              <div id={questionID} {...provided.dragHandleProps}>
                <FalconCardHeader
                  title={questionTitle}
                  light={true}
                  className={isQuestionReorderEnabled ? 'cursor-pointer' : ''}
                >
                  <div className="mt-2 mb-2">
                    <div
                      className="m-1 d-inline cursor-pointer pt-2 pb-2 pr-3 pl-3 click-icon avatar-xl"
                      onClick={this.toggleSection}
                    >
                      <div className="d-inline">
                        <FontAwesomeIcon
                          icon={this.state.collapseSection ? 'caret-down' : 'caret-up'}
                          transform="grow-5"
                          className="d-inline text-secondary"
                        />
                      </div>
                    </div>
                  </div>
                </FalconCardHeader>
              </div>
              <CardBody id={'cardBodyContainer' + id} className={this.state.collapseSection ? 'd-none' : 'd-block'}>
                <Row>
                  <FormGroup className="col-md-6 col-sm-12">
                    <Label for="readonly" className="fs-0">
                      DDS Course <strong className="text-danger">&#42;</strong>
                    </Label>
                    <Select
                      onChange={course => {
                        this.setState({ course: course });
                        const newValue = course ? course.label : '';
                        const oldValue =
                          this.previousQuestionState['course'] && this.previousQuestionState['course'].label
                            ? this.previousQuestionState['course'].label
                            : '';
                        this.updateQuestionModel['course'] = { fieldName: 'Course', newValue, oldValue };
                        isBlocking();
                      }}
                      options={formOptions.courseOptions}
                      value={this.state.course}
                      isDisabled={!isQuestionEditbyUser}
                      styles={customReactSelectStyles}
                      required
                    />
                  </FormGroup>
                  <FormGroup className="col-md-6 col-sm-12">
                    <Label for="readonly" className="fs-0">
                      Semester of course <strong className="text-danger">&#42;</strong>
                    </Label>
                    <Select
                      onChange={semester => {
                        this.setState({ course_semester: semester });
                        const newValue = semester ? semester.label : '';
                        const oldValue =
                          this.previousQuestionState['course_semester'] &&
                          this.previousQuestionState['course_semester'].label
                            ? this.previousQuestionState['course_semester'].label
                            : '';
                        this.updateQuestionModel['semester'] = { fieldName: 'Semester of Course', newValue, oldValue };
                        isBlocking();
                      }}
                      options={formOptions.semesterOptions}
                      value={this.state.course_semester}
                      isDisabled={!isQuestionEditbyUser}
                      required
                    />
                  </FormGroup>
                </Row>

                <Row>
                  <FormGroup className="col-md-6 col-sm-12">
                    <Label for="readonly" className="fs-0">
                      DDS Year <strong className="text-danger">&#42;</strong>
                    </Label>
                    <Select
                      onChange={year => {
                        this.setState({ course_year: year });
                        const newValue = year ? year.label : '';
                        const oldValue =
                          this.previousQuestionState['course_year'] && this.previousQuestionState['course_year'].label
                            ? this.previousQuestionState['course_year'].label
                            : '';
                        this.updateQuestionModel['year'] = { fieldName: 'DDS Year', newValue, oldValue };
                        isBlocking();
                      }}
                      options={formOptions.ddsYearOptions}
                      value={this.state.course_year}
                      isDisabled={!isQuestionEditbyUser}
                      required
                    />
                  </FormGroup>
                  <div className="col-md-6 col-sm-12">
                    <FormGroup>
                      <Label for="exampleName" className="fs-0">
                        Created By <strong className="text-danger">&#42;</strong>
                      </Label>
                      <Select
                        className="bg-testlet-form-disabled"
                        isDisabled={!isUserAdmin}
                        name="question_creator_name"
                        onChange={userModel => {
                          this.changeQuestionCreator(userModel);
                        }}
                        options={collaborators}
                        type="text"
                        value={{ label: this.state.question_creator, value: this.state.created_by }}
                        onMenuScrollToBottom={this.props.fetchUsersFromDB}
                      />
                    </FormGroup>
                  </div>
                </Row>

                <Row>
                  <FormGroup className="col-md-6 col-sm-12">
                    <Label for="readonly" className="fs-0">
                      Foundational Knowledge (check all that apply) <strong className="text-danger">&#42;</strong>
                    </Label>
                    <Select
                      onChange={values => {
                        values = values || [];
                        this.setState({ foundational_knowledge: values });
                        const newValue = values ? values.map(value => value.label).join() : '';
                        const oldValue = this.previousQuestionState['foundational_knowledge']
                          ? this.previousQuestionState['foundational_knowledge'].map(value => value.label).join()
                          : '';
                        this.updateQuestionModel['foundational_knowledge'] = {
                          fieldName: 'Foundational Knowledge',
                          newValue,
                          oldValue
                        };
                        isBlocking();
                      }}
                      options={formOptions.fkOptions}
                      value={this.state.foundational_knowledge}
                      isMulti
                      isDisabled={!isQuestionEditbyUser}
                      hideSelectedOptions={false}
                    />
                  </FormGroup>
                  <FormGroup className="col-md-6 col-sm-12">
                    <Label for="readonly" className="fs-0">
                      Clinical Content (check all that apply) <strong className="text-danger">&#42;</strong>
                    </Label>
                    <Select
                      id="clinical-content"
                      onChange={values => {
                        values = values || [];
                        this.setState({ clinical_content: values });
                        const newValue = values ? values.map(value => value.label).join() : '';
                        const oldValue = this.previousQuestionState['clinical_content']
                          ? this.previousQuestionState['clinical_content'].map(value => value.label).join()
                          : '';
                        this.updateQuestionModel['clinical_content'] = {
                          fieldName: 'Clinical Content',
                          newValue,
                          oldValue
                        };
                        isBlocking();
                      }}
                      options={formOptions.ccOptions}
                      value={this.state.clinical_content}
                      isMulti
                      isDisabled={!isQuestionEditbyUser}
                      styles={customReactSelectStyles}
                      hideSelectedOptions={false}
                    />
                  </FormGroup>
                </Row>

                <Row>
                  <FormGroup className="col-md-6 col-sm-12">
                    <Label for="readonly" className="fs-0" id={'mock-exam-' + id}>
                      Exam
                    </Label>
                    <Select
                      onChange={values => {
                        values = values || [];
                        this.setState({ mockExam: values });
                        const newValue = values ? values.map(value => value.label).join() : '';
                        const oldValue = this.previousQuestionState['mockExam']
                          ? this.previousQuestionState['mockExam'].map(value => value.label).join()
                          : '';
                        this.updateQuestionModel['mockExam'] = {
                          fieldName: 'Exam',
                          newValue,
                          oldValue
                        };
                        isBlocking();
                      }}
                      options={formOptions.inbdeYearSemester}
                      value={this.state.mockExam}
                      isDisabled={!isUserAdmin}
                      isMulti
                    />
                    <UncontrolledTooltip placement="top" target={'mock-exam-' + id}>
                      This field can only be accessed by the admins and denotes the exam where this question will be
                      used.
                    </UncontrolledTooltip>
                  </FormGroup>
                  <FormGroup className="col-md-6 col-sm-12">
                    <Label for="readonly" className="fs-0">
                      Question Collaborators
                    </Label>
                    <Select
                      name="question-collaborators"
                      type="select"
                      onChange={this.handleCollaborators}
                      value={this.state.collaborators}
                      options={collaboratorOptions}
                      isMulti
                      isDisabled={!isQuestionEditbyUser}
                      onMenuScrollToBottom={this.props.fetchUsersFromDB}
                    />
                  </FormGroup>
                </Row>

                <Row>
                  <div className="col-md-6 col-sm-12">
                    <FormGroup>
                      <Label for="readonly" className="fs-0">
                        Systems/Areas <strong className="text-danger">&#42;</strong>
                      </Label>
                      <Select
                        name={'systems_and_areas'}
                        onChange={values => {
                          values = values || [];
                          this.setState({ systems_areas: values });
                          const newValue = values ? values.map(value => value.label).join() : '';
                          const oldValue =
                            this.previousQuestionState['systems_areas'] &&
                            this.previousQuestionState['systems_areas'].label
                              ? this.previousQuestionState['systems_areas'].label
                              : '';
                          this.updateQuestionModel['systems_areas'] = {
                            fieldName: 'Systems/Areas',
                            newValue,
                            oldValue
                          };
                          isBlocking();
                        }}
                        value={this.state.systems_areas}
                        options={formOptions.systemAreasOptions}
                        isMulti
                        isDisabled={!isQuestionEditbyUser}
                      />
                    </FormGroup>
                  </div>
                  <FormGroup className="col-md-6 col-sm-12">
                    <Label for="readonly" className="fs-0">
                      Level of Difficulty <strong className="text-danger">&#42;</strong>
                    </Label>
                    <Select
                      id={questionID + 'difficulty-level'}
                      onChange={level => {
                        this.setState({ difficulty_level: level });
                        const newValue = level ? level.label : '';
                        const oldValue =
                          this.previousQuestionState['difficulty_level'] &&
                          this.previousQuestionState['difficulty_level'].label
                            ? this.previousQuestionState['difficulty_level'].label
                            : '';
                        this.updateQuestionModel['difficulty_level'] = {
                          fieldName: 'Level of Difficulty',
                          newValue,
                          oldValue
                        };
                        isBlocking();
                      }}
                      options={formOptions.diffcultyLevelOptions}
                      value={this.state.difficulty_level}
                      isDisabled={!isQuestionEditbyUser}
                    />
                  </FormGroup>
                </Row>

                {/* Invite Collaborators Modal */}
                <Modal id="collabModal" isOpen={this.state.collabModal} toggle={this.handleCollab}>
                  <ModalHeader toggle={this.handleCollab}>
                    {this.state.collabFlag === 'add' ? 'Add' : 'Remove'} Collaborator(s)
                  </ModalHeader>
                  <ModalBody>
                    <Label for="collabModalComment">
                      Are you sure you want to{' '}
                      {this.state.collabFlag === 'add'
                        ? 'add the selected faculty member(s) as collaborator(s) for this question?'
                        : 'remove this collaborator from this question? They will no longer be able to contribute to this question.'}
                    </Label>
                  </ModalBody>
                  <ModalFooter>
                    <Button color="secondary" onClick={this.handleCollab}>
                      Cancel
                    </Button>
                    {this.state.collabFlag === 'add' ? (
                      <Button color="success" onClick={this.submitCollaborators}>
                        Invite
                      </Button>
                    ) : (
                      <Button color="danger" onClick={this.submitCollaborators}>
                        Remove
                      </Button>
                    )}
                  </ModalFooter>
                </Modal>

                <Row>
                  <FormGroup className="col-md-6 col-sm-12">
                    <Label for="exampleName" className="fs-0">
                      Key Concepts <strong className="text-danger">&#42;</strong>
                    </Label>
                    <Input
                      type="text"
                      name="key-concepts"
                      placeholder="A comma seperated list of concepts"
                      onChange={e => {
                        this.setState({ key_concepts: e.target.value });
                        const newValue = e.target.value;
                        const oldValue = this.previousQuestionState['key_concepts']
                          ? this.previousQuestionState['key_concepts'].join()
                          : '';
                        this.updateQuestionModel['key_concepts'] = {
                          fieldName: 'Key Concepts',
                          newValue,
                          oldValue
                        };
                        isBlocking();
                      }}
                      value={this.state.key_concepts}
                      disabled={!isQuestionEditbyUser}
                      className={!isQuestionEditbyUser ? 'bg-testlet-form-disabled' : ''}
                      required
                    />
                  </FormGroup>
                  <FormGroup className="col-md-6 col-sm-12">
                    <Label for="exampleName" className="fs-0">
                      Mock Exam
                    </Label>
                    <Select
                      options={formOptions.semesterMockExamOptions}
                      onChange={values => {
                        values = values || [];
                        this.setState({ semester_mock_exam: values });
                        const newValue = values ? values.map(value => value.label).join() : '';
                        const oldValue = this.previousQuestionState['semester_mock_exam']
                          ? this.previousQuestionState['semester_mock_exam'].map(value => value.label).join()
                          : '';
                        this.updateQuestionModel['semester_mock_exam'] = {
                          fieldName: 'Mock Exam',
                          newValue,
                          oldValue
                        };
                        isBlocking();
                      }}
                      value={this.state.semester_mock_exam}
                      isDisabled={!isUserAdmin}
                      isMulti
                    />
                  </FormGroup>
                </Row>

                <Row>
                  <Col>
                    <FormGroup>
                      <Label for="exampleText" className="fs-1">
                        Question stem <strong className="text-danger">&#42;</strong>
                      </Label>
                      <Input
                        type="textarea"
                        rows="4"
                        name="text"
                        id="question-stem"
                        value={this.state.question_stem.text}
                        onChange={e => {
                          const question_stem = this.state.question_stem;
                          question_stem.text = e.target.value;
                          this.setState({ question_stem });
                          const oldValue = this.previousQuestionState['question_stem'].text;
                          this.updateQuestionModel['question_stem'] = {
                            fieldName: 'Question Stem',
                            newValue: e.target.value,
                            oldValue
                          };
                          isBlocking();
                        }}
                        disabled={!isQuestionEditbyUser}
                        className={!isQuestionEditbyUser ? 'bg-testlet-form-disabled' : ''}
                        required
                      />
                      <p className="mb-0">
                        <Button
                          className="pl-0 fs--1"
                          color="link"
                          onClick={this.toggleUploadAttachmentModal.bind(this, 'question_stem')}
                          disabled={!isQuestionEditbyUser}
                        >
                          Upload an image
                        </Button>
                      </p>
                      <div className="d-flex">
                        <div className="mr-auto align-self-center">
                          {this.state.question_stem.attachments.map((item, index) => {
                            return (
                              <div className="mb-1" key={index}>
                                <FontAwesomeIcon
                                  icon="check-circle"
                                  transform="grow-0"
                                  className="d-inline mr-2 text-primary"
                                />
                                <Button
                                  color="link primary"
                                  className="p-0 fs--1 font-weight-bold"
                                  onClick={this.togglePreviewModal.bind(this, item, 'question_stem')}
                                >
                                  {item.file_name}
                                </Button>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </FormGroup>
                  </Col>
                </Row>

                {this.state.answers.map(answer => {
                  return (
                    <AnswerChoice
                      key={answer.id}
                      questionNumber={id}
                      answerOptions={answer}
                      setAnswerInformation={this.getAnswerInformation}
                      isTriggerAjax={this.props.isTriggerAjax}
                      deleteAnswerChoice={this.deleteAnswerChoice}
                      setCorrectAnswer={this.setCorrectAnswerChoice}
                      isBlocking={isBlocking}
                      updateAnswerInfoForComments={this.updateAnswerInfoForComments}
                      isUserQuestionCreator={isQuestionEditbyUser}
                      toggleImagePreviewModal={this.togglePreviewModal}
                      addComment={this.addComment}
                    />
                  );
                })}

                <Row className="mt-4">
                  <Col>
                    <ButtonIcon
                      className="mr-2"
                      color="falcon-primary"
                      icon="plus"
                      transform="shrink-3"
                      onClick={this.addAnswerChoice}
                      disabled={!isQuestionEditbyUser}
                    >
                      Add answer choice
                    </ButtonIcon>
                  </Col>
                </Row>
              </CardBody>
              {this.documentPreviewModal()}

              <CardFooter className={'bg-100 mt-2 ' + (this.state.collapseSection ? 'd-none' : 'd-block')}>
                <div className="mb-2">
                  <h5 className="fs-0">Review Material for {questionTitle}</h5>
                  <small>
                    Upload review materials for students. Allowed file formats image only (e.g PNG, JPG, JPEG, PDF etc).
                    No DOC or DOCX formats.
                  </small>
                </div>
                <div className="d-flex">
                  <div className="mr-auto align-self-center">
                    {this.state.review_material.map((item, index) => {
                      return (
                        <div className="mb-1" key={index}>
                          <FontAwesomeIcon
                            icon="check-circle"
                            transform="grow-0"
                            className="d-inline mr-2 text-primary"
                          />
                          <Button
                            color="link primary"
                            className="p-0 fs--1 font-weight-bold"
                            onClick={this.togglePreviewModal.bind(this, item, 'review')}
                          >
                            {item.file_name}
                          </Button>
                        </div>
                      );
                    })}
                  </div>
                </div>
                <div className="p-2 row justify-content-between">
                  <ButtonIcon
                    className="ml-2"
                    color="primary"
                    icon="link"
                    transform="shrink-2"
                    onClick={this.toggleUploadAttachmentModal.bind(this, 'review')}
                    disabled={!isQuestionEditbyUser}
                  >
                    Upload
                  </ButtonIcon>
                  <ButtonIcon
                    className={classNames('ml-2 d-none', {
                      'd-inline-block': isShowCommentsAndChangesButton,
                      'notification-indicator notification-indicator-danger': isQuestionCommentsRead
                    })}
                    color="secondary"
                    icon="comments"
                    transform="shrink-2"
                    onClick={this.props.toggleCommentsModal.bind(this, questionCommentsKey)}
                  >
                    Changes
                  </ButtonIcon>
                </div>
                {isDeleteQuestion && (
                  <div className="p-2 row">
                    <Button
                      className="ml-auto mr-auto text-danger"
                      color="link"
                      icon="trash"
                      transform="shrink-2"
                      onClick={this.deleteQuestionMethod}
                      disabled={!isQuestionDeleteByUser}
                    >
                      Delete Question
                    </Button>
                  </div>
                )}
              </CardFooter>
            </Card>
          )}
        </Draggable>
        <DifficultyLevelToolTip id={questionID + 'difficulty-level'} difficultyLevel={difficultyLevel} />
      </Fragment>
    );
  }
}

TestletQuestion.contextType = TestletContext;

TestletQuestion.propTypes = {
  question: PropTypes.object,
  formProperties: PropTypes.object,
  deleteQuestion: PropTypes.func,
  setQuestionInformation: PropTypes.func,
  isTriggerAjax: PropTypes.bool,
  isBlocking: PropTypes.func,
  isDeleteQuestion: PropTypes.bool,
  toggleCommentsModal: PropTypes.func,
  isQuestionCommentsRead: PropTypes.bool,
  testletCreatorModel: PropTypes.object,
  isShowCommentsAndChangesButton: PropTypes.bool,
  isCollaboratorInMultipleSections: PropTypes.func,
  isCollaboratorAtTestletLevel: PropTypes.func,
  fetchUsersFromDB: PropTypes.func,
  idx: PropTypes.number
};

export default TestletQuestion;
