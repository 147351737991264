import { contentTypes } from '../content/contentTypes';

export const getContentTypeOptions = isSearch => {
  const contentTypeSearchOptions = [];
  let id = 1;
  for (let contentType in contentTypes) {
    const type = contentTypes[contentType];
    const searchObjectModel = {
      id,
      name: type.name,
      value: type.type,
      filterValue: type.type,
      selectedName: type.name,
      selected: Boolean(isSearch)
    };
    contentTypeSearchOptions.push(searchObjectModel);
    id += 1;
  }

  return {
    key: 'content_type',
    filterValue: 'content_type',
    label: Boolean(isSearch) ? 'Content Type' : 'Question Type',
    selectedFilterLabel: 'Type',
    options: contentTypeSearchOptions,
    theme: 'info'
  };
};
