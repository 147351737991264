import { isEmpty } from 'lodash';
import { isIterableArray } from '../../../../helpers/utils';

export const convertUsers = (userDocs, isProductionEnvironment) => {
  const users = [];

  userDocs.forEach(doc => {
    if (doc && doc.exists) {
      const data = doc.data();
      const label = isProductionEnvironment ? data.full_name : data.full_name + ' (' + data.email + ')';
      const userModel = {
        value: doc.id,
        label,
        id: doc.id,
        email: data.email,
        name: data.full_name
      };

      users.push(userModel);
    }
  });

  return users;
};

export function getCollaboratorName(collaboratorModel) {
  if (collaboratorModel) {
    let collaboratorName = collaboratorModel.label;
    const collaboratorEmail = collaboratorModel.email;

    if (collaboratorEmail) {
      const collaboratorNameArray = collaboratorName.split(' ');
      const lastIndex = collaboratorNameArray.length - 1;

      const isHaveEmailInName = collaboratorNameArray[lastIndex].indexOf(collaboratorEmail) !== -1;
      return isHaveEmailInName ? collaboratorNameArray.slice(0, lastIndex).join(' ') : collaboratorName;
    }

    return collaboratorName;
  }

  return '';
}

export const getCollaboratorNameArray = collaboratorArray => {
  if (isIterableArray(collaboratorArray)) {
    return collaboratorArray
      .map(collab => {
        return getCollaboratorName(collab);
      })
      .join(', ');
  }

  return '';
};

export const getCourseSemesterText = question => {
  const { course, course_year: courseYear, course_semester: courseSemester } = question;
  const courses = course && isEmpty(course) ? '' : course['label'];
  const course_year = isEmpty(courseYear) ? '' : courseYear['label'];
  const course_semester = isEmpty(courseSemester) ? '' : courseSemester['label'];
  return [courses, course_year, course_semester].filter(option => option !== '').join(', ');
};

export const getFormattedCourseList = courseList => {
  const formattedCourses = [];
  let counter = 0;

  const curriculumSorted = {};

  for (let curriculumKey in courseList) {
    const { curriculumOrder } = courseList[curriculumKey];
    if (!curriculumSorted[curriculumOrder]) {
      curriculumSorted[curriculumOrder] = [];
    }
    curriculumSorted[curriculumOrder].push(curriculumKey);
  }

  for (let curriculumOrder in curriculumSorted) {
    const curriculums = curriculumSorted[curriculumOrder];
    // eslint-disable-next-line no-loop-func
    curriculums.forEach(curriculumKey => {
      const curriculum = courseList[curriculumKey]['courses'] || courseList[curriculumKey];
      if (Object.keys(curriculum).length > 0) {
        formattedCourses.push({
          value: counter,
          label: curriculumKey,
          isDisabled: true,
          className: 'text-black'
        });
        counter += 1;
        for (let course in curriculum) {
          const courseId = curriculum[course]['course_id'] || course;
          formattedCourses.push({
            courseId: course,
            title: curriculum[course]['title'],
            value: counter,
            label: courseId + ': ' + curriculum[course]['title'],
            isDisabled: false,
            curriculum: curriculumKey
          });
          counter += 1;
        }
      }
    });
  }

  // Add mock course option at the end
  const mockCourse = {
    courseId: 'mock',
    title: 'Mock',
    value: counter,
    label: 'Mock',
    isDisabled: false,
    curriculum: '',
  };
  formattedCourses.push(mockCourse);

  return formattedCourses;
};

export const getInbdePrepCourseText = (inbdeCourse, publishedInbdePrepCourse) => {
  let inbdePrepCourseText = '';
  let inbdeCourseText = inbdeCourse && inbdeCourse['label'] ? inbdeCourse['label'] : '';
  if (publishedInbdePrepCourse) {
    if (isIterableArray(publishedInbdePrepCourse)) {
      inbdePrepCourseText = publishedInbdePrepCourse
        .map(course => (course && course['label'] ? course['label'] : ''))
        .filter(courseName => courseName !== '')
        .join('; ');
    } else {
      inbdePrepCourseText = publishedInbdePrepCourse['label'] ? publishedInbdePrepCourse['label'] : '';
    }
  }

  // check if INBDE Course already included in published course(s)
  if (inbdePrepCourseText) {
    if (inbdePrepCourseText.indexOf(inbdeCourseText) === -1) {
      // INBDE Course not in published course(s)
      inbdePrepCourseText += '; ' + inbdeCourseText;
    }
  } else {
    inbdePrepCourseText = inbdeCourseText;
  }

  return inbdePrepCourseText;
};

export const getMockExamText = mockExamArray => {
  const mockExams = [];

  if (mockExamArray) {
    let mockExamData;
    if (isIterableArray(mockExamArray)) {
      mockExamArray.forEach(exam => {
        mockExamData = !isEmpty(exam) && exam['label'] ? exam['label'] : '';
        mockExams.push(mockExamData);
      });
    } else {
      mockExamData = !isEmpty(mockExamArray) && mockExamArray['label'] ? mockExamArray['label'] : '';
      mockExams.push(mockExamData);
    }
  }

  return mockExams.filter(option => option !== '').join('; ');
};

export const updateUsers = (existingUsers, newUsers) => {
  const updatedUsers = [...existingUsers, ...newUsers];
  const userHashMap = {};
  const finalUpdatedUsers = [];

  for (let i = 0; i < updatedUsers.length; i += 1) {
    const currentUser = updatedUsers[i];
    if (userHashMap[currentUser.value]) {
      continue;
    } else {
      finalUpdatedUsers.push(currentUser);
      userHashMap[currentUser.value] = true;
    }
  }

  return finalUpdatedUsers;
};
