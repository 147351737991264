import React from 'react';
import PropTypes from 'prop-types';
import { Nav, NavItem } from 'reactstrap';
import { NavLink, withRouter } from 'react-router-dom';
import NavbarVerticalMenuItem from './NavbarVerticalMenuItem';
import CreateContentButton from './createContent/CreateContentButton';

const NavbarVerticalMenu = ({ routes, location, handleNavbarVerticalCollapse }) => {
  return routes.map((route, index) => {
    if (!route.children) {
      if (route.key === 'create') {
        return <CreateContentButton route={route} key={index} />;
      }

      return (
        <NavItem key={index}>
          {route.newTab ? (
            <a href={route.to} target="_blank" rel="noopener noreferrer" className="nav-link pl-0 pr-0">
              <NavbarVerticalMenuItem route={route} customClass="" />
            </a>
          ) : (
            <NavLink
              className="nav-link pl-0"
              {...route}
              onClick={() => {
                handleNavbarVerticalCollapse();
              }}
            >
              <NavbarVerticalMenuItem route={route} customClass="" />
            </NavLink>
          )}
        </NavItem>
      );
    }

    return (
      <NavItem key={index} className="pb-2">
        <NavbarVerticalMenuItem route={route} customClass="text-black pb-2" />
        <Nav>
          <NavbarVerticalMenu
            routes={route.children}
            location={location}
            handleNavbarVerticalCollapse={handleNavbarVerticalCollapse}
          />
        </Nav>
      </NavItem>
    );
  });
};

NavbarVerticalMenu.propTypes = {
  routes: PropTypes.array.isRequired,
  location: PropTypes.object.isRequired
};

export default withRouter(NavbarVerticalMenu);
