import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Button, Row } from 'reactstrap';
import TypeBadge from '../../../widgets/TypeBadge';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { customTestletEditRoute } from '../../../routes';
import { testletTypes, testletStatusTypes } from '../../../helpers/testletTypes';

const draftUrl = customTestletEditRoute.to;

const TestletActions = ({
  flag,
  isAdmin,
  isCollab,
  review,
  statusDetails: details,
  testlet,
  toggleAccept,
  toggleClone,
  toggleCloseCollaboration,
  toggleCollab,
  toggleDelete,
  toggleComment,
  toggleEndTestletCollaboration,
  toggleExportTestlet,
  toggleFlag,
  toggleModal,
  togglePublish,
  toggleReject,
  toggleRepublish,
  toggleRestore,
  toggleSubmit,
  toggleUnflag,
  toggleUnpublish,
  userId
}) => {
  const { created_by, id, is_published, testlet_information, is_latest_version, is_deleted, testlet_type } = testlet;
  const { testlet_title } = testlet_information;

  const isUserTestletCreator = userId === created_by;
  const testletTitle = testlet_title !== '' ? testlet_title : '<No-Title>';
  const isTestletInProgress = testletStatusTypes.IN_PROGRESS.includes(testlet_type);
  const isTestletInReview = testletStatusTypes.UNDER_REVIEW.includes(testlet_type);
  const isTestletApproved = testletStatusTypes.APPROVED.includes(testlet_type);

  const showEditButton = isTestletInProgress || review;
  const showSubmitButton = isTestletInProgress && !review;
  const showDeleteButton = isTestletInProgress && (isAdmin || isUserTestletCreator) && !review;
  const showApproveButton = review && isTestletInReview && isAdmin;
  const showReviseButton = review && isAdmin && isTestletInReview;
  // Button to add self to testlet as collaborator
  const showJoinCollaborationButton =
    !review && testlet_type === testletTypes.OPEN_FOR_COLLABORATION && !isCollab && !isUserTestletCreator;
  // Button to change testlet type to that of 'Opened for collaboration'
  const showOpenTestletForCollaboration = review && testlet_type === testletTypes.UNPUBLISHED && isAdmin;
  // Button to change testlet type to that of 'Unpublished'
  const showCloseCollaborationButton = testlet_type === testletTypes.OPEN_FOR_COLLABORATION && isAdmin && review;
  const showPublishButton = testlet_type === testletTypes.UNPUBLISHED && isAdmin && !is_published && review;
  const showUnpublishButton = testlet_type === testletTypes.PUBLISHED && is_published && review;
  const showRepublishButton = showUnpublishButton;
  const showFlagButton = !isAdmin && isTestletApproved && !isCollab && !isUserTestletCreator;
  const showEndCollaborationButton = review && isTestletInReview && !is_latest_version && isAdmin;
  const showMakeCopyButton =
    testlet_type === testletTypes.UNPUBLISHED || testlet_type === testletTypes.PUBLISHED || isAdmin;
  const showRestoreButton = is_deleted && isAdmin;
  const showExportButton = Boolean(!is_deleted && !flag && isAdmin) && isTestletApproved;

  return (
    <Row className="testlet-action-buttons-content w-100 pb-2 pt-2 pr-2 pl-2 pr-lg-5 pl-lg-5">
      <div className="testlet-action-button-container-info d-none d-lg-flex col-12 col-md-5 mb-1 mb-md-0">
        <div className="d-flex mt-auto mb-auto w-100">
          <h5 className="font-weight-bold text-white text-truncate max-w-75 mb-auto mt-auto">{testletTitle} &emsp;</h5>
          <h5 className="mb-0 ml-2">
            <TypeBadge details={details} testletId={id} />
          </h5>
        </div>
      </div>
      <div className="testlet-action-buttons d-flex ml-auto mr-auto mr-md-0 flex-wrap pl-2 pl-lg-0">
        {is_deleted ? (
          <Fragment>
            {showRestoreButton && (
              <Button color="success" onClick={toggleRestore} className="m-1">
                Restore
              </Button>
            )}
          </Fragment>
        ) : (
          <Fragment>
            {showEditButton && (
              <Link to={draftUrl.replace('{testletId}', id)} className="text-white">
                <Button color="success" className="m-1">
                  <FontAwesomeIcon icon="pencil-alt" className="d-inline mr-2 text-white" />
                  Edit
                </Button>
              </Link>
            )}
            {showSubmitButton && (
              <Button color="primary" className="m-1" onClick={toggleSubmit}>
                Submit for Review
              </Button>
            )}
            {showDeleteButton && (
              <Button color="danger" className="m-1" onClick={toggleDelete}>
                Delete
              </Button>
            )}
            {showApproveButton && (
              <Button color="primary" onClick={toggleAccept} className="m-1">
                Approve
              </Button>
            )}
            {showReviseButton && (
              <Button color="danger" onClick={toggleReject} className="m-1">
                Revise
              </Button>
            )}
            {showJoinCollaborationButton && (
              <Button color="success" className="m-1" onClick={toggleCollab}>
                Collaborate
              </Button>
            )}
            {showOpenTestletForCollaboration && (
              <Button color="success" className="m-1" onClick={toggleCloseCollaboration}>
                Open For Collaboration
              </Button>
            )}
            {showCloseCollaborationButton && (
              <Button color="warning" className="m-1" onClick={toggleCloseCollaboration}>
                Close For Collaboration
              </Button>
            )}
            {showPublishButton && (
              <Button color="primary" onClick={togglePublish} className="m-1">
                Publish
              </Button>
            )}
            {showUnpublishButton && (
              <Button color="warning" onClick={toggleUnpublish} className="m-1">
                Unpublish
              </Button>
            )}
            {showRepublishButton && (
              <Button color="primary" onClick={toggleRepublish} className="m-1">
                Republish
              </Button>
            )}
            {showFlagButton && (
              <Button color="danger" onClick={toggleFlag} className="m-1">
                Flag
              </Button>
            )}
            {flag && (
              <>
                <Button color="success" onClick={toggleUnflag} className="m-1">
                  <FontAwesomeIcon icon="flag" className="d-inline mr-2 text-white" />
                  Unflag
                </Button>
                <Button color="info" onClick={toggleComment} className="m-1" disabled>
                  <FontAwesomeIcon icon="inbox" className="d-inline mr-2 text-white" />
                  Comment
                </Button>
              </>
            )}
            {showEndCollaborationButton && (
              <Button color="warning" className="m-1" onClick={toggleEndTestletCollaboration}>
                End Collaboration
              </Button>
            )}
            {showMakeCopyButton && (
              <Button color="info" onClick={toggleClone} className="m-1">
                Make a Copy
              </Button>
            )}
          </Fragment>
        )}
        {showExportButton && (
          <Button color="success" className="m-1" onClick={toggleExportTestlet}>
            <FontAwesomeIcon icon="file-pdf" className="d-inline mr-2 text-white" />
            Export
          </Button>
        )}
        <Button color="secondary" onClick={toggleModal} className="m-1">
          Close
        </Button>
      </div>
    </Row>
  );
};

TestletActions.propTypes = {
  flag: PropTypes.bool,
  isAdmin: PropTypes.bool,
  isCollab: PropTypes.bool,
  review: PropTypes.bool,
  statusDetails: PropTypes.object,
  testlet: PropTypes.object,
  toggleAccept: PropTypes.func,
  toggleClone: PropTypes.func,
  toggleCloseCollaboration: PropTypes.func,
  toggleCollab: PropTypes.func,
  toggleComment: PropTypes.func,
  toggleDelete: PropTypes.func,
  toggleEndTestletCollaboration: PropTypes.func,
  toggleExportTestlet: PropTypes.func,
  toggleFlag: PropTypes.func,
  toggleModal: PropTypes.func,
  togglePublish: PropTypes.func,
  toggleReject: PropTypes.func,
  toggleRepublish: PropTypes.func,
  toggleRestore: PropTypes.func,
  toggleSubmit: PropTypes.func,
  toggleUnflag: PropTypes.func,
  toggleUnpublish: PropTypes.func,
  userId: PropTypes.string
};

export default TestletActions;
