import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button, Row } from 'reactstrap';

class InlineFilterSelected extends Component {
  constructor(props) {
    super(props);

    this.clearFilter = this.clearFilter.bind(this);
  }

  clearFilter() {
    const { clearFilters, selectedFilter } = this.props;
    clearFilters(selectedFilter.label);
  }

  getFilterOptions(filter) {
    const filterOptions = filter.selectedFilters.join('; ');
    return filterOptions;
  }

  render() {
    const { selectedFilter: filter } = this.props;
    const filterOptions = this.getFilterOptions(filter);

    return (
      <div className="col-12 col-md-4 col-lg-3 p-0 p-1">
        <Row className="bg-400 p-0 m-0">
          <div className="col-11 p-0">
            <div className="text-black m-1 pt-2 pb-2 pl-2 pr-2 text-center">
              <div className="fs--1 text-truncate">
                <strong>{filter.selectedFilterLabel}: </strong>
                {filterOptions}
              </div>
            </div>
          </div>
          <div className="col-1 p-0 d-flex">
            <Button close className="m-auto fs-0 float-none" onClick={this.clearFilter} />
          </div>
        </Row>
      </div>
    );
  }
}

InlineFilterSelected.propTypes = {
  selectedFilter: PropTypes.object,
  clearFilters: PropTypes.func
};

export default InlineFilterSelected;
