import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, CustomInput, FormGroup, Input, Label, Row } from 'reactstrap';
import { isEqual } from 'lodash';

export const StandAloneQuestionFormAnswerChoice = ({
  answerChoice,
  attachmentModel,
  deleteAnswer,
  isSubmitAnswerChoice,
  markAnswerAsUpdated,
  persistAnswer,
  previewAttachment,
  questionId,
  uploadAttachment
}) => {
  const [answerState, setAnswerState] = useState(answerChoice);
  const [attachmentToAddIn, setAttachmentToAddIn] = useState(null);
  const [previousIsSubmitAnswerChoice, setPreviousIsSubmitAnswerChoice] = useState(isSubmitAnswerChoice);
  const [previousAnswerChoice, setPreviousAnswerChoice] = useState(answerChoice);
  const mountedRef = useRef(null);

  // method definitions
  const handlePreviewAttachment = (file, key) => {
    previewAttachment(file, key, answerState['id']);
  };

  const handleUploadAttachment = key => {
    setAttachmentToAddIn(key);
    const uploadKey = 'answers/' + key + '_' + (answerState['id'] + 1);
    uploadAttachment(uploadKey);
  };

  const updateAnswer = (fieldName, newValue) => {
    answerState[fieldName] = newValue;
    setAnswerState(answerState);
    markAnswerAsUpdated();
  };

  // use effect hook for real-time change and collaboration
  useEffect(() => {
    mountedRef.current = true;

    function updateAnswerFromProps() {
      if (mountedRef.current) {
        setPreviousAnswerChoice(answerChoice);
        setAnswerState(answerChoice);
      }
    }

    if (!isEqual(answerChoice, answerState) && !isEqual(previousAnswerChoice, answerChoice)) {
      updateAnswerFromProps();
    }
    return () => {
      mountedRef.current = false;
    };
  }, [answerChoice, answerState, previousAnswerChoice]);

  // use effect hook for persisting question
  useEffect(() => {
    mountedRef.current = true;

    function addAttachments(answer) {
      if (attachmentToAddIn === 'answer_choice') {
        answer['answer_choice']['attachments'].push(attachmentModel);
      } else if (attachmentToAddIn === 'answer_explanation') {
        answer['explanation']['attachments'].push(attachmentModel);
      }
    }

    function handleAnswerSubmit() {
      const updatedAnswer = JSON.parse(JSON.stringify(answerState));
      updatedAnswer['answer_choice'].text = updatedAnswer['answer_choice'].text.trim();
      updatedAnswer['explanation'].text = updatedAnswer['explanation'].text.trim();

      if (attachmentModel && attachmentToAddIn) {
        addAttachments(updatedAnswer);
        setAttachmentToAddIn(null);
      }

      persistAnswer(updatedAnswer);
    }

    if (isSubmitAnswerChoice && previousIsSubmitAnswerChoice !== isSubmitAnswerChoice) {
      handleAnswerSubmit();
    }

    mountedRef.current && setPreviousIsSubmitAnswerChoice(isSubmitAnswerChoice);
    return () => {
      mountedRef.current = false;
    };
  }, [
    answerState,
    attachmentModel,
    attachmentToAddIn,
    isSubmitAnswerChoice,
    persistAnswer,
    previousIsSubmitAnswerChoice
  ]);

  return (
    <div className="border-bottom">
      <Row className="mt-2">
        <div className="col-lg-5 col-12">
          <FormGroup>
            <Label for="exampleName" className="fs-0">
              Answer choice {answerState['id'] + 1} <strong className="text-danger">&#42;</strong>
            </Label>
            <Input
              type="textarea"
              name="answer-choice"
              id={'answer-choice-' + answerState['id']}
              onChange={e => {
                const newAnswerChoice = answerState['answer_choice'];
                newAnswerChoice.text = e.target.value;
                updateAnswer('answer_choice', newAnswerChoice);
              }}
              value={answerState['answer_choice']['text']}
              required
            />
            <p className="mt-1 mb-0">
              <Button className="pl-0 fs--1" color="link" onClick={() => handleUploadAttachment('answer_choice')}>
                Upload an image
              </Button>
            </p>
            <div className="d-flex">
              <div className="mr-auto align-self-center">
                {answerState['answer_choice']['attachments'].map((item, index) => {
                  return (
                    <div className="mb-1" key={index}>
                      <FontAwesomeIcon icon="check-circle" transform="grow-0" className="d-inline mr-2 text-primary" />
                      <Button
                        color="link primary"
                        className="p-0 fs--1 font-weight-bold"
                        onClick={() => handlePreviewAttachment(item, 'answer_choice')}
                      >
                        {item.file_name}
                      </Button>
                    </div>
                  );
                })}
              </div>
            </div>
          </FormGroup>
        </div>
        <div className="col-lg-5 col-8">
          <FormGroup>
            <Label for="exampleName" className="fs-0">
              Explanation
            </Label>
            <Input
              type="textarea"
              name="name"
              onChange={e => {
                const newExplanation = answerState['explanation'];
                newExplanation.text = e.target.value;
                updateAnswer('explanation', newExplanation);
              }}
              value={answerState['explanation']['text']}
            />
            <p className="mb-0">
              <Button className="pl-0 fs--1" color="link" onClick={() => handleUploadAttachment('answer_explanation')}>
                Upload an image
              </Button>
            </p>
            <div className="d-flex">
              <div className="mr-auto align-self-center">
                {answerState['explanation']['attachments'].map((item, index) => {
                  return (
                    <div className="mb-1" key={index}>
                      <FontAwesomeIcon icon="check-circle" transform="grow-0" className="d-inline mr-2 text-primary" />
                      <Button
                        color="link primary"
                        className="p-0 fs--1 font-weight-bold"
                        onClick={() => handlePreviewAttachment(item, 'answer_explanation')}
                      >
                        {item.file_name}
                      </Button>
                    </div>
                  );
                })}
              </div>
            </div>
          </FormGroup>
        </div>
        <div className="col-lg-1 col-2">
          <FormGroup className="form-check pl-1">
            <Row className="mb-2">
              <Label className="mr-auto ml-auto" for="exampleName">
                Correct
              </Label>
            </Row>
            <Row>
              <CustomInput
                type="switch"
                id={'isCorrectSwitch' + answerState['id'] + questionId}
                name="customSwitch"
                className="mr-auto ml-auto"
                onChange={() => {
                  updateAnswer('isCorrect', !answerState['isCorrect']);
                }}
                checked={answerState['isCorrect']}
                value={answerState['isCorrect']}
              />
            </Row>
          </FormGroup>
        </div>
        {answerState['id'] > 1 && (
          <div className="col-lg-1 col-2">
            <Row className="mb-2">
              <Label className="invisible mr-auto ml-auto" for="exampleName">
                Remove
              </Label>
            </Row>
            <Row>
              <Button
                color="link"
                className="btn-reveal py-0 px-2 mr-auto ml-auto"
                onClick={() => {
                  deleteAnswer(answerState['id']);
                }}
              >
                <FontAwesomeIcon color="red" icon="times" />
              </Button>
            </Row>
          </div>
        )}
      </Row>
    </div>
  );
};

StandAloneQuestionFormAnswerChoice.propTypes = {
  answerChoice: PropTypes.object.isRequired,
  attachmentModel: PropTypes.object,
  deleteAnswer: PropTypes.func,
  isSubmitAnswerChoice: PropTypes.bool,
  markAnswerAsUpdated: PropTypes.func,
  persistAnswer: PropTypes.func,
  previewAttachment: PropTypes.func,
  questionId: PropTypes.string.isRequired,
  uploadAttachment: PropTypes.func
};

StandAloneQuestionFormAnswerChoice.defaultProps = {
  attachmentModel: null,
  deleteAnswer: () => {},
  isSubmitAnswerChoice: false,
  markAnswerAsUpdated: () => {},
  persistAnswer: () => {},
  previewAttachment: () => {},
  uploadAttachment: () => {}
};
