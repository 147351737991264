import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button, Card, CardBody, Row } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FalconCardHeader from '../../common/FalconCardHeader';
import AnswerContent from './AnswerContent';
import { isIterableArray } from '../../../helpers/utils';
import DifficultyLevelToolTip from '../common/DifficultyLevelToolTip';
import { get, isEmpty } from 'lodash';
import { getMockExamText } from '../../content/view/view';

class QuestionContent extends Component {
  constructor(props) {
    super(props);

    this.togglePreviewModal = this.togglePreviewModal.bind(this);
  }

  togglePreviewModal(file) {
    this.props.togglePreviewModal(file);
  }

  render() {
    const { question, number } = this.props;

    const {
      answers,
      clinical_content,
      foundational_knowledge,
      key_concepts,
      question_creator,
      question_stem,
      systems_areas,
      review_material,
      mockExam: mockExams,
      semester_mock_exam
    } = question;

    const course = get(question, 'course.label', '');
    const course_semester = get(question, 'course_semester.label', '');
    const course_year = get(question, 'course_year.label', '');
    const difficulty_level = get(question, 'difficulty_level.label', '');
    const questionStemText = typeof question_stem.text === 'string' ? question_stem.text : question_stem;
    const questionStemAttachments = question_stem.attachments ? question_stem.attachments : [];
    const clinicalContent = clinical_content || [];
    const foundationalKnowledge = foundational_knowledge || [];
    const systemsAndAreas = systems_areas || [];
    const reviewMaterial = review_material || [];
    const answerList = answers || [];

    let exams = [];
    if (mockExams) {
      let mockExam;
      if (isIterableArray(mockExams)) {
        question.mockExam.forEach(exam => {
          mockExam = !isEmpty(exam) && exam.label ? exam.label : '';
          exams.push(mockExam);
        });
      } else {
        mockExam = !isEmpty(mockExams) && mockExams.label ? mockExams.label : '';
        exams.push(mockExam);
      }
    }
    exams = exams.filter(option => option !== '').join('; ');
    const mockExamsText = getMockExamText(semester_mock_exam);

    return (
      <Card id="question1" className="mb-2 shadow-none border">
        <FalconCardHeader title={'Question ' + number} titleTag="h6" />
        <CardBody>
          <Row className="ml-0 p-2">
            <div className="col-sm-12 col-lg-3 font-weight-semi-bold">Course:</div>
            <div className="col-sm-12 col-lg-9">{course}</div>
          </Row>
          <Row className="ml-0 p-2">
            <div className="col-sm-12 col-lg-3 font-weight-semi-bold">Semester of course:</div>
            <div className="col-sm-12 col-lg-9">{course_semester}</div>
          </Row>
          <Row className="ml-0 p-2">
            <div className="col-sm-12 col-lg-3 font-weight-semi-bold">DDS Year:</div>
            <div className="col-sm-12 col-lg-9">{course_year}</div>
          </Row>
          <Row className="ml-0 p-2">
            <div className="col-sm-12 col-lg-3 font-weight-semi-bold">Created By:</div>
            <div className="col-sm-12 col-lg-9">{question_creator}</div>
          </Row>
          <Row className="ml-0 p-2">
            <div className="col-sm-12 col-lg-3 font-weight-semi-bold">Foundational Knowledge:</div>
            <div className="col-sm-12 col-lg-9">
              {foundationalKnowledge.map((option, index) => {
                return (
                  <div key={option.value || index}>
                    {option.label || ''}
                    <br />
                  </div>
                );
              })}
            </div>
          </Row>
          <Row className="ml-0 p-2">
            <div className="col-sm-12 col-lg-3 font-weight-semi-bold">Clinical Content:</div>
            <div className="col-sm-12 col-lg-9">
              {clinicalContent.map((option, index) => {
                return (
                  <div key={option.value || index}>
                    {option.label || ''}
                    <br />
                  </div>
                );
              })}
            </div>
          </Row>
          <Row className="ml-0 p-2">
            <div className="col-sm-12 col-lg-3 font-weight-semi-bold">Exam(s):</div>
            <div className="col-sm-12 col-lg-9">{exams}</div>
          </Row>
          <Row className="ml-0 p-2">
            <div className="col-sm-12 col-lg-3 font-weight-semi-bold">Mock Exam(s):</div>
            <div className="col-sm-12 col-lg-9">{mockExamsText}</div>
          </Row>
          <Row className="ml-0 p-2">
            <div className="col-sm-12 col-lg-3 font-weight-semi-bold">System / Area(s):</div>
            <div className="col-sm-12 col-lg-9">
              {systemsAndAreas.map((option, index) => {
                return (
                  <div key={option.value || index}>
                    {option.label || ''}
                    <br />
                  </div>
                );
              })}
            </div>
          </Row>
          <Row className="ml-0 p-2">
            <div className="col-sm-12 col-lg-3 font-weight-semi-bold">Key Concepts:</div>
            <div className="col-sm-12 col-lg-9">{key_concepts}</div>
          </Row>
          <Row className="ml-0 p-2">
            <div className="col-sm-12 col-lg-3 font-weight-semi-bold">Level of Difficulty:</div>
            <div id={'question-' + number + '-difficulty-level'} className="col-sm-12 col-lg-9 text-warning">
              {difficulty_level}
            </div>
            <DifficultyLevelToolTip
              id={'question-' + number + '-difficulty-level'}
              difficultyLevel={difficulty_level}
            />
          </Row>
          <Row className="ml-0 p-2">
            <div className="col-sm-12 col-lg-3 font-weight-semi-bold">Question stem:</div>
            {!!questionStemText && (
              <>
                <div className="col-sm-12 col-lg-9">{questionStemText}</div>
                <div className="col-sm-12 col-lg-3" />
              </>
            )}
            <div className="col-sm-12 col-lg-9">
              {questionStemAttachments.map((item, index) => {
                return (
                  <div className="mr-4" key={item.url || index}>
                    <FontAwesomeIcon icon="paperclip" transform="grow-2" className="d-inline mr-2 text-primary" />
                    <Button
                      color="link primary"
                      className="p-0 font-weight-bold"
                      onClick={this.togglePreviewModal.bind(this, item)}
                    >
                      {item.file_name || ''}
                    </Button>
                  </div>
                );
              })}
            </div>
          </Row>
          {answerList.map((answer, index) => (
            <AnswerContent key={answer.id || index} answer={answer} togglePreviewModal={this.togglePreviewModal} />
          ))}
          <Row className="ml-0 p-2">
            <div className="col-sm-12 col-lg-3 font-weight-semi-bold">Question Explanation:</div>
            <div className="col-sm-12 col-lg-9" />
          </Row>
          <Row className="ml-0 p-2">
            <div className="col-sm-12 col-lg-3 font-weight-semi-bold">Review Material(s):</div>
            <div className="col-sm-12 col-lg-9">
              {reviewMaterial.map((image, index) => {
                return (
                  <div className="mr-4" key={index}>
                    <FontAwesomeIcon icon="paperclip" transform="grow-2" className="d-inline mr-2 text-primary" />
                    <Button
                      color="link primary"
                      className="p-0 font-weight-bold"
                      onClick={this.togglePreviewModal.bind(this, image)}
                    >
                      {image.file_name || ''}
                    </Button>
                  </div>
                );
              })}
            </div>
          </Row>
          <hr />
        </CardBody>
      </Card>
    );
  }
}

QuestionContent.propTypes = {
  togglePreviewModal: PropTypes.func,
  number: PropTypes.number,
  question: PropTypes.object.isRequired
};

export default QuestionContent;
