import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router';
import { toast } from 'react-toastify';
import { ContentDetailCardBody, ContentDetailCardHeader } from '../components/content/content';
import { ContentService } from '../services/Content/content';
import SpinnerModal from '../components/common/SpinnerModal';
import { UserContext } from '../Contexts';
import { validateAccess } from '../components/content/view/stand-alone-question/stand-alone-question';
import { testletStatusTypes } from '../helpers/testletTypes';

const errorPageUrl = '/errors/404';

class ViewQuestionPage extends Component {
  _isMounted = false;
  #contentService = new ContentService();

  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      pageTitle: 'View Stand-Alone Question',
      questionData: null
    };

    this.getQuestion = this.getQuestion.bind(this);
    this.handleValidation = this.handleValidation.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;

    this.getQuestion();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  async getQuestion() {
    const { user } = this.context;
    const { questionId } = this.props;

    const data = await this.#contentService.getContentDocById(questionId);
    this.handleValidation(data, user);
  }

  handleValidation(data, user) {
    let { pageTitle } = this.state;

    try {
      validateAccess(data, user);

      const { question } = data;
      const { question_stem } = question;
      pageTitle = question_stem && question_stem['text'] ? question_stem['text'] : pageTitle;

      this._isMounted && this.setState({ isLoading: false, pageTitle, questionData: data });
    } catch (error) {
      const toastMessage = error.message || 'This question cannot be viewed';
      toast.error(toastMessage);

      this._isMounted && this.setState({ isLoading: false, questionData: null });
    }
  }

  render() {
    const { questionId } = this.props;
    const { isLoading, pageTitle, questionData } = this.state;

    let contentType, contentStatus, isFlagged;
    if (questionData) {
      const { content_type, is_flagged, testlet_type } = questionData;
      contentType = content_type;
      contentStatus = testlet_type;
      isFlagged = is_flagged;
    }

    const isApproved = testletStatusTypes.APPROVED.includes(contentStatus);

    return (
      <Fragment>
        {isLoading ? (
          <SpinnerModal background={'bg-black opacity-50'} />
        ) : (
          <div className="min-vh-75 min-vh-lg-100">
            {questionData ? (
              <>
                <div className="mb-lg-4 mb-2 border-bottom border-500">
                  <div className="pl-2">
                    <div className="pr-2 pt-2 pb-2">
                      <div className="title view-question-page-title pr-2 pt-2 pb-2">
                        <ContentDetailCardHeader
                          contentId={questionId}
                          contentStatus={contentStatus}
                          contentType={contentType}
                          isApproved={isApproved}
                          isFlagged={isFlagged}
                          title={pageTitle}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="ml-lg-2">
                  <ContentDetailCardBody contentData={questionData} />
                </div>
              </>
            ) : (
              <Redirect to={errorPageUrl} />
            )}
          </div>
        )}
      </Fragment>
    );
  }
}

ViewQuestionPage.propTypes = {
  questionId: PropTypes.string.isRequired
};

ViewQuestionPage.contextType = UserContext;

export default ViewQuestionPage;
