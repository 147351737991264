import React, { Component } from 'react';
import LoadingCard from './LoadingCard';

class LoadingCardList extends Component {
  constructor(props) {
    super(props);

    const cards = 5;
    const cardsArray = [];
    for (let i = 0; i < cards; i += 1) {
      cardsArray.push(<LoadingCard key={i} />);
    }

    this.state = { cardsArray };
  }

  render() {
    const { cardsArray } = this.state;
    return cardsArray;
  }
}

export default LoadingCardList;
