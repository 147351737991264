import { v4 as uuidv4 } from 'uuid';
import fileDownload from 'js-file-download';
import { validateSave, validateSubmission } from '../../components/content/content';
import { contentTypes, facultyContentStatus } from '../../data/content/content';
import { getBaseUrl, getContentName, getCurrentTimeStamp, isFeatureActive, isObjectNullOrEmpty, isUserAccessOfTypeAdmin } from '../../helpers/inbdeUtils';
import { testletStatusTypes, testletTypes } from '../../helpers/testletTypes';
import { ContentRepository } from '../../repository/content/ContentRepository';
import { standAloneQuestion, testlet } from '../../repository/content/models/models';
import CollaborationService from '../Collaboration/CollaborationService';
import { customContentRoutes } from '../../routes';
import { getCopyOfObjectContents, isIterableArray, openLinkInNewTab } from '../../helpers/utils';
import { copiedContentConverter, getInbdeCourseName } from './helpers';
import featureToggles from '../Feature/toggles';
import AuthenticationService from '../Authentication/AuthenticationService';
import { authorizationHeader, customAuthorizationHeader, getHttpResource } from '../HttpClient/httpClient';

const notifyUsers = 'notifyUsers';
const generateExportFile = 'generateLmsExportFile';

export class ContentService {
  #authenticationService;
  #collaborationService;
  #contentRepository;

  constructor() {
    this.#authenticationService = new AuthenticationService();
    this.#collaborationService = new CollaborationService();
    this.#contentRepository = new ContentRepository();
  }

  async approveContentByAdmin(contentId) {
    // TO-DO: assign content_status based on content_type
    const { UNPUBLISHED: approvedStatus } = testletTypes;

    const requestBody = {
      is_public: true,
      testlet_type: approvedStatus,
      version: 1
    };

    return await this.updateContentByAdmin(contentId, requestBody);
  }

  async checkIfFileExists(filePath) {
    const isExists = await this.#contentRepository.checkIfFileExists(filePath);
    return isExists;
  }

  async copyContent(contentData, user) {
    const copiedData = getCopyOfObjectContents(contentData);
    if (copiedData === null) {
      throw new Error('the content received was invalid or has missing data');
    }

    const currentTimestamp = getCurrentTimeStamp();
    const newContentData = copiedContentConverter(currentTimestamp, copiedData, user);

    const newContentId = await this.createNewContentOfType(null, newContentData);
    return newContentId;
  }

  async createNewContentOfType(contentId, contentDoc) {
    if (typeof contentId === 'undefined' || contentId === null) {
      const createdContentId = await this.#contentRepository.createDoc(contentDoc);
      return createdContentId;
    } else {
      return await this.#contentRepository.createDocWithId(contentId, contentDoc);
    }
  }

  async createNewStandAloneQuestion(userId, userDisplayName) {
    try {
      const currentTimeStamp = getCurrentTimeStamp();

      const newQuestionTemplate = JSON.parse(JSON.stringify(standAloneQuestion));
      newQuestionTemplate.created_by = userId;
      newQuestionTemplate.created_on = currentTimeStamp;
      newQuestionTemplate.question.created_at = currentTimeStamp;
      newQuestionTemplate.question.created_by = userId;
      newQuestionTemplate.question.question_creator = userDisplayName;
      newQuestionTemplate.question.uuid = uuidv4();
      newQuestionTemplate.testlet_uid = newQuestionTemplate.question.uuid;

      const createdQuestionId = await this.createNewContentOfType(null, newQuestionTemplate);
      return createdQuestionId;
    } catch (_error) {
      return null;
    }
  }

  async createNewTestlet(userId, userDisplayName) {
    try {
      const currentTimeStamp = getCurrentTimeStamp();

      const newTestletTemplate = JSON.parse(JSON.stringify(testlet));
      newTestletTemplate.created_by = userId;
      newTestletTemplate.created_on = currentTimeStamp;
      newTestletTemplate.questions[0].created_at = currentTimeStamp;
      newTestletTemplate.questions[0].created_by = userId;
      newTestletTemplate.questions[0].question_creator = userDisplayName;
      newTestletTemplate.questions[0].uuid = uuidv4();
      newTestletTemplate.testlet_uid = uuidv4();
      newTestletTemplate.testlet_information.testlet_creator = userDisplayName;

      const createdTestletId = await this.createNewContentOfType(null, newTestletTemplate);
      return createdTestletId;
    } catch (_error) {
      return null;
    }
  }

  async deleteAttachmentFromStorage(url) {
    let isSuccess = false;
    try {
      await this.#contentRepository.deleteFileFromStorageWithUrl(url);
      isSuccess = true;
    } catch {}
    return isSuccess;
  }

  async deleteContent(docId) {
    const currentTimestamp = getCurrentTimeStamp();

    const deleteRequest = {
      deleted_on: currentTimestamp,
      is_deleted: true,
      updated_on: currentTimestamp
    };

    return await this.#contentRepository.updateDoc(docId, deleteRequest);
  }

  async exportContent(contentId, contentData, user, toggles) {
    const { access_type, google_id_token } = user;
    if (!isUserAccessOfTypeAdmin(access_type)) {
      throw new Error('You do not have access to export this content');
    }

    const { export_data, testlet_type, updated_on } = contentData;
    if (!testletStatusTypes.APPROVED.includes(testlet_type)) {
      throw new Error('This content cannot be exported');
    }

    if (isFeatureActive(featureToggles.isSaveLmsExportFileEnabled, toggles, null, null)) {
      return this.exportContentWithoutImages(contentId, export_data, updated_on);
    } else {
      return this.exportContentWithImages(contentId, contentData, google_id_token);
    }
  }

  async exportContentWithImages(contentId, contentData, googleIdToken) {
    const firebaseFunctionUrl = '/' + generateExportFile;
    const params = {
      contentId
    };

    const accessToken = await this.#authenticationService.getUserAuthenticationToken();
    const headers = {
      [authorizationHeader]: "Bearer " + googleIdToken,
      [customAuthorizationHeader]: accessToken
    };
    const configs = {
      headers,
      params,
      responseType: 'blob'
    };

    const res = await getHttpResource(firebaseFunctionUrl, configs);
    if (res) {
      const { data } = res;
      if (!data) {
        return false;
      }

      const name = getContentName(contentData, true) || 'export_' + contentId;
      const filename = name + '.zip';
      fileDownload(data, filename);

      return true;
    }

    return false;
  }

  async exportContentWithoutImages(contentId, export_data, updated_on) {
    if (export_data) {
      const { content_updated_at, download_url } = export_data;
      if (updated_on === content_updated_at) {

        if (download_url && typeof download_url === 'string') {
          openLinkInNewTab(download_url);
          return true;
        }

        const isSuccess = await this.usePathToExport(contentId, export_data);
        if (isSuccess) {
          return true;
        }
      }
    }

    const res = await this.#contentRepository.callAsyncFunction(generateExportFile, { contentId });
    if (res.data) {
      const { data } = res.data;

      if (data) {
        const isSuccess = await this.usePathToExport(contentId, data);
        if (isSuccess) {
          return true;
        }
      }
    }

    return false;
  }

  async flagContent(contentId, isFlag, contentData, user) {
    const requestBody = {
      is_flagged: Boolean(isFlag)
    };

    const isSuccess = await this.updateContentByAdmin(contentId, requestBody);

    if (isSuccess && isFlag) {
      const { content_type } = contentData;
      const inbdeCourse = getInbdeCourseName(contentData);

      this.#collaborationService.sendAdminEmailForContentFlagged(content_type, user, inbdeCourse);
    }
    return isSuccess;
  }

  async getContentDocById(docId) {
    const doc = await this.#contentRepository.getDoc(docId);
    return doc;
  }

  getDocListenerById(docId, isEndListener, callback) {
    this.#contentRepository.getDocWithListener(docId, isEndListener, callback);
  }

  async getUserContentOfTypeAndStatus(userId, contentType, contentStatus, limit, startAfter) {
    const content = await this.#contentRepository.getExistingContentCreatedByUserHavingTypeAndStatus(
      userId,
      contentType,
      contentStatus,
      true,
      startAfter,
      limit
    );

    return content;
  }

  async getInprogressUserContentOfType(userId, contentType, limit) {
    try {
      const inprogressStatus = facultyContentStatus.DRAFTS.types;
      const result = await this.getUserContentOfTypeAndStatus(userId, contentType, inprogressStatus, limit, null);

      return result;
    } catch (_error) {
      return [];
    }
  }

  inviteCollaborator(contentId, contentType, collaboratorEmail, user) {
    try {
      if (!collaboratorEmail) {
        throw new Error('Collaborator email is missing');
      }

      const contentLink = getBaseUrl() + customContentRoutes[contentType].editPath.replace('{contentId}', contentId);
      const contentTypeModel = contentTypes[contentType];

      const recipients = { toEmails: [collaboratorEmail] };
      const requestBody = {
        contentTypeModel,
        recipients,
        notificationType: 'invite-new-collaborator',
        links: [contentLink],
        signedInUser: user
      };

      this.#contentRepository.callFunction(notifyUsers, requestBody);
    } catch {}
  }

  notifyAdminsAboutContentSubmission(contentId, user, contentData) {
    const { content_type } = contentData;
    const inbdeCourse = getInbdeCourseName(contentData);

    this.#collaborationService.sendAdminEmailForContentSubmission(contentId, content_type, user, inbdeCourse);
  }

  notifyUsersAboutContentRevision(contentId, contentData, signedInUser) {
    try {
      const { collaboratorIds, content_type, created_by, is_latest_version, last_submitted_by } = contentData;
      if (!is_latest_version) {
        return;
      }
      const usersToNotify = last_submitted_by ? [last_submitted_by] : [...collaboratorIds, created_by];
      this.#collaborationService.sendContentActionEmail(
        'Revised',
        null,
        contentId,
        content_type,
        usersToNotify,
        signedInUser
      );
    } catch (error) {}
  }

  async publishContentByAdmin(contentId, publishInbdeCourses) {
    const { PUBLISHED: publishStatus } = testletTypes;

    if (!isIterableArray(publishInbdeCourses) || isObjectNullOrEmpty(publishInbdeCourses)) {
      throw new Error('INBDE Prep. Course field cannot be empty');
    }

    const processedCourses = publishInbdeCourses.filter(course => course && course['label']);
    const requestBody = {
      is_published: true,
      published_inbdeCourse: processedCourses,
      testlet_type: publishStatus
    };

    return await this.updateContentByAdmin(contentId, requestBody);
  }

  async rejectContentByAdmin(contentId, contentData, user) {
    const { UNDER_REVISION: reviseStatus } = testletTypes;

    const requestBody = {
      is_public: false,
      is_published: false,
      published_inbdeCourse: [],
      testlet_type: reviseStatus
    };

    const isUpdated = await this.updateContentByAdmin(contentId, requestBody);

    if (isUpdated) {
      this.notifyUsersAboutContentRevision(contentId, contentData, user);
    }

    return isUpdated;
  }

  async restoreContentByAdmin(contentId) {
    const requestBody = {
      deleted_on: null,
      is_deleted: false
    };

    return await this.updateContentByAdmin(contentId, requestBody);
  }

  async submitContent(contentId, data, user) {
    const { uid: signedInUserId } = user;

    validateSubmission(data);
    data['testlet_type'] = testletTypes.SUBMITTED_FOR_REVIEW_TO_ADMIN;
    data['last_submitted_by'] = signedInUserId;

    const isSuccess = await this.updateContent(contentId, data);
    if (isSuccess) {
      this.notifyAdminsAboutContentSubmission(contentId, user, data);
    }

    return isSuccess;
  }

  async unpublishContentByAdmin(contentId) {
    const { UNPUBLISHED: unpublishStatus } = testletTypes;

    const requestBody = {
      is_published: false,
      published_inbdeCourse: [],
      testlet_type: unpublishStatus
    };

    return await this.updateContentByAdmin(contentId, requestBody);
  }

  async updateContent(contentId, data) {
    validateSave(data);

    data['content_id'] = contentId;
    data['updated_on'] = getCurrentTimeStamp();
    return await this.#contentRepository.updateDoc(contentId, data);
  }

  async updateContentByAdmin(contentId, requestBody) {
    // confirm if user has admin access?

    if (isObjectNullOrEmpty(requestBody)) {
      throw new Error('Cannot update {content} as no valid action performed');
    }

    const currentTimeStamp = getCurrentTimeStamp();
    requestBody['updated_on'] = currentTimeStamp;

    return await this.#contentRepository.updateDoc(contentId, requestBody);
  }

  uploadFileToStorage(completeFilePath, file, metaData, callback, downloadUrlCallback) {
    this.#contentRepository.uploadFileToStorage(completeFilePath, file, metaData, callback, downloadUrlCallback);
  }

  async usePathToExport(contentId, export_data) {
    const { path } = export_data;

    if (path && typeof path === 'string') {
      const url = await this.#contentRepository.getDownloadUrlFromPath(path);

      if (typeof url === 'string') {
        openLinkInNewTab(url);
        export_data['download_url'] = url;
        await this.#contentRepository.updateDoc(contentId, { export_data: export_data });
        return true;
      }
    }
  }
}
