import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import FalconCardHeader from '../components/common/FalconCardHeader';
import { Button, Card, CardBody, Row, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import rocket from '../assets/img/illustrations/rocket.png';
import FileViewer from 'react-file-viewer';
import QuestionContent from '../components/testlet/expanded/QuestionContent';
import { isIterableArray } from '../helpers/utils';
import { customTestletViewRoute } from '../routes';
import _ from 'lodash';

const file = rocket;
const type = 'png';
const viewRoute = customTestletViewRoute.to;

class TestletCardExpandableContent extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      previewModalFlag: false,
      filePath: file,
      fileType: type
    };

    this.togglePreviewModal = this.togglePreviewModal.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  togglePreviewModal(file) {
    const { previewModalFlag } = this.state;

    if (!previewModalFlag) {
      const fileType = file['content_type'].split('/').pop();
      this._isMounted &&
        this.setState({
          previewModalFlag: true,
          filePath: file.url,
          fileType
        });
    } else {
      this._isMounted && this.setState({ previewModalFlag: false });
    }
  }

  documentPreviewModal() {
    const { fileType, filePath, previewModalFlag } = this.state;
    return (
      <Fragment>
        <Modal isOpen={previewModalFlag} toggle={this.togglePreviewModal} className="modal-lg modal-xl">
          <ModalHeader title={''}>Document Preview</ModalHeader>
          <ModalBody className="vh-50 vh-md-75">
            <FileViewer fileType={fileType} filePath={filePath} onError={this.onError} />
          </ModalBody>
          <ModalFooter>
            <Button onClick={this.togglePreviewModal}>Close</Button>
          </ModalFooter>
        </Modal>
      </Fragment>
    );
  }

  onError(e) {
    console.error(e);
  }

  render() {
    const { data } = this.props;
    const {
      testlet_information,
      patient_information,
      questions,
      published_inbdeCourse,
      published_versions,
      version,
      id
    } = data;
    const { inbdeCourse, collaboratos, testlet_creator } = testlet_information;

    let inbdePrepCourse =
      published_inbdeCourse && !_.isEmpty(published_inbdeCourse) ? published_inbdeCourse : inbdeCourse;
    if (inbdePrepCourse) {
      if (isIterableArray(inbdePrepCourse)) {
        inbdePrepCourse = inbdePrepCourse.map(course => (course && course['label'] ? course['label'] : '')).join('; ');
      } else {
        inbdePrepCourse = inbdePrepCourse['label'] ? inbdePrepCourse['label'] : '';
      }
    } else inbdePrepCourse = '';

    const collaborators = collaboratos
      .map(collab => {
        return collab['label'];
      })
      .join(', ');

    const otherVersions = isIterableArray(published_versions)
      ? published_versions.filter(versions => versions.id !== id)
      : [];

    return (
      <div>
        <Card id="testletInformation" className="mb-2 shadow-none border">
          <FalconCardHeader title="Testlet Information" />
          <CardBody>
            <Row className="ml-0 p-2">
              <div className="col-sm-12 col-lg-3 font-weight-semi-bold">INBDE Prep Course:</div>
              <div className="col-sm-12 col-lg-9">{inbdePrepCourse}</div>
            </Row>
            <Row className="ml-0 p-2">
              <div className="col-sm-12 col-lg-3 font-weight-semi-bold">Testlet Originator:</div>
              <div className="col-sm-12 col-lg-9">{testlet_creator}</div>
            </Row>
            {collaborators && (
              <Row className="ml-0 p-2">
                <div className="col-sm-12 col-lg-3 font-weight-semi-bold">Collaborators:</div>
                <div className="col-sm-12 col-lg-9">{collaborators}</div>
              </Row>
            )}
            <Row className="ml-0 p-2">
              <div className="col-sm-12 col-lg-3 font-weight-semi-bold">Testlet Version:</div>
              <div className="col-sm-12 col-lg-9">{version}</div>
            </Row>
            {isIterableArray(otherVersions) && (
              <Row className="ml-0 p-2">
                <div className="col-sm-12 col-lg-3 font-weight-semi-bold">Other Version:</div>
                <div className="col-sm-12 col-lg-9">
                  {otherVersions.map(version => {
                    return (
                      <div key={version.id}>
                        <a
                          className="m-0 fs-0"
                          href={viewRoute.replace('{testletId}', version.id)}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Version {version.version} - Submitted by{' '}
                          {version.name_of_user_submitted ? version.name_of_user_submitted : testlet_creator}
                        </a>
                      </div>
                    );
                  })}
                </div>
              </Row>
            )}
          </CardBody>
        </Card>
        <Card id="patientInformation" className="mb-2 shadow-none border">
          <FalconCardHeader title="Patient Information" />
          <CardBody>
            <Row className="ml-0 p-2">
              <div className="col-sm-12 col-lg-3 font-weight-semi-bold">About the Patient:</div>
              <div className="col-sm-12 col-lg-9">{patient_information['about_patient']}</div>
            </Row>
            <Row className="ml-0 p-2">
              <div className="col-sm-12 col-lg-3 font-weight-semi-bold">Background / Patient History:</div>
              <div className="col-sm-12 col-lg-9">{patient_information['patient_history']}</div>
            </Row>
            <Row className="ml-0 p-2">
              <div className="col-sm-12 col-lg-3 font-weight-semi-bold">Chief Complaint:</div>
              <div className="col-sm-12 col-lg-9">{patient_information['patient_complaint']}</div>
            </Row>
            <Row className="ml-0 p-2">
              <div className="col-sm-12 col-lg-3 font-weight-semi-bold">Current Findings:</div>
              <div className="col-sm-12 col-lg-9">{patient_information['current_findings']}</div>
            </Row>
            <Row className="ml-0 p-2">
              <div className="col-sm-12 col-lg-3 font-weight-semi-bold">Patient Images:</div>
              <div className="col-sm-12 col-lg-9">
                {patient_information['patient_images'].map((image, index) => {
                  return (
                    <div className="mr-4" key={index}>
                      <FontAwesomeIcon icon="paperclip" transform="grow-2" className="d-inline mr-2 text-primary" />
                      <Button
                        color="link primary"
                        className="p-0 font-weight-bold"
                        onClick={this.togglePreviewModal.bind(this, image)}
                      >
                        {image['file_name']}
                      </Button>
                    </div>
                  );
                })}
              </div>
            </Row>
          </CardBody>
        </Card>
        <Card id="questionList" className="mb-2 shadow-none border">
          <FalconCardHeader title="Questions" />
          <CardBody>
            {questions.map((question, index) => (
              <QuestionContent
                key={question.id}
                number={index + 1}
                question={question}
                togglePreviewModal={this.togglePreviewModal}
              />
            ))}
          </CardBody>
        </Card>
        {this.documentPreviewModal()}
      </div>
    );
  }
}

TestletCardExpandableContent.propTypes = {
  data: PropTypes.object
};

export default TestletCardExpandableContent;
