import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import TestletCardExpandableContent from '../widgets/TestletCardExpandableContent';
import SpinnerModal from '../components/common/SpinnerModal';
import { UserContext } from '../Contexts';
import TestletService from '../services/Testlet/TestletService';
import { getStatusDetails, getPublishStatusBadge, getUnpublishStatusBadge } from '../helpers/inbdeUtils';
import { Row } from 'reactstrap';
import TypesBadge from '../widgets/TypeBadge';
import classNames from 'classnames';

const errorPageUrl = '/errors/404';
const isTestletOfTypePublic = type => type === '3' || type === '4' || type === '5';
const isTestletVieweableByUser = (testlet, user) => {
  const { is_deleted, testlet_type, created_by, collaboratorIds, version } = testlet;
  const { access_type, uid } = user;
  const isAdmin = access_type === 'admin';

  if (isAdmin) {
    return true;
  }

  if (is_deleted) {
    return false;
  }

  if (created_by === uid || collaboratorIds.includes(uid)) {
    return true;
  }

  if (isTestletOfTypePublic(testlet_type) || version > 0) {
    return true;
  }

  return false;
};
const displayTypeBadge = testlet => {
  const { id, is_published, testlet_type } = testlet;

  const isTestletApproved = isTestletOfTypePublic(testlet_type);
  const typeBadge = getStatusDetails(testlet_type.toString());
  const publishBadge = is_published ? getPublishStatusBadge() : getUnpublishStatusBadge();

  return (
    <div className="col-12 col-lg-6">
      <Row>
        {isTestletApproved && (
          <h5 className="cursor-pointer mr-2 mb-2 mb-md-0 ml-lg-auto ml-2">
            <TypesBadge details={publishBadge} testletId={id + '-published'} />
          </h5>
        )}
        <h5
          className={classNames('cursor-pointer mb-0 ml-2 mr-4', {
            'ml-lg-0': isTestletApproved,
            'ml-lg-auto': !isTestletApproved
          })}
        >
          <TypesBadge details={typeBadge} testletId={id} />
        </h5>
      </Row>
    </div>
  );
};

class ViewTestletPage extends Component {
  _isMounted = false;
  testletService = new TestletService();

  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      testlet: null,
      pageTitle: 'View Testlet'
    };
  }

  componentDidMount() {
    this._isMounted = true;
    this.fetchTestlet();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  async fetchTestlet() {
    const { testletId } = this.props;
    const { user } = this.context;

    // fetch testlet and check if user can view it
    const data = await this.testletService.getTestlet(testletId);
    if (data && isTestletVieweableByUser(data, user)) {
      const { testlet_information } = data;
      const { testlet_title } = testlet_information;

      data['id'] = testletId;
      this._isMounted && this.setState({ testlet: data, pageTitle: testlet_title });
    }

    this._isMounted && this.setState({ isLoading: false });
  }

  render() {
    const { testlet, isLoading, pageTitle } = this.state;

    return (
      <Fragment>
        {isLoading ? (
          <SpinnerModal background={'bg-black opacity-50'} />
        ) : (
          <div className="min-vh-75 min-vh-lg-100">
            <div className="mb-lg-4 mb-2 border-bottom border-500">
              <div className="pl-2">
                <div className="pr-2 pt-2 pb-2">
                  <div className="title view-testlet-page-title pr-2 pt-2 pb-2">
                    <Row className="pl-3 pr-2 pt-3">
                      <div className="d-flex col-12 col-lg-6">
                        <h4 className="mb-2 mb-lg-0 mt-auto text-truncate">{pageTitle}</h4>
                      </div>
                      {testlet && displayTypeBadge(testlet)}
                    </Row>
                  </div>
                </div>
              </div>
            </div>
            <div className="ml-lg-2">
              {testlet ? <TestletCardExpandableContent data={testlet} /> : <Redirect to={errorPageUrl} />}
            </div>
          </div>
        )}
      </Fragment>
    );
  }
}

ViewTestletPage.contextType = UserContext;

ViewTestletPage.propTypes = {
  testletId: PropTypes.string.isRequired
};

export default ViewTestletPage;
