import React, { Component, Fragment } from 'react';
import MemberAccessControl from '../widgets/MemberAccessControl';

class ManageAccessPage extends Component {
  render() {
    return (
      <Fragment>
        <div className="min-vh-75 min-vh-lg-100">
          <div className="mb-lg-4 mb-2 border-bottom border-400">
            <div className="pl-2">
              <div className="mb-lg-2 p-2">
                <div className="title access-page-title pr-2 pt-2 pb-2">
                  <h3>Access Management</h3>
                </div>
              </div>
            </div>
          </div>
          <div className="ml-lg-2">
            <div className="manage-member-access-section">
              <MemberAccessControl />
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default ManageAccessPage;
