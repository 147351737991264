import React, { Component, Fragment } from 'react';
import { CustomInput, Button, FormGroup, Label, Input, Row } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import UploadFile from '../components/files/UploadFile';
import { TestletContext } from '../Contexts';
import _ from 'lodash';

const baseStorageDir = 'testlets';
const fileTypes = ['image/*', 'application/pdf'];

class AnswerChoice extends Component {
  prevAnswerModel = {};
  updateAnswerModel = {};

  constructor(props) {
    super(props);

    this.state = {
      answer: {},
      attachmentToAddIn: '',
      isDocumentModalOpen: false,
      id: '',
      answer_choice: {
        text: '',
        attachments: []
      },
      explanation: {
        text: '',
        attachments: []
      },
      isCorrect: false,
      isModalOpen: false,
      userId: null,
      testletId: null
    };

    this.deleteAnswer = this.deleteAnswer.bind(this);
    this.setFileModel = this.setFileModel.bind(this);
    this.toggleCorrectAnswer = this.toggleCorrectAnswer.bind(this);
    this.toggleModal = this.toggleModal.bind(this);
  }

  componentDidMount() {
    const { user, testletId } = this.context;
    this.setState({ userId: user.uid, testletId });

    this.updateStateFromProps();
  }

  componentDidUpdate(prevProps) {
    if (
      !_.isEqual(this.props.answerOptions, this.state.answer) &&
      !_.isEqual(prevProps.answerOptions, this.props.answerOptions)
    ) {
      this.updateStateFromProps();
    }

    if (prevProps.isTriggerAjax !== this.props.isTriggerAjax) {
      this.props.isTriggerAjax && this.submitAnswerInformation();
    }
  }

  deleteAnswer(e) {
    e.preventDefault();

    this.props.deleteAnswerChoice(this.state.id);
  }

  toggleCorrectAnswer(e) {
    const { id, isCorrect } = this.state;

    this.setState(state => ({ isCorrect: !state.isCorrect }));

    this.props.setCorrectAnswer(id, !isCorrect);
    this.props.isBlocking();
  }

  toggleModal(key) {
    this.setState(state => ({ isModalOpen: !state.isModalOpen, attachmentToAddIn: key }));
  }

  setFileModel(fileModel) {
    const { addComment } = this.props;
    const { attachmentToAddIn, explanation, answer_choice } = this.state;

    if (attachmentToAddIn === 'answer_explanation') {
      const newExplanation = explanation;
      newExplanation['attachments'] = [...explanation.attachments, fileModel];

      this.setState({ explanation: newExplanation }, () => {
        this.toggleModal(null);
        this.props.isBlocking();
      });
    } else if (attachmentToAddIn === 'answer_choice') {
      const newAnswerChoice = answer_choice;
      newAnswerChoice['attachments'] = [...answer_choice.attachments, fileModel];

      this.setState({ answer_choice: newAnswerChoice }, () => {
        this.toggleModal(null);
        this.props.isBlocking();
      });
    }
    addComment(fileModel.file_name, true, true, false);
  }

  submitAnswerInformation() {
    const { setAnswerInformation, updateAnswerInfoForComments } = this.props;
    const { id, answer_choice, explanation, isCorrect } = this.state;

    const content = {
      id,
      answer_choice: {
        text: answer_choice.text.trim(),
        attachments: answer_choice.attachments
      },
      explanation: {
        text: explanation.text.trim(),
        attachments: explanation.attachments
      },
      isCorrect
    };

    if (!_.isEmpty(this.updateAnswerModel)) {
      updateAnswerInfoForComments(this.updateAnswerModel, id);
      this.prevAnswerModel = JSON.parse(JSON.stringify(content));
    }

    this.updateAnswerModel = {};
    setAnswerInformation(content);
  }

  updateStateFromProps() {
    const { answerOptions: answer } = this.props;
    const prevAnswerState = {
      answer: answer,
      id: answer.id,
      answer_choice: answer.answer_choice,
      explanation: answer.explanation,
      isCorrect: answer.isCorrect
    };

    this.setState({ ...prevAnswerState });

    delete prevAnswerState['answer'];

    this.prevAnswerModel = JSON.parse(JSON.stringify(prevAnswerState));
  }

  render() {
    const { toggleImagePreviewModal, isUserQuestionCreator, questionNumber: questionId, isBlocking } = this.props;
    const { id, answer_choice, attachmentToAddIn, explanation, isCorrect, userId, testletId, isModalOpen } = this.state;
    const uploadSubDir = testletId + '/question_' + (questionId + 1) + '/answers/' + attachmentToAddIn + '_' + (id + 1);

    return (
      <Fragment>
        <UploadFile
          allowedFileTypes={fileTypes}
          baseStoragePath={baseStorageDir}
          creatorId={userId}
          isModalOpen={isModalOpen}
          parentRefId={uploadSubDir}
          setFileModel={this.setFileModel}
          toggleModal={this.toggleModal}
        />
        <div className="border-bottom">
          <Row className="mt-2">
            <div className="col-lg-5 col-12">
              <FormGroup>
                <Label for="exampleName" className="fs-0">
                  Answer choice {id + 1} <strong className="text-danger">&#42;</strong>
                </Label>
                <Input
                  type="textarea"
                  name="answer-choice"
                  onChange={e => {
                    const newAnswerChoice = answer_choice;
                    newAnswerChoice.text = e.target.value;
                    this.setState({ answer_choice: newAnswerChoice });
                    const oldValue = this.prevAnswerModel['answer_choice'].text;
                    this.updateAnswerModel['answer_choice'] = {
                      fieldName: 'Answer Choice ' + (id + 1),
                      newValue: e.target.value,
                      oldValue
                    };
                    isBlocking();
                  }}
                  value={answer_choice.text}
                  disabled={!isUserQuestionCreator}
                  className={!isUserQuestionCreator ? 'bg-testlet-form-disabled' : ''}
                  required
                />
                <p className="mt-1 mb-0">
                  <Button
                    className="pl-0 fs--1"
                    color="link"
                    onClick={this.toggleModal.bind(this, 'answer_choice')}
                    disabled={!isUserQuestionCreator}
                  >
                    Upload an image
                  </Button>
                </p>
                <div className="d-flex">
                  <div className="mr-auto align-self-center">
                    {answer_choice.attachments.map((item, index) => {
                      return (
                        <div className="mb-1" key={index}>
                          <FontAwesomeIcon
                            icon="check-circle"
                            transform="grow-0"
                            className="d-inline mr-2 text-primary"
                          />
                          <Button
                            color="link primary"
                            className="p-0 fs--1 font-weight-bold"
                            onClick={() => toggleImagePreviewModal(item, 'answer_choice')}
                          >
                            {item.file_name}
                          </Button>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </FormGroup>
            </div>
            <div className="col-lg-5 col-8">
              <FormGroup>
                <Label for="exampleName" className="fs-0">
                  Explanation
                </Label>
                <Input
                  type="textarea"
                  name="name"
                  onChange={e => {
                    const newExplanation = explanation;
                    newExplanation.text = e.target.value;
                    this.setState({ explanation: newExplanation });
                    const oldValue = this.prevAnswerModel['explanation'].text;
                    this.updateAnswerModel['explanation'] = {
                      fieldName: 'Explanation ' + (id + 1),
                      newValue: e.target.value,
                      oldValue
                    };
                    isBlocking();
                  }}
                  value={explanation.text}
                  disabled={!isUserQuestionCreator}
                  className={!isUserQuestionCreator ? 'bg-testlet-form-disabled' : ''}
                />
                <p className="mb-0">
                  <Button
                    className="pl-0 fs--1"
                    color="link"
                    onClick={this.toggleModal.bind(this, 'answer_explanation')}
                    disabled={!isUserQuestionCreator}
                  >
                    Upload an image
                  </Button>
                </p>
                <div className="d-flex">
                  <div className="mr-auto align-self-center">
                    {explanation.attachments.map((item, index) => {
                      return (
                        <div className="mb-1" key={index}>
                          <FontAwesomeIcon
                            icon="check-circle"
                            transform="grow-0"
                            className="d-inline mr-2 text-primary"
                          />
                          <Button
                            color="link primary"
                            className="p-0 fs--1 font-weight-bold"
                            onClick={() => toggleImagePreviewModal(item, 'answer_explanation')}
                          >
                            {item.file_name}
                          </Button>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </FormGroup>
            </div>
            <div className="col-lg-1 col-2">
              <FormGroup className="form-check pl-1">
                <Row className="mb-2">
                  <Label className="mr-auto ml-auto" for="exampleName">
                    Correct
                  </Label>
                </Row>
                <Row>
                  <CustomInput
                    type="switch"
                    id={'isCorrectSwitch' + id + questionId}
                    name="customSwitch"
                    className="mr-auto ml-auto"
                    onChange={this.toggleCorrectAnswer}
                    checked={isCorrect}
                    value={isCorrect}
                    disabled={!isUserQuestionCreator}
                  />
                </Row>
              </FormGroup>
            </div>
            {id > 1 && (
              <div className="col-lg-1 col-2">
                <Row className="mb-2">
                  <Label className="invisible mr-auto ml-auto" for="exampleName">
                    Remove
                  </Label>
                </Row>
                <Row>
                  <Button
                    color="link"
                    className="btn-reveal py-0 px-2 mr-auto ml-auto"
                    onClick={this.deleteAnswer}
                    disabled={!isUserQuestionCreator}
                  >
                    <FontAwesomeIcon color="red" icon="times" />
                  </Button>
                </Row>
              </div>
            )}
          </Row>
        </div>
      </Fragment>
    );
  }
}

AnswerChoice.contextType = TestletContext;

AnswerChoice.propTypes = {
  questionNumber: PropTypes.number,
  answerOptions: PropTypes.object,
  setAnswerInformation: PropTypes.func,
  isTriggerAjax: PropTypes.bool,
  isBlocking: PropTypes.func,
  isUserQuestionCreator: PropTypes.bool,
  deleteAnswerChoice: PropTypes.func,
  setCorrectAnswer: PropTypes.func,
  toggleImagePreviewModal: PropTypes.func,
  updateAnswerInfoForComments: PropTypes.func,
  addComment: PropTypes.func
};

export default AnswerChoice;
