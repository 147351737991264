import React from 'react';

const actionFormatter = (dataField, { id, email, is_active, access_type }) => {
  // return <ManageUserAccessTableActions id={id} email={email} is_active={is_active} access_type={access_type} />;
  return <div />;
};

export const columns = [
  {
    dataField: 'course_year',
    text: 'Course Year',
    sort: true,
    classes: 'align-middle text-truncate'
  },
  {
    dataField: 'course_id',
    text: 'Course Id',
    sort: true,
    classes: 'align-middle text-truncate'
  },
  {
    dataField: 'course_name',
    text: 'Course Name',
    sort: true,
    classes: 'align-middle text-truncate'
  },
  {
    dataField: 'curriculum',
    text: 'Curriculum',
    sort: true,
    classes: 'align-middle text-truncate'
  },
  {
    dataField: 'action',
    text: '',
    formatter: actionFormatter,
    classes: 'align-middle'
  }
];
