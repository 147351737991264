/* eslint-disable react/display-name */
import React, { Fragment, createRef, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import paginationFactory, { PaginationProvider } from 'react-bootstrap-table2-paginator';
import BootstrapTable from 'react-bootstrap-table-next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Col, Row, Spinner } from 'reactstrap';
import PaginationActions from '../../helpers/PaginationActions';
import { tableWaitLoadTime } from '../../helpers/constants';

const paginationActions = new PaginationActions();

// eslint-disable-next-line react/prop-types
const SelectRowInput = ({ indeterminate, rowIndex, ...rest }) => (
  <div className="custom-control custom-checkbox">
    <input
      className="custom-control-input"
      {...rest}
      onChange={() => {}}
      ref={input => {
        if (input) input.indeterminate = indeterminate;
      }}
    />
    <label className="custom-control-label" />
  </div>
);

const selectRow = onSelect => ({
  mode: 'checkbox',
  clickToSelect: false,
  // eslint-disable-next-line react/prop-types
  selectionHeaderRenderer: ({ mode, ...rest }) => <SelectRowInput type="checkbox" {...rest} />,
  // eslint-disable-next-line react/prop-types
  selectionRenderer: ({ mode, ...rest }) => <SelectRowInput type={mode} {...rest} />,
  onSelect: onSelect,
  onSelectAll: onSelect
});

const waitForData = tableWaitLoadTime;

const INBDETable = ({ setIsSelected, data, columns }) => {
  const [hideLoader, setHideLoader] = useState(false);
  let table = createRef();
  const mountedRef = useRef(null);

  const tableLoader = () => {
    return (
      <div className="d-flex min-vh-50 align-items-center justify-content-center">
        {hideLoader ? <p>No results found</p> : <Spinner color="primary" className="avatar-3xl" />}
      </div>
    );
  };

  const onSelect = () => {
    setImmediate(() => {
      mountedRef.current && setIsSelected(!!table.current.selectionContext.selected.length);
    });
  };

  const options = {
    custom: true,
    sizePerPage: 20,
    totalSize: data.length
  };

  useEffect(() => {
    mountedRef.current = true;
    if (data && data.length) {
      mountedRef.current && setHideLoader(true);
    }
    return () => {
      mountedRef.current = false;
    };
  }, [data]);

  setTimeout(() => {
    mountedRef.current && setHideLoader(true);
  }, waitForData);

  return (
    <PaginationProvider pagination={paginationFactory(options)}>
      {({ paginationProps, paginationTableProps }) => {
        const lastIndex = paginationProps.page * paginationProps.sizePerPage;

        return (
          <Fragment>
            <div className="table-responsive">
              <BootstrapTable
                ref={table}
                bootstrap4
                keyField="id"
                data={data}
                columns={columns}
                selectRow={selectRow(onSelect)}
                bordered={false}
                classes="table-dashboard table-sm fs--1 border-bottom mb-0"
                rowClasses="btn-reveal-trigger"
                headerClasses="bg-200 text-900"
                noDataIndication={() => tableLoader()}
                {...paginationTableProps}
              />
            </div>
            <Row noGutters className="px-1 py-3">
              <Col xs="auto" className="ml-auto">
                <Button
                  color="falcon-default"
                  size="sm"
                  onClick={paginationActions.handleFirstPage(paginationProps)}
                  disabled={paginationProps.page === 1}
                >
                  <FontAwesomeIcon icon="angle-double-left" />
                </Button>
                <Button
                  color="falcon-default"
                  size="sm"
                  onClick={paginationActions.handlePrevPage(paginationProps)}
                  disabled={paginationProps.page === 1}
                >
                  <FontAwesomeIcon icon="angle-left" />
                </Button>
                {paginationActions
                  .getPaginationArray(paginationProps.totalSize, paginationProps.sizePerPage, paginationProps.page)
                  .map(pageNo => (
                    <Button
                      color={paginationProps.page === pageNo ? 'falcon-primary' : 'falcon-default'}
                      size="sm"
                      className="ml-2"
                      onClick={() => paginationProps.onPageChange(pageNo)}
                      key={pageNo}
                    >
                      {pageNo}
                    </Button>
                  ))}
                <Button
                  color="falcon-default"
                  size="sm"
                  className="ml-2"
                  onClick={paginationActions.handleNextPage(paginationProps)}
                  disabled={lastIndex >= paginationProps.totalSize}
                >
                  <FontAwesomeIcon icon="angle-right" />
                </Button>
                <Button
                  color="falcon-default"
                  size="sm"
                  className="ml-2"
                  onClick={paginationActions.handleLastPage(
                    paginationProps,
                    paginationProps.totalSize,
                    paginationProps.sizePerPage
                  )}
                  disabled={lastIndex >= paginationProps.totalSize}
                >
                  <FontAwesomeIcon icon="angle-double-right" />
                </Button>
              </Col>
            </Row>
          </Fragment>
        );
      }}
    </PaginationProvider>
  );
};

INBDETable.propTypes = {
  value: PropTypes.any,
  data: PropTypes.array,
  setIsSelected: PropTypes.func,
  columns: PropTypes.array
};
INBDETable.defaultProps = { value: `INBDETable`, data: [], setIsSelected: () => {}, columns: [] };

export default INBDETable;
