import * as firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/functions';
import 'firebase/storage';
import { isProd } from '../helpers/inbdeUtils';

// set firebase functions regions (us-east1 for prod and us-central1 for qa)
const isProdEnv = isProd();
const environmentSuffix = isProdEnv ? 'PROD' : 'QA';
const FIREABSE_APP_NAME = process.env['REACT_APP_FIREBASE_PROJECT_ID_' + environmentSuffix];
const FIREBASE_FUNCTIONS_REGION = process.env['REACT_APP_FIREBASE_REGION_' + environmentSuffix] || 'us-central1';

export class BaseRepository {
  #firestore;
  #firestoreCollection;
  #functions;
  #storage;
  #unsubscribe;

  constructor(collectionName) {
    this.#firestore = firebase.firestore();
    this.#firestoreCollection = this.#firestore.collection(collectionName);
    this.#functions = firebase.app().functions(FIREBASE_FUNCTIONS_REGION);
    this.#storage = firebase.storage();
  }

  async callAsyncFunction(functionName, requestBody) {
    const callableFunction = this.#functions.httpsCallable(functionName);
    return await callableFunction(requestBody);
  }

  callFunction(functionName, requestBody) {
    const callableFunction = this.#functions.httpsCallable(functionName);
    callableFunction(requestBody);
  }

  async checkIfFileExists(filePath) {
    let isExists = false;
    let err = null;
    try {
      await this.#storage.ref(filePath).getMetadata();
      isExists = true;
    } catch (error) {
      if (error.code === 'storage/object-not-found') {
        isExists = false;
      } else {
        isExists = null;
        err = error;
      }
    }

    return { isExists, err };
  }

  async createDoc(document) {
    try {
      const docRef = await this.#firestoreCollection.add(document);
      return docRef.id;
    } catch (error) {
      throw Error(error);
    }
  }

  async createDocWithId(documentId, document) {
    try {
      await this.#firestoreCollection.doc(documentId).set(document);
      return documentId;
    } catch (error) {
      throw Error(error);
    }
  }

  async deleteDocWithId(documentId) {
    await this.#firestoreCollection.doc(documentId).delete();
  }

  async deleteFileFromStorageWithUrl(url) {
    const ref = this.#storage.refFromURL(url);
    await ref.delete();
  }

  async executeQuery(query, limit, startAfter) {
    if (startAfter) {
      query = query.startAfter(startAfter);
    }

    if (limit) {
      query = query.limit(limit);
    }

    const result = await query.get();
    return result.docs;
  }

  createCloudFunctionName(functionName) {
    const cloudFunctionUrl = "https://{region}-{appName}.cloudfunctions.net/{functionName}";
    return cloudFunctionUrl.replace('{region}', FIREBASE_FUNCTIONS_REGION)
      .replace('{appName}', FIREABSE_APP_NAME).replace('{functionName}', functionName);
  }

  async getDoc(id) {
    try {
      const doc = await this.#firestoreCollection.doc(id).get();
      if (doc.exists) {
        return doc.data();
      }
    } catch (_error) {}

    return null;
  }

  getDocWithListener(docId, isEnd, callback) {
    this.#unsubscribe && this.#unsubscribe();

    if (docId) {
      this.unsubscribe = this.#firestoreCollection.doc(docId).onSnapshot(
        doc => {
          callback(doc.data());
        },
        function() {}
      );
    }

    isEnd && this.unsubscribe && this.unsubscribe();
  }

  async getDownloadUrlFromPath(path) {
    try {
      const ref = this.#storage.ref(path);
      const downloadUrl = await ref.getDownloadURL();

      return downloadUrl;
    } catch {
      return null;
    }
  }

  async updateDoc(id, updateRequest) {
    try {
      await this.#firestoreCollection.doc(id).update(updateRequest);
      return true;
    } catch (_error) {
      return false;
    }
  }

  uploadFileToStorage(filePath, fileContent, additionalInfo, progressCallback, downloadUrlCallback) {
    try {
      const ref = this.#storage.ref(filePath);
      const metaData = {
        customMetadata: additionalInfo
      };
      const uploadTask = ref.put(fileContent, metaData);

      // Listen for state changes, errors, and completion of the upload.
      uploadTask.on(
        firebase.storage.TaskEvent.STATE_CHANGED, // or 'state_changed'
        snapshot => {
          // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
          const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          progressCallback(progress);
        },
        () => {
          // A full list of error codes is available at
          // https://firebase.google.com/docs/storage/web/handle-errors
          downloadUrlCallback(null);
        },
        () => {
          // Upload completed successfully, now we can get the download URL
          uploadTask.snapshot.ref
            .getDownloadURL()
            .then(downloadURL => {
              // return the download url for the file
              downloadUrlCallback(downloadURL);
            })
            .catch(() => {
              downloadUrlCallback(null);
            });
        }
      );
    } catch {
      downloadUrlCallback(null);
    }
  }
}
