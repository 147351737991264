import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { UncontrolledDropdown, DropdownToggle, DropdownMenu } from 'reactstrap';
import SearchOptions from '../search/SearchOptions';

class InlineFilter extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = { dropdownOpen: false };

    this.handleChange = this.handleChange.bind(this);
    this.onToggle = this.onToggle.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;

    this.setState({ dropdownOpen: false });
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  handleChange(id, selected) {
    const { keyString: key, onChange } = this.props;

    const updateId = id + (key === 'cc' ? 1 : 0);
    onChange(key, updateId, selected);
  }

  onToggle() {
    this._isMounted && this.setState(state => ({ dropdownOpen: !state.dropdownOpen }));
  }

  render() {
    const { label, options, theme } = this.props;
    const { dropdownOpen } = this.state;
    return (
      <UncontrolledDropdown isOpen={dropdownOpen} toggle={this.onToggle} className="p-2">
        <DropdownToggle caret color={theme ? theme : 'falcon-primary'} data-toggle="dropdown">
          {label}
        </DropdownToggle>
        <DropdownMenu className="p-2 max-vh-25 max-vw-50 overflow-scroll testlet-filter-options">
          <SearchOptions searchOptions={options} onChange={this.handleChange} />
        </DropdownMenu>
      </UncontrolledDropdown>
    );
  }
}

InlineFilter.propTypes = {
  keyString: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  theme: PropTypes.string
};

export default InlineFilter;
