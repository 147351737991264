import React from 'react';
import PropTypes from 'prop-types';
import { CardBody } from 'reactstrap';
import ChatSidebar from './sidebar/ChatSidebar';
import ChatContent from './content/ChatContent';
import Flex from '../common/Flex';
import chatOptions from '../../data/chat/options';

const Chat = ({ option }) => {
  const isShowChatSidebar = chatOptions[option].isShowChatSidebar;
  const isShowChatContent = chatOptions[option].isShowChatContent;

  return (
    <div className="card-chat">
      <CardBody tag={Flex} className="p-0 h-100">
        {isShowChatSidebar && <ChatSidebar />}
        {isShowChatContent && <ChatContent />}
      </CardBody>
    </div>
  );
};

Chat.propTypes = { option: PropTypes.string.isRequired };

export default Chat;
