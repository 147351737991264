import React, { Component } from 'react';
import PropTypes from 'prop-types';
import InlineFilter from './InlineFilter';

class InlineFilterWrapper extends Component {
  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(key, id, selected) {
    const { onChange } = this.props;
    onChange(key, id, selected);
  }

  render() {
    const { filterOptions } = this.props;
    return filterOptions.map((filterOption, index) => {
      const { key, label, options, theme } = filterOption;
      return (
        <InlineFilter
          key={index}
          options={options}
          keyString={key}
          label={label}
          onChange={this.handleChange}
          theme={theme || ''}
        />
      );
    });
  }
}

InlineFilterWrapper.propTypes = {
  filterOptions: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired
};

export default InlineFilterWrapper;
