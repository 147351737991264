import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { notFoundError } from '../routes';
import { Redirect, Route, Switch } from 'react-router';
import QuestionForm from '../pages/QuestionForm';
import ViewQuestionPage from '../pages/ViewQuestionPage';
import { UserContext } from '../Contexts';
import featureToggles from '../services/Feature/toggles';
import { isFeatureActive } from '../helpers/inbdeUtils';

const validQuestionActions = { edit: 'edit', view: 'view' };
const QuestionRoutes = ({ action, contentId }) => {
  const [isEditQuestion, setIsEditQuestion] = useState(false);
  const [isRedirectToError, setIsRedirectToError] = useState(false);
  const [isViewQuestion, setIsViewQuestion] = useState(false);
  const { toggles, user } = useContext(UserContext);

  useEffect(() => {
    const { email } = user;
    if (!isFeatureActive(featureToggles.isStandAloneQuestionsEnabled, toggles, null, email)) {
      setIsRedirectToError(true);
      return;
    }

    switch (validQuestionActions[action]) {
      case 'edit':
        setIsEditQuestion(true);
        break;
      case 'view':
        setIsViewQuestion(true);
        break;
      default:
        setIsRedirectToError(true);
        break;
    }
  }, [action, toggles, user]);

  return (
    <Switch>
      {isEditQuestion && <Route render={props => <QuestionForm {...props} questionId={contentId} />} />}
      {isViewQuestion && <Route render={props => <ViewQuestionPage {...props} questionId={contentId} />} />}
      {isRedirectToError && <Redirect to={notFoundError} />}
    </Switch>
  );
};

QuestionRoutes.propTypes = {
  contentId: PropTypes.string.isRequired,
  action: PropTypes.string.isRequired
};

export default QuestionRoutes;
