import React from 'react';
import PropTypes from 'prop-types';
import ChatProvider from './ChatProvider';
import Chat from './Chat';

const ChatWrapper = ({ options }) => {
  const param = options.params ? options.params : 'default';

  return (
    <ChatProvider options={options}>
      <Chat option={param} />
    </ChatProvider>
  );
};

ChatWrapper.propTypes = { options: PropTypes.object.isRequired };

export default ChatWrapper;
