export const accessTypes = {
  ADMIN: 'admin',
  FACULTY: 'faculty'
};

export const accessTypeLabels = {
  admin: 'Admin',
  faculty: 'Faculty'
};

export const allAccessTypesArray = [accessTypes.ADMIN, accessTypes.FACULTY];
