import { personalContentPath } from '../../routes';

const TestletTypes = {
  DRAFTS: {
    key: 'DRAFTS',
    types: [1, '1', '1A', '1B', '2B'],
    linkTo: personalContentPath + '/drafts'
  },
  PENDING: {
    key: 'PENDING',
    types: ['2', '2A'],
    linkTo: personalContentPath + '/pending'
  },
  COLLAB_REVIEW: {
    key: 'COLLAB_REVIEW',
    types: ['2C'],
    linkTo: personalContentPath + '/collaboration-review'
  },
  UNPUBLISHED: {
    key: 'UNPUBLISHED',
    types: ['3', '4'],
    linkTo: personalContentPath + '/unpublished'
  },
  PUBLISHED: {
    key: 'PUBLISHED',
    types: ['5'],
    linkTo: personalContentPath + '/published'
  }
};

export default TestletTypes;
