import React from 'react';
import PropTypes from 'prop-types';
import TypesBadge from '../TypeBadge';

export const UnpublishedBadge = ({ contentId }) => {
  const className = 'warning';
  const text = 'Unpublished';
  const tooltip = 'This question has not yet been released to students in an INBDE Prep Course';

  const details = { className, text, tooltip };

  return <TypesBadge contentId={contentId + '-unpublished'} details={details} />;
};

UnpublishedBadge.propTypes = {
  contentId: PropTypes.string
};
