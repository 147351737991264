import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import { CloseButton, Fade } from '../components/common/Toast';
import { isProd } from '../helpers/inbdeUtils';
import LandingPage from '../pages/LandingPage';
import { prodCurrentUrl, prodOldUrl, qaCurrentUrl, qaOldUrl } from '../routes';

const handleRedirect = () => {
  const currentUrl = window.location.hostname;
  const currentPath = window.location.pathname;
  const isProdEnv = isProd();

  if (isProdEnv && prodOldUrl.includes(currentUrl)) {
    window.location = 'https://' + prodCurrentUrl + currentPath;
  }

  if (!isProdEnv && qaOldUrl.includes(currentUrl)) {
    window.location = 'https://' + qaCurrentUrl + currentPath;
  }
};

const Layout = () => {
  handleRedirect();

  return (
    <Router fallback={<span />}>
      <Switch>
        <Route path="/" component={LandingPage} />
      </Switch>
      <ToastContainer transition={Fade} closeButton={<CloseButton />} position={toast.POSITION.BOTTOM_LEFT} />
    </Router>
  );
};

export default Layout;
