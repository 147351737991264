import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Form, Label, Input } from 'reactstrap';

class AdminFacultyCommentActivity extends Component {
  constructor(props) {
    super(props);

    this.state = { value: '' };

    this.handleChange = this.handleChange.bind(this);
    this.submitDialog = this.submitDialog.bind(this);
    this.toggleModal = this.toggleModal.bind(this);
  }

  handleChange(event) {
    this.setState({ value: event.target.value });
  }

  submitDialog(event) {
    event.preventDefault();

    const comment = this.state.value;
    this.props.dialogDetails.dialogActionButton(comment);
    this.setState({ value: '' });
  }

  toggleModal() {
    this.props.dialogDetails.toggleDialogFunction();
  }

  render() {
    const dialogDetails = this.props.dialogDetails;
    const comments = dialogDetails.comments;

    return (
      <Modal id={dialogDetails.id} isOpen={dialogDetails.dialogToggleFlag} toggle={this.toggleModal}>
        <ModalHeader toggle={this.toggleModal}>{dialogDetails.title}</ModalHeader>
        <Form onSubmit={this.submitDialog}>
          <ModalBody>
            {comments.map(comment => {
              return (
                <div key={comment.id}>
                  <Label>
                    {comment.from}: {comment.text}
                    <br />
                  </Label>
                </div>
              );
            })}
            <Input
              className="p-2 mb-2"
              draggable={false}
              id={dialogDetails.id}
              name={dialogDetails.id + 'Comment'}
              onChange={this.handleChange}
              placeholder="Please enter your comment..."
              type="textarea"
              value={this.state.value}
              required
            />
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={this.toggleModal}>
              Cancel
            </Button>
            <Button color="primary" type="submit">
              {dialogDetails.buttonText}
            </Button>{' '}
          </ModalFooter>
        </Form>
      </Modal>
    );
  }
}

AdminFacultyCommentActivity.propTypes = {
  dialogDetails: PropTypes.object
};

export default AdminFacultyCommentActivity;
