import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Spinner } from 'reactstrap';

class DraftSubmitDialog extends Component {
  constructor(props) {
    super(props);

    this.handleSubmitDialog = this.handleSubmitDialog.bind(this);
    this.submitTestlet = this.submitTestlet.bind(this);
  }

  handleSubmitDialog() {
    this.props.submitDialog();
  }

  submitTestlet() {
    this.props.submitTestlet();
  }

  render() {
    const { isLoading, submitModal } = this.props;

    return (
      <Modal id="draftModalActions" isOpen={submitModal} toggle={this.handleSubmitDialog}>
        <ModalHeader toggle={this.handleSubmitDialog}>Confirmation</ModalHeader>
        <ModalBody>Are you sure you wish to submit this draft for review?</ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={this.handleSubmitDialog}>
            Cancel
          </Button>
          <Button color="primary" type="submit" onClick={this.submitTestlet} disabled={isLoading}>
            Confirm
            {isLoading && <Spinner size="sm" color="light" className="ml-2" />}
          </Button>{' '}
        </ModalFooter>
      </Modal>
    );
  }
}

DraftSubmitDialog.propTypes = {
  isLoading: PropTypes.bool,
  submitDialog: PropTypes.func,
  submitModal: PropTypes.bool,
  submitTestlet: PropTypes.func
};

export default DraftSubmitDialog;
