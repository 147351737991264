import { adminAllContentPath } from '../../routes';

export const TestletTypes = {
  DRAFTS: {
    key: 'DRAFTS',
    types: [1, '1', '1A', '1B', '2B']
  },
  ALL: {
    key: 'ALL',
    value: 'All Content',
    linkTo: adminAllContentPath + '/all'
  },
  ARCHIVES: {
    key: 'ARCHIVES',
    linkTo: adminAllContentPath + '/archives'
  },
  REJECTED_COLLABS: {
    key: 'REJECTED_COLLABS',
    linkTo: adminAllContentPath + '/rejected-collaborations'
  }
};
