import TestletTypes from '../testlet/my_testlet_types';

const testletStatuses = [
  {
    id: TestletTypes.DRAFTS.types,
    value: 'In Progress',
    linkTo: TestletTypes.DRAFTS.linkTo
  },
  {
    id: TestletTypes.PENDING.types,
    value: 'Under Review',
    linkTo: TestletTypes.PENDING.linkTo
  },
  {
    id: TestletTypes.COLLAB_REVIEW.types,
    value: 'Review Collab',
    linkTo: TestletTypes.COLLAB_REVIEW.linkTo
  },
  {
    id: TestletTypes.UNPUBLISHED.types,
    value: 'Unpublished',
    linkTo: TestletTypes.UNPUBLISHED.linkTo
  },
  {
    id: TestletTypes.PUBLISHED.types,
    value: 'Published',
    linkTo: TestletTypes.PUBLISHED.linkTo
  }
];

export default testletStatuses;
