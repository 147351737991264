import React from 'react';
import PropTypes from 'prop-types';
import TypesBadge from '../TypeBadge';

export const PublishedBadge = ({ contentId }) => {
  const className = 'success';
  const text = 'Published';
  const tooltip = 'This question has been released to students in an INBDE Prep Course';

  const details = { className, text, tooltip };

  return <TypesBadge contentId={contentId + '-published'} details={details} />;
};

PublishedBadge.propTypes = {
  contentId: PropTypes.string
};
