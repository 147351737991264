import React from 'react';
import PropTypes from 'prop-types';
import { organizationName } from '../../helpers/constants';

const AddMemberConfirmationMessage = ({ accessType, isEmailMissingFromList, userName, value }) => (
  <div>
    {isEmailMissingFromList ? (
      <p className="mb-0 p-2">
        The email you submitted: <br />
        <br />
        <strong>{value}</strong>
        <br />
        <br />
        is not present in the {organizationName} faculty list.
        <br />
        <br />
        Are you sure you wish to continue and add this user to the whitelist with <strong>{accessType}</strong> access?
      </p>
    ) : (
      <p className="mb-0 p-2">
        The email you submitted: <br />
        <strong>{value}</strong>
        <br />
        <br /> was found in the {organizationName} faculty list and belongs to:
        <br />
        {userName && (
            <strong>{userName}</strong>
        )}
      </p>
    )}
  </div>
);

AddMemberConfirmationMessage.propTypes = {
  accessType: PropTypes.string.isRequired,
  isEmailMissingFromList: PropTypes.bool.isRequired,
  userName: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired
};

export default AddMemberConfirmationMessage;
